import Markdown from 'react-markdown';
import { Grid } from '@mui/material';
import MainContainer from '../../components/containers/main-container/MainContainer';
import NavigationBar from '../../components/navigations/navigation-bar/NavigationBar';
import { content } from './content.v1';

const TermsAndConditionsPage = () => (
  <MainContainer>
    <NavigationBar />
    <Grid container justifyContent="center">
      <Markdown children={content} />
    </Grid>
  </MainContainer>
);

export default TermsAndConditionsPage;
