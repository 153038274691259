import { useState, useEffect } from 'react';
import {
  Grid, TextField, FormControl, MenuItem,
} from '@mui/material';
import { useFormik } from 'formik';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import { paymentAccountSchema } from './PaymentAccount.schema';
import SimpleButton from '../../../../../../components/buttons/simple-button/SimpleButton';
import { useTranslation } from 'react-i18next';
import { AccountTypeEntity, BankEntity } from '../../../../../../entities';
import usePersonal from '../../../usePersonal';
import SimpleModal from '../../../../../../components/dialogs/SimpleModal/SimpleModal';
import { Select, EditListItem } from '../../../../../../components/inputs';
import useDialog from '../../../../../../hooks/useDialog';
import SimpleDialog from '../../../../../../components/dialogs/SimpleDialog/SimpleDialog';
import { Label } from '../../../../../../components/typographies';
import useAnalytics from '../../../../../../hooks/useAnalytics/useAnalytics';

interface PaymentAccountProps {
  disabled?: boolean;
}

const PaymentAccount = ({ disabled = false }: PaymentAccountProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('my-account');
  const { accountTypes, fetchAccountTypes } = usePersonal();
  const { banks, fetchBanks } = usePersonal();
  const { 
    status,
    error,
    fetchAuthPaymentAccount, 
    updateAuthPaymentAccount,
    paymentAccount,
  } = usePersonal();
  const { dialog, onCloseDialog } = useDialog(error, 'Error');
  const [disabledForm, setDisabledForm] = useState<boolean>(!!paymentAccount);
  const { traceBtnClick } = useAnalytics();
  
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: paymentAccount?.name || undefined,
      rut: paymentAccount?.rut || undefined,
      accountBankId: paymentAccount?.accountBankId || undefined,
      accountType: paymentAccount?.accountType || undefined,
      accountNumber: paymentAccount?.accountNumber || undefined,
    },
    validationSchema: paymentAccountSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      setDisabledForm(true);
      const newValues = {
        name: values.name,
        rut: values.rut,
        accountBankId: values.accountBankId,
        accountNumber: !values.accountNumber?.includes('X') ? values.accountNumber : undefined,
        accountType: values.accountType,
      };
      const success = await updateAuthPaymentAccount(newValues);
      setDisabledForm(false);
      if (success) {
        fetchAuthPaymentAccount();
        setOpen(false);
      }
    },
  });

  useEffect(() => {
    fetchBanks();
    fetchAccountTypes();
    fetchAuthPaymentAccount();
  }, []);

  useEffect(() => {
    if (!!paymentAccount) {
      setDisabledForm(true);
    }
  }, [paymentAccount]);

  return (
    <>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      <SimpleModal
        open={open}
        onClose={() => { setOpen(false); }}
        title={t('personal.contact.paymentAccount.title')}
        subtitle={t('personal.contact.paymentAccount.subtitle')}
      >
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  name="name"
                  label={t('personal.contact.paymentAccount.name') as string}
                  variant="outlined"
                  value={form.values.name}
                  onChange={form.handleChange}
                  error={form.touched.name && Boolean(form.errors.name)}
                  helperText={form.touched.name && form.errors.name}
                  disabled={disabledForm}
                  inputProps={{ maxLength: 100 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  name="rut"
                  label={t('personal.contact.paymentAccount.rut') as string}
                  variant="outlined"
                  value={form.values.rut}
                  onChange={form.handleChange}
                  error={form.touched.rut && Boolean(form.errors.rut)}
                  helperText={form.touched.rut && form.errors.rut}
                  disabled={disabledForm}
                  inputProps={{ maxLength: 12 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="dense" fullWidth disabled={disabledForm}>
                <Select
                  MenuProps={{ PaperProps: { sx: { maxHeight: 160 } } }}
                  label={t('personal.contact.paymentAccount.accountBank') as string}
                  name="accountBankId"
                  value={form.values.accountBankId}
                  onChange={form.handleChange}
                  disabled={disabledForm}
                  error={form.touched.accountBankId && Boolean(form.errors.accountBankId)}
                  helperText={form.errors.accountBankId}
                >
                  {banks?.map((bank: BankEntity) => (
                    <MenuItem value={bank.id} key={bank.id}>{bank.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="dense" fullWidth disabled={disabledForm}>
                <Select
                  label={t('personal.contact.paymentAccount.accountType') as string}
                  name="accountType"
                  value={form.values.accountType}
                  onChange={form.handleChange}
                  disabled={disabledForm}
                  error={form.touched.accountType && Boolean(form.errors.accountType)}
                  helperText={form.errors.accountType}
                >
                  {accountTypes?.map((accountType: AccountTypeEntity) => (
                    <MenuItem value={accountType.id} key={accountType.id}>{accountType.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  name="accountNumber"
                  label={t('personal.contact.paymentAccount.accountNumber') as string}
                  variant="outlined"
                  value={form.values.accountNumber}
                  onChange={(event: any) => {
                    const inputValue = event.target.value;
                    // Filtra los guiones del valor ingresado y cualquier otro carácter no numérico
                    const newValue = inputValue.replace(/[^0-9]/g, '');
                    form.setFieldValue('accountNumber', newValue);
                  }}
                  error={form.touched.accountNumber && Boolean(form.errors.accountNumber)}
                  helperText={form.touched.accountNumber && form.errors.accountNumber}
                  disabled={disabledForm}
                  inputProps={{ maxLength: 100, inputMode: 'numeric', pattern: '[0-9]*' }} // Solo permite números en móviles
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={1} pt={1}>
            <Grid item alignSelf="flex-end">
              {disabledForm
              && (
                <SimpleButton 
                  onClick={() => { 
                    traceBtnClick({
                      step: 'step editar cuenta de pago',
                      product: 'Editar cuenta de pago',
                    });
                    form.setFieldValue('accountNumber', ''); 
                    setDisabledForm(false); 
                  }} 
                  loading={status === 'loading'} 
                  disabled={status === 'loading'}
                >
                  {t('personal.contact.paymentAccount.edit')}
                </SimpleButton>
              )}
              {!disabledForm
              && (
                <SimpleButton
                  onClick={() => { 
                    traceBtnClick({
                      step: 'step guardar cuenta de pago',
                      product: t('personal.contact.paymentAccount.save'),
                    });
                    form.handleSubmit();
                  }} 
                  loading={status === 'loading'} 
                  disabled={status === 'loading'}
                >
                  {t('personal.contact.paymentAccount.save')}
                </SimpleButton>
              )}
            </Grid>
          </Grid>
        </>
      </SimpleModal>
      <EditListItem
        label={t('personal.contact.paymentAccount.title')}
        content={
          <Label style={{ fontSize: '14px' }}>
          *Completa este campo para recibir pagos por tus cursos y clases.
          </Label>
        }
        onClick={() => { 
          traceBtnClick({
            step: 'step abrir cuenta de pago',
            product: t('personal.contact.paymentAccount.title'),
          });
          setOpen(true); 
        }}
        disabled={disabled || status === 'loading'}
        loading={status === 'loading'}
        icon={paymentAccount ? <VisibilityIcon /> : undefined}
      />
    </>
  );
};

PaymentAccount.displayName = 'PaymentAccount';
export default PaymentAccount;
