import styled from '@emotion/styled';
import { Card, Typography } from '@mui/material';
import {
  AssignmentIndOutlined as ProfileIcon,
  ShoppingCartOutlined as PurchaseIcon,
  ExitToAppOutlined as LogoutIcon,
  AdminPanelSettingsOutlined as AdminIcon,
} from '@mui/icons-material';

export const StyledMenuCard = styled(Card)`
  background: #FAFAFA;
  padding-top: 8px;
  height: 100%;
`;

export const StyledTitleTypography = styled(Typography)`
  font-size: 18px;
  opacity: .87;
  padding-left: 8px;
  padding-right: 8px;
`;

export const StyledSubtitleTypography = styled(Typography)`
  font-size: 14px;
  opacity: .87;
  padding-left: 8px;
  padding-right: 8px;
`;

export const StyledProfileIcon = styled(ProfileIcon)`
  color: #737373;
  width: 60px;
  height: auto;
`;

export const StyledPurchaseIcon = styled(PurchaseIcon)`
  color: #737373;
  width: 60px;
  height: auto;
`;

export const StyledLogoutIcon = styled(LogoutIcon)`
  color: #737373;
  width: 60px;
  height: auto;
`;

export const StyledAdminIcon = styled(AdminIcon)`
  color: #737373;
  width: 60px;
  height: auto;
`;
