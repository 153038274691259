import firebase from 'firebase/compat/app';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';
import { EventName } from './eventName';
import { Action, Params, Product, STATUS, Step } from './params';

const useAnalytics = () => {
  const { authUser } = useContext(AuthContext);
  const query = new URLSearchParams(useLocation().search);

  const traceEvent = (eventName: EventName, params: Params) => {
    if (process.env.REACT_APP_IGNORE_ANALYTICS === 'true') {
      return;
    }
    const eventData = {
      ...params,
      path: window.location.pathname,
      search: window.location.search,
      user_id: authUser?.data?.id,
      user_email: authUser?.data?.email,
      userId: authUser?.data?.id,
      userEmail: authUser?.data?.email,
      utm_source: query.get('utm_source'),
      utm_medium: query.get('utm_medium'),
      utm_campaign: query.get('utm_campaign'),
      utm_term: query.get('utm_term'),
      utm_content: query.get('utm_content'),
    };
    if (authUser?.data?.id) {
      firebase.analytics().setUserId(authUser?.data?.id?.toString());
      const userProperties = {
        email: authUser?.data?.email,
        display_name: authUser?.data?.displayName,
        name: authUser?.data?.name,
        lastname: authUser?.data?.lastname,
        is_admin: authUser?.data?.isAdmin,
      };
      firebase.analytics().setUserProperties(userProperties);
    }
    firebase.analytics().logEvent(eventName as string, eventData);
  };  

  const traceBtnClick = ({
    product,
    step,
    status = STATUS.OK,
  }: {
    product: Product;
    step: Step;
    status?: STATUS;
  }) => {
    traceEvent(EventName.BTN_CLICK, {
      action: Action.CLICK,
      product,
      status,
      step,
    });
  };
  
  const traceVirtualPage = ({
    product,
    status = STATUS.OK,
  }: {
    product: Product;
    status?: STATUS;
  }) => {
    traceEvent(EventName.VIRTUAL_PAGE, {
      action: Action.PAGEVIEW,
      product,
      status,
    });
  };

  return {
    traceBtnClick,
    traceVirtualPage,
  };
};

export default useAnalytics;
