import { forwardRef } from 'react';
import { GroupEntity } from '../../../entities';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Container, Item } from './GroupDetail.styled';

interface GroupDetailProps {
  group: GroupEntity;
  showIndex?: boolean;
}

export const GroupDetail = forwardRef<HTMLDivElement, GroupDetailProps>(
  ({ group, showIndex = true, ...rest }, ref) => {
    const { t } = useTranslation(['components'], { keyPrefix: 'group-detail' });
    return (
      <Container {...rest} ref={ref} >
        {group?.sessions?.length === 0 && (
          <Item>
            <Typography>Sin horarios definidos</Typography>
          </Item>
        )}
        {group?.sessions?.map((session: any, index: number) => (
          <Item key={session.id}>
            <Typography textTransform="capitalize">
              {((showIndex && group?.publication?.agenda === 'scheduled') ? `${t('session')} ${index + 1}:` : '')}
            </Typography>
            <Typography>
              {`${session.dateFormat} ${session.startTime} a ${session.endTime}, ${t('duration')} ${session.durationFormat}`}
            </Typography>
          </Item>
        ))}
      </Container>
    );
  });