import styled from '@emotion/styled';
import { Container } from '@mui/material';
import SimpleButton from '../../buttons/simple-button/SimpleButton';

interface StyledButtonProps {
  customtextcolor?: string;
}

export const StyledShoppingCartButton = styled(SimpleButton)(
  ({ customtextcolor }: StyledButtonProps) => `
    margin-left: 8px;
    ${customtextcolor && `
      color: ${customtextcolor};
    `};
  `,
);

export const StyledContainer = styled(Container)`
  padding-top: 16px;
  padding-bottom: 16px;
`;
