import { forwardRef } from 'react';
import { ChipProps as MuiChipProps } from '@mui/material';
import styled from '@emotion/styled';
import SimpleChip from '../../chips/simple-chip/SimpleChip';

const StyledChip = styled(SimpleChip)`
  text-transform: uppercase;
  margin-right: 0;
  margin-left: 2px;
`;

interface PublicationChipProps extends MuiChipProps {
  type?: string;
  onClick?: () => void;
}

const PublicationChip = forwardRef<HTMLDivElement, PublicationChipProps>(
  ({ type = 'default', ...rest }, ref) => {
    const configs = [
      { type: 'active', color: 'secondary', label: 'Activo' },
      { type: 'no-active', color: 'default', label: 'Inactivo' },
      { type: 'review', color: 'warning', label: 'En revisión' },
      { type: 'draft', color: 'default', label: 'Borrador' },
      { type: 'archived', color: 'info', label: 'Archivado' },
      { type: 'approved', color: 'secondary', label: 'Aprobado' },
      { type: 'rejected', color: 'error', label: 'No Aprobado' },
      { type: 'started', color: 'primary', label: 'Iniciado' },
      { type: 'no-started', color: 'secondary', label: 'No iniciado' },
    ];
    const color = configs.find((config) => config.type === type)?.color || 'default';
    const label = configs.find((config) => config.type === type)?.label || type;

    return (
      <StyledChip
        {...rest}
        size="small"
        color={color}
        label={label}
        ref={ref}
      />
    );
  },
);

PublicationChip.displayName = 'PublicationChip';
export default PublicationChip;
