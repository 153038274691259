import { useLocation, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ListButton, SimpleButton } from '../../../components/buttons';
import DividedContainer from '../../../components/containers/divided-container/DividedContainer';
import NavigationBar from '../../../components/navigations/navigation-bar/NavigationBar';
import PageTitle from '../../../components/typographies/PageTitle';
import PageSubtitle from '../../../components/typographies/PageSubtitle';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';
import { useEffect } from 'react';

const MyAccountPersonal = () => {
  const { t } = useTranslation(['my-account']);
  const navigate = useNavigate();
  const location = useLocation();
  const { traceBtnClick } = useAnalytics();

  useEffect(() => {
    return () => {
      sessionStorage.setItem('from', location.pathname);
    };
  }, []);

  const onBack = () => {
    const previousPage = '/my-account';
    const from = sessionStorage.getItem('from');
    return from === previousPage ? navigate(-1) : navigate(previousPage);
  };
  
  return (
    <DividedContainer>
      <NavigationBar variant="variant4" />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageTitle>{t('personal.title')}</PageTitle>
        </Grid>
        <Grid item xs={12}>
          <PageSubtitle>{t('personal.subtitle')}</PageSubtitle>
        </Grid>
        <Grid item xs={12}>
          <ListButton
            title={t('personal.profileItem')}
            onClick={() => { 
              traceBtnClick({
                step: 'step ir a perfil personal',
                product: t('personal.profileItem'),
              });
              navigate('/my-account/personal/profile');
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ListButton
            title={t('personal.contactItem')}
            onClick={() => { 
              traceBtnClick({
                step: 'step ir a información de contacto',
                product: t('personal.contactItem'),
              });
              navigate('/my-account/personal/contact');
            }}
          />
        </Grid>
        <Grid item paddingTop={2}>
          <SimpleButton variant='text' onClick={onBack}>
            Ir a Mi Cuenta
          </SimpleButton>
        </Grid>
      </Grid>
    </DividedContainer>
  );
};

export default MyAccountPersonal;
