import DividedContainer from '../../components/containers/divided-container/DividedContainer';
import NavigationBar from '../../components/navigations/navigation-bar/NavigationBar';
import FormLogin from './components/form-login/FormLogin';

const Login = () => (
  <DividedContainer>
    <NavigationBar variant="variant1" />
    <FormLogin />
  </DividedContainer>
);

export default Login;
