import { forwardRef } from 'react';
import {
  TextField, useMediaQuery, InputAdornment,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  SearchOutlined as SearchOutlinedIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { useFormik } from 'formik';
import { ContainerSearch } from './Search.styled';
import { useTranslation } from 'react-i18next';
import { IconButton, SimpleButton } from '../../../../../../components/buttons';
import useAnalytics from '../../../../../../hooks/useAnalytics/useAnalytics';

interface InitialValues {
  id?: string;
  state?: string | string[];
  search?: string;
}
interface ISearch {
  disabled?: boolean;
  initialValues?: InitialValues;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (values: any) => void;
}

export const Search = forwardRef<HTMLDivElement, ISearch>(
  ({
    onSubmit,
    initialValues = {},
    disabled,
    ...props
  }, ref) => {
    const { t } = useTranslation('my-account', { keyPrefix: 'admin.publications' });
    const theme = useTheme();
    const isdownsm = useMediaQuery(theme.breakpoints.down('sm'));
    const { traceBtnClick } = useAnalytics();
    
    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
    });

    const cleanForm = () => { 
      traceBtnClick({
        step: 'step limpiar formulario de búsqueda',
        product: 'Limpiar formulario de búsqueda',
      });
      form.setFieldValue('id', '');
      form.setFieldValue('state', '');
      form.setFieldValue('search', '');
      form.handleSubmit();
    };

    return (
      <form noValidate onSubmit={form.handleSubmit}>
        <ContainerSearch style={{ flexDirection: isdownsm ? 'column' : 'row' }} ref={ref} {...props}>
          <TextField
            name="search"
            label={t('form.search.label') as string}
            value={form.values.search}
            onChange={form.handleChange}
            error={(form.touched.search && Boolean(form.errors.search))}
            helperText={form.touched.search && form.errors.search}
            variant="outlined"
            fullWidth
            style={isdownsm ? { paddingTop: 8 } : { paddingRight: 8 }}
            InputProps={{
              endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
            }}
            disabled={disabled}
            inputProps={{ maxLength: 100 }}
          />
				 <TextField
            name="id"
            label={t('form.id.label') as string}
            value={form.values.id}
            onChange={form.handleChange}
            error={(form.touched.id && Boolean(form.errors.id))}
            helperText={form.touched.id && form.errors.id}
            variant="outlined"
            fullWidth
            style={isdownsm ? { paddingTop: 8 } : { paddingRight: 8 }}
            disabled={disabled}
            inputProps={{ maxLength: 100 }}
          />
          <IconButton
            type="submit"
            style={isdownsm ? { margin: '8px auto', width: '100%' } : { margin:'4px 0 4px 4px' }}
          >
            <SearchOutlinedIcon />
          </IconButton>
          <SimpleButton
            size='small'
            onClick={cleanForm}
            style={isdownsm ? { margin: '8px auto', width: '100%' } : { margin:'4px 0 4px 4px' }}
          >
            {t('form.clear.label') as string}
          </SimpleButton>
        </ContainerSearch>
      </form>
    );
  },
);
