import { useContext, ReactElement } from 'react';
import { LinearProgress } from '@mui/material';
import AuthContext from '../../../contexts/AuthContext';
import NotFound from '../../../pages/not-found/NotFound';

interface AdminAccessProps {
  children: ReactElement;
}

const AdminAccess = ({ children }: AdminAccessProps) => {
  const { authUser, isLogged, isInitializing } = useContext(AuthContext);

  if (!isInitializing) {
    return (!isLogged || !authUser?.data?.isAdmin ? <NotFound /> : children);
  }
  return (<LinearProgress />);
};

AdminAccess.displayName = 'AdminAccess';
export default AdminAccess;
