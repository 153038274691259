import { useContext, ReactElement } from 'react';
import { LinearProgress } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';
import AuthContext from '../../../contexts/AuthContext';

interface NoAuthAccessProps {
  children: ReactElement;
}

const NoAuthAccess = ({ children }: NoAuthAccessProps) => {
  const { isLogged, isInitializing } = useContext(AuthContext);
  const location = useLocation();

  if (!isInitializing) {
    return (isLogged ? <Navigate to="/" state={{ from: location }} replace /> : children);
  }
  return (<LinearProgress />);
};

NoAuthAccess.displayName = 'NoAuthAccess';
export default NoAuthAccess;
