import { useState } from 'react';
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import {
  StyledFormControl,
} from './FormDialogForgotPassword.styled';
import { formDialogForgotPasswordSchema } from './FormDialogForgotPassword.schema';
import { useTranslation } from 'react-i18next';
import SimpleButton from '../../../../components/buttons/simple-button/SimpleButton';
import SimpleDialog from '../../../../components/dialogs/SimpleDialog/SimpleDialog';
import useLogin from '../../useLogin';
import useAnalytics from '../../../../hooks/useAnalytics/useAnalytics';

interface FormDialogForgotPasswordProps {
  email?: string;
}
const FormDialogForgotPassword = ({ email = '' }: FormDialogForgotPasswordProps) => {
  const { t } = useTranslation('login', { keyPrefix: 'formDialogForgotPassword' });
  const { traceBtnClick } = useAnalytics();
  const { sendPasswordResetEmail, status } = useLogin();
  const [dialog, setDialog] = useState({ open: false, sent: false });

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      email,
    },
    validationSchema: formDialogForgotPasswordSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      await sendPasswordResetEmail(values.email);
      setDialog({ ...dialog, sent: true });
    },
  });

  const onCloseDialog = () => {
    traceBtnClick({
      step: 'step cerrar modal de reset password',
      product: t('forgotPassword'),
    });
    setDialog({ ...dialog, open: false });
  };

  const handleClickOpenModal = async () => {
    traceBtnClick({
      step: 'step abrir modal de reset password',
      product: t('forgotPassword'),
    });
    setDialog({ open: true, sent: false });
  };

  return (
    <>
      <SimpleDialog
        {...dialog}
        onClose={onCloseDialog}
        content={dialog.sent ? t('sent') : t('forgot')}
        actions={!dialog.sent ? (
          <SimpleButton 
            onClick={() => { 
              traceBtnClick({
                step: 'step enviar email de reset password',
                product: t('button'),
              });
              form.handleSubmit();
            }} 
            disabled={status === 'loading'} 
            loading={status === 'loading'}
          >
            {t('button')}
          </SimpleButton>
        ) : undefined}
      >
        {!dialog.sent ? (
          <form>
            <StyledFormControl margin="dense" fullWidth>
              <TextField
                name="email"
                type="email"
                label={t('emailLabel') as string}
                variant="outlined"
                value={form.values.email}
                onChange={form.handleChange}
                error={form.touched.email && Boolean(form.errors.email)}
                helperText={form.touched.email && form.errors.email}
                inputProps={{ maxLength: 100 }}
              />
            </StyledFormControl>
          </form>
        ) : undefined}
      </SimpleDialog>
      <SimpleButton 
        onClick={handleClickOpenModal} 
        size="medium" 
        variant="text"
        style={{ color: 'gray' }}
      >
        {t('forgotPassword')}
      </SimpleButton>
    </>
  );
};

FormDialogForgotPassword.displayName = 'FormDialogForgotPassword';
export default FormDialogForgotPassword;
