import { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { GroupEntity, PublicationEntity } from '../../../entities';
import { putAuthPublication, putToReviewAuthPublication } from '../../../services/authPublication';
import { deleteAuthGroup, putAuthGroups } from '../../../services/authGroup';
import { deleteAuthSession } from '../../../services/authSession';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
  publication?: PublicationEntity;
}

const useDetails = () => {
  const [state, setState] = useState<StateProps>({ status: 'idle', error: null });

  const uploadVisual = async (visual: any, id: number, name: string = 'undefined') => {
    const refStorage = firebase.storage().ref(`publications/${id}/${name}`);
    await refStorage.put(visual);
    const url = await refStorage.getDownloadURL();
    return url;
  };

  const updateAuthPublication = async (id: number, publication: PublicationEntity) => {
    setState({ status: 'loading' });
    try {
      if (publication.audiovisuals) {
        for (let i = 0; i < publication.audiovisuals.length; i++) {
          const visual = publication.audiovisuals[i];
          if (!visual.id) {
            const { file, ...restVisual } = visual;
            const visualURL = await uploadVisual(file, id, `${Date.now()}-${i + 1}`);
            publication.audiovisuals[i] = { 
              alt: '',
              ...restVisual, 
              url: visualURL, 
            };
          }
        }
      }
      await putAuthPublication(id, publication);
      setState({ status: 'loaded' });
      return true;
    } catch (error) {
      setState({ status: 'loaded', error });
    }
    return false;
  };

  const updateAuthGroups = async (publicationId: number, groups: GroupEntity[]) => {
    setState({ status: 'loading' });
    try {
      await putAuthGroups(publicationId, groups);
      setState({ status: 'loaded' });
      return true;
    } catch (error) {
      setState({ status: 'loaded', error });
    }
    return false;
  };

  const removeAuthGroup = async (groupId: number) => {
    setState({ status: 'loading' });
    try {
      await deleteAuthGroup(groupId);
      setState({ status: 'loaded' });
    } catch (error) {
      setState({ status: 'loaded', error });
    }
  };

  const removeAuthSession = async (sessionId: number) => {
    setState({ status: 'loading' });
    try {
      await deleteAuthSession(sessionId);
      setState({ status: 'loaded' });
    } catch (error) {
      setState({ status: 'loaded', error });
    }
  };

  const toReviewAuthPublication = async (id: number) => {
    setState({ status: 'loading' });
    try {
      await putToReviewAuthPublication(id);
      setState({ status: 'loaded' });
      return true;
    } catch (error) {
      setState({ status: 'loaded', error });
    }
    return false;
  };

  return {
    ...state,
    // fetchAuthPublication,
    updateAuthPublication,
    updateAuthGroups,
    removeAuthGroup,
    removeAuthSession,
    toReviewAuthPublication,
  };
};

export default useDetails;