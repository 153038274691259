import { forwardRef } from 'react';
import { Stepper } from '..';

interface PublicationStepperProps {
  activeStepIndex?: number;
}
const PublicationStepper = forwardRef<HTMLParagraphElement, PublicationStepperProps>(
  ({ activeStepIndex = 0, ...props }, ref) => {
    const steps = ['Descripción', 'Detalles', 'Agenda', 'Horarios'];
    return (
      <Stepper 
        {...props}
        activeStepIndex={activeStepIndex}
        steps={steps}
        ref={ref}
      />
    );
  },
);

PublicationStepper.displayName = 'PublicationStepper';
export default PublicationStepper;
