import * as validator from 'yup';
import i18n from 'i18next';
import { today } from '../../../../utils/Day';

const currentDate = today();

const regexHHMM = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

const quotaSchema =  validator.number()
  .min(1, i18n.t('schemas:quota:required'))
  .required(i18n.t('schemas:quota:required'))
  .test('minQuota', i18n.t('schemas:quota:min'), (value, context) => {
    const minQuota = context?.parent?.minQuota || 0; 
    return !!value && +value >= minQuota;
  })
  ;

const dateSchema = validator.date()
  .typeError(i18n.t('schemas:date:notValid'))
  .min(currentDate, i18n.t('schemas:date:mintoday'))
  .required(i18n.t('schemas:date:required'));
  
const startTimeSchema = validator.string()
  .typeError(i18n.t('schemas:startTime:notValid'))
  .matches(regexHHMM, i18n.t('schemas:startTime:notValid'));

const endTimeSchema = validator.string()
  .typeError(i18n.t('schemas:endTime:notValid'))
  .matches(regexHHMM, i18n.t('schemas:startTime:notValid'));
  // .test('isAfterTime', i18n.t('schemas:endTime:notValid'), (value, context) => {
  //   const { startTime } = context?.parent || ''; 
  //   return value && startTime && timeToMinutes(value) > timeToMinutes(startTime);
  // });

// const periodicitySchema = validator.string()
//   .oneOf(['no', 'weekly', null], i18n.t('schemas:periodicity:required'));

const groupSchema = validator.object({
  quota: quotaSchema,
  sessions: validator.array().of(validator.object().shape({
    date: dateSchema,
    startTime: startTimeSchema.required(i18n.t('schemas:startTime:required')),
    endTime: endTimeSchema.required(i18n.t('schemas:endTime:required')),
  })),
});

const weekSchema = validator.object().shape({
  startTime: startTimeSchema.when('disabled', {
    is: false,
    then: (schema) => schema
      .required(i18n.t('schemas:startTime:required')),
    otherwise: (schema) => schema.notRequired(),
  }),
  endTime: endTimeSchema.when('disabled', {
    is: false,
    then: (schema) => schema
      .required(i18n.t('schemas:endTime:required')),
    otherwise: (schema) => schema.notRequired(),
  }),
});

// const weekSchemaWithQuota = weekSchema.concat(
//   validator.object().shape({
//     quota: validator.number().when('disabled', {
//       is: false,
//       then: () => quotaSchema,
//       otherwise: (schema) => schema.notRequired(),
//     }),
//   }),
// );

export const stepHorariosSchema = validator.object({
  groups:  validator.array().of(groupSchema).when('periodicity', {
    is: 'no',
    then: (schema) => schema
      .required(i18n.t('schemas:groups:required')),
    otherwise: (schema) => schema.notRequired(),
  }),
  // week: validator.array().when('periodicity', {
  //   is: 'weekly',
  //   then: (schema) => schema
  //     .of(weekSchemaWithQuota).required(i18n.t('schemas:week:required')),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  // periodicity: periodicitySchema,
  // weeklySince: validator.date()
  //   .typeError(i18n.t('schemas:date:notValid'))
  //   .nullable().when('periodicity', {
  //     is: 'weekly',
  //     then: (schema) => schema
  //       .required(i18n.t('schemas:weeklySince:required'))
  //       .min(currentDate, i18n.t('schemas:weeklySince:mintoday')),
  //     otherwise: (schema) => schema.notRequired(),
  //   }),
  // weeklyUntil: validator.date()
  //   .typeError(i18n.t('schemas:date:notValid'))
  //   .nullable().when('periodicity', {
  //     is: 'weekly',
  //     then: (schema) => schema
  //       .required(i18n.t('schemas:weeklyUntil:required'))
  //       .min(currentDate, i18n.t('schemas:weeklyUntil:mintoday')),
  //     otherwise: (schema) => schema.notRequired(),
  //   }),
});

export const stepHorariosCourseSchema = validator.object({
  groups:  validator.array().when('periodicity', {
    is: 'no',
    then: (schema) => schema
      .required(i18n.t('schemas:groups:required')),
    otherwise: (schema) => schema.notRequired(),
  }),
  // week: validator.array().when('periodicity', {
  //   is: 'weekly',
  //   then: (schema) => schema
  //     .of(weekSchemaWithQuota).required(i18n.t('schemas:week:required')),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  // periodicity: periodicitySchema,
  // weeklySince: validator.date()
  //   .typeError(i18n.t('schemas:date:notValid'))
  //   .nullable().when('periodicity', {
  //     is: 'weekly',
  //     then: (schema) => schema
  //       .required(i18n.t('schemas:weeklySince:required'))
  //       .min(currentDate, i18n.t('schemas:weeklySince:mintoday')),
  //     otherwise: (schema) => schema.notRequired(),
  //   }),
  // weeklyUntil: validator.date()
  //   .typeError(i18n.t('schemas:date:notValid'))
  //   .nullable().when('periodicity', {
  //     is: 'weekly',
  //     then: (schema) => schema
  //       .required(i18n.t('schemas:weeklyUntil:required'))
  //       .min(currentDate, i18n.t('schemas:weeklyUntil:mintoday')),
  //     otherwise: (schema) => schema.notRequired(),
  //   }),
});

export const groupCourseScheduledSchema = validator.object({
  quota: quotaSchema,
  sessions: validator.array().of(validator.object().shape({
    date: dateSchema,
    startTime: startTimeSchema.required(i18n.t('schemas:startTime:required')),
    endTime: endTimeSchema.required(i18n.t('schemas:endTime:required')),
  })),
  // week: validator.array().when('periodicity', {
  //   is: 'weekly',
  //   then: (schema) => schema
  //     .of(weekSchema).required(i18n.t('schemas:week:required')),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  // periodicity: periodicitySchema,
  // weeklySince: validator.date()
  //   .typeError(i18n.t('schemas:date:notValid'))
  //   .nullable().when('periodicity', {
  //     is: 'weekly',
  //     then: (schema) => schema
  //       .required(i18n.t('schemas:weeklySince:required'))
  //       .min(currentDate, i18n.t('schemas:weeklySince:mintoday')),
  //     otherwise: (schema) => schema.notRequired(),
  //   }),
  // weeklyUntil: validator.date()
  //   .typeError(i18n.t('schemas:date:notValid'))
  //   .nullable().when('periodicity', {
  //     is: 'weekly',
  //     then: (schema) => schema
  //       .required(i18n.t('schemas:weeklyUntil:required'))
  //       .min(currentDate, i18n.t('schemas:weeklyUntil:mintoday')),
  //     otherwise: (schema) => schema.notRequired(),
  //   }),
});

export const groupFlexibleSchema = validator.object({
  quota: quotaSchema,
  countSessions: validator.number()
    .min(1, i18n.t('schemas:countSessions:required'))
    .required(i18n.t('schemas:countSessions:required')),
  week: validator.array().of(weekSchema).notRequired(),
  // week: validator.array().when('periodicity', {
  //   is: 'weekly',
  //   then: (schema) => schema
  //     .of(weekSchema).required(i18n.t('schemas:week:required')),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  // periodicity: periodicitySchema,
});