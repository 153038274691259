import styled from '@emotion/styled';
import {
  WatchOutlined as WatchOutlinedIcon,
  TodayOutlined as TodayOutlinedIcon,
} from '@mui/icons-material';
import SimpleButton from '../../buttons/simple-button/SimpleButton';

export const StyledWatchOutlinedIcon = styled(WatchOutlinedIcon)`
  color: #000; 
  opacity: .54;
`;

export const StyledTodayOutlinedIcon = styled(TodayOutlinedIcon)`
  color: #000; 
  opacity: .54;
`;

export const StyledButton = styled(SimpleButton)`
  color: #04919A;
  text-align: center;
  font-size: 14px;
  text-transform: none;
  text-decoration: underline;
  padding: 0px 16px 0px 16px;
  &:hover{
    background-color: #fff;
    text-decoration: underline;
  }
`;
