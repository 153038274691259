import { forwardRef } from 'react';
import { Card, ContainerImage, ContentCard, ImageBenefitCard, TitleCard } from './BenefitCard.styled';

interface BenefitCardProps {
  img: string; 
  altImg: string; 
  title: string; 
  description: string; 
}

const BenefitCard = forwardRef<HTMLDivElement, BenefitCardProps>(
  ({ img, altImg, title, description, ...props }, ref) => (
    <Card ref={ref} {...props}>
      <ContainerImage>
        <ImageBenefitCard src={img} alt={altImg}/>
      </ContainerImage>
      <TitleCard>{title}</TitleCard>
      <ContentCard>
        {description}
      </ContentCard>
    </Card>
  ),
);

export default BenefitCard;