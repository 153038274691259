import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es-mx';
import updateLocale from 'dayjs/plugin/updateLocale';

const locale = 'es-mx';
dayjs.extend(updateLocale);
dayjs.updateLocale(locale, {
  weekStart: 1,
});

export { dayjs, Dayjs, locale };