import { useEffect, useState } from 'react';
import { Grid, TextField, FormControl, FormGroup } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import useDetails from '../../../useDetails';
import useDialog from '../../../../../../hooks/useDialog';
import { knowledgeSchema } from './Knowledge.schema';
import SimpleChip from '../../../../../../components/chips/simple-chip/SimpleChip';
import SimpleDialog from '../../../../../../components/dialogs/SimpleDialog/SimpleDialog';
import SimpleModal from '../../../../../../components/dialogs/SimpleModal/SimpleModal';
import { EditListItem, CheckboxLabel } from '../../../../../../components/inputs';
import SimpleButton from '../../../../../../components/buttons/simple-button/SimpleButton';
import useKnowledges from './useKnowledges';
import { KnowledgeId } from '../../../../../../entities/KnowledgeEntity';
import { PublicationTypeEntity } from '../../../../../../entities/PublicationEntity';
import useAnalytics from '../../../../../../hooks/useAnalytics/useAnalytics';

interface KnowledgeProps {
  form: any;
  type?: PublicationTypeEntity;
  disabled?: boolean;
}

const Knowledge = ({ form, type, disabled }: KnowledgeProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('my-publication', { keyPrefix: 'details.step-detalles.knowledge' });
  const { traceBtnClick } = useAnalytics();
  
  const { updateAuthPublication, status, error } = useDetails();
  const { fetchKnowledges, status: statusKnowledges, error: errorKnowledges, knowledges } = useKnowledges();

  const { dialog, onCloseDialog } = useDialog(error || errorKnowledges, 'Error');

  const localForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      knowledgesId: form.values.knowledgesId,
      knowledgeDetail: form.values.knowledgeDetail,
    },
    validationSchema: knowledgeSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const success = await updateAuthPublication(form?.values?.id, values);
      if (!success) {
        return;
      }
      form.setFieldValue('knowledgesId', values.knowledgesId);
      form.setFieldValue('knowledgeDetail', values.knowledgeDetail, false);
    },
  });

  const handleCheckboxCheck = (event: any) => {
    if (event.target.checked) {
      const checked = knowledges?.find((item: any) => `${item.id}` === event.target.name);
      localForm.setFieldValue('knowledgesId', [...localForm.values.knowledgesId, checked?.id]);
    } else {
      localForm.setFieldValue('knowledgesId', localForm.values.knowledgesId.filter((knowledgeId: number) => `${knowledgeId}` !== event.target.name));
    }
  };

  const Content = () => (
    form.values.knowledgesId?.map((knowledgeId: KnowledgeId) => (
      <SimpleChip
        style={{ marginLeft: '2px', marginBottom: '1px' }}
        key={knowledgeId}
        label={knowledges.find((item: any) => `${item.id}` === `${knowledgeId}`)?.name}
      />
    ))
  );

  useEffect(() => {
    fetchKnowledges();
  }, []);

  useEffect(() => {
    if (status !== 'loaded') {
      return;
    }
    if (!error) {
      setOpen(false);
    }
  }, [status]);

  return (
    <>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      <SimpleModal
        open={open}
        onClose={() => { setOpen(false); }}
        title={t('title')}
        subtitle={t(`subtitle.${type}`, '') || t('subtitle.class')}
      >
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <FormGroup onChange={handleCheckboxCheck}>
              {knowledges.map((knowledge: any) => (
                <CheckboxLabel
                  key={knowledge.id}
                  checked={!!(localForm.values.knowledgesId?.find((id: number) => `${id}` === `${knowledge.id}`))}
                  name={`${knowledge.id}`}
                  title={knowledge.name}
                  descriptionProps={{ fontSize: 13 }}
                  direction='row'
                  disabled={disabled || status === 'loading' || statusKnowledges === 'loading'}
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item>
            <FormControl variant="outlined" margin="dense" fullWidth>
              <TextField
                name="knowledgeDetail"
                label={t('detail') as string}
                variant="outlined"
                disabled={disabled || status === 'loading' || statusKnowledges === 'loading'}
                value={localForm.values.knowledgeDetail}
                onChange={localForm.handleChange}
                error={localForm.touched.knowledgeDetail && Boolean(localForm.errors.knowledgeDetail)}
                helperText={localForm.touched.knowledgeDetail && localForm.errors.knowledgeDetail as string}
                inputProps={{ maxLength: 100 }}
              />
            </FormControl>
          </Grid>
          <Grid item alignSelf="flex-end">
            <SimpleButton 
              onClick={() => { 
                traceBtnClick({
                  step: 'step guardar knowledge',
                  product: t('save') + 'item knowledge',
                });
                localForm.handleSubmit();
              }} 
              disabled={disabled || status === 'loading' || statusKnowledges === 'loading' || localForm?.values?.knowledgesId.length === 0} 
              loading={status === 'loading' || statusKnowledges === 'loading'}>
              {t('save')}
            </SimpleButton>
          </Grid>
        </Grid>
      </SimpleModal>
      <EditListItem
        label={t('title')}
        onClick={() => { 
          traceBtnClick({
            step: 'step abrir dialogo knowledge',
            product: 'item knowledge',
          });
          setOpen(true); 
        }}
        content={form.values.knowledgesId && <Content />}
        error={form.touched.knowledgesId && Boolean(form.errors.knowledgesId)}
        helperText={form.touched.knowledgesId && form.errors.knowledgesId}
        disabled={disabled}
      />
    </>
  );
};

Knowledge.displayName = 'Knowledge';
export default Knowledge;
