import { useEffect } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SimpleError from '../../../../components/errors/simple-error/SimpleError';
import { NavigationBottom } from '../../../../components/navigations';
import { formSchema } from './StepDetalles.schema';
import Price from './components/price/Price.component';
import Mode from './components/mode/Mode.component';
import Category from './components/category/Category.component';
import Knowledge from './components/knowledge/Knowledge.component';
import Ageranges from './components/ageranges/Ageranges.component';
import Audiovisuals from './components/audiovisuals/Audiovisuals.component';
import getPublicationId from '../../../../utils/getPublicationId';
import useMyPublication from '../../useMyPublication';
import PageSubtitle from '../../../../components/typographies/PageSubtitle';

const StepDetalles = () => {
  const { t } = useTranslation('my-publication', { keyPrefix: 'details.step-detalles' });
  const navigate = useNavigate();
  const location = useLocation();
  const { identifier } = useParams();
  const id = getPublicationId(identifier as string);

  const { fetchAuthPublication, status: statusFetch, error: errorFetch, data: publication } = useMyPublication();

  const onContinue = () => {
    navigate(`/my-publication/${identifier}/details/step-agenda`);
  };

  type InitialValuesType = {
    [key: string]: any,
  };
  const initialValues: InitialValuesType = {
    id: publication?.id || undefined,
    price: publication?.price || undefined,
    mode: publication?.mode || undefined,
    location: publication?.location || undefined,
    modeDetail: publication?.modeDetail || undefined,
    knowledgesId: publication?.knowledges?.map((knowledge) => (knowledge.id)) || [],
    knowledgeDetail: publication?.knowledgeDetail || undefined,
    categoryId: publication?.categoryId || undefined,
    audiovisuals: publication?.audiovisuals || [],
    agerangesId: publication?.ageranges?.map((agerange) => (agerange.id)) || [],
  };
  const form = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: formSchema,
    validateOnChange: true,
    onSubmit: async () => {
      onContinue();
    },
  });

  useEffect(() => {
    return () => {
      sessionStorage.setItem('from', location.pathname);
    };
  }, []);

  useEffect(() => {
    if (id && statusFetch === 'idle') {
      const attributes = [
        'id',
        'type',
        'state',
        'price',
        'mode',
        'modeDetail',
        'location',
        'knowledges',
        'knowledgeDetail',
        'categoryId',
        'category',
        'audiovisuals',
        'ageranges',
      ];
      fetchAuthPublication(id, attributes);
    }
  }, [id]);

  if (statusFetch === 'loading') {
    return <LinearProgress />;
  }

  return (
    <>
      {(!id || errorFetch) && (
        <SimpleError>{errorFetch?.message}</SimpleError>
      )}
      {!!publication && (
        <>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={12}>
              <PageSubtitle>{t(`subtitle.${publication?.type}`, '') || t('subtitle.class')}</PageSubtitle>
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={0}>
            <Grid item xs={12}>
              <Price form={form} type={publication?.type} />
            </Grid>
            <Grid item xs={12}>
              <Mode form={form} type={publication?.type} />
            </Grid>
            <Grid item xs={12}>
              <Category form={form} type={publication?.type} />
            </Grid>
            <Grid item xs={12}>
              <Knowledge form={form} type={publication?.type} />
            </Grid>
            <Grid item xs={12}>
              <Ageranges form={form} type={publication?.type} />
            </Grid>
            <Grid item xs={12} width="inherit">
              <Audiovisuals form={form} />
            </Grid>
          </Grid>
          <NavigationBottom 
            handleClickSave={() => form.handleSubmit()}
            handleClickPrevious={()=> {
              const previousPageIdentifier = `/my-publication/${identifier}/details/step-descripcion`;
              const previousPageId = `/my-publication/${id}/details/step-descripcion`;
              const from = sessionStorage.getItem('from');
              return (from === previousPageIdentifier || from === previousPageId) ? navigate(-1) : navigate(previousPageIdentifier);
            }}
          />
        </>
      )}
    </>
  );
};

export default StepDetalles;
