import { forwardRef, useEffect, useState } from 'react';
import {
  TextField, useMediaQuery, InputAdornment, Autocomplete, Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DateInput, SliderHours } from '../../../../components/inputs';
import {
  SearchOutlined as SearchOutlinedIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { useFormik } from 'formik';
import { ContainerSearch, StyledSkeleton } from './Search.styled';
import { useTranslation } from 'react-i18next';
import useSearch from './useSearch';
import { CategoryEntity } from '../../../../entities';
import IconButton from '../../../../components/buttons/icon-button/IconButton.component';
import MoreFilters from './MoreFilters';
import { dayjs } from '../../../../configs/dayjs';
import useAnalytics from '../../../../hooks/useAnalytics/useAnalytics';

interface InitialValues {
  categoryId?: number;
  search?: string;
  date?: string;
  time?: string;
  location?: string;
  mode?: string;
}
interface ISearch {
  disabled?: boolean;
  initialValues?: InitialValues;
  onSubmit: (values: any) => void;
}

export const Search = forwardRef<HTMLDivElement, ISearch>(
  ({
    onSubmit,
    initialValues = {},
    disabled,
    ...props
  }, ref) => {
    const { t } = useTranslation(['publications']);
    const theme = useTheme();
    const isdownsm = useMediaQuery(theme.breakpoints.down('sm'));
    const { fetchAll, categories, locations, status } = useSearch();
    const { traceBtnClick } = useAnalytics();
    const [category, setCategory] = useState<CategoryEntity | null>(null);
    
    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
    });

    const handleEnterPress = (event: any) => {
      if (event.key === 'Enter') {
        form.handleSubmit();
      }
    };

    useEffect(() => {
      fetchAll();
    }, []);

    useEffect(() => {
      if (categories?.length > 0) {
        const selected = form.values.categoryId ? categories.find((c) => c.id === form.values.categoryId) : null;
        setCategory(selected || null);
      }
    }, [categories, form.values.categoryId]);

    const hideMoreFilters = true;

    const onChangeSliderHours = (values?: string[]) => {
      form.setFieldValue('time', values?.join(',')) ;
    };

    const onChangeMoreFilters = (params: { [name: string]: string | undefined }) => {
      form.setFieldValue(Object.keys(params)[0], Object.values(params)[0]);
    };

    return (
      <ContainerSearch style={{ flexDirection: isdownsm ? 'column' : 'row' }} ref={ref} {...props}>
        {status === 'loading' ? (
          <StyledSkeleton width="100%" />
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12} md={hideMoreFilters ? 5.5 : 4}>
              <TextField
                name="search"
                label={t('form.search.label') as string}
                value={form.values.search}
                onChange={form.handleChange}
                error={(form.touched.search && Boolean(form.errors.search))}
                helperText={form.touched.search && form.errors.search}
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                }}
                disabled={disabled}
                onKeyDown={handleEnterPress}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12}md={hideMoreFilters ? 5.5 : 4}>
              <Autocomplete
                onChange={(_, value: any) => form.setFieldValue('categoryId', value?.id)}
                value={category}
                options={categories}
                autoHighlight
                fullWidth
                getOptionLabel={(option: CategoryEntity) => option?.name || ''}
                disabled={disabled}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    name="categoryId"
                    label={t('form.category.label')}
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off',
                      maxLength: 100,
                    }}
                    onKeyDown={handleEnterPress}
                  />
                )}
              />
            </Grid>
            {!hideMoreFilters && (
              <>
                <Grid item xs={12} md={4}>
                  <DateInput
                    disablePast
                    label={t('form.calendar.label') as string}
                    value={form.values.date || null} 
                    onChange={(value: any) => form.setFieldValue('date', !!value ? (dayjs(value).format('YYYY-MM-DD').toString()) : '')}
                    disabled={disabled}
                    name="date"
                    size="medium"
                    fullWidth 
                    renderInput={() => <></>} 
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SliderHours
                    name="time"
                    marks={['00:00', '8:00', '12:00', '18:00', '23:59']}
                    values={form.values.time?.split(',') || []}
                    onChange={onChangeSliderHours}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MoreFilters 
                    onChange={onChangeMoreFilters}
                    values={{ 
                      mode: form.values.mode || undefined, 
                      location: form.values.location || undefined, 
                    }}
                    locationsValues={locations}
                  />
                </Grid> 
              </>)}
            <Grid item xs={12} md={1}>
              <IconButton
                onClick={() => { 
                  traceBtnClick({
                    step: 'step buscar',
                    product: 'icon buscar',
                  });
                  form.handleSubmit();
                }}
                style={isdownsm ? { margin: '8px auto', width: '100%' } : {}}
              >
                <SearchOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        )}

      </ContainerSearch>
    );
  },
);
