import React from 'react';
import { Autocomplete, TextField, TextFieldProps, Typography } from '@mui/material';

interface SelectTimeProps {
  name: string;
  onChange: (value: string | null) => void;
  value?: string | null;
  label?: string;
  variant?: TextFieldProps['variant'];
  size?: TextFieldProps['size'];
  helperText?: any;
  error?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  id?: string;
  style?: TextFieldProps['style'];
}

const generateTimeSlots = (): string[] => {
  const timeSlots: string[] = [];
  const eachMinutes = 30;
  
  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes += eachMinutes) {
      const hourFormatted = hours.toString().padStart(2, '0');
      const minuteFormatted = minutes.toString().padStart(2, '0');
      timeSlots.push(`${hourFormatted}:${minuteFormatted}`);
    }
  }
  
  return timeSlots;
};

const fontSizes = {
  small: '0.875rem',
  medium: '1rem',
  large: '1rem',
};

const SelectTime = React.forwardRef<HTMLInputElement, SelectTimeProps>(
  ({
    name,
    onChange,
    value,
    label,
    variant = 'outlined', 
    size = 'small',
    helperText,
    error,
    fullWidth,
    disabled,
    id,
    ...rest
  }, ref) => {
    const timeSlots = generateTimeSlots();
    return (
      <Autocomplete
        autoSelect
        freeSolo
        id={id}
        options={timeSlots}
        size={size}
        value={value}
        fullWidth={fullWidth}
        disabled={disabled}
        onChange={(_: any, newValue: string | null) => {
          onChange(newValue ? newValue : '');
        }}
        ListboxProps={{ style: { maxHeight: '160px' } }}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Typography fontSize={fontSizes[size]}>
                {option}
              </Typography>
            </li>
          );
        }}
        renderInput={(params) => <TextField 
          {...params} 
          variant={variant}
          size={size} 
          label={label}
          name={name}
          fullWidth={fullWidth}
          InputLabelProps={{ style: { fontSize: fontSizes[size] } }}
          error={error}
          helperText={helperText}
          inputProps={{
            ...params.inputProps,
            style: { ...params.inputProps?.style, fontSize: fontSizes[size], height: '23px' },
            autoComplete: 'off',
          }}
        />}
        ref={ref} 
        {...rest} 
      />
    );
  });

SelectTime.displayName = 'SelectTime';
export default SelectTime;
