import { forwardRef, ReactElement, ReactNode } from 'react';
import { CircularProgress, ListItemText } from '@mui/material';
import { ArrowForward as DefaultIcon } from '@mui/icons-material';
import {
  StyledListItemButton,
  StyledListItem,
  StyledListItemIcon,
} from './ListButton.styled';

interface ListButtonProps {
  title: string;
  icon?: ReactElement;
  disabled?: boolean;
  selected?: boolean;
  onClick?: () => any;
  hideIcon?: boolean;
  children?: ReactNode;
  style?: any;
  loading?: boolean;
}

const ListButton = forwardRef<HTMLDivElement, ListButtonProps>(
  ({
    title, 
    icon = <DefaultIcon />, 
    disabled = false, 
    selected = false, 
    onClick, 
    hideIcon, 
    children, 
    loading, 
    ...rest
  }, ref) => {
    const Content = (
      <>
        <ListItemText primary={title} />
        {children}
        <div>
          {loading && <CircularProgress size={20} />}
          {!hideIcon && <StyledListItemIcon>{icon}</StyledListItemIcon>}
        </div>
      </>
    );
    return (onClick ? (
      <StyledListItemButton 
        onClick={onClick} 
        disabled={disabled} 
        selected={selected} 
        ref={ref} 
        {...rest}
      >
        {Content}
      </StyledListItemButton>
    ) : (
      <StyledListItem {...rest}>
        {Content}
      </StyledListItem>
    ));
  },
);

ListButton.displayName = 'ListButton';
export default ListButton;
