import * as validator from 'yup';
import i18n from 'i18next';
import { validateRut } from '../../../../utils/rutUtilities';

const isValidRut = (rut?: string) => (validateRut(rut));

export const contactSchema = validator.object({
  name: validator
    .string()
    .required(i18n.t('schemas:name:required')),
  lastname: validator
    .string()
    .required(i18n.t('schemas:lastname:required')),
  birthdate: validator
    .string()
    .required(i18n.t('schemas:birthdate:required')).nullable(),
  phoneNumber: validator
    .string()
    .required(i18n.t('schemas:phoneNumber:required'))
    .min(7, i18n.t('schemas:phoneNumber:notValid'))
    .max(15, i18n.t('schemas:phoneNumber:notValid')),
  identificationValue: validator
    .string()
    .required(i18n.t('schemas:rut:required'))
    .test('rut', i18n.t('schemas:rut:notValid'), isValidRut),
});
