/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';

interface GurappIconProps {
  color?: string;
  width?: number;
}

const GurappIcon = ({ color = '#737373', width = 32, ...props }: GurappIconProps) => (
  <SvgIcon style={{ width, height: 'auto' }}{...props}>
    <svg viewBox="0 0 63 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M32.5 60C46.3 60 57.5 48.8 57.5 35C57.5 21.2 46.3 10 32.5 10C18.7 10 7.5 21.2 7.5 35C7.5 48.8 18.7 60 32.5 60ZM51.675 29.4C52.2 31.175 52.5 33.05 52.5 35C52.5 46.025 43.525 55 32.5 55C21.475 55 12.5 46.025 12.5 35C12.5 34.9574 12.5029 34.9147 12.5058 34.8731C12.5112 34.7926 12.5165 34.7159 12.5 34.65C19 32.2 24.225 27.175 26.85 20.775C31.45 26.4 38.425 30 46.25 30C48.125 30 49.925 29.775 51.675 29.4Z" fill={color} />
      <ellipse cx="32.5" cy="40" rx="5" ry="5" fill={color} />
      <path d="M17.5 40C22.9348 34.3403 36.5435 26.4168 47.5 40" stroke={color} strokeWidth="4" />
      <path d="M25.4272 2.5C21.8175 29.8923 41.9717 29.3769 52.5 25.6951C50.6949 18.6261 44.2278 14.6498 41.2199 13.5453C32.1958 11.778 26.9314 5.44541 25.4272 2.5Z" fill={color} stroke={color} />
    </svg>
  </SvgIcon>
);

GurappIcon.displayName = 'GurappIcon';
export default GurappIcon;
