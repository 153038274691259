import { useEffect, useState } from 'react';
import { Grid, RadioGroup } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import useDetails from '../../../useDetails';
import useDialog from '../../../../../../hooks/useDialog';
import useCategories from './useCategories';
import { categorySchema } from './Category.schema';
import { CategoryEntity } from '../../../../../../entities';
import SimpleChip from '../../../../../../components/chips/simple-chip/SimpleChip';
import SimpleDialog from '../../../../../../components/dialogs/SimpleDialog/SimpleDialog';
import SimpleModal from '../../../../../../components/dialogs/SimpleModal/SimpleModal';
import { RadioLabel, EditListItem } from '../../../../../../components/inputs';
import SimpleButton from '../../../../../../components/buttons/simple-button/SimpleButton';
import { PublicationTypeEntity } from '../../../../../../entities/PublicationEntity';
import useAnalytics from '../../../../../../hooks/useAnalytics/useAnalytics';

interface CategoryProps {
  form: any;
  type?: PublicationTypeEntity;
  disabled?: boolean;
}

const Category = ({ form, type, disabled }: CategoryProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('my-publication', { keyPrefix: 'details.step-detalles.category' });
  const { traceBtnClick } = useAnalytics();
  
  const { updateAuthPublication, status, error } = useDetails();
  const { fetchCategories, status: statusCategories, error: errorCategories, categories } = useCategories();
  const { dialog, onCloseDialog } = useDialog(error || errorCategories, 'Error');

  const localForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      categoryId: form.values.categoryId,
    },
    validationSchema: categorySchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      await updateAuthPublication(form?.values?.id, values);
      form.setFieldValue('categoryId', values.categoryId);
    },
  });

  const handleRadioCheck = (event: any) => {
    localForm.setFieldValue('categoryId', parseInt(event.target.value, 10));
  };

  const Content = () => {
    const category = categories.find((item: CategoryEntity) => item.id === form.values.categoryId);
    return (
      <SimpleChip label={category?.name} />
    );
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (status !== 'loaded') {
      return;
    }
    if (!error) {
      setOpen(false);
    }
  }, [status]);

  return (
    <>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      <SimpleModal
        open={open}
        onClose={() => { setOpen(false); }}
        title={t('title')}
        subtitle={t(`subtitle.${type}`, '') || t('subtitle.class')}
      >
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <RadioGroup onChange={handleRadioCheck}>
              {categories.map((category: CategoryEntity) => (
                <RadioLabel
                  key={category.id}
                  checked={localForm.values.categoryId === category.id}
                  value={category.id}
                  title={category.name}
                  description={category.description}
                  descriptionProps={{ fontSize: 13 }}
                  disabled={disabled || status === 'loading' || statusCategories === 'loading'}
                  direction="row"
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item alignSelf="flex-end">
            <SimpleButton 
              onClick={() => { 
                traceBtnClick({
                  step: 'step guardar category',
                  product: t('save') + 'item category',
                });
                localForm.handleSubmit();
              }} 
              disabled={disabled || status === 'loading' || statusCategories === 'loading'} 
              loading={status === 'loading'  || statusCategories === 'loading'}
            >
              {t('save')}
            </SimpleButton>
          </Grid>
        </Grid>
      </SimpleModal>
      <EditListItem
        label={t('title')}
        onClick={() => { 
          traceBtnClick({
            step: 'step abrir dialogo category',
            product: 'item category',
          });
          setOpen(true); 
        }}
        content={form.values.categoryId && <Content />}
        error={form.touched.categoryId && Boolean(form.errors.categoryId)}
        helperText={form.touched.categoryId && form.errors.categoryId}
        disabled={disabled}
      />
    </>
  );
};

Category.displayName = 'Category';

export default Category;
