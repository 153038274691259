import ScrollTopButton from '../buttons/scroll-top-button/ScrollTopButton';
import { CopyRight, FooterComponent, Logo } from './Footer.styled';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import useBodyHasScroll from '../../hooks/useBodyHasScroll';

const Footer = () => {
  let copyright = String.fromCodePoint(169);
  const { hasScroll } = useBodyHasScroll();
  return (
    <FooterComponent>
      <div>
        <Logo />
      </div>
      <div>
        <CopyRight> Copyright {copyright} {new Date().getFullYear()} GurApp. Todos los derechos reservados
          {'  |  Síguenos'}
          <a href="https://www.instagram.com/gurappcl" target="_blank" rel="noreferrer">
            <InstagramIcon htmlColor='white' fontSize='small'/>
          </a>
          <a href="https://web.facebook.com/profile.php?id=100025662520387" target="_blank" rel="noreferrer">
            <FacebookIcon htmlColor='white' fontSize='small'/>
          </a>
          {'Contáctanos'}
          <a href="mailto:contacto@gurapp.cl" target="_blank" rel="noreferrer">
            <EmailIcon htmlColor='white' fontSize='small'/>
          </a>
        </CopyRight>
      </div>
      {hasScroll && <ScrollTopButton></ScrollTopButton>}
    </FooterComponent>
  );
};

export default Footer;