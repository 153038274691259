import styled from '@emotion/styled';

export const ImageList = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  gap: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  transition: opacity 0.3s ease-in-out;
`;
