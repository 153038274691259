import styled from '@emotion/styled';
import { Container } from '@mui/material';
import { forwardRef } from 'react';
import Footer from '../../footer/Footer';

const BaseMainContainer = styled(Container)`
  padding-top: 8px;
  padding-bottom: 40px;
`;
BaseMainContainer.defaultProps = {
  maxWidth: 'xl',
};
interface MainContainerProps {
  showFooter?: boolean;
  children?: React.ReactNode;
}


const MainContainer = forwardRef<HTMLDivElement, MainContainerProps>(
  ({ showFooter, ...props }, ref) => (
    <>
      <BaseMainContainer {...props} ref={ref} />
      {showFooter && <Footer />}
    </>
  ),
);

export default MainContainer;