import Section from './Section';
import { Grid } from '@mui/material';

interface Props {
  contents: {
    value: string;
    label: string;
    description: string;
  }[];
}
const VerticalContent = ({ contents }: Props) => {
  return (
    <>
      {contents?.map((content) => (
				  !!content.description ? 
          <Grid item xs={12} key={content.label}>
            <Section title={content.label} description={content.description} key={content.label}/>
          </Grid> : null
      ))}
    </>
  );
};

export default VerticalContent;


