import { useEffect, useState } from 'react';
import {
  Grid, TextField, FormControl, InputAdornment, CircularProgress,
} from '@mui/material';
import { useFormik } from 'formik';
import SimpleDialog from '../../../../../../components/dialogs/SimpleDialog/SimpleDialog';
import SimpleModal from '../../../../../../components/dialogs/SimpleModal/SimpleModal';
import { useTranslation } from 'react-i18next';
import useDialog from '../../../../../../hooks/useDialog';
import usePrice from './usePrice.hook';
import { priceSchema } from './Price.schema';
import useDetails from '../../../useDetails';
import getPriceFormatted from '../../../../../../utils/getPriceFormatted';
import Subtitle from '../../../../../../components/typographies/subtitle/Subtitle';
import SimpleChip from '../../../../../../components/chips/simple-chip/SimpleChip';
import SimpleButton from '../../../../../../components/buttons/simple-button/SimpleButton';
import EditListItem from '../../../../../../components/inputs/edit-list-item/EditListItem';
import getNumberFormatCurrency from '../../../../../../utils/getNumberFormatCurrency';
import { PublicationTypeEntity } from '../../../../../../entities/PublicationEntity';
import useAnalytics from '../../../../../../hooks/useAnalytics/useAnalytics';

interface PriceProps {
  form: any;
  type?: PublicationTypeEntity;
  disabled?: boolean;
}

const Price = ({ form, type, disabled }: PriceProps) => {
  const [open, setOpen] = useState(false);
  const [delay, setDelay] = useState(false);
  const { t } = useTranslation('my-publication', { keyPrefix: 'details.step-detalles.price' });
  const { prices, setTotalPrice, status: statusPrice } = usePrice();
  const { updateAuthPublication, status, error } = useDetails();
  const { dialog, onCloseDialog } = useDialog(error, 'Error');
  const { traceBtnClick } = useAnalytics();
  
  const localForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: form.values.price,
    },
    validationSchema: priceSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      await updateAuthPublication(form?.values?.id, values);
      form.setFieldValue('price', values.price);
    },
  });

  const Content = () => <SimpleChip label={getPriceFormatted(form.values.price)} />;

  useEffect(() => {
    setDelay(true);
    const delayDebounceFn = setTimeout(() => {
      setTotalPrice(localForm.values.price || 0);
      setDelay(false);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [localForm.values.price]);

  useEffect(() => {
    if (status !== 'loaded') {
      return;
    }
    if (!error) {
      setOpen(false);
    }
  }, [status]);

  const inProgress = statusPrice === 'loading' || delay;
  return (
    <>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      <SimpleModal
        open={open}
        onClose={() => { setOpen(false); }}
        title={t('title')}
        subtitle={t(`subtitle.${type}`, '') || t('subtitle.class')}
      >
        <Grid container direction="column" alignItems="flex-end" spacing={1}>
          <Grid item>
            <FormControl margin="dense">
              <TextField
                name="price"
                label={t('label') as string}
                variant="outlined"
                value={localForm.values.price}
                onChange={localForm.handleChange}
                error={localForm.touched.price && Boolean(localForm.errors.price)}
                helperText={localForm.touched.price && localForm.errors.price as string}
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  inputProps: { min: 0 },
                }}
                disabled={disabled || status === 'loading'}
              />
            </FormControl>
          </Grid>
          <Grid item>
            {localForm.values.price > 0 && (
              <div style={{ minHeight: 60, display: 'flex', alignItems: 'center', textAlign: 'end' }}>
                <Subtitle variant="variant4">
                  <>
                    {`${t('complement')}:`}<br/>
                    {inProgress ? <CircularProgress size={16} /> : ` ${getNumberFormatCurrency(prices.profit)}`}
                  </>
                </Subtitle>
              </div>
            )}
          </Grid>
          <Grid item alignSelf="flex-end">
            <SimpleButton 
              onClick={() => { 
                traceBtnClick({
                  step: 'step guardar price',
                  product: t('save') + 'item price',
                });
                localForm.handleSubmit(); 
              }} 
              disabled={status === 'loading' || inProgress} 
              loading={status === 'loading'}
            >
              {t('save')}
            </SimpleButton>
          </Grid>
        </Grid>
      </SimpleModal>
      <EditListItem
        label={t('title')}
        onClick={() => { 
          traceBtnClick({
            step: 'step abrir dialogo price',
            product: 'item price',
          });
          setOpen(true); 
        }}
        content={(form.values.price) ? <Content /> : undefined}
        error={form.touched.price && Boolean(form.errors.price)}
        helperText={form.touched.price && form.errors.price}
        disabled={disabled}
      />
    </>
  );
};

Price.displayName = 'Price';
export default Price;
