import * as validator from 'yup';
import i18n from 'i18next';

export const modeSchema = validator.object({
  mode: validator.string().required(i18n.t('schemas:mode:required')),
  modeDetail: validator.string(),
  location: validator.object().when('mode', {
    is: 'online',
    then: (schema) => schema.notRequired(), 
    otherwise: (schema) => schema.required(i18n.t('schemas:location:required')),
  }),
});
