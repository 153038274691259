import { forwardRef, useContext } from 'react';
import { Grid, Drawer, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import CartItem from '../cart-item/CartItem';
import CartContext from '../../../contexts/CartContext';
import { StyledShoppingCartButton, StyledContainer } from './ShoppingCart.styled';
import getPriceFormatted from '../../../utils/getPriceFormatted';
import SimpleButton from '../../buttons/simple-button/SimpleButton';
import Label from '../../typographies/label/Label';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';

interface ShoppingCartProps {
  style?: any;
}

const ShoppingCart = forwardRef<HTMLButtonElement, ShoppingCartProps>(
  (props, ref) => {
    const navigate = useNavigate();
    const { t } = useTranslation('components', { keyPrefix: 'shopping-cart' });
    const { traceBtnClick } = useAnalytics();
    const {
      cart = [], openCart, setOpenCart, totalPrice, totalQuantity,
    } = useContext(CartContext);
    const handleGoToCart = () => { 
      traceBtnClick({
        step: 'step ir a checkout',
        product: t('buyButton'),
      });
      navigate('/checkout'); 
    };
    const onOpenDrawer = () => { 
      traceBtnClick({
        step: 'step abrir carrito lateral',
        product: 'icono carrito',
      });
      setOpenCart(true); 
    };
    const onCloseDrawer = () => { 
      traceBtnClick({
        step: 'step cerrar carrito lateral',
        product: 'icono carrito',
      });
      setOpenCart(false); 
    };

    return (
      <>
        <StyledShoppingCartButton
          variant="text"
          startIcon={<ShoppingCartOutlinedIcon />}
          onClick={onOpenDrawer}
          {...props}
          ref={ref}
        >
          {(totalQuantity > 0) ? (
            <Label variant="variant2">{`${totalQuantity}`}</Label>
          ) : ''}
        </StyledShoppingCartButton>

        <Drawer open={openCart} anchor="right" onClose={onCloseDrawer}>
          <StyledContainer maxWidth="sm">
            <Grid container direction="row" justifyContent="space-between" spacing={2}>
              <Grid item xs={11} paddingBottom={2}>
                <Label variant="variant2">
                  {t('title')}
                </Label>
              </Grid>
              <Grid item xs={1}>
                <IconButton 
                  aria-label="Cerrar" 
                  component="span" 
                  onClick={onCloseDrawer} 
                  style={{ padding: 0, float: 'right' }}
                >
                  <HighlightOffIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {cart.map((item: any) => (
                  <CartItem {...item} key={item.group.id} />
                ))}
              </Grid>
              {(cart?.length > 0 && totalPrice > 0) && (
                <>
                  <Grid item xs={8} textAlign="right" paddingTop={2}>
                    <Label>{t('totalPrice')}</Label>
                  </Grid>
                  <Grid item xs={4} textAlign="right" paddingTop={2}>
                    <Label>{getPriceFormatted(totalPrice)}</Label>
                  </Grid>
                  <Grid item xs={12} textAlign="right">
                    <SimpleButton onClick={handleGoToCart}>{t('buyButton')}</SimpleButton>
                  </Grid>
                </>
              )}
              {cart?.length === 0 && (
                <Grid item xs={12} textAlign="center" marginTop={8}>
                  <Label>{t('empty')}</Label>
                </Grid>
              )}
            </Grid>
          </StyledContainer>
        </Drawer>
      </>
    );
  },
);

ShoppingCart.displayName = 'ShoppingCart';
export default ShoppingCart;
