import { forwardRef, useState } from 'react';
import { FormHelperText, Grid, IconButton, TextField, Typography } from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { getIn } from 'formik';
import { GroupEntity } from '../../../../../entities';
import { LinkButton } from '../../../../../components/buttons';
import { disableToday, getNewInitialGroup, isIdTemporal } from '../StepHorarios.utils';
import useDetails from '../../useDetails';
import DeleteDialog from '../components/delete-dialog/DeleteDialog';
import useDialog from '../../../../../hooks/useDialog';
import SimpleDialog from '../../../../../components/dialogs/SimpleDialog/SimpleDialog';
import { DateInput, SelectTime } from '../../../../../components/inputs';
import useAnalytics from '../../../../../hooks/useAnalytics/useAnalytics';

interface PeriodicityNoProps {
  statusUpdate?: string;
  form: any;
}

const PeriodicityNo = forwardRef<HTMLDivElement, PeriodicityNoProps>(
  ({
    statusUpdate,
    form = undefined,
    ...props
  }, ref) => {
    const { removeAuthGroup, error: errorRemove } = useDetails();
    const { traceBtnClick } = useAnalytics();
    const [deleteDialog, setDeleteDialog] = useState({});
    const { dialog, onCloseDialog } = useDialog(errorRemove, 'Error');
    const onCloseDeleteDialog = () => { setDeleteDialog({ ...deleteDialog, open: false }); };
		
    const addGroup = () => {
      form.setFieldValue('groups', [
        ...form.values.groups, 
        getNewInitialGroup(),
      ]);
    };

    const deleteGroupForm = (group: GroupEntity) => {
      const values = [...form.values.groups];
      const indexToDelete = form.values.groups.findIndex((item: GroupEntity) => item.id === group.id);
      if (indexToDelete > -1) {
        values.splice(indexToDelete, 1);
      }
      form.setFieldValue('groups', values);
    };

    const handleDeleteGroup = (group: GroupEntity) => {
      if (isIdTemporal(group?.id)) {
        deleteGroupForm(group);
        return;
      }
      setDeleteDialog({ open: true, value: group, groupHasSold: group?.hasSold });
    };

    const onContinueDeleteGroup = (value: any) => {
      if (value.id) {
        removeAuthGroup(value.id);
        deleteGroupForm(value);
        onCloseDeleteDialog();
      }
    };

    return (
      <div {...props} ref={ref}>
        <SimpleDialog {...dialog} onClose={onCloseDialog} />
        <DeleteDialog {...deleteDialog} onClose={onCloseDeleteDialog} onContinue={onContinueDeleteGroup} />
        {form.values.groups?.map((group: GroupEntity, index: number) => {
          const groupName = `groups[${index}]`;
          const sessionName = `${groupName}.sessions[0]`;
          const helperText = (group?.quotaSold || 0) > 0 ? `¡Ya vendiste ${group.quotaSold} cupo${(group?.quotaSold || 0) > 1 ? 's' : ''}!` : '';
          const showDelete = (!group?.hasSold && !isIdTemporal(group?.id)) || index !== 0;

          return (
            <Grid container columns={12} spacing={1} key={group.id} mb={1}>
              <Grid item xs={12} alignSelf='center'>
                <Typography variant="body2" style={{ fontWeight: 300 }}>{`Clase ${index + 1 }`}</Typography>
              </Grid>
							
              <Grid item xs={12} sm={4} md={12} lg={4}>
                <DateInput
                  disablePast
                  shouldDisableDate={disableToday}
                  label={'Fecha'}
                  value={getIn(form.values, `${sessionName}.date`)}
                  onChange={(value) => {
                    form.setFieldValue( `${sessionName}.date`, value);
                  }}
                  disabled={!!group?.hasSold || statusUpdate === 'loading'}
                  size='small'
                  fullWidth 
                  renderInput={() => <></>} 
                  name={`${sessionName}.date`} 
                  error={getIn(form.touched, `${sessionName}.date`) && Boolean(getIn(form.errors, `${sessionName}.date`))}
                  helperText={getIn(form.touched, `${sessionName}.date`) && getIn(form.errors, `${sessionName}.date`)}
                />
              </Grid>
              <Grid item xs={4} sm={2.5} md={4} lg={2.5}>
                <SelectTime
                  name={`${sessionName}.startTime`}
                  onChange={(value) => {
                    form.setFieldValue( `${sessionName}.startTime`, value);
                  }}
                  value={getIn(form.values, `${sessionName}.startTime`)}
                  error={getIn(form.touched, `${sessionName}.startTime`) && Boolean(getIn(form.errors, `${sessionName}.startTime`))}
                  helperText={getIn(form.touched, `${sessionName}.startTime`) && getIn(form.errors, `${sessionName}.startTime`)}
                  label='Hora inicio'
                  fullWidth
                  disabled={!!group?.hasSold || statusUpdate === 'loading'}
                />
              </Grid>
              <Grid item xs={4} sm={2.5} md={4} lg={2.5}>
                <SelectTime
                  name={`${sessionName}.endTime`}
                  onChange={(value) => {
                    form.setFieldValue( `${sessionName}.endTime`, value);
                  }}
                  value={getIn(form.values, `${sessionName}.endTime`)}
                  error={getIn(form.touched, `${sessionName}.endTime`) && Boolean(getIn(form.errors, `${sessionName}.endTime`))}
                  helperText={getIn(form.touched, `${sessionName}.endTime`) && getIn(form.errors, `${sessionName}.endTime`)}
                  label='Hora término'
                  fullWidth
                  disabled={!!group?.hasSold || statusUpdate === 'loading'}
                />
              </Grid>
              <Grid item xs={4} sm={3} md={4} lg={3}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    size='small'
                    name={`${groupName}.quota`}
                    label={'Cupos'}
                    variant="outlined"
                    onChange={form.handleChange}
                    value={getIn(form.values, `${groupName}.quota`)}
                    error={getIn(form.touched, `${groupName}.quota`) && Boolean(getIn(form.errors, `${groupName}.quota`))}
                    helperText={getIn(form.touched, `${groupName}.quota`) && getIn(form.errors, `${groupName}.quota`)}
                    type="number"
                    disabled={statusUpdate === 'loading'}
                    fullWidth
                    InputLabelProps={{ style: { fontSize: '0.875rem' } }}
                    inputProps={{
                      style: { fontSize: '0.875rem', height: '23px' },
                      min: 0,
                    }}
                  />
                  {showDelete && (
                    <IconButton 
                      aria-label="eliminar" 
                      onClick={() => { 
                        traceBtnClick({
                          step: `step eliminar grupo ${isIdTemporal(group?.id) ? 'temporal' : 'no temporal'} en clase sin periodicidad`,
                          product: 'icono eliminar',
                        });
                        handleDeleteGroup(group); 
                      }} 
                      disabled={statusUpdate === 'loading'}
                    >
                      <DeleteForeverOutlinedIcon fontSize="small" />
                    </IconButton>
                  )}
                </div>
                {helperText && (
                  <FormHelperText>
                    {helperText}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          );
        })}
        <Grid container item mt={2}>
          <LinkButton
            onClick={(event) => {
              traceBtnClick({
                step: 'step agregar grupo en clase sin periodicidad',
                product: '+ pulsa aquí para agregar más clases',
              });
              addGroup();
              event.preventDefault();
            }}
            texts={['', '+ pulsa aquí para agregar más clases']}
            disabled={statusUpdate === 'loading'}
          />
        </Grid>
      </div>
    );
  },
);

PeriodicityNo.displayName = 'PeriodicityNo';
export default PeriodicityNo;
