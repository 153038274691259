import { forwardRef, ReactElement } from 'react';
import {
  ListItemText, IconButton, FormHelperText, CircularProgress, ListItemButton, ListItem, 
} from '@mui/material';
import { EditOutlined as DefaultIcon } from '@mui/icons-material';
import { DeleteForeverOutlined as  DeleteForeverOutlinedIcon } from '@mui/icons-material';

import {
  StyledListItem,
  StyledListItemIcon,
} from './EditListItem.styled';

interface EditListItemProps {
  label: string;
  content?: ReactElement;
  icon?: ReactElement;
  disabled?: boolean;
  error?: boolean;
  helperText?: any;
  loading?: boolean;
  onClick?: () => any;
  onDelete?: () => any;
  type?: 'ListItem' | 'ListItemButton';
}

const EditListItem = forwardRef<HTMLLIElement, EditListItemProps>(
  ({
    label,
    content,
    icon = <DefaultIcon />,
    disabled = false,
    error,
    helperText,
    loading,
    onClick,
    onDelete,
    type = 'ListItemButton',
    ...rest
  }, ref) => {
    const secondaryAction = (
      <StyledListItemIcon>
        {loading && <CircularProgress size={24} color="inherit" />}
        {!!onDelete && (
          <IconButton size="small" aria-label="delete" disabled={disabled} onClick={onDelete}>
            <DeleteForeverOutlinedIcon />
          </IconButton>
        )}
        <IconButton size="small" aria-label={label} disabled={disabled} onClick={onClick}>
          {icon}
        </IconButton>
      </StyledListItemIcon>
    );

    const listItemText = <ListItemText
      primary={label}
      secondary={content ?? <></>}
      secondaryTypographyProps={{ component: 'div', minHeight: 16 }}
    />;

    return (
      <>
        <StyledListItem
          ref={ref} 
          error={error ? 1 : 0}
          secondaryAction={secondaryAction}
          dense
          disablePadding
          {...rest}
        >
          {type === 'ListItem' ? 
            <ListItem style={{ paddingRight: 48  }}>{listItemText}</ListItem> : 
            <ListItemButton onClick={onClick}>{listItemText}</ListItemButton>
          }
        </StyledListItem>
        {error && (
          <FormHelperText variant="outlined" error>
            {typeof helperText === 'object' ? Object.values(helperText).join(', ') : helperText}
          </FormHelperText>
        )}
      </>
    );
  },
);

EditListItem.displayName = 'EditListItem';
export default EditListItem;
