import { FormControlLabel, Radio, Typography } from '@mui/material';

interface RadioLabelProps {
  title?: string;
  description?: string;
  checked?: boolean;
  value?: any;
  direction?: 'row' | 'column';
  style?: any;
  titleProps?: any;
  descriptionProps?: any;
  disabled?: boolean;
}

const RowContainerStyle = { display: 'flex', gap: '4px', alignItems: 'baseline' };

const RadioLabel = ({ 
  checked, 
  value, 
  title, 
  description, 
  direction, 
  titleProps = {}, 
  descriptionProps = {},
  disabled,
  style,
  ...props
}: RadioLabelProps) => (
  <FormControlLabel
    value={value}
    control={(<Radio size="small" checked={checked} disabled={disabled}/>)}
    label={<div style={direction === 'row' ? RowContainerStyle : {}}>
      <Typography fontWeight={500} {...titleProps}>{title}</Typography>
      <Typography fontWeight={300} {...descriptionProps}>{description}</Typography>
    </div>}
    disabled={disabled}
    style={style}
    {...props}
  />
);

RadioLabel.displayName = 'RadioLabel';
export default RadioLabel;
