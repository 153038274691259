import { CircularNumber, CardContainer, Content, StepContainer, Subtitle, Title, Step } from './HowToBeGuruCard.styled';
import { StrongText } from '../../../pages/be-guru/BeGuru.styled';


const HowToBeGuruCard = () => {

  const stepsToBeGuru = [
    {
      id: '1',
      title: 'Crea tu perfil\nGurú',
      description: 'Es importante que tus futuros exploradores sepan quien eres y cuál es tu metodología. Te guiaremos en cada paso ¡No te preocupes!',
    },
    {
      id: '2',
      title: 'Publica tus alternativas\nde cursos',
      description: 'Crea todos los grupos que quieras y sube tus clases. Debes añadir información relevante, como la descripción del curso, requisitos y horarios, entre otras cosas',
    },
    {
      id: '3',
      title: '¡Listo! Ya puedes dictar tus\nclases y generar ingresos',
      description: 'Una vez que tus clases estén publicadas, los exploradores podrán contratarlas. ¡Nosotros nos encargamos de gestionar el pago!',
    },
  ];

  return (
    <div>
      <CardContainer>
        <Title><StrongText>Ser Gurú</StrongText>  nunca fue tan fácil</Title>
        <StepContainer>
          {
            stepsToBeGuru.map((stepToBeGuru) => (
              <Step key={stepToBeGuru.id}>
                <CircularNumber>{stepToBeGuru.id}</CircularNumber>
                <Subtitle>
                  {stepToBeGuru.title}
                </Subtitle>
                <Content>
                  {stepToBeGuru.description}
                </Content>
              </Step>
            ))
          }
        </StepContainer>
      </CardContainer>
    </div>
  );
};

export default HowToBeGuruCard;