import { forwardRef } from 'react';
import { ChipProps as MuiChipProps } from '@mui/material';
import { StyledChip } from './SimpleChip.styled';

interface ChipProps extends MuiChipProps {
  label?: string;
  color?: any;
  maxCharacters?: number;
}
const MAX_CHARACTERS = 25;

const SimpleChip = forwardRef<HTMLDivElement, ChipProps>(
  ({ label = '', maxCharacters = MAX_CHARACTERS, ...props }, ref) => {
    const labelChip = maxCharacters > 0 && label?.length > maxCharacters ? `${label.substring(0, maxCharacters)}...` : label;
    return (
      <StyledChip
        color="secondary"
        ref={ref}
        label={labelChip}
        {...props}
      />
    );
  },
);

SimpleChip.displayName = 'SimpleChip';
export default SimpleChip;
