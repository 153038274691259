import { Grid } from '@mui/material';
import { forwardRef } from 'react';
import { NavigationBar, PublicationStepper } from '../../navigations';
import DividedContainer from '../divided-container/DividedContainer';

interface PublicationStepsContainerProps {
  activeStepIndex?: number;
  children?: React.ReactNode;
}


const PublicationStepsContainer = forwardRef<HTMLDivElement, PublicationStepsContainerProps>(
  ({ activeStepIndex, children }, ref) => (
    <>
      <DividedContainer variant='variant1' ref={ref}>
        <NavigationBar variant="variant4" />
        <Grid container direction="column">
          <Grid item xs={12} mb={2}>
            <PublicationStepper activeStepIndex={activeStepIndex} />
          </Grid>
        </Grid>
        {children}
      </DividedContainer>
    </>
  ),
);

export default PublicationStepsContainer;