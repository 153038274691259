import { forwardRef, ReactElement } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import styled from '@emotion/styled';

interface PageSubtitleProps extends TypographyProps {
  children?: string | ReactElement;
}
const StyledTypography = styled(Typography)`
`;

const PageSubtitle = forwardRef<HTMLParagraphElement, PageSubtitleProps>(
  ({ ...rest }, ref) => {
    return <StyledTypography variant="h2" ref={ref} {...rest} />;
  },
);

PageSubtitle.displayName = 'PageSubtitle';
export default PageSubtitle;
