import { Grid, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { NavigationBottom } from '../../../../../components/navigations';
import useDetails from '../../useDetails';
import { GroupEntity, PublicationEntity } from '../../../../../entities';
import { LinkButton } from '../../../../../components/buttons';
import SimpleDialog from '../../../../../components/dialogs/SimpleDialog/SimpleDialog';
import useDialog from '../../../../../hooks/useDialog';
import { getNewInitialGroup, initialGroup } from '../StepHorarios.utils';
import Group from './Group';
import { stepHorariosCourseSchema } from '../StepHorarios.schema';
import PageSubtitle from '../../../../../components/typographies/PageSubtitle';
import useAnalytics from '../../../../../hooks/useAnalytics/useAnalytics';
import getPublicationId from '../../../../../utils/getPublicationId';

interface StepHorariosCourseProps {
  publication: PublicationEntity;
}
const StepHorariosCourse = ({ publication }: StepHorariosCourseProps) => {
  const { identifier } = useParams();
  const id = getPublicationId(identifier as string);
  const { t } = useTranslation('my-publication', { keyPrefix: 'details.step-horarios.course' });
  const navigate = useNavigate();
  const { traceBtnClick } = useAnalytics();
  
  const { toReviewAuthPublication, status: statusUpdate, error: errorUpdate } = useDetails();
  const { dialog, onCloseDialog } = useDialog(errorUpdate, 'Error');

  const publicationOriginalGroups = publication?.groups || [];
  const initialGroups: any = publicationOriginalGroups.length > 0 ? 
    publication?.groups : [initialGroup];

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      groups: initialGroups,
    },
    validationSchema: stepHorariosCourseSchema,
    validateOnChange: true,
    onSubmit: async () => {
      const toReview = publication?.state === 'draft';
      if (toReview) {
        const successToReview = await toReviewAuthPublication(publication.id || 0);
        if (!successToReview) {
          return;
        }
      }
      navigate(`/my-publication/${identifier}/details/final-step?type=${publication.type}&toReview=${toReview}`);
    },
  });

  const addGroup = () => {
    form.setFieldValue('groups', [
      ...form.values.groups, 
      getNewInitialGroup(),
    ]);
  };

  return (
    <>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageSubtitle>{t('subtitle')}</PageSubtitle>
        </Grid>
      </Grid>
      <Grid container direction="column">
        {form.values.groups.map((group: GroupEntity) => { 
          const warningDelete = `¡Ya tienes ${group?.quotaSold} vendido${group?.quotaSold && group?.quotaSold > 1 ? 's' : ''}!, sólo puedes cambiar los cupos o eliminar esta opción.`;

          return (
            <Grid item xs={12} key={group.id}>
              <Group 
                form={form} 
                disabled={statusUpdate === 'loading'}
                group={group}
                publication={publication}
              />
              {!!group?.hasSold && (
                <Grid item xs={12}>
                  <FormHelperText error>
                    {warningDelete}
                  </FormHelperText>
                </Grid>
              )}
            </Grid>
          );
        })}
      </Grid>
      <Grid container marginTop={2}>
        <Grid item>
          <LinkButton
            onClick={(event) => {
              traceBtnClick({
                step: 'step agregar más cursos',
                product: t('add-more-courses') + t('add-more-courses-2'),
              });
              addGroup();
              event.preventDefault();
            }}
            texts={[t('add-more-courses'), t('add-more-courses-2')]}
          />
        </Grid>
      </Grid>
        
      <NavigationBottom 
        handleClickSave={() => form.handleSubmit()}
        handleClickPrevious={()=> {
          const previousPageIdentifier = `/my-publication/${identifier}/details/step-agenda`;
          const previousPageId = `/my-publication/${id}/details/step-agenda`;
          const from = sessionStorage.getItem('from');
          return (from === previousPageIdentifier || from === previousPageId) ? navigate(-1) : navigate(previousPageIdentifier);
        }}
        disabled={statusUpdate === 'loading'}
        saveText={publication.state === 'draft' ? t('save_draft') : t('finish')} 
        saveProps={{ loading: statusUpdate === 'loading' }}
      />
    </>
  );
};

export default StepHorariosCourse;
