import API from './config/apiInstance';
import apiError from './apiError';

export async function postQuestionAnswer(
  publicationId: number, 
  question: string,
) {
  try {
    await API.post('/question-answer', {
      publicationId,
      question,
    });
  } catch (error) {
    throw apiError({
      code: '/question-answer/postQuestionAnswer',
      message: 'Hubo un problema al intentar guardar pregunta',
    });
  }
}

export async function putQuestionAnswer(
  questionAnswerId: number, 
  answer: string,
) {
  try {
    await API.put(`/auth/question-answer/${questionAnswerId}`, {
      answer,
    });
  } catch (error) {
    throw apiError({
      code: '/question-answer/putQuestionAnswer',
      message: 'Hubo un problema al intentar guardar respuesta',
    });
  }
}
