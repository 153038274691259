import { useNavigate } from 'react-router-dom';
import { Chip, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DividedContainer from '../../../components/containers/divided-container/DividedContainer';
import NavigationBar from '../../../components/navigations/navigation-bar/NavigationBar';
import { ListButton, SimpleButton } from '../../../components/buttons';
import FlagContext from '../../../contexts/FlagContext';
import { useContext } from 'react';
import useGuru from './useGuru';
import PageTitle from '../../../components/typographies/PageTitle';
import PageSubtitle from '../../../components/typographies/PageSubtitle';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';

const Guru = () => {
  const { financesAvailable } = useContext(FlagContext);
  const { countMyDrafts, countMyPublications, status } = useGuru();
  const { t } = useTranslation(['my-account']);
  const navigate = useNavigate();
  const { traceBtnClick } = useAnalytics();
  
  return (
    <DividedContainer>
      <NavigationBar variant="variant4" />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageTitle>{t('guru.title')}</PageTitle>
        </Grid>
        <Grid item xs={12}>
          <PageSubtitle>{t('guru.subtitle')}</PageSubtitle>
        </Grid>
        <Grid item xs={12}>
          <ListButton
            title={t('guru.newPublicationItem')}
            onClick={() => { 
              traceBtnClick({
                step: 'step crear nueva publicación',
                product: t('guru.newPublicationItem'),
              });
              navigate('/new-publication');
            }}
            selected
          />
        </Grid>
        <Grid item xs={12}>
          <ListButton
            title={t('guru.publicationsItem')}
            onClick={() => { 
              traceBtnClick({
                step: 'step ir a mis publicaciones',
                product: t('guru.publicationsItem'),
              });
              navigate('/my-publications');
            }}
            disabled={status === 'loading' || (countMyPublications === 0 && status === 'loaded')}
            loading={status === 'loading'}
          >
            {countMyPublications > 0 && <Chip label={countMyPublications} />}
          </ListButton>
        </Grid>
        <Grid item xs={12}>
          <ListButton
            title={t('guru.draftsItem')}
            onClick={() => { 
              traceBtnClick({
                step: 'step ir a mis borradores',
                product: t('guru.draftsItem'),
              });
              navigate('/my-publications?state=draft');
            }}
            disabled={status === 'loading' || (countMyDrafts === 0 && status === 'loaded')}
            loading={status === 'loading'}
          >
            {countMyDrafts > 0 && <Chip label={countMyDrafts} />}
          </ListButton>
        </Grid>
        {financesAvailable && (
          <Grid item xs={12}>
            <ListButton
              title={t('guru.financesItem')}
              onClick={() => { 
                traceBtnClick({
                  step: 'step ir a mis finanzas',
                  product: t('guru.financesItem'),
                });
                navigate('/my-account/guru/finances');
              }}
            />
          </Grid>
        )}
        <Grid item paddingTop={2}>
          <SimpleButton 
            variant='text' 
            onClick={() => { 
              traceBtnClick({
                step: 'step ir a mi cuenta',
                product: t('guru.financesItem'),
              });
              navigate('/my-account'); 
            }}
          >
            Ir a Mi Cuenta
          </SimpleButton>
        </Grid>
      </Grid>
    </DividedContainer>
  );
};

export default Guru;
