import * as validator from 'yup';
import i18n from 'i18next';

import emailSchema from '../../../../schemas/email/emailSchema';
import passwordSchema from '../../../../schemas/password/passwordSchema';

export const formCreateAccountSchema = validator.object({
  email: emailSchema,
  password: passwordSchema,
  repeatPassword: validator
    .string()
    .required(i18n.t('schemas:repeatPassword:required'))
    .oneOf([validator.ref('password')], i18n.t('schemas:repeatPassword:repeatNotMatches')),
});
