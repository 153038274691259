import API from './config/apiInstance';
import apiError from './apiError';

export async function putAuthGroups(publicationId: number, payload: any) {
  try {
    await API.put(`/auth/groups?publicationId=${publicationId}`, payload);
  } catch (error) {
    throw apiError({
      code: '/auth/publication/putAuthGroups',
      message: 'Hubo un problema al modificar grupos',
    });
  }
}

export async function deleteAuthGroup(groupId: number) {
  try {
    await API.delete(`/auth/group/${groupId}`);
  } catch (error) {
    throw apiError({
      code: '/auth/group/:id',
      message: 'Hubo un problema al eliminar grupo',
    });
  }
}

export async function getAuthExplorersByGroup(groupId: number) {
  try {
    const response = await API.get(`/auth/group/${groupId}/explorers/csv`, { 
      'responseType': 'blob', 
    });
    return response;
  } catch (error) {
    throw apiError({
      code: '/auth/group/:id/explorers/csv',
      message: 'Hubo un problema al obtener exploradores de grupo',
    });
  }
}
