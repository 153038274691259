import API from './config/apiInstance';
import apiError from './apiError';

export async function deleteAuthSession(sessionId: number) {
  try {
    await API.delete(`/auth/session/${sessionId}`);
  } catch (error) {
    throw apiError({
      code: '/auth/session/:id',
      message: 'Hubo un problema al eliminar sesión',
    });
  }
}
