import { useState } from 'react';
import { PublicationEntity } from '../../entities';
import { postAuthPublication } from '../../services/authPublication';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
  data?: PublicationEntity;
}

const useNewPublication = () => {
  const [state, setState] = useState<StateProps>({ status: 'idle', error: null });

  const createAuthPublication = async (publication: PublicationEntity) => {
    setState({ status: 'loading' });
    try {
      const response = await postAuthPublication(publication);
      setState({ status: 'loaded' });
      return +(response.headers?.location || 0);
    } catch (error) {
      setState({ status: 'loaded', error });
      return null;
    }
  };

  return {
    ...state,
    createAuthPublication,
  };
};

export default useNewPublication;