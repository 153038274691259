import { useState } from 'react';
import { CategoryEntity } from '../../../../../../entities';
import { getCategories } from '../../../../../../services/category';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: 'generic';
  categories: CategoryEntity[];
}

const useCategories = () => {
  const [state, setState] = useState<StateProps>({
    status: 'idle',
    categories: [],
  });

  const fetchCategories = async () => {
    setState({ status: 'loading', categories: [] });
    try {
      const response = await getCategories();
      setState({
        status: 'loaded',
        categories: response?.data,
      });
    } catch (error: any) {
      setState({ status: 'loaded', categories: [], error: error?.code || 'generic' });
    }
  };

  return {
    ...state,
    fetchCategories,
  };
};

export default useCategories;