import { forwardRef, useState } from 'react';
import StarRateIcon from '@mui/icons-material/StarRate';
import { PublicationEntity } from '../../../entities';
import getPriceFormatted from '../../../utils/getPriceFormatted';
import getRoundedEvaluation from '../../../utils/getRoundedEvaluation';
import defaultImagePublication from '../../images/default-image-publication/defaultImagePublication.webp';
import PublicationChip from '../../chips/publication-chip/PublicationChip';
import { StyledChipContainer, CardNoActionArea, Title } from './PublicationCard.styled';
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';

interface PublicationCardProps {
  publication: PublicationEntity;
  header?: string;
  onClick?: () => void;
}

const getChipType = (publication: PublicationEntity) => {
  if (publication?.state === 'approved') {
    return publication?.active ? 'active' : 'no-active';
  }
  return publication?.state;
};

const PublicationCard = forwardRef<HTMLDivElement, PublicationCardProps>(
  ({
    publication, header, onClick, ...props
  }, ref) => {
    const chipType = getChipType(publication);
    const CardArea = !!onClick ? CardActionArea : CardNoActionArea;
    const borderRadius = 4;
    const [isLoading, setIsLoading] = useState(true);
    return (
      <Card 
        elevation={0} 
        sx={{ 
          maxWidth: 290, 
          margin: 'auto', 
          borderRadius,
          opacity: isLoading ? 0 : 1,
          transition: 'opacity 0.3s ease-in-out',
        }} 
        {...props} 
        ref={ref}
      >
        <CardArea onClick={onClick}>
          <CardMedia
            component="img"
            alt={`Publication ${publication?.title}`}
            height="315"
            image={publication?.audiovisuals?.find((visual: any) => visual.fav)?.url || defaultImagePublication}
            sx={{ borderRadius }}
            onLoad={() => setIsLoading(false)}
          />
          <CardContent style={{ padding: '12px 0px' }}>
            {!!header && (
              <Typography fontSize={13}>
                {header}
              </Typography>
            )}
            {publication?.evaluation && (
              <Grid container>
                <Grid item xs={2}>
                  <StarRateIcon style={{ color: '#faaf00' }} />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body1' fontSize={13}>
                    {getRoundedEvaluation(publication?.evaluation)} ({publication?.evaluationCount ? publication?.evaluationCount : 0})
                  </Typography>
                </Grid>
              </Grid>
            )}
            {!!publication?.title && (
              <Title fontWeight={500} fontSize={15}>
                {publication?.title}
              </Title>
            )}
            {!!publication?.price && (
              <Typography fontSize={13} textAlign={'right'}>
                {getPriceFormatted(publication?.price)}
              </Typography>
            )}
            {chipType && (
              <StyledChipContainer>
                <PublicationChip type={chipType} />
              </StyledChipContainer>
            )}
          </CardContent>
        </CardArea>
      </Card>
    );
  },
);

PublicationCard.displayName = 'PublicationCard';
export default PublicationCard;
