import styled from '@emotion/styled';
import { Paper } from '@mui/material';

export const ContainerImageItem = styled(Paper)`
  padding: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const ContainerControls = styled.div`
`;

export const ContainerTopControls   = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerBottomControls = styled.div`
  display: flex;
  align-items: center;
`;
