import styled from '@emotion/styled';
import { ListItemButton, ListItemIcon, ListItem } from '@mui/material';

export const StyledListItemButton = styled(ListItemButton)`
  background-color: white;
  box-shadow: 0px 0px 3px rgba(32, 10, 10, 0.25);
  margin: 8px 0 0 0;
`;

export const StyledListItem = styled(ListItem)`
  background-color: white;
  box-shadow: 0px 0px 3px rgba(32, 10, 10, 0.25);
  margin: 8px 0 0 0;
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  justify-content: flex-end;
`;
