import { forwardRef, ReactElement } from 'react';
import { ButtonProps } from '@mui/material';
import { StyledIconButton } from './IconButton.styled';

interface IIconButton extends ButtonProps {
  children: ReactElement;
  size?: 'small' | 'medium' | 'large' | undefined;
}

const IconButton = forwardRef<HTMLButtonElement, IIconButton>(
  ({ children, size = 'large', ...rest }, ref) => (
    <StyledIconButton size={size} ref={ref} {...rest}>
      {children}
    </StyledIconButton>
  ),
);

IconButton.displayName = 'IconButton';
export default IconButton;
