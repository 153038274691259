import { forwardRef } from 'react';
import { CircularProgress, FormHelperText } from '@mui/material';
import {
  StyledButton,
  StyledTypography,
} from './CartControl.styled';

interface CartControlProps {
  onClickAddOne: () => void;
  onClickRemoveOne: () => void;
  quantity: number;
  disabled?: boolean;
  loading?: boolean;
  limit?: number;
}

const CartControl = forwardRef<HTMLDivElement, CartControlProps>(
  ({
    onClickAddOne, onClickRemoveOne, quantity, disabled, loading, limit, ...rest
  }, ref) => {
    return (
      <>
        <div ref={ref} {...rest}>
          {loading && <CircularProgress size={24} />}
          {!loading && (
            <>
              <StyledTypography fontWeight={500}>{`${quantity || 1} un`}</StyledTypography>
              <StyledButton variant="text" onClick={onClickRemoveOne} disabled={disabled}>
              -
              </StyledButton>
              <StyledButton variant="text" onClick={onClickAddOne} disabled={disabled || (!!limit && quantity === limit)}>
              +
              </StyledButton>
            </>
          )}
        </div>
        <FormHelperText error>
          {(!!limit && quantity === limit) && (
            quantity > 1 ? `Lo sentimos, solo quedan ${quantity} cupos` : `Lo sentimos, solo queda ${quantity} cupo`
          )}
        </FormHelperText>
      </>
    );
  },
);

CartControl.displayName = 'CartControl';
export default CartControl;
