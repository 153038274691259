import { Rating, Typography, Grid } from '@mui/material';
import { ReviewEntity } from '../../../entities';
import { Label } from '../../typographies';

const Review = ({ evaluation, comment, date }: ReviewEntity) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Rating
          value={evaluation}
          precision={0.5}
          readOnly={true}
        />
      </Grid>
      <Grid item xs={6}>
        <Label variant={'variant4'}>{date.toString()}</Label>
      </Grid>
      <Grid item xs={12}>
        <Typography>{comment}</Typography>
      </Grid>
    </Grid>
  );
};

export default Review;