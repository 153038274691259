import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SimpleButton } from '../buttons';
import { Content, Subtitle } from '../cards/how-to-be-guru/HowToBeGuruCard.styled';

interface SimpleAccordionProps {
  id: string;
  title: string;
  content: string;
  expanded: boolean;
  handleChange: (id: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  handleButton?: () => void;
  textButton?: string;
}

const SimpleAccordion = React.forwardRef<HTMLDivElement, SimpleAccordionProps>(
  ({ id, title, content, expanded, handleChange, handleButton, textButton = 'Saber más', ...rest }, ref) => {
    const contentParts = content.split(/(\*[^*]+\*)/g);

    return (
      <Accordion 
        id={id}
        expanded={expanded} 
        onChange={handleChange?.(id)} 
        sx={{ backgroundColor: 'transparent' }}
        {...rest}
        ref={ref}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${id}bh-content`}
          id={`panel${id}bh-header`}
        >
          <Subtitle>{title}</Subtitle>
        </AccordionSummary>
        <AccordionDetails style={{ paddingTop: 0 }}>
          <Content style={{ textAlign: 'start' }}>
            {contentParts.map((part, index) => {
              if (part.startsWith('*') && part.endsWith('*')) {
                // Eliminar los asteriscos y aplicar estilo de negrita
                const boldText = part.slice(1, -1);
                return (
                  <span key={index} style={{ fontWeight: 'bold' }}>{boldText}</span>
                );
              }
              // Texto normal
              return <span key={index}>{part}</span>;
            })}
          </Content>
          {!!handleButton && (
            <SimpleButton 
              size='small' 
              variant='text' 
              onClick={handleButton}
              style={{ float: 'right', marginBottom: '.5rem', marginTop: '.5rem' }}
            >
              {textButton}
            </SimpleButton>
          )}
        </AccordionDetails>
      </Accordion>
    );
  });

export default SimpleAccordion;