import API from './config/apiInstance';
import apiError from './apiError';

export async function getAccountTypes() {
  try {
    const response = await API.get('/account-type');
    if (response && response.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    throw apiError({ code: '/bank', message: 'Hubo un problema al intentar buscar tipos de cuentas' });
  }
}
