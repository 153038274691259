import { forwardRef } from 'react';
import IconInfo from './IconInfo';
import { AgerangeEntity, PublicationEntity } from '../../../../entities';

interface IconInfoAgerangesPros {
  publication?: PublicationEntity;
}

const IconInfoAgeranges = forwardRef<HTMLDivElement, IconInfoAgerangesPros>(
  ({ publication }, ref) => {
    const isAll = publication?.ageranges?.length === 5;
    const textAll = 'Todas las edades';
    const textNotAll = publication?.ageranges?.map((ages : AgerangeEntity) => ages.name).join(', ');  

    return !!publication?.ageranges && publication?.ageranges?.length > 0 ? (
      <IconInfo
        icon="agerange"
        text={isAll ? textAll : textNotAll}
        ref={ref}
      />
    ) : null;
  });
export default IconInfoAgeranges;