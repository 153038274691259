import { useState } from 'react';
import { getFinancesExcel } from '../../../../services/finances';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded' | 'error';
  error?: any;
  data?: any;
}

const useFinanceExcel = () => {
  const [state, setState] = useState<StateProps>({ status: 'idle', error: null });

  const downloadExcel = async (params: string) => {
    setState({ status: 'loading', data: [] });
    try {
      const response = await getFinancesExcel(params);
      setState({
        data: response,
        status: 'loaded',
      });
      return response;
    } catch (error) {
      setState({ status: 'error', error });
      throw error;
    }
  };

  return {
    ...state,
    downloadExcel,
  };
};

export default useFinanceExcel;