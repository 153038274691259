const getObjectDifferences = (obj1: any, obj2: any): any | undefined => {
  const differences: any = {};

  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) {
      differences[key] = obj2[key];
    }
  }

  return Object.keys(differences).length > 0 ? differences : undefined;
};

export default getObjectDifferences;