import { Container, Grid, Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NavigationBar from '../../components/navigations/navigation-bar/NavigationBar';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ChangeEvent, useEffect } from 'react';
import { CheckoutItemEntity, GroupEntity } from '../../entities';
import MainContainer from '../../components/containers/main-container/MainContainer';
import { SkeletonText } from '../../components/typographies/title/Title.styled';
import PublicationCard from '../../components/cards/publication-card/PublicationCard';
import { BackButton } from '../../components/buttons';
import useMyPurchases from './useMyPurchases';
import { SkeletonPublicationCard } from '../../components/cards/publication-card/PublicationCard.styled';
import PageSubtitle from '../../components/typographies/PageSubtitle';
import PageTitle from '../../components/typographies/PageTitle';
import useAnalytics from '../../hooks/useAnalytics/useAnalytics';
import getPublicationIdentifier from '../../utils/getPublicationIdentifier';

const MyAccountMyPurchases = () => {
  const { t } = useTranslation('my-purchases');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { fetchMyPurchases, data, count, status, page, totalPages } = useMyPurchases();
  const { traceBtnClick } = useAnalytics();
  const pageParam: string | null = searchParams.get('page');

  useEffect(() => {
    fetchMyPurchases({ page: pageParam });
  }, [pageParam]);

  const handleClickPage = (event: ChangeEvent<unknown>, pageEvent: number) => {
    const params = {
      ...(pageEvent && { page: `${pageEvent}` }),
    };
    traceBtnClick({
      step: 'step cambiar página',
      product: pageEvent.toString(),
    });
    navigate(`/my-purchases?${params && createSearchParams(params)}`);
  };

  const handleClickCheckoutItem = (checkoutItem: CheckoutItemEntity) => {
    traceBtnClick({
      step: 'step ver detalle de compra',
      product: getPublicationIdentifier(checkoutItem.group?.publication),
    });
    navigate(`/my-purchase/${checkoutItem.id}`);
  };

  const getHeader = (group?: GroupEntity) => {
    const nextSession = group?.nextSession;
    if (nextSession) {
      return `Próxima sesión: ${nextSession?.dateFormat2}, ${nextSession?.startTime} hs` ;
    }
    return 'Finalizado';
  };

  return (
    <MainContainer>
      <NavigationBar variant="variant2" />
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle>{t('title')}</PageTitle>
          </Grid>
          <Grid item xs={12}>
            <PageSubtitle>{t('subtitle')}</PageSubtitle>
          </Grid>
          <Grid item xs={12}>
            <PageSubtitle>
              <>
                {status === 'loading' && <SkeletonText />}
                {status === 'loaded' && `${count || '0'} ${t('results')}`}
                {status === 'error' && `${t('error')}`}
              </>
            </PageSubtitle>
          </Grid>
        </Grid>

        {status === 'loading' && (
          <Grid container direction="row" spacing={4} paddingTop={4}>
            {[...Array(4)].map((_, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <SkeletonPublicationCard />
              </Grid>
            ))}
          </Grid>
        )}

        {status !== 'loading' && data && data?.length > 0 && (
          <Grid container direction="row" spacing={4} paddingTop={4}>
            {data?.map((checkoutItem: CheckoutItemEntity) => (
              <Grid key={checkoutItem.id} item xs={12} sm={6} md={3}>
                <PublicationCard
                  header={getHeader(checkoutItem?.group)}
                  publication={{
                    ...checkoutItem?.group?.publication,
                    state: undefined,
                  }}
                  onClick={() => handleClickCheckoutItem(checkoutItem)}
                />
              </Grid>
            ))}
            <Grid item xs={12} display="flex" justifyContent="center" marginTop={1} marginBottom={2}>
              {page && (
                <Pagination
                  count={totalPages}
                  page={page}
                  color="primary"
                  onChange={handleClickPage}
                />
              )}
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item>
            <BackButton 
              disabled={status === 'loading'} 
              onClick={() => { 
                traceBtnClick({ step: 'step volver a mi cuenta', product: 'Atrás' });
                navigate('/my-account'); 
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default MyAccountMyPurchases;
