import { forwardRef } from 'react';
import { CardContent } from '@mui/material';
import {
  StyledMenuCard,
  StyledTitleTypography,
  StyledSubtitleTypography,
  StyledProfileIcon,
  StyledPurchaseIcon,
  StyledLogoutIcon,
  StyledAdminIcon,
} from './MenuCard.styled';
import GurappIcon from '../../icons/gurapp-icon/GurappIcon';

interface MenuCardProps {
  icon?: 'profile' | 'purchase' | 'guru' | 'logout' | 'admin' | undefined;
  title: string;
  subtitle?: string;
  onClick?: () => any;
  disabled?: boolean;
}

const icons = {
  profile: <StyledProfileIcon />,
  purchase: <StyledPurchaseIcon />,
  guru: <GurappIcon width={60} color="#737373" />,
  logout: <StyledLogoutIcon />,
  admin: <StyledAdminIcon />,
};

const MenuCard = forwardRef<HTMLDivElement, MenuCardProps>(
  ({
    icon, title, subtitle, onClick, disabled, ...rest
  }, ref) => (
    <StyledMenuCard 
      ref={ref} 
      {...rest}
      onClick={disabled ? undefined : onClick}
      style={{ 
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}

    >
      <CardContent>
        {icon && icons[icon]}
        <StyledTitleTypography gutterBottom>
          {title}
        </StyledTitleTypography>
        <StyledSubtitleTypography>
          {subtitle}
        </StyledSubtitleTypography>
      </CardContent>
    </StyledMenuCard>
  ),
);

MenuCard.displayName = 'MenuCard';
export default MenuCard;
