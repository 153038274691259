import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid, FormControl, TextField, Container, Rating, Typography } from '@mui/material';
import { Title } from '../../components/typographies';
import GurappLogo from '../../components/logos/gurapp-logo/GurappLogo';
import { SimpleButton } from '../../components/buttons';
import { useFormik } from 'formik';
import usePublicationReview from './usePublicationReview';
import useAnalytics from '../../hooks/useAnalytics/useAnalytics';

const PublicationReview = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const evaluation = useRef(parseInt(searchParams.get('evaluation') as string));
  const reviewId = useRef(searchParams.get('reviewId') as string);
  const publicationTitle = useRef(searchParams.get('publicationTitle') as string);
  const { traceBtnClick } = useAnalytics();
  
  const { status, error, sendPublicationReview } = usePublicationReview();
  const form = useFormik({
    initialValues: {
      reviewId: reviewId.current,
      evaluation: evaluation.current,
      comment: undefined,
    },
    onSubmit: async (values) => {
      await sendPublicationReview(values.reviewId, values.evaluation, values.comment);
    },
  });

  useEffect(() => {
    searchParams.delete('reviewId');
    searchParams.delete('evaluation');
    searchParams.delete('publicationTitle');
    setSearchParams(searchParams);
  }, [reviewId, evaluation, publicationTitle]);

  return (
    <Container>
      <GurappLogo />
      <Grid item xs={12}>
        {
          status === 'loaded' && !error ? (
            <Title variant="variant3">
              Gracias por tu evaluación
            </Title>    
          ) : (
            <>
              <Title variant="variant3">
                Valora tu experiencia
              </Title>
              <Typography variant='body1' style={{ fontWeight: '700' }}>
                {`Publicación "${publicationTitle.current}"`}
              </Typography>
              <Rating
                name="evaluation"
                value={form.values.evaluation}
                onChange={form.handleChange}
                size="large"
              />
              <FormControl margin="dense" fullWidth>
                <TextField
                  name="comment"
                  label={'Cuéntanos más detalles de cómo fue tu experiencia... ¡Les servirá a otros exploradores como tú!...'}
                  variant="outlined"
                  fullWidth
                  value={form.values.comment}
                  onChange={form.handleChange}
                  multiline
                  minRows={2}
                  maxRows={6}
                  error={error}
                  inputProps={{ maxLength: 1000 }}
                />
              </FormControl>
              <SimpleButton
                onClick={() => { 
                  traceBtnClick({
                    step: 'step enviar evaluación',
                    product: 'Enviar',
                  });
                  form.handleSubmit();
                }}
              >
                Enviar
              </SimpleButton>
            </>
          )
        }
      </Grid>
        
    </Container>
  );
};

export default PublicationReview;