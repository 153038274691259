import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import SimpleButton from '../../buttons/simple-button/SimpleButton';

export const StyledButton = styled(SimpleButton)`
  font-size: 16px;
  padding: 0px 6px;
  margin: 0px 2px;
  min-width: 8px;
  font-weight: 500;
  color: #6501A3;
  background-color: #fff;
  border: none;  
  text-transform: initial;
  &:hover {
    background-color: #fff;
  }
`;

export const StyledTypography = styled(Typography)`
  display: inline;
  margin-right: 8px;
`;
