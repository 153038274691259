import { useState } from 'react';
import { CheckoutEntity } from '../../entities';
import { getCheckout } from '../../services/checkout';
import { createSearchParams } from 'react-router-dom';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded' | 'error';
  error?: 'generic';
  data?: CheckoutEntity;
}

function pause() {
  return new Promise(function (resolve) {
    setTimeout(resolve, 4000);
  });
}

const usePurchase = () => {
  const [state, setState] = useState<StateProps>({
    status: 'idle',
  });

  const fetchCheckout = async (id: number, params?: { state: string[] }) => {
    setState({ status: 'loading', data: undefined });
    
    try {
      await pause();
      const response = await getCheckout(id, `?${params ? createSearchParams(params) : ''}`);
      setState({
        data: response,
        status: 'idle', 
      });
    } catch (error: any) {
      setState({ status: 'error', error: error?.code || 'generic' });
    }
  };



  return {
    ...state,
    fetchCheckout,
  };
};

export default usePurchase;