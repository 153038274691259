import styled from '@emotion/styled';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import SimpleButton from '../../buttons/simple-button/SimpleButton';

interface IStyledAccordionDetails {
  bg?: number;
}

export const StyledButton = styled(SimpleButton)`
  font-size: 16px;
  padding: 0px;
  margin:0px;
  font-weight: normal;
  color: #04919A;
  text-decoration: underline;
  text-transform: initial;

  &:hover {
    background-color: #fff;
    text-decoration: underline;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)(
  ({ bg = 0 } : IStyledAccordionDetails) => `
    ${bg === 1 && 'background-color: rgba(196, 196, 196, 0.4);'};
  `,
);

export const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: 0px;
  margin: 0;
`;
