import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useMyPurchase from './../useMyPurchase';
import {  Grid, LinearProgress, Typography } from '@mui/material';
import { StyledTodayOutlinedIcon } from '../../../components/cart/group/Group.styled';
import { GroupDetail } from '../../../components/publication/group-detail/GroupDetail';

const MyPurchasePublication = () => {
  const { id } = useParams();
  const { fetchPurchase, data, status } = useMyPurchase();

  useEffect(() => {
    fetchPurchase(+(id || 0));
  }, []);

  return (
    <>
      {status === 'loading' && <LinearProgress />}
      {status === 'loaded' && data && (
        <Grid container>
          {(data?.group?.sessions && data?.group.sessions.length > 1) && (
            <>
              <Grid item xs={2}>
                <StyledTodayOutlinedIcon />
              </Grid>
              <Grid item xs={10}>
                <Typography>
                  {`${data?.group?.sessions?.length} clases`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <GroupDetail group={data?.group} />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default MyPurchasePublication;
