import imageCompression from 'browser-image-compression';

const getImagesCompressed = async (imageFiles: any, maxSizeMB = 0.128) => {
  const maxWidthOrHeight = 900;
  const maxWidthOrHeightSmall = maxWidthOrHeight / 3 * 2;
  const maxWidthOrHeightSmaller = maxWidthOrHeight / 2;
  const options = {
    maxSizeMB,
    maxWidthOrHeight,
    useWebWorker: true,
    fileType: 'image/webp',
  };

  const getSizeInMB = (size: number) => size / 1024 / 1024;

  const fileToArray = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const file of imageFiles) {
    // eslint-disable-next-line no-await-in-loop
    let compressedFile = await imageCompression(file, options);
    if ((getSizeInMB(compressedFile.size)) > maxSizeMB) {
      compressedFile = await imageCompression(file, { ...options, maxWidthOrHeight: maxWidthOrHeightSmall });
    }
    if ((getSizeInMB(compressedFile.size)) > maxSizeMB) {
      compressedFile = await imageCompression(file, { ...options, maxWidthOrHeight: maxWidthOrHeightSmaller });
    }
    const blobToFile = new File(
      [compressedFile],
      compressedFile.name,
      { type: compressedFile.type },
    );
   
    fileToArray.push(blobToFile);
    
  }

  return fileToArray;
};

export default getImagesCompressed;
