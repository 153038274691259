import { useEffect } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DividedContainer from '../../../components/containers/divided-container/DividedContainer';
import { NavigationBar } from '../../../components/navigations';
import useMyPublication from '../useMyPublication';
import { useParams } from 'react-router-dom';
import { GroupEntity } from '../../../entities';
import Group from './components/Group';
import getPublicationId from '../../../utils/getPublicationId';
import SimpleError from '../../../components/errors/simple-error/SimpleError';
import PageTitle from '../../../components/typographies/PageTitle';
import PageSubtitle from '../../../components/typographies/PageSubtitle';

const Explorers = () => {
  const { t } = useTranslation('my-publication', { keyPrefix: 'explorers' });
  const { identifier } = useParams();
  const { fetchAuthPublication, data: publication, status, error: errorFetch } = useMyPublication();

  useEffect(() => {
    const id = getPublicationId(identifier as string);
    const attributes = [
      'id', 
      'type',
      'state',
      'groups',
      'groups.sessions',
      'groups.checkoutItems',
      'groups.checkoutItems.checkout',
    ];
    fetchAuthPublication(id, attributes);
  }, []);

  // const timelines = ['current', 'future'];
  // const filterByTimeline = (group: GroupEntity) => (timelines.includes(group.timeline || ''));
  // const groups = publication?.groups?.filter(filterByTimeline) || [];
  const groups = publication?.state === 'approved' ? publication?.groups || [] : [];
  return (
    <>
      {status === 'loading' && <LinearProgress />}
      {status === 'loaded' && (
        <DividedContainer>
          <NavigationBar variant="variant4" />
          {errorFetch ? (
            <SimpleError>{errorFetch?.message}</SimpleError>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <PageTitle>{`${t(`title.${publication?.type}`, '') || t('title.class')}`}</PageTitle>
              </Grid>
              <Grid item xs={12}>
                <PageSubtitle>{`${t(`subtitle.${publication?.type}`, '') || t('subtitle.class')}`}</PageSubtitle>
              </Grid>
              <Grid item xs={12}>
                {groups?.length > 0 ? groups.map((group: GroupEntity) => (
                  <Group key={group.id} group={group} publication={publication}/>
                )) : (
                  <PageSubtitle>{`${t(`empty.${publication?.type}`, '') || t('empty.class')}`}</PageSubtitle>
                )}
              </Grid>
            </Grid>
          )}
        </DividedContainer>
      )}
    </>
  );
};

export default Explorers;