import { Grid } from '@mui/material';
import Title from '../../typographies/title/Title';
import Paragraph from '../../typographies/Paragraph';

interface SimpleErrorProps {
  children?: string;
  title?: string;
}
const SimpleError = ({ children = 'Hubo un problema', title = 'Lo sentimos' }: SimpleErrorProps) => {
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item pt={8}>
        <Title variant="variant3">
          {title as string}
        </Title>
      </Grid>
      <Grid item pt={2} textAlign="center">
        <Paragraph>
          {children as string}
        </Paragraph>
      </Grid>
    </Grid>
  );
};

export default SimpleError;
