import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import getPublicationId from '../../../../utils/getPublicationId';
import SimpleError from '../../../../components/errors/simple-error/SimpleError';
import { LinearProgress } from '@mui/material';
import TopMessage from '../../../../components/message/top-message/TopMessage';
import { PublicationTemplate } from '../../../../components/publication/publication-template/PublicationTemplate';
import Groups from '../../../../components/publication/groups/Groups';
import useAdminPublication from './useAdminPublication';

const AdminPublicationsPreview = () => {
  const { identifier } = useParams();
  const { fetchAdminPublication, data, status, error: errorFetch } = useAdminPublication();

  useEffect(() => {
    const id = getPublicationId(identifier as string);

    const attributes = [
      'id',
      'type',
      'title',
      'description',
      'detail',
      'material',
      'price',
      'mode',
      'modeDetail',
      'location',
      'knowledges',
      'knowledgeDetail',
      'categoryId',
      'category',
      'audiovisuals',
      'state',
      'active',
      'createdById',
      'createdBy',
      'ageranges',
      'groups',
      'groups.sessions',
      'groups.checkoutItems',
      'groups.checkoutItems.checkout',
      // 'groups.weekly',
      'lastGroupDate', 
      'lastGroupStartTime', 
      'agenda',
      'minDuration',
      'minDurationFormat',
      'maxDuration',
      'maxDurationFormat',
      'minQuota',
      'maxQuota',
    ];
    fetchAdminPublication(id, attributes);
  }, []);

  return (
    <>
      {(errorFetch) && (
        <SimpleError>{errorFetch?.message}</SimpleError>
      )}
      {status === 'loading' && <LinearProgress />}
      {status === 'loaded' && data && (
        <>
          <TopMessage 
            title={'Esta es una previsualización.'}
          />
          <PublicationTemplate publication={data}>
            <Groups groups={data?.groups} disabledActions/>
          </PublicationTemplate>
        </>
      )}
    </>
  );
};

export default AdminPublicationsPreview;