import { Grid } from '@mui/material';
import defaultImagePublication from '../../images/default-image-publication/defaultImagePublication.webp';
import { useTranslation } from 'react-i18next';
import {
  StyledButtonDelete,
  StyledDivider,
  StyledCardMedia,
} from './CartItem.styled';
import getPriceFormatted from '../../../utils/getPriceFormatted';
import ShowSchedule from '../show-schedule/ShowSchedule';
import Label from '../../typographies/label/Label';
import CartControl from '../cart-control/CartControl';
import useCart from '../../../hooks/useCart';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';

interface ICartItemProps {
  group?: any;
  quantity: number;
}

const CartItem = ({ group, quantity }: ICartItemProps) => {
  const { t } = useTranslation('components', { keyPrefix: 'cart-item' });
  const { traceBtnClick } = useAnalytics();
  const { addQuantityGroup, removeQuantityGroup, deleteGroup } = useCart();

  const onClickRemoveOne = () => {
    traceBtnClick({
      step: 'step desminuir 1 elemento del carrito en cart item',
      product: '- (menos)',
    });
    removeQuantityGroup?.(group.id);
  };

  const onClickAddOne = () => {
    traceBtnClick({
      step: 'step aumentar 1 elemento del carrito  en cart item',
      product: '+ (más)',
    });
    addQuantityGroup?.(group.id);
  };

  const onClickDelete = () => {
    traceBtnClick({
      step: 'step eliminar elemento del carrito  en cart item',
      product: 'Eliminar',
    });
    deleteGroup?.(group.id);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <StyledCardMedia image={group?.publication?.audiovisuals?.find((visual: any) => visual.fav)?.url || defaultImagePublication} />
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Label style={{ fontWeight: 500 }}>{group?.publication?.title}</Label>
            </Grid>
            <Grid item xs={4} textAlign="right">
              <Label>{getPriceFormatted(group?.publication?.price)}</Label>
            </Grid>
            <Grid item xs={12}>
              <ShowSchedule title={'Ver horarios'} group={group} />
            </Grid>
            <Grid item xs={6}>
              <CartControl 
                onClickAddOne={onClickAddOne} 
                onClickRemoveOne={onClickRemoveOne} 
                quantity={quantity} 
                limit={group?.quotaAvailable}
              />
            </Grid>
            <Grid item xs={6} textAlign="right">
              <StyledButtonDelete variant="text" onClick={onClickDelete}>{t('delete')}</StyledButtonDelete>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <StyledDivider />
    </>
  );
};

CartItem.displayName = 'CartItem';
export default CartItem;
