import styled from '@emotion/styled';
import { Skeleton, Typography } from '@mui/material';

interface IStyledVariant {
  isdownmd?: number;
}

export const StyledVariant1 = styled(Typography)`
  font-weight: 900;
  font-size: 54px;
  line-height: 50px; 
  letter-spacing: 0.25px;
  color: #fff;
  /* opacity: 0.9; */
  text-transform: uppercase;
`;

export const StyledVariant2 = styled(Typography)(
  ({ isdownmd = 0 }: IStyledVariant) => `
  font-weight: 900;
  font-size: 48px;
  line-height: 48px; 
  letter-spacing: 0.25px;
  // opacity: 0.9;
  text-transform: none;
  ${isdownmd === 1 ? (`
    font-size: 36px;
    line-height: 1.2;
  `) : ''};
`,
);

export const StyledVariant3 = styled(Typography)`
  font-weight: 900;
  font-size: 36px;
  line-height: 36px; 
  letter-spacing: 0.25px;
`;

export const StyledVariant4 = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  /* opacity: 0.87; */
  letter-spacing: .25px;
`;

export const StyledVariant5 = styled(Typography)`
  font-weight: 500;
  font-size: 28px;
  line-height: 32px; 
  letter-spacing: 0.15px;
`;

export const StyledVariant6 = styled(Typography)`
  font-weight: 900;
  font-size: 48px;
  line-height: 48px; 
  letter-spacing: 0.25px;
  /* opacity: 0.9; */
  text-transform: uppercase;
  color: #fff;
  margin: 8px 0;
`;

export const SkeletonText = styled(Skeleton)``;
SkeletonText.defaultProps = { variant: 'rectangular', height: 16, width: '30%' };
