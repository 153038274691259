import {
  createContext, useEffect, useState,
} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAuthUser } from '../services/authUser';
import { UserEntity } from '../entities';

interface AuthUser {
  isInitializing?: boolean;
  error?: any;
  data?: UserEntity;
}

interface FirebaseUser {
  isInitializing?: boolean;
  isLogged?: boolean;
  lastSignInTime?: number;
}

interface AuthContextProps {
  authUser?: AuthUser;
  firebaseUser?: FirebaseUser;
  isInitializing?: boolean;
  isLogged?: boolean;
  refreshAuthUser?: () => void;
  logout?: () => void;
  isRecentlyLogger?: () => boolean;
}
const AuthContext = createContext<AuthContextProps>({});

export const AuthContextProvider = (props: any) => {
  const [authUser, setAuthUser] = useState<AuthUser>({ isInitializing: true });
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser>({ isInitializing: true });

  const refreshAuthUser = async () => {
    try {
      if (!firebaseUser.isLogged) {
        setAuthUser({});
      } else {
        setAuthUser({ isInitializing: true });
        const response = await getAuthUser();
        setAuthUser({ data: response });
      }
    } catch (error) {
      setAuthUser({ error });
    }
  };

  const logout = () => {
    firebase.auth().signOut();
  };

  const isRecentlyLogger = () => {
    const toleranceInMs = 3000;
    const currentTime = new Date().getTime();
    const isRecentlyLogged = (currentTime - toleranceInMs) <= (firebaseUser?.lastSignInTime || 0);
    return isRecentlyLogged;
  };

  useEffect(() => {
    const unlisten = firebase.auth().onAuthStateChanged(
      (_auth) => {
        if (_auth) {
          const lastSignInTime = (_auth.toJSON() as any)?.lastLoginAt ?? 0;
          setFirebaseUser({ isLogged: true, lastSignInTime: +lastSignInTime });
        } else {
          setFirebaseUser({ isLogged: false });
        }
      },
    );
    return () => { unlisten(); };
  }, []);

  useEffect(() => {
    refreshAuthUser();
  }, [firebaseUser]);

  return (
    <AuthContext.Provider 
      value={{
        authUser,
        firebaseUser,
        refreshAuthUser,
        logout,
        isInitializing: Boolean((authUser && authUser.isInitializing) || (firebaseUser && firebaseUser.isInitializing)),
        isLogged: Boolean(firebaseUser && firebaseUser.isLogged),
        isRecentlyLogger,
      }}  
      {...props}
    />
  );
};

export default AuthContext;
