// import API from './config/apiInstance';
import apiError from './apiError';

export async function getKnowledges(): Promise<any> {
  try {
    const count = 4;
    const page = 1;
    const perPage = 10;
    const totalPages = Math.ceil(count / perPage);
    const rows = [
      {
        'id': 'none',
        'name': 'Sin conocimiento previo',
        'order': 1,
      },
      {
        'id': 'basic',
        'name': 'Básico',
        'order': 2,
      },
      {
        'id': 'intermediate',
        'name': 'Intermedio',
        'order': 3,
      },
      {
        'id': 'advanced',
        'name': 'Avanzado',
        'order': 4,
      },
    ];
    const response =  {
      data: {
        page,
        per_page: perPage <= count ? perPage : count,
        count,
        total_pages: totalPages,
        data: rows,
      },
    };
    // const response = await API.get('/knowledge');
    if (response && response.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    throw apiError({ code: '/knowledge', message: 'Hubo un problema al intentar buscar niveles de conocimiento' });
  }
}
