export const content = `

# **TÉRMINOS LEGALES Y CONDICIONES GENERALES DE USO DEL SITIO WEB GURAPP.CL.**

**PRIMERO: DEL CONTRATO DE TÉRMINOS Y CONDICIONES.**

Los Presentes Términos de Servicio (" **Términos**") son un contrato legal vinculante entre usted, **GURU** o **EXPLORADOR,** y **GURAPP.CL** que rigen el derecho a usar los sitios web, las aplicaciones y otros ofrecimientos de **GURAPP.CL**. Cuando estos Términos utilizan " **el Sitio Web**", " **GURAPP.CL**", " **GURAPP**", " **nosotros** ," " **nos**", " **nuestros**", **"GURAPP SPA",** se refieren a la entidad de **GURAPP.CL** con quien contrata el usuario.

Del mismo modo, el presente documento, regula los términos y condiciones generales aplicables al acceso y uso que el Usuario realizará del Sitio Web: **GURAPP.CL** , así como a cualquier tipo de información, contenido, imagen, video, audio u otro material comunicado o presente en el Sitio Web.


**SEGUNDO: DE LA DESCRIPCIÓN DEL SERVICIO.**

**GURAPP.CL** es una plataforma de intermediación en línea para vendedores (en adelante **GURÚS** ), y compradores (en adelante **EXPLORADORES** ), de cursos y clases que tengan como finalidad el aprendizaje, experiencia y entretenimiento de las personas.

Los **EXPLORADORES** pueden comprar cursos y clases que estén publicados por un **GURÚ**. Los **GURÚS** pueden publicar contenido relacionado a sus clases, ya sea con imágenes, videos o textos. Ambos usuarios pueden compartir comentarios.

**TERCERO: DE LAS DEFINICIONES.**

**GURAPP.CL:** es una plataforma de intermediación en línea para vendedores (en adelante Gurús), y compradores (en adelante exploradores), de cursos y clases que tengan como finalidad el aprendizaje, experiencia y entretenimiento de las personas.

**SITIO WEB** : la apariencia externa de las interfaces de pantalla, tanto de forma estática como de forma dinámica, es decir, el árbol de la navegación, funcionalidades y contenidos y todos los elementos integrados tanto en los interfaces de pantalla como en el árbol de navegación (en adelante, "Contenidos") y todos aquellos servicios o recuerdos en línea que en su caso ofrezca a los usuarios (en adelante, "Servicios").

**GURÚ:** usuario de la plataforma **GURAPP.CL** , que detenta experticia en una determinada ciencia o arte, cuya finalidad es poner a disposición, a título oneroso, cursos y clases que tengan como finalidad el aprendizaje, experiencia y entretenimiento de los **EXPLORADORES**. El **GURÚ** debe ser mayor de 18 años y plenamente capaz.

**EXPLORADOR:** usuario de la plataforma **GURAPP.CL** , cuya finalidad es adquirir conocimientos en una determinada ciencia o arte, a título oneroso, a través de cursos y clases que serán impartidas por los **GURÚS**. El **EXPLORADOR** debe ser mayor de 18 años o debe actuar a través de su representante, que para estos efectos será el usuario y quien ejerza todos y cada uno de los derechos de su pupilo.

**CUARTO: DE LA RESPONSABILIDAD DE GURAPP.CL.**

**GURAPP.CL** , es una plataforma que presta servicios de apoyo a la venta y comercialización de cursos y clases, en consecuencia, no posee, controla, ofrece ni administra ninguna clase, curso o servicios que pueda prestar el **GURÚ**. **GURAPP.CL,** no es parte de los contratos celebrados directamente entre los **EXPLORADORES** y los **GURÚS**. El servicio que presta GURAPP, y su responsabilidad, se limita a la eficiente gestión de comunicación entre GURÚS y EXPLORADORES, facilitando la prestación de los servicios y su consecuente comercialización.

**GURAPP.CL** no responderá, en ningún caso, en las siguientes circunstancias:

1. Uso indebido que Usuarios o visitantes al Sitio Web realicen del contenido almacenado, o de los productos que adquirieran en el mismo, así como de los derechos de propiedad industrial o intelectual contenidos en el Sitio Web, o en los productos o servicios.
2. Daños y perjuicios, directos (previstos e imprevistos) o indirectos, causados a Usuarios a consecuencia del funcionamiento del Sitio Web, así como de errores generados por elementos técnicos del Sitio.
3. Respecto de los contenidos de Sitios Web a las que los Usuarios puedan acceder a traves de nuestra plataforma, se trate de una pagina recomenda por GURAPAP, se trate de una recomendación de los usuarios.
4. Pérdida, mal uso o uso no autorizado de contraseña o códigos de validación para el ingreso a la cuenta del usuario.
5. Interrupción temporal de Sitio Web o su plataforma por caso fortuito o fuerza mayor. Con todo, **GURAPP.CL** adoptará todas las medidas para restablecer el correcto funcionamiento del Sitio Web y de su sistema comunicacional lo más pronto posible.
6. Información relativa a la plataforma que se encuentre en Sitios Web distintos o ajenos a **GURAPP.CL**.
7. Existencia de virus u otros elementos perjudiciales en los documentos o archivos almacenados en los sistemas informáticos de todo tipo de propiedad de los Usuarios
8. Uso indebido de las tecnologías y plataformas puestas a disposición de los usuarios.
9. Daños producidos por el uso indebido o mala fe de los Usuarios al utilizar GURAPP.CL.

**QUINTO: DE LA RESPONSABILIDAD DE LOS USUARIOS.**

El Usuario, **EXPLORADOR** y **GURÚ** , adhiere a los términos y condiciones desde el instante de hacer ingreso al Sitio Web, para realizar un correcto uso del mismo y sus contenidos. Así, es de responsabilidad del usuario:

1. Usar la información, Contenidos y/o Servicios y datos ofrecidos por **GURAPP.CL** libremente, con la sola limitación de respetar estos Términos y Condiciones, el Ordenamiento Jurídico Chileno, la Moral y las buenas costumbres, y los derechos de terceros.
2. Mantener una actitud educada, respetuosa y cordial con los otros usuarios. **GURAPP.CL** se reserva el derecho de eliminar todos aquellos comentarios, contenidos e interacciones que se aparten de esta conducta.
3. Evitar contenidos, comentarios e interacciones que infrinjan la ley, el respeto a la dignidad de las personas, que sean discriminatorios, que contengan contenido pornográfico o que constituyan mensajes conocidos como "spam", o que atenten contra la juventud o infancia, el orden, o la seguridad pública o que, a juicio de GURAPP, no resulten apropiados para el Sitio Web.
4. Cada usuario será exclusivamente responsable de todos sus comentarios e interacciones. **GURAPP.CL** no se hace responsable de las opiniones, de cualquier tipo, emitidas por los usuarios mediante comentarios, herramientas o extensiones de blogging.
5. El usuario acepta que es exclusivamente responsable de los conflictos que pudiera enfrentar con otro usuario. **GURAPP.CL** declara que no tiene obligación alguna de intervenir ante un conflicto entre usuarios.

**SEXTO: DEL GURÚ.**

El acceso a la plataforma no supone una relación laboral entre **GURAPP.CL** y el **GURÚ**. Así, el servicio que presta el **GURÚ** al **EXPLORADOR** , en caso alguno supone una vinculación sujeta a subordinación y dependencia del primero con **GURAPP.CL,** en consecuencia, **GURAP.CL,** no tieneni tendrá obligación alguna de carácter laboral, rigiéndose la relación entre **GURAPP.CL** y el **GURÚ** conforme a las normas del Código Civil.

**SÉPTIMO: DEL EXPLORADOR.**

El acceso a la plataforma no supone una relación comercial entre **GURAPP.CL** y el **EXPLORADOR**. Así, la prestación efectiva del servicio, su calidad y satisfacción es un asunto que depende única y exclusivamente del **GURÚ** , sin perjuicio de los comentarios que el **EXPLORADOR** formule a fin de proporcionar referencia a los otros usuarios de la plataforma, entendiéndose satisfecha la participación de **GURAPP.CL,** con la intermediación entre **GURÚ** y **EXPLORADOR**.

**OCTAVO: DEL PROCEDIMIENTO DE CONTRATACIÓN DE CURSOS Y CLASES.**

Para realizar compras o contratar servicios en el Sitio Web, se deben seguir los siguientes pasos, haciendo click cuando corresponda:

1. Confirmar haber leído y aceptado los presentes Términos y Condiciones.
2. Seleccionar el servicio a contratar, agregándolo al "carro de compra", u alguna opción análoga ("canasto", "cesta").
3. Iniciar sesión en el sitio o ingresar correo electrónico e información personal solicitada dentro de la web.
4. Seleccionar la clase y/o curso con sus correspondientes horarios y canales. Ante la eventualidad de no existir alternativas disponibles, seguir las instrucciones para la entrega de acuerdo a lo que se señale en el sitio.
5. Seleccionar el medio de pago.
6. Una vez que se ha realizado la orden de compra o de contratación del servicio, se desplegará en la pantalla la siguiente información.

- Descripción del servicio contratado.
- Valor del servicio.
- Fecha de inicio del servicio contratado.
- Medio de pago.
- Valor total de la operación.
- Otras condiciones de la orden del servicio adquirido.
- Posibilidad de imprimir y conservar la orden, por parte del Usuario.
- Número de identificación único de la orden, con la cual el Usuario puede hacer seguimiento en línea de ella, o bien utilizarlo para identificarla al momento de realizarla entrega del servicio.


1. Envío de la información al correo electrónico registrado por el Usuario.
2. La orden luego será enviada de forma automática a un proceso de pago seguro por medio de una empresa externa, en el cual se resguardará siempre la seguridad y privacidad del usuario y del proceso mismo de contratación, disponibilidad, vigencia y cupo del medio de pago que se haya seleccionado.

**NOVENO: DEL PRECIO Y COMISIONES.**

Cada curso o clase intermediada adquirida mediante **GURAPP.CL** , tendrá un costo cuya cuantía es determinada por el propio **GURÚ** , que para efectos de transparencia y contratación informada del curso o clase, deberá ser indicado en moneda nacional y el numeral con todos sus dígitos.

El **GURÚ** , autoriza expresamente a descontar un porcentaje del valor contratado a título de comisión por la intermediación realizada, el cual será explicitado en su oportunidad a través de la página web.

**DÉCIMO: DE LA TRIBUTACIÓN.**

El **GURÚ** , será exclusivamente responsable de la emisión y entrega de los instrumentos tributarios exigidos por nuestra legislación al **EXPLORADOR** , así como de los tributos que se devenguen a propósito de la señalada operación. **GURAPP.CL** , solo será responsable de la emisión y entrega de los instrumentos tributarios exigidos por nuestra legislación respecto de la comisión señalada en el apartado anterior.

**DÉCIMO PRIMERO: DE LOS MEDIOS DE PAGO.**

A menos que se indique un medio distinto para casos u ofertas específicas, los productos y servicios que se informan y transan en este sitio sólo podrán ser pagados por medio de la plataforma para cobros online Mercado Pago.

Otros medios de pago que pudieran ser anunciados y aceptados a futuro, serán informados pertinentemente por los canales y conductos que correspondan.

El Usuario declara que entiende que estos medios o portales de pago son de propiedad de terceras empresas o personas proveedoras de dichos servicios, independientes y no vinculadas a **GURAPP.CL** , por lo que la continuidad de su prestación de servicios en el tiempo, así como el correcto funcionamiento de sus herramientas y botones de pago en línea, son de exclusiva responsabilidad de las proveedoras de estos servicios y en ningún caso de **GURAPP.CL**.

No obstante, en el caso de producirse un doble cobro por parte de la empresa proveedora de dicho servicio, GURAPP.CL, brindará el apoyo necesario al afectado a fin de resolver la incidencia.

Al momento de registrarse, hacer login, para la realización de transferencias y para comunicarse, GURAPP.CL utiliza certificados digitales de seguridad (SSL), con el fin de encriptar la información. GURAPP no almacena ni conserva datos financieros de sus EXPLORADORES. Respecto de los GURÚS, solo se almacenan aquellos datos necesarios para la realización del pago del servicio, y en ningún caso, datos que permitan acceder a su cuenta.

**DÉCIMO SEGUNDO: DE LA POLÍTICA DE SEGURIDAD DE DATOS.**

**GURAPP.CL** adoptará todas las medidas necesarias para resguardar los datos de sus Usuarios, como sistemas de encriptado de información, certificados de seguridad y otros semejantes o equivalentes. En caso de realizarse cambios hechos por el Usuario en la información registrada o ingresada en el Sitio Web, o bien, si se produce la detección de cualquier tipo de irregularidad atribuible al Usuario relacionada con la información entregada o con transferencias realizadas por el Usuario, así como con todo lo relacionado a la identificación en las mismas o el uso de medios de pago o simplemente como medida de protección de identidad, el personal del Sitio Web se pondrá en contacto con el Usuario, mediante correo electrónico, para corroborar los datos y la información, así como para evitar la existencia de posibles fraudes.

En la eventualidad de no establecerse contacto en un plazo de 24 horas, y pensando en la seguridad del Usuario, cualquier compra de un producto o contratación de un servicio no será confirmada. Se informará al Usuario mediante correo electrónico el hecho de haber quedado sin efectos producto de no haber podido comprobarse su identidad o medio de pago utilizado. Adicionalmente, los comprobantes en que consten las gestiones realizadas para intentar contactarse con el Usuario con el objetivo de confirmar la transacción, quedarán a disposición de éste último por un plazo de 15 días, en caso que el Usuario quisiera confirmar la compra o contratación.

**Datos Personales.**

Al acceder al Sitio Web **GURAPP.CL** , los Usuarios o visitantes garantizan que la información que proporcionan para ingresar o para la compra de productos o contratación de servicios es propia, veraz, completa, exacta y actualizada.

De acuerdo a lo establecido en la Ley N° 19.628 de Protección de Datos Personales, los datos de este tipo que se suministren al Sitio Web, pasan a formar parte de una base de datos perteneciente a **GURAPP.CL** y serán destinados única y exclusivamente para los fines que motivaron su entrega, especialmente para la comunicación entre **GURAPP.CL** y sus Usuarios, validar datos relativos a compras o contratación de servicios, concretar despachos, y responder consultas. Estos datos nunca serán comunicados o compartidos con otras empresas sin expresa autorización de su titular (el Usuario) ni serán transferidos internacionalmente.

**GURAPP.CL,** realizará encuestas de opinión a través de su página web, cuyos resultados serán de su propiedad y por lo tanto de libre disposición y sin limitaciones a su dominio. Lo anterior no se extiende a los participantes de las encuestas por lo que sus datos personales serán privados.

**GURAPP.CL** jamás solicitará a sus Usuarios la entrega de datos personales o financieros, salvo al momento de la creación de usuario y/o la compra de un servicio en la página web.

**GURAPP.CL** presume que los datos incorporados por los Usuarios o por personas autorizadas por éstos son correctos y exactos. Los Usuarios, al aceptar los presentes Términos y Condiciones, manifiestan su conformidad con que los datos personales aportados al rellenar formularios presentes en **GURAPP.CL** puedan ser utilizados posteriormente para la elaboración y envío de ofertas que puedan diferir de las ofrecidas en el Sitio Web.

Sin perjuicio de lo anterior, **GURAPP.CL** garantiza a todos sus Usuarios el libre ejercicio de los derechos contemplados en la Ley N° 19.628 de Protección de Datos Personales en lo relativo a la información, modificación, cancelación y bloqueo de sus datos personales.

**Documentos Electrónicos.**

Los Usuarios, como receptores manuales de documentos electrónicos según lo establecido en la Resolución Exenta N° 11 del 14 de febrero de 2003, dictada por el Servicio De Impuestos Internos, la cual establece el procedimiento para que contribuyentes autorizados para emitir documentos electrónicos puedan también realizar su envío por estos mismos medios a los receptores manuales; declaran y aceptan que, al aprobar estos Términos y Condiciones, autorizan a **GURAPP.CL** , para que el documento tributario correspondiente a la transacción por compra de producto o contratación de servicio, le sea entregada únicamente a través de un medio electrónico. Igualmente, se autoriza que el aviso de publicación del documento tributario sea enviado a los Usuarios por correo electrónico.

**DÉCIMO TERCERO: PROPIEDAD INTELECTUAL Y DERECHOS RESERVADOS.**

Todos los derechos presentes en el Sitio Web, incluyendo la propiedad intelectual, respecto al mismo Sitio Web, páginas y otro tipo de contenido, como podría ser, de forma no taxativa, textos de todo tipo, material gráfico o audiovisual (incluyendo, pero no limitado a imágenes, clips de audio y videos), logos y logotipos, íconos de todo tipo, material y contenido descargable, recopilaciones de datos e información y códigos fuente de la página, son de propiedad de **GURAPP** o cualquiera que pudiera utilizarse en el futuro.

Asimismo, todo o parte del contenido mencionado en el párrafo anterior, se encuentra registrado y protegido como marca comercial, creación o imagen comercial, por lo tanto, los derechos son de propiedad de **GURAPP.CL** , y se prohíbe su uso indebido y reproducción con fines comerciales sin previa autorización. **GURAPP.CL**.

El nombre **GURAPP.CL** , logotipo asociado y eslogan son propiedad de **GURAPP.CL** , por lo que no debe usar estas marcas ni recursos sin previo aviso ni autorización. No debe editar, ni modificar nada de lo asociado a la marca **GURAPP.CL**. Infringir con esto, podría infringir la legislación de derechos de autor o marca comercial.

**GURAPP.CL** se reserva el derecho de deducir las acciones judiciales que la ley franquea frente a cualquier trasgresión en esta materia

**DÉCIMO CUARTO: COMUNICACIONES.**

**GURAPP.CL** se obliga, en caso de enviar cualquier tipo de comunicación e información, incluyendo publicidad o promociones, a cualquier medio de contacto proporcionado por el Usuario, incluyendo números telefónicos o correo electrónico, dicha comunicación contendrá al menos la siguiente información:

1. Identificación del tipo de mensaje, dejando en claro al Usuario el tipo de información que se trata, especialmente si es un mensaje que contiene publicidad o promociones, todo lo cual figurará en el campo de asunto del mensaje (e-mail), o bien se informará en primer lugar (comunicación telefónica por voz o texto).
2. Existencia del derecho del consumidor, reconocido por la normativa chilena, relacionado con la facultad que tiene el Usuario para solicitar el cese de este tipo de mensajes. Al mismo tiempo, se reconoce la obligación por parte de **GURAPP.CL** de suspender los envíos de información en caso de recibir la solicitud por parte del Usuario.
3. La garantía de un proceso expedito, sencillo y efectivo para el caso que el Usuario quiera hacer la solicitud, para la cual el **GURAPP.CL** puede requerir algún tipo de información previo a llevarlo a cabo.
4. En caso que se trate de publicidad o promociones provenientes de sitios externos, o de empresas, compañías o marcas asociadas con **GURAPP.CL** , el mensaje contendrá la información necesaria para identificar al anunciante, incluyendo su denominación comercial si la tuviera.
5. Las promociones que se ofrezcan por medio de este Sitio Web no son necesariamente las mismas que ofrezcan otros canales de venta utilizados por las empresas, tales como locales físicos, anuncios por TV o radio, catálogos u otros, a menos que se señale expresamente en este sitio o en la publicidad que realicen las empresas de cada promoción.

**DÉCIMO QUINTO: DE LA LEGISLACIÓN APLICADA.**

Para todos los efectos legales **GURAPP.CL** , el **GURÚ** y el **EXPLORADOR** , fijan su domicilio en la ciudad de Santiago de Chile, siendo competentes los tribunales de dicha jurisdicción y aplicable exclusivamente la legislación chilena.

03/08/2023
`;