import { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import { useTranslation } from 'react-i18next';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
}

const useLogin = () => {
  const { t } = useTranslation(['login']);

  const [state, setState] = useState<StateProps>({ status: 'idle', error: null });

  const sendPasswordResetEmail = async (email: string) => {
    setState({ status: 'loading' });
    firebase.auth().languageCode = process.env.REACT_APP_LANGUAGE || 'es';
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      setState({ status: 'loaded' });
    } catch (e) {
      setState({ status: 'loaded' });
      // TODO: if not exist, send email inviting create account
    }
  };

  const signInWithEmailAndPassword = async (email: string, password: string) => {
    setState({ status: 'loading' });
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      setState({ status: 'loaded' });
    } catch (event: any) {
      setState({
        status: 'loaded',
        error: {
          message: t(`error.${event.code}`, event.message),
        },
      });
    }
  };

  const signInWithGoogle = async () => {
    setState({ status: 'loading', error: null });
    try {
      await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
      setState({ status: 'loaded' });
    } catch (event: any) {
      if (event?.code !== 'auth/popup-closed-by-user') {
        setState({
          status: 'loaded',
          error: {
            message: t(`error.${event.code}`, event.message),
          },
        });
      } else {
        setState({ status: 'loaded' });
      }
    }
  };

  return {
    error: state.error,
    status: state.status,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithGoogle,
  };
};

export default useLogin;
