import { useContext, useState } from 'react';
import CartContext from '../../../contexts/CartContext';
import { checkout } from '../../../services/checkout';

interface StateProps {
  status: 'idle' | 'loading' | 'redirect';
  error?: any;
  redirect?: string;
  id?: number;
}

const useIdentification = () => {
  const [state, setState] = useState<StateProps>({ status: 'idle', error: null, redirect: undefined });
  const { cart = [] } = useContext(CartContext);

  const doCheckout = async (payer: any) => {
    setState({ status: 'loading' });
    try {
      const items = cart.map((item) => ({
        quantity: item.quantity,
        groupId: item.group?.id,
      }));
      const payload = {
        payer,
        items,
      };
      const { redirect, id } = await checkout(payload);
      setState({ status: 'redirect', redirect, id });
    } catch (error) {
      setState({ status: 'redirect', error });
    }
  };

  return {
    ...state,
    doCheckout,
  };
};

export default useIdentification;
