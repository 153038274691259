import { forwardRef, useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { getIn } from 'formik';
import { GroupEntity, SessionEntity } from '../../../../../entities';
import { LinkButton } from '../../../../../components/buttons';
import { disableToday, getNewInitialSession, isIdTemporal } from '../StepHorarios.utils';
import useDetails from '../../useDetails';
import DeleteDialog from '../components/delete-dialog/DeleteDialog';
import useDialog from '../../../../../hooks/useDialog';
import SimpleDialog from '../../../../../components/dialogs/SimpleDialog/SimpleDialog';
import { DateInput, SelectTime } from '../../../../../components/inputs';
import useAnalytics from '../../../../../hooks/useAnalytics/useAnalytics';

interface PeriodicityNoProps {
  statusUpdate?: string;
  form: any;
  group?: GroupEntity;
}

const PeriodicityNo = forwardRef<HTMLDivElement, PeriodicityNoProps>(
  ({
    statusUpdate,
    form = undefined,
    group,
    ...props
  }, ref) => {
    const { traceBtnClick } = useAnalytics();
    const { removeAuthSession, error: errorRemoveAuthSession } = useDetails();
    const [deleteDialog, setDeleteDialog] = useState({});
    const { dialog, onCloseDialog } = useDialog(errorRemoveAuthSession, 'Error');
    const onCloseDeleteDialog = () => { setDeleteDialog({ ...deleteDialog, open: false }); };
		
    const addSession = () => {
      form.setFieldValue('sessions', [
        ...form.values.sessions, 
        getNewInitialSession(),
      ]);
    };

    const deleteSessionForm = (session: SessionEntity) => {
      const values = [...form.values.sessions];
      const indexToDelete = form.values.sessions.findIndex((item: SessionEntity) => item.id === session.id);
      if (indexToDelete > -1) {
        values.splice(indexToDelete, 1);
      }
      form.setFieldValue('sessions', values);
    };

    const handleDeleteSession = (session: SessionEntity) => {
      if (isIdTemporal(session?.id)) {
        deleteSessionForm(session);
      	return;
      }
      setDeleteDialog({ open: true, value: session });
    };

    const onContinueDeleteSession = (session?: SessionEntity) => {
      if (session?.id) {
        removeAuthSession(session.id);
        deleteSessionForm(session);
        onCloseDeleteDialog();
      }
    };

    return (
      <div {...props} ref={ref}>
        <SimpleDialog {...dialog} onClose={onCloseDialog} />
        <DeleteDialog {...deleteDialog} onClose={onCloseDeleteDialog} onContinue={onContinueDeleteSession} />
        {form.values.sessions?.map((session: SessionEntity, indexSession: number) => {
          const name = `sessions[${indexSession}]`;
          const showDelete = (!group?.hasSold && !isIdTemporal(session?.id)) || indexSession !== 0;
          
          return (
            <Grid container columns={11} spacing={1} key={session.id} mb={1}>
              <Grid item xs={11} alignSelf='center'>
                <Typography variant="body2" style={{ fontWeight: 300 }}>{`Sesión ${indexSession + 1 }`}</Typography>
              </Grid>
							
              <Grid item xs={11} sm={4} md={11} lg={4}>
                <DateInput
                  disablePast
                  shouldDisableDate={disableToday}
                  label={'Fecha'}
                  value={getIn(form.values, `${name}.date`)}
                  onChange={(value) => {
                    form.setFieldValue( `${name}.date`, value);
                  }}
                  disabled={group?.hasSold || statusUpdate === 'loading'}
                  size='small'
                  fullWidth renderInput={() => <></>} 
                  name={`${name}.date`} 
                  error={getIn(form.touched, `${name}.date`) && Boolean(getIn(form.errors, `${name}.date`))}
                  helperText={getIn(form.touched, `${name}.date`) && getIn(form.errors, `${name}.date`)}
                />
              </Grid>
              <Grid item xs={4} sm={3} md={4} lg={3}>
                <SelectTime
                  name={`${name}.startTime`}
                  onChange={(value) => {
                    form.setFieldValue( `${name}.startTime`, value);
                  }}
                  value={getIn(form.values, `${name}.startTime`)}
                  error={getIn(form.touched, `${name}.startTime`) && Boolean(getIn(form.errors, `${name}.startTime`))}
                  helperText={getIn(form.touched, `${name}.startTime`) && getIn(form.errors, `${name}.startTime`)}
                  label='Hora inicio'
                  fullWidth
                  disabled={group?.hasSold || statusUpdate === 'loading'}
                />
              </Grid>
              <Grid item xs={4} sm={3} md={4} lg={3}>
                <SelectTime
                  name={`${name}.endTime`}
                  onChange={(value) => {
                    form.setFieldValue( `${name}.endTime`, value);
                  }}
                  value={getIn(form.values, `${name}.endTime`)}
                  error={getIn(form.touched, `${name}.endTime`) && Boolean(getIn(form.errors, `${name}.endTime`))}
                  helperText={getIn(form.touched, `${name}.endTime`) && getIn(form.errors, `${name}.endTime`)}
                  label='Hora término'
                  fullWidth
                  disabled={group?.hasSold || statusUpdate === 'loading'}
                />
              </Grid>
              <Grid item xs={1} textAlign='center'>
                {showDelete && (
                  <IconButton 
                    aria-label="eliminar" 
                    onClick={() => { 
                      traceBtnClick({
                        step: 'step agregar fecha a eliminar en horarios en curso sin periodicidad',
                        product: 'eliminar',
                      });
                      handleDeleteSession(session); 
                    }} 
                    disabled={statusUpdate === 'loading'}
                  >
                    <DeleteForeverOutlinedIcon fontSize="small" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          );
        })}
        {!group?.hasSold && (
          <Grid container item mt={2}>
            <LinkButton
              onClick={(event) => {
                traceBtnClick({
                  step: 'step agregar fecha en horarios en curso sin periodicidad',
                  product: '+ pulsa aquí para agregar más sesiones',
                });
                addSession();
                event.preventDefault();
              }}
              texts={['', '+ pulsa aquí para agregar más sesiones']}
              disabled={statusUpdate === 'loading'}
            />
          </Grid>
        )}
       
      </div>
    );
  },
);

PeriodicityNo.displayName = 'PeriodicityNo';
export default PeriodicityNo;
