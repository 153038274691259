import { forwardRef } from 'react';
import IconInfo from './IconInfo';
import { PublicationEntity } from '../../../../entities';

interface IconInfoDurationPros {
  publication?: PublicationEntity;
}

const IconInfoDuration = forwardRef<HTMLDivElement, IconInfoDurationPros>(
  ({ publication }, ref) => {
    const isEqual = publication?.minDuration === publication?.maxDuration;
    const textEqual = `Clases de ${publication?.minDurationFormat}`;
    const textNotEqual = `Clases entre ${publication?.minDurationFormat} y ${publication?.maxDurationFormat}`;

    return !!publication?.minDuration && !!publication?.maxDuration ? (
      <IconInfo
        icon="schedule"
        text={isEqual ? textEqual : textNotEqual}
        ref={ref}
      />
    ) : null;
  });
export default IconInfoDuration;