import { useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { ListEntity } from '../../entities';
import PublicationEntity from '../../entities/PublicationEntity';
import { getAuthPurchases } from '../../services/authPurchase';

interface StateProps extends ListEntity {
  status: 'idle' | 'loading' | 'loaded' | 'error';
  error?: any;
  data?: PublicationEntity[];
}

interface FetchMyPurchasesProps {
  page?: any;
  state?: any;
}
const useMyPurchases = () => {
  const [state, setState] = useState<StateProps>({ status: 'idle', error: null });

  const fetchMyPurchases = async (props: FetchMyPurchasesProps) => {
    setState({ status: 'loading', data: [] });
    try {
      const params = {
        per_page: '8',
        ...(props.page && { page: props.page }),
      };

      const response = await getAuthPurchases(`?${params && createSearchParams(params)}`);
      setState({ 
        data: response?.data,
        count: response?.count,
        page: response?.page,
        perPage: response?.per_page,
        totalPages: response?.total_pages,
        status: 'loaded', 
      });
    } catch (error) {
      setState({ status: 'error', error });
    }
  };

  return {
    ...state,
    fetchMyPurchases,
  };
};

export default useMyPurchases;