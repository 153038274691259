import API from './config/apiInstance';
import apiError from './apiError';

export async function getBanks() {
  try {
    const response = await API.get('/bank');
    if (response && response.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    throw apiError({ code: '/bank', message: 'Hubo un problema al intentar buscar bancos' });
  }
}
