import { useEffect, useState } from 'react';
import { Grid, FormGroup } from '@mui/material';
import { useFormik } from 'formik';
import useDetails from '../../../useDetails';
import useAgeranges from './useAgeranges';
import { useTranslation } from 'react-i18next';
import useDialog from '../../../../../../hooks/useDialog';
import { agerangesSchema } from './Ageranges.schema';
import SimpleChip from '../../../../../../components/chips/simple-chip/SimpleChip';
import { EditListItem, CheckboxLabel } from '../../../../../../components/inputs';
import SimpleModal from '../../../../../../components/dialogs/SimpleModal/SimpleModal';
import SimpleButton from '../../../../../../components/buttons/simple-button/SimpleButton';
import SimpleDialog from '../../../../../../components/dialogs/SimpleDialog/SimpleDialog';
import { PublicationTypeEntity } from '../../../../../../entities/PublicationEntity';
import useAnalytics from '../../../../../../hooks/useAnalytics/useAnalytics';

interface AgerangesProps {
  form: any;
  type?: PublicationTypeEntity;
  disabled?: boolean;
}

const Ageranges = ({ form, type, disabled }: AgerangesProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('my-publication', { keyPrefix: 'details.step-detalles.ageranges' });
  const { traceBtnClick } = useAnalytics();
  
  const { updateAuthPublication, status, error } = useDetails();
  const { fetchAgeranges, status: statusAgeranges, error: errorAgeranges, ageranges } = useAgeranges();

  const { dialog, onCloseDialog } = useDialog(error || errorAgeranges, 'Error');

  const localForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      agerangesId: form.values.agerangesId,
    },
    validationSchema: agerangesSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const success = await updateAuthPublication(form?.values?.id, values);
      if (!success) {
        return;
      }
      form.setFieldValue('agerangesId', values.agerangesId);
    },
  });

  const handleCheckboxCheck = (event: any) => {
    if (event.target.checked) {
      const checked = ageranges?.find((item: any) => `${item.id}` === event.target.name);
      localForm.setFieldValue('agerangesId', [...localForm.values.agerangesId, checked?.id]);
    } else {
      localForm.setFieldValue('agerangesId', localForm.values.agerangesId.filter((agerangeId: number) => `${agerangeId}` !== event.target.name));
    }
  };

  const Content = () => (
    form.values.agerangesId?.map((agerangeId: number) => (
      <SimpleChip
        style={{ marginLeft: '2px', marginBottom: '1px' }}
        key={agerangeId}
        label={ageranges.find((item) => `${item.id}` === `${agerangeId}`)?.name}
      />
    ))
  );

  useEffect(() => {
    fetchAgeranges();
  }, []);

  useEffect(() => {
    if (status !== 'loaded') {
      return;
    }
    if (!error) {
      setOpen(false);
    }
  }, [status]);

  return (
    <>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      <SimpleModal
        open={open}
        onClose={() => { setOpen(false); }}
        title={t('title')}
        subtitle={t(`subtitle.${type}`, '') || t('subtitle.class')}
      >
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <FormGroup onChange={handleCheckboxCheck}>
              {ageranges.map((agerange: any) => (
                <CheckboxLabel
                  key={agerange.id}
                  checked={!!(localForm.values.agerangesId?.find((id: number) => `${id}` === `${agerange.id}`))}
                  name={`${agerange.id}`}
                  title={agerange.name}
                  description={agerange.description}
                  descriptionProps={{ fontSize: 13 }}
                  direction='row'
                  disabled={disabled || status === 'loading' || statusAgeranges === 'loading'}
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item alignSelf="flex-end">
            <SimpleButton
              onClick={() => { 
                traceBtnClick({
                  step: 'step guardar ageranges',
                  product: t('save') + 'item ageranges',
                });
                localForm.handleSubmit();
              }}
              disabled={disabled || status === 'loading' || statusAgeranges === 'loading' || localForm?.values?.agerangesId.length === 0}
              loading={status === 'loading' || statusAgeranges === 'loading'}
            >
              {t('save')}
            </SimpleButton>
          </Grid>
        </Grid>
      </SimpleModal>
      <EditListItem
        label={t('title')}
        onClick={() => { 
          traceBtnClick({
            step: 'step abrir dialogo ageranges',
            product: 'item ageranges',
          });
          setOpen(true); 
        }}
        content={form.values.agerangesId && <Content />}
        error={form.touched.agerangesId && Boolean(form.errors.agerangesId)}
        helperText={form.touched.agerangesId && form.errors.agerangesId}
        disabled={disabled}
      />
    </>
  );
};

Ageranges.displayName = 'Ageranges';
export default Ageranges;
