import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const StyledVariant1 = styled(Typography)`
  font-size: 16px;
  opacity: 0.9;
`;

export const StyledVariant2 = styled(Typography)`
  font-size: 16px;
  opacity: 0.9;
  font-weight: 700;
`;

export const StyledVariant4 = styled(Typography)`
  font-size: 14px;
  opacity: 0.6;
`;
