import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { NavigationBottom } from '../../../../../components/navigations';
import useDetails from '../../useDetails';
import { Subtitle } from '../../../../../components/typographies';
import { GroupEntity, PublicationEntity } from '../../../../../entities';
import SimpleDialog from '../../../../../components/dialogs/SimpleDialog/SimpleDialog';
import useDialog from '../../../../../hooks/useDialog';
import { DayOfWeek, getInitialWeek, getMapGroup, getPeriodicityWeek, initialGroup } from '../StepHorarios.utils';
import PeriodicityWeekly from './PeriodicityWeekly';
import { groupFlexibleSchema } from '../StepHorarios.schema';
import getPublicationId from '../../../../../utils/getPublicationId';
import TextFieldForm from '../../../../../components/inputs/text-field-form-v2/TextFieldForm';

interface StepHorariosFlexibleProps {
  publication: PublicationEntity;
}
const StepHorariosFlexible = ({ publication }: StepHorariosFlexibleProps) => {
  const { identifier } = useParams();
  const id = getPublicationId(identifier as string);
  const navigate = useNavigate();
  
  const { updateAuthGroups, toReviewAuthPublication, error: errorUpdate, status: statusUpdate } = useDetails();
  const { dialog, onCloseDialog } = useDialog(errorUpdate, 'Error');

  // const group: any = publication?.groups && publication?.groups?.length > 0 ? 
  //   publication?.groups[0] : { ...initialGroup, periodicity: 'weekly', sessions: undefined };
  const group: any = publication?.groups && publication?.groups?.length > 0 ? 
    publication?.groups[0] : { ...initialGroup, sessions: undefined };

  const initialWeek = getInitialWeek(group?.week);

  const form = useFormik({
    enableReinitialize: false,
    initialValues: {
      quota: group.quota,
      countSessions: group.sessions?.length,
      minQuota: group.quotaSold || 0,
      week: initialWeek,
    },
    validationSchema: groupFlexibleSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      let groups: GroupEntity[] = [];
      const weekAvailable = values.week?.filter((item: DayOfWeek) => 
        item.times && item.times?.length > 0 && item.times?.every((time: any) => time.startTime && time.endTime));
      const week = getPeriodicityWeek(weekAvailable);
      groups = getMapGroup([{
        id: group.id || 'temporal',
        quota: values.quota,
        week,
        agenda: group.agenda || publication.agenda,
        sessions: Array.from({ length: values.countSessions }, () => ({ date: '', startTime: '', endTime: '' })),
      }]);
      const success = await updateAuthGroups(publication.id || 0, groups);
      if (!success) {
        return;
      }
      const toReview = publication?.state === 'draft';
      if (toReview) {
        const successToReview = await toReviewAuthPublication(publication.id || 0);
        if (!successToReview) {
          return;
        }
      }
      navigate(`/my-publication/${identifier}/details/final-step?type=${publication.type}&toReview=${toReview}&toSave=true`);
    },
  });

  return (
    <form>
      {/* <input style={{ display: 'none' }} autoComplete="cc-csc"/> */}
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      <Grid container direction={'row'} spacing={1}>
        <Grid item xs={12}>
          <Subtitle variant="variant4">
          Indica la cantidad de sesiones incluidas en cada compra y el número de cupos que deseas ofrecer.
          </Subtitle>
        </Grid>
        <TextFieldForm 
          label='Cantidad de sesiones'
          name="countSessions"
          form={form}
          loading={statusUpdate === 'loading'}
          type="number"
          size='small'
          xs={6}
        />
        <TextFieldForm 
          label='Cupos'
          name="quota"
          form={form}
          loading={statusUpdate === 'loading'}
          type="number"
          size='small'
          xs={6}
          helperText={(group?.quotaSold || 0) > 0 ? `¡Ya vendiste ${group.quotaSold} cupo${group.quotaSold > 1 ? 's' : ''}!` : ''}
        />
        <Grid item xs={12}>
          <Subtitle variant="variant4"> 
        (Opcional) Ingresa los horarios disponibles durante la semana para que tus estudiantes puedan elegir el que mejor les convenga.
          </Subtitle>
          <Subtitle variant="variant7"> 
        * Ten en cuenta que tus estudiantes coordinarán los días específicos directamente contigo.
          </Subtitle>
        </Grid>
      </Grid>
      
      <PeriodicityWeekly
        statusUpdate={statusUpdate}
        form={form}
      />

      <Grid container mb={2} />

      <NavigationBottom
        handleClickSave={() => form.handleSubmit()}
        handleClickPrevious={()=> {
          const previousPageIdentifier = `/my-publication/${identifier}/details/step-agenda`;
          const previousPageId = `/my-publication/${id}/details/step-agenda`;
          const from = sessionStorage.getItem('from');
          return (from === previousPageIdentifier || from === previousPageId) ? navigate(-1) : navigate(previousPageIdentifier);
        }}
        disabled={statusUpdate === 'loading'}
        saveProps={{ loading: statusUpdate === 'loading' }}
      />
    </form>
  );
};

export default StepHorariosFlexible;
