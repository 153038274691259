import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Grid, TextField, FormControl, LinearProgress } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import useCreateAccount from '../../useCreateAccount';
import useDialog from '../../../../hooks/useDialog';
import AuthContext from '../../../../contexts/AuthContext';
import SimpleDialog from '../../../../components/dialogs/SimpleDialog/SimpleDialog';
import { Subtitle } from '../../../../components/typographies';
import { GoogleButton, SimpleButton, LinkButton } from '../../../../components/buttons';
import { formCreateAccountSchema } from './FormCreateAccount.schema';
import PageTitle from '../../../../components/typographies/PageTitle';
import PageSubtitle from '../../../../components/typographies/PageSubtitle';
import useAnalytics from '../../../../hooks/useAnalytics/useAnalytics';

const FormCreateAccount = () => {
  const { t } = useTranslation(['create-account']);
  const {
    signInWithGoogle, createUserWithEmailAndPassword, status, error,
  } = useCreateAccount();
  const { dialog, onCloseDialog } = useDialog(error, 'Error');
  const { isLogged, isInitializing, authUser } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const modeParam: string | null = searchParams.get('mode');
  const navigate = useNavigate();
  const { traceBtnClick } = useAnalytics();

  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
      repeatPassword: '',
    },
    validationSchema: formCreateAccountSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      await createUserWithEmailAndPassword(values.email, values.password);
    },
  });

  const onClickButtonGoogle = async () => {
    traceBtnClick({
      step: 'step continuar con Google',
      product: 'continuar con Google',
    });
    await signInWithGoogle();
  };

  const onClickContinue = async () => {
    traceBtnClick({
      step: 'step continuar registro',
      product: t('continue'),
    });
    form.handleSubmit();
  };

  const onClickGoToLogin = async () => {
    traceBtnClick({
      step: 'step ir a login',
      product: t('login') + t('login2'),
    });
    navigate('/login');
  };

  useEffect(() => {
    if (isLogged && !isInitializing) {
      if (modeParam === 'beguru' && (!authUser?.data?.stepsFilled || !authUser?.data?.stepsFilled?.personalProfile)) {
        navigate('/my-account/personal/profile?mode=beguru', { replace: true });
      } else {
        navigate('/my-account', { replace: true });
      }
    }
  }, [isInitializing]);

  return (
    <>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      {isInitializing ? <LinearProgress /> : (
        <>
          <form>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <PageTitle>{t(`${modeParam === 'beguru' ? 'titleBeGuru' : 'title'}`)}</PageTitle>
              </Grid>
              <Grid item xs={12}>
                <PageSubtitle>{t(`${modeParam === 'beguru' ? 'subtitleBeGuru' : 'subtitle'}`)}</PageSubtitle>
              </Grid>
              <Grid item xs={12}>
                <GoogleButton onClick={onClickButtonGoogle} disabled={status === 'loading'} />
              </Grid>
              <Grid item xs={12}>
                <Subtitle variant="variant3">{t('alternative')}</Subtitle>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    name="email"
                    label={t('emailLabel') as string}
                    variant="outlined"
                    type="email"
                    fullWidth
                    value={form.values.email}
                    onChange={form.handleChange}
                    error={form.touched.email && Boolean(form.errors.email)}
                    helperText={form.touched.email && form.errors.email}
                    disabled={status === 'loading'}
                    autoFocus
                    inputProps={{ maxLength: 100 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    name="password"
                    label={t('passwordLabel') as string}
                    variant="outlined"
                    type="password"
                    fullWidth
                    value={form.values.password}
                    onChange={form.handleChange}
                    error={form.touched.password && Boolean(form.errors.password)}
                    helperText={form.touched.password && form.errors.password}
                    disabled={status === 'loading'}
                    inputProps={{ maxLength: 100 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    name="repeatPassword"
                    label={t('repeatPasswordLabel') as string}
                    variant="outlined"
                    type="password"
                    autoComplete="new-password"
                    fullWidth
                    value={form.values.repeatPassword}
                    onChange={form.handleChange}
                    error={form.touched.repeatPassword && Boolean(form.errors.repeatPassword)}
                    helperText={form.touched.repeatPassword && form.errors.repeatPassword}
                    disabled={status === 'loading'}
                    inputProps={{ maxLength: 100 }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </form>
          <Grid
            container
            direction="row-reverse"
            paddingTop={3}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item paddingBottom={2}>
              <SimpleButton onClick={onClickContinue} disabled={status === 'loading'} loading={status === 'loading'}>
                {t('continue')}
              </SimpleButton>
            </Grid>
            <Grid item paddingBottom={2}>
              <LinkButton
                onClick={onClickGoToLogin}
                texts={[
                  t('login'),
                  t('login2'),
                ]}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

FormCreateAccount.displayName = 'FormCreateAccount';
export default FormCreateAccount;
