import { useEffect, useRef, useState } from 'react';
import { ImageList } from './ImageListItem.styled';
import ImageItem from '../image-item/ImageItem';
import getImagesCompressed from '../../../utils/getImagesCompressed';
import { Grid, CircularProgress } from '@mui/material';
import { LinkButton } from '../../buttons';

interface ImageListItemProps {
  disabled?: boolean;
  name: string;
  maxValues?: number;
  onChange?: any;
  value: any;
  onErrorMaxValues?: () => void;
  onClick?: () => any;
}

const ImageListItem = ({
  disabled,
  name,
  maxValues = 1,
  onChange,
  value = [],
  onErrorMaxValues,
  onClick,
}: ImageListItemProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const internalDisabled = disabled || loading;
    
  const [loadedImages, setLoadedImages] = useState<number>(0);
  const [allImagesLoaded, setAllImagesLoaded] = useState<boolean>(false);
  const handleImageLoad = () => {
    setLoadedImages((prevLoadedImages) => prevLoadedImages + 1);
  };
  useEffect(() => {
    if (loadedImages === value?.length) {
      setAllImagesLoaded(true);
    }
  }, [loadedImages]);

  const getUniqueValues = (values: any) => values.reduce((accumulator: any, currentValue: any) => {
    if (!accumulator.find((item: any) => item.url === currentValue.url)) {
      accumulator.push(currentValue);
    }
    return accumulator;
  }, []);

  const onInternalChange = async () => {
    if (inputRef.current?.value.length === 0) {
      return;
    }
    if (maxValues !== 1) {
      const newFilesLength = Array.from(inputRef.current?.files || []).length;
      const currentFilesLength = Array.from(value || []).length;
      if (newFilesLength + currentFilesLength > maxValues) {
        onErrorMaxValues?.();
        return;
      }
    }
    setLoading(true);
    const files = (await getImagesCompressed(inputRef.current?.files)).map((file: any, index: number) => ({
      file,
      // name: file.name,
      order: value.length + index + 1,
      url: URL.createObjectURL(file),
    }));
    const newValue = maxValues > 1 ? getUniqueValues([...value, ...files]) : files;
    if (newValue.length > 0 && !newValue.find((v: any) => v.fav)) {
      newValue[0].fav = 1;
    }
    onChange?.({
      // ...inputRef,
      target: {
        // ...inputRef.current,
        value: newValue,
        name,
      },
    });
    setLoading(false);
  };

  const handleOnClick = () => { inputRef?.current?.click(); };

  const changeOrder = (currentOrder: number, newOrder: number) => {
    const newValue = value.map((v: any) => {
      if (v.order === currentOrder) {
        return { ...v, order: newOrder };
      }
      if (v.order === newOrder) {
        return { ...v, order: currentOrder };
      }
      return v;
    });
    onChange?.({
      target: {
        value: newValue,
        name,
      },
    });
  };

  const handleDelete = (item: any) => {
    const newValue = value
      .filter((v: any) => v.order !== item.order)
      .map((v: any) => {
        if (v.order > item.order) {
          return { ...v, order: v.order - 1 };
        }
        return v;
      });
    if (item.fav && newValue.length > 0) {
      newValue[0].fav = 1;
    }
    onChange?.({
      target: {
        value: newValue,
        name,
      },
    });
  };

  const onClickArrowBack = (item: any) => {
    const currentOrder = item.order;
    const previousOrder = currentOrder - 1;
    changeOrder(currentOrder, previousOrder);
  };

  const onClickArrowForward = (item: any) => {
    const currentOrder = item.order;
    const nextOrder = currentOrder + 1;
    changeOrder(currentOrder, nextOrder);
  };

  const onClickStar = (item: any) => {
    const newValue = value.map((v: any) => ({
      ...v,
      fav: v.url === item.url,
    }));
    onChange?.({
      target: {
        value: newValue,
        name,
      },
    });
  };

  return (
    <Grid container direction="column">
      <Grid item width="inherit">
        <ImageList style={{ opacity: !allImagesLoaded ? 0 : 1 }}>
          <input style={{ display: 'none' }}
            disabled={internalDisabled}
            accept="image/*"
            name={name}
            id="icon-button-file"
            ref={inputRef}
            type="file"
            onChange={onInternalChange}
            multiple={maxValues > 1}
          />
          {value?.sort((a: any, b: any) => a.order - b.order).map((audiovisual: any) => (
            <ImageItem
              key={audiovisual.order}
              src={audiovisual?.url}
              alt={audiovisual.alt || 'imagen publicación'}
              fav={audiovisual.fav}
              disabled={disabled}
              onLoad={handleImageLoad}
              onClickDelete={() => handleDelete(audiovisual)}
              onClickArrowBack={() => onClickArrowBack(audiovisual)}
              onClickArrowForward={() => onClickArrowForward(audiovisual)}
              onClickStar={() => onClickStar(audiovisual)}
              showDelete
              showArrowBack={audiovisual.order !== 1 && value?.length > 1}
              showArrowForward={audiovisual.order !== value?.length && value?.length > 1}
              showStar
            />
          ))}
          {(loading || (value?.length > 0 && !loadedImages)) && <CircularProgress size={20} />}
        </ImageList>
      </Grid>
      <Grid item alignSelf="flex-end">
        <LinkButton
          onClick={() => { 
            handleOnClick();
            onClick?.();
          }}
          disabled={disabled}
          texts={['', '+ Agregar imagen']}
        />
      </Grid>
    </Grid>
  );
};

ImageListItem.displayName = 'ImageListItem';
export default ImageListItem;
