import { forwardRef, useContext } from 'react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PersonIcon from '@mui/icons-material/Person';
import { StyledLoggedButton, StyledNotLoggedButton, StyledNotLoggedIconButton } from './LoginButton.styled';
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import AuthContext from '../../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';

interface LoginButtonProps {
  style?: any;
  color?: 'primary';
}

const LoginButton = forwardRef<HTMLButtonElement, LoginButtonProps>(
  ({ ...rest }, ref) => {
    const theme = useTheme();
    const { traceBtnClick } = useAnalytics();
    const isUpMD = useMediaQuery(theme.breakpoints.up('md'));
    const { t } = useTranslation(['components']);
    const { isLogged } = useContext(AuthContext);
    const children = isLogged ? t('navigation-bar.my-profile') : t('navigation-bar.login');
    const navigate = useNavigate();

    const goToLogin = () => { 
      traceBtnClick({
        step: 'step ir a login',
        product: 'Login',
      });
      navigate('/login'); 
    };

    const LoggedButton = () => <StyledLoggedButton 
      startIcon={children as string && <AccountCircleOutlinedIcon />}
      variant="outlined"
      size="large"
      href='/my-account'
      ref={ref}   
      {...rest}
    >
      {children}
      {!children && <CircularProgress size={25} />}
    </StyledLoggedButton>;

    const NotLoggedUpMDButton = () => 
      <StyledNotLoggedButton 
        ref={ref} 
        {...rest} 
        onClick={goToLogin}
        aria-label='login'
      >
        Login
      </StyledNotLoggedButton>
    ;
    const NotLoggedDownMDButton = () => 
      <StyledNotLoggedIconButton 
        ref={ref} {...rest} 
        onClick={goToLogin}
        aria-label='login'
      >
        <PersonIcon fontSize='medium' />
      </StyledNotLoggedIconButton>
    ;
    const NotLoggedButton = () => isUpMD ? <NotLoggedUpMDButton /> : <NotLoggedDownMDButton />;
    
    return (
      isLogged ? <LoggedButton /> : <NotLoggedButton /> 
    );
  },
);

LoginButton.displayName = 'LoginButton';
export default LoginButton;
