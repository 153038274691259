import { forwardRef, ReactElement } from 'react';
import { Typography } from '@mui/material';
import {
  StyledVariant1,
  StyledVariant2,
  StyledVariant4,
} from './Label.styled';

interface LabelProps {
  variant?: 'variant1' | 'variant2' | 'variant4';
  children?: string | ReactElement;
  id?: string;
  isdownmd?: number;
  style?: any;
}
const Label = forwardRef<HTMLParagraphElement, LabelProps>(
  ({ variant = 'variant1', ...rest }, ref) => {
    if (variant === 'variant1') {
      return <StyledVariant1 ref={ref} {...rest} />;
    }

    if (variant === 'variant2') {
      return <StyledVariant2 ref={ref} {...rest} />;
    }

    if (variant === 'variant4') {
      return <StyledVariant4 ref={ref} {...rest} />;
    }

    return (
      <Typography ref={ref} {...rest} />
    );
  },
);

Label.displayName = 'Label';
export default Label;
