import { useContext, ReactElement } from 'react';
import { LinearProgress } from '@mui/material';
import FlagContext from '../../../contexts/FlagContext';
import NotFound from '../../../pages/not-found/NotFound';

interface FinancesAccessProps {
  children: ReactElement;
}

const FinancesAccess = ({ children }: FinancesAccessProps) => {
  const { financesAvailable, loaded } = useContext(FlagContext);

  if (loaded) {
    return (financesAvailable ? children : <NotFound />);
  }
  return (<LinearProgress />);
};

FinancesAccess.displayName = 'FinancesAccess';
export default FinancesAccess;
