import { forwardRef } from 'react';
import SimpleDialog from '../../../../../../components/dialogs/SimpleDialog/SimpleDialog';
import { useTranslation } from 'react-i18next';
import { PublicationEntity } from '../../../../../../entities';
import { SimpleButton } from '../../../../../../components/buttons';

interface NewStateDialogProps {
  onClose: () => void;
  onContinue: (entity: any) => void;
  open: boolean;
  value?: PublicationEntity;
  status?: string;
}

const NewStateDialog = forwardRef<HTMLDivElement, NewStateDialogProps>(
  ({
    onClose,
    onContinue,
    value,
    status,
    ...props
  }, ref) => {
    const { t } = useTranslation('my-account', { keyPrefix: 'admin.publications.dialog' });
    return (
      <SimpleDialog 
        title={t('title')}
        actions={
          <>
            <SimpleButton 
              onClick={onClose} 
              variant="text" 
              disabled={status === 'loading'}
            >
              {t('keep-button')}
            </SimpleButton>
            <SimpleButton 
              onClick={() => onContinue(value)} 
              variant="text" 
              disabled={status === 'loading'}
              loading={status === 'loading'}
            >
              {t('continue-button')}
            </SimpleButton>
          </>}
        ref={ref}
        onClose={onClose}
        {...props} 
      />
    );
  },
);

NewStateDialog.displayName = 'NewStateDialog';
export default NewStateDialog;
