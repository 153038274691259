import { Dayjs, dayjs } from '../../../../configs/dayjs';
import { GroupEntity, SessionEntity } from '../../../../entities';
// import { Periodicity } from '../../../../entities/GroupEntity';
import { getDateFormat } from '../../../../utils/Day';

export const isIdTemporal = (id?: any) => (
  id?.toString().includes('temporal')
);

export const getNearestQuarterHour = (eachMinutes: number = 15): string => {
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes();
  const quarterHours = Math.floor(minutes / eachMinutes);
  const adjustedMinutes = quarterHours * eachMinutes;

  return `${hours}:${adjustedMinutes.toString().padStart(2, '0')}`;
};

export const timeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

export const initialSession = {
  id: 'temporal',
  date: null,
  startTime: '',
  endTime: '',
};

export const initialGroup = {
  id: 'temporal',
  quota: undefined,
  // periodicity: 'no',
  // weeklySince: null,
  // weeklyUntil: null,
  sessions: [initialSession],
};

export const getNewInitialSession = () => ({
  ...initialSession,
  id: `temporal${Math.random()}`,
});

export const getNewInitialGroup = () => ({
  ...initialGroup,
  id: `temporal${Math.random()}`,
  sessions: [getNewInitialSession()],
});

export const getMapGroup = (groups: GroupEntity[]): GroupEntity[] => (
  groups?.map((group: GroupEntity) => ({
    id: isIdTemporal(group.id) ? undefined : group.id,
    quota: group.quota,
    // periodicity: group.periodicity,
    week: group.week,
    sessions: group.sessions?.map((session: SessionEntity) => ({
      id: isIdTemporal(session.id) ? undefined : session.id,
      date: session.date,
      startTime: session.startTime,
      endTime: session.endTime,
    })),
    agenda: group.agenda,
  }))
);

type Time = {
  startTime?: string;
  endTime?: string;
  quota?: number;
};

export interface DayOfWeek {
  value: 'Lunes' | 'Martes' | 'Miércoles' | 'Jueves' | 'Viernes' | 'Sábado' | 'Domingo';
  label?: 'Lunes' | 'Martes' | 'Miércoles' | 'Jueves' | 'Viernes' | 'Sábado' | 'Domingo';
  times?: Time[];
}

const week: DayOfWeek[] = [
  { value: 'Lunes', label: 'Lunes', times: [{ startTime: '', endTime: '', quota: undefined }] },
  { value: 'Martes', label: 'Martes', times: [{ startTime: '', endTime: '', quota: undefined }] },
  { value: 'Miércoles', label: 'Miércoles', times: [{ startTime: '', endTime: '', quota: undefined }] },
  { value: 'Jueves', label: 'Jueves', times: [{ startTime: '', endTime: '', quota: undefined }] },
  { value: 'Viernes', label: 'Viernes', times: [{ startTime: '', endTime: '', quota: undefined }] },
  { value: 'Sábado', label: 'Sábado', times: [] },
  { value: 'Domingo', label: 'Domingo', times: [] },
];

const getTimesFromString = (value: string) => {
  try {
    return  JSON.parse(value);
  } catch (e) {
    return [];
  }
};
export const getWeekArrayByString = (weekString: string): DayOfWeek[] => {
  if (!weekString) return [];
  const items = weekString?.split('\n')?.filter((day) => !day.startsWith('Remove')).map((day: any) => {
    const [value, timesString] = day.split(/,(.*)/s);
    return { value, times: getTimesFromString(timesString) };
  });
  return items.filter((item: any) => item.times?.length > 0);
};

export const getRemoveDatesArrayByString = (item: string): Dayjs[] => {
  if (!item) return [];
  const items = item?.split('\n')?.filter((day) => day.startsWith('Remove')).map((day: any) => {
    const [, date] = day.split(',');
    return dayjs(date);
  });
  return items;
};

export const getInitialWeek = (periodicityWeek?: string): DayOfWeek[] => {
  const initialWeek: DayOfWeek[] = week;
  if (!!periodicityWeek) {
    const periodicityWeekArray = getWeekArrayByString(periodicityWeek);
    if (periodicityWeekArray.length > 0) {
      initialWeek.forEach((_: any, index: number) => {
        initialWeek[index].times = [];
      });
    }
    periodicityWeekArray.forEach((item: any) => {
      const index = initialWeek.findIndex((i) => i.value === item.value);
      if (index !== -1) {
        initialWeek[index].times = item.times;
      }
    });
  }
  return initialWeek;
};
export const getInitialRemoveDates = (periodicityWeek?: string): Dayjs[] => {
  const initialRemoveDates: Dayjs[] = [];
  if (!!periodicityWeek) {
    const periodicityWeekArray = getRemoveDatesArrayByString(periodicityWeek);
    initialRemoveDates.push(...periodicityWeekArray);
  }
  return initialRemoveDates;
};

export const getPeriodicityWeek = (_week: DayOfWeek[], _removeDates?: Dayjs[]): string => {
  const weekString = _week?.map((item: any) => `${item.value},${JSON.stringify(item.times || [])}${item.quota ? `,${item.quota}` : '' }`).join('\n') || '';
  const removeDatesString = _removeDates?.map((item: any) => `Remove,${getDateFormat(item)}`).join('\n') || '';
  return `${weekString}${!!removeDatesString ? `\n${removeDatesString}` : ''}`;
};

// export const periodicities: { value: Periodicity, label: string }[] = [
//   { value:  'no', label: 'No se repite' },
//   { value:  'weekly', label: 'Repetir semanalmente' },
// ];

export const isOnlyOneAvailable = (_week: DayOfWeek[]) => {
  //TODO: analizar si esta regla debe ir, ver casos de uso
  return false;
  // retorne si existe solo un dia disponible
  const countTimes = _week.reduce((acc: number, item: DayOfWeek) => {
    return acc + (item.times?.length ? item.times?.length : 0);
  }, 0);  
  return countTimes === 1;
};

const dateMatchWithDay = (date: Dayjs, day: string): boolean => {
  const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  return diasSemana[date.get('day')] === day;
};

interface GetDatesBetween {
  since: Dayjs, 
  until: Dayjs, 
  weekAvailable: DayOfWeek[],
  removes: any[],
}
export const getDatesBetween = ({ since, until, weekAvailable, removes }:GetDatesBetween) => {
  const datesBetween: any = [];
  if (since && until && since <= until && weekAvailable?.length > 0) {
    let current = since;
    const stop = until?.add(1, 'day');
    while (current < stop) {
      for (const day of weekAvailable) {
        if (dateMatchWithDay(current, day.value)) {
          for (const time of day.times || []) {
            datesBetween.push({
              fecha: current,
              startTime: time?.startTime,
              endTime: time?.endTime,
              quota: time?.quota,
            });
          }
        }
      }
      current = current?.add(1, 'day');
    }
  }
  return datesBetween.filter((date: any) => {
    const exists = removes.filter((removeDate: Dayjs) => removeDate.isSame(date.fecha)).length > 0;
    return !exists;
  });
};

export const disableToday = (date: any) => {
  // Compara la fecha proporcionada con la fecha de hoy
  return dayjs().isSame(date, 'day');
};