import { Avatar, Container, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MenuCard from '../../components/cards/menu-card/MenuCard';
import MainContainer from '../../components/containers/main-container/MainContainer';
import NavigationBar from '../../components/navigations/navigation-bar/NavigationBar';
import AuthContext from '../../contexts/AuthContext';
import useMyAccount from './useMyAccount';
import PageTitle from '../../components/typographies/PageTitle';
import PageSubtitle from '../../components/typographies/PageSubtitle';
import { StyledSubtitleTypography, StyledTitleTypography } from '../../components/cards/menu-card/MenuCard.styled';
import { SimpleButton } from '../../components/buttons';
import useAnalytics from '../../hooks/useAnalytics/useAnalytics';

const MyAccount = () => {
  const { t } = useTranslation(['my-account']);
  const navigate = useNavigate();
  const { authUser, logout } = useContext(AuthContext);
  const { count } = useMyAccount();
  const theme = useTheme();
  const isdownsm = useMediaQuery(theme.breakpoints.down('sm'));
  const { traceBtnClick } = useAnalytics();
  
  const onClickLogout = () => {
    traceBtnClick({
      step: 'step cerrar sesión',
      product: 'Cerrar sesión',
    });
    logout?.();
  };

  return (
    <MainContainer showFooter>
      <NavigationBar variant="variant4" />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageTitle>{t('title')}</PageTitle>
        </Grid>
        <Grid item xs={12}>
          <PageSubtitle>{t('subtitle')}</PageSubtitle>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Container maxWidth="lg">
        <Grid container direction="row" paddingTop={2}>
          <Grid container item xs={12} sm={3}>
            <Grid item xs={12} padding={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
              <Avatar
                alt={authUser?.data?.displayName}
                src={authUser?.data?.photoUrl}
                sx={{ 
                  width: '100%', 
                  maxWidth: isdownsm ? 120 : 240, 
                  height: 'auto', 
                  aspectRatio: '1',
                  marginBottom: 1,
                }}
              />
              <StyledTitleTypography>
                {authUser?.data?.displayName}
              </StyledTitleTypography>
              <StyledSubtitleTypography gutterBottom>
                {authUser?.data?.email}
              </StyledSubtitleTypography>
              <SimpleButton onClick={onClickLogout} variant='outlined'>
                Cerrar sesión
              </SimpleButton>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={9} spacing={2}>
            <Grid item xs={12} sm={6}>
              <MenuCard
                title={t('menu.personal.title')}
                subtitle={t('menu.personal.subtitle')}
                icon="profile"
                onClick={() => { 
                  traceBtnClick({
                    step: `step ir a ${t('menu.personal.title')}`,
                    product: 'icon profile',
                  });
                  navigate('/my-account/personal');
                }}
              />
            </Grid>
            {authUser?.data?.isGuru && (
              <Grid item xs={12} sm={6}>
                <MenuCard
                  title={t('menu.guru.title')}
                  subtitle={t('menu.guru.subtitle')}
                  icon="guru"
                  onClick={() => { 
                    traceBtnClick({
                      step: `step ir a ${t('menu.guru.title')}`,
                      product: 'icon guru',
                    });
                    navigate('/my-account/guru'); 
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <MenuCard
                title={t('menu.purchase.title')}
                subtitle={t('menu.purchase.subtitle')}
                icon="purchase"
                onClick={() => { 
                  traceBtnClick({
                    step: `step ir a ${t('menu.purchase.title')}`,
                    product: 'icon purchase',
                  });
                  navigate('/my-purchases'); 
                }}
                disabled={!count || count === 0}
              />
            </Grid>
            {authUser?.data?.isAdmin && (
              <Grid item xs={12} sm={6}>
                <MenuCard
                  title={t('menu.admin.title')}
                  icon="admin"
                  onClick={() => { 
                    traceBtnClick({
                      step: `step ir a ${t('menu.admin.title')}`,
                      product: 'icon admin',
                    });
                    navigate('/my-account/admin'); 
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default MyAccount;
