import API from './config/apiInstance';
import apiError from './apiError';

export async function getAuthProfit() {
  try {
    const response = await API.get('/auth/checkout-item/profit');
    if (response && response.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    throw apiError({
      code: 'GET /auth/checkout-item',
      message: 'Hubo un problema al obtener las utilidades del usuario',
    });
  }
}
