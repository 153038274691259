import { useState } from 'react';
import PublicationEntity from '../../entities/PublicationEntity';
import { getPublication } from '../../services/publication';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
  data?: PublicationEntity;
}

const usePublication = () => {
  const [state, setState] = useState<StateProps>({ status: 'idle', error: null });

  const fetchPublication = async (id: number, attributes?: string[]) => {
    setState({ status: 'loading' });
    try {
      const data = await getPublication(id, attributes);
      setState({ data, status: 'loaded' });
    } catch (error) {
      setState({ status: 'loaded', error });
    }
  };

  return {
    ...state,
    fetchPublication,
  };
};

export default usePublication;