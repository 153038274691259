import { Grid, LinearProgress } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SimpleDialog from '../../../../components/dialogs/SimpleDialog/SimpleDialog';
import SimpleError from '../../../../components/errors/simple-error/SimpleError';
import { NavigationBottom } from '../../../../components/navigations';
import useDialog from '../../../../hooks/useDialog';
import useDetails from '../useDetails';
import stepDescripcionSchema from './StepDescripcion.schema';
import TextFieldForm from '../../../../components/inputs/text-field-form-v2/TextFieldForm';
import { useEffect } from 'react';
import getPublicationId from '../../../../utils/getPublicationId';
import useMyPublication from '../../useMyPublication';
import getObjectDifferences from '../../../../utils/getObjectDifferences';

const StepDescripcion = () => {
  const { t } = useTranslation('my-publication', { keyPrefix: 'details.step-descripcion' });
  const navigate = useNavigate();
  const location = useLocation();
  const { identifier } = useParams();
  const id = getPublicationId(identifier as string);

  const { fetchAuthPublication, status: statusFetch, error: errorFetch, data: publication } = useMyPublication();
  const { updateAuthPublication, status: statusUpdate, error: errorUpdate } = useDetails();
  const { dialog, onCloseDialog } = useDialog(errorUpdate, 'Error');

  const onContinue = () => {
    navigate(`/my-publication/${identifier}/details/step-detalles`);
  };

  type InitialValuesType = {
    [key: string]: any,
  };
  const initialValues: InitialValuesType = {
    title: publication?.title || '',
    description: publication?.description || '',
    detail: publication?.detail || '',
    material: publication?.material || '',
  };
  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: stepDescripcionSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const newValues = getObjectDifferences(initialValues, values);
      if (!!newValues) {
        updateAuthPublication(id, newValues);
      } else {
        onContinue();
      }
    },
  });

  useEffect(() => {
    if (sessionStorage.getItem('from')?.startsWith('/new-publication/type')) {
      onContinue();
    }
    if (id && statusFetch === 'idle') {
      const attributes = [
        'id',
        'type',
        'title',
        'state',
        'description',
        'detail',
        'material',
      ];
      fetchAuthPublication(id, attributes);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      sessionStorage.setItem('from', location.pathname);
    };
  }, []);

  useEffect(() => {
    if (statusUpdate !== 'loaded') {
      return;
    }
    if (statusUpdate === 'loaded' && !errorUpdate) {
	    onContinue();
	  }
  }, [statusUpdate]);

  if (statusFetch === 'loading') {
    return <LinearProgress />;
  }

  return (
    <>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      {(!id || errorFetch) && (
        <SimpleError>{errorFetch?.message}</SimpleError>
      )}
      {!!publication && (
        <>
          <Grid container direction="column" spacing={1}>
            <TextFieldForm
              title={t(`form.title.describe.${publication?.type}`, '') || t('form.title.describe.class')}
              name="title"
              form={form}
              label={t('form.title.label') as string}
              loading={statusUpdate === 'loading'}
              inputProps={{ maxLength: 200 }}
            />
            <TextFieldForm
              title={t(`form.description.describe.${publication?.type}`, '') || t('form.description.describe.class')}
              name="description"
              form={form}
              label={t('form.description.label') as string}
              loading={statusUpdate === 'loading'}
              inputProps={{ maxLength: 2000 }}
              multiline
              minRows={3}
              maxRows={5}
            />
            <TextFieldForm
              title={t(`form.detail.describe.${publication?.type}`, '') || t('form.detail.describe.class')}
              name="detail"
              form={form}
              label={t(`form.detail.label.${publication?.type}`, '') as string || t('form.detail.label.class') as string}
              loading={statusUpdate === 'loading'}
              inputProps={{ maxLength: 2000 }}
              multiline
              minRows={2}
              maxRows={4}
            />
            <TextFieldForm
              title={t(`form.material.describe.${publication?.type}`, '') || t('form.detail.describe.class')}
              name="material"
              form={form}
              label={t('form.material.label') as string}
              loading={statusUpdate === 'loading'}
              inputProps={{ maxLength: 1000 }}
              multiline
              minRows={2}
              maxRows={3}
            />
          </Grid>
          <NavigationBottom 
            handleClickSave={() => form.handleSubmit()}
            handleClickPrevious={()=> {
              const previousPageNew = '/new-publication/type';
              const previousPage = `/my-publication/${identifier}`;
              const from = sessionStorage.getItem('from');
              return from?.startsWith(previousPageNew) || from === previousPage ? navigate(-1) : navigate(previousPage);
            }}
            disabled={statusUpdate === 'loading'}
            saveProps={{ loading: statusUpdate === 'loading' }}
          />
        </>
      )}
    </>
  );
};

export default StepDescripcion;
