import { forwardRef } from 'react';
import {
  StyledCircleNumber,
} from './CircleNumber.styled';

interface CircleNumberProps {
  children?: string;
}
const CircleNumber = forwardRef<HTMLParagraphElement, CircleNumberProps>(
  ({ children, ...rest }, ref) => (
    <StyledCircleNumber
      align="center"
      ref={ref}
      {...rest}
    >
      {children}
    </StyledCircleNumber>
  ),
);

CircleNumber.displayName = 'CircleNumber';
export default CircleNumber;
