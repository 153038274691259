import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavigationBar from '../../../../components/navigations/navigation-bar/NavigationBar';
import DividedContainer from '../../../../components/containers/divided-container/DividedContainer';
import PageTitle from '../../../../components/typographies/PageTitle';
import PageSubtitle from '../../../../components/typographies/PageSubtitle';
import { FormControl, Grid, InputAdornment, TextField } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import AuthContext from '../../../../contexts/AuthContext';
import usePersonal from '../usePersonal';
import useDialog from '../../../../hooks/useDialog';
import { contactSchema } from './Contact.schema';
import SimpleDialog from '../../../../components/dialogs/SimpleDialog/SimpleDialog';
import { DateInput } from '../../../../components/inputs';
import PaymentAccount from './components/payment-account/PaymentAccount';
import { NavigationBottom } from '../../../../components/navigations';

const MyAccountPersonalContact = () => {
  const { t } = useTranslation(['my-account']);
  const { authUser } = useContext(AuthContext);
  const { updateProfile, status, error } = usePersonal();
  const { dialog, onCloseDialog } = useDialog(error, 'Error');
  const [searchParams] = useSearchParams();
  const modeParam: string | null = searchParams.get('mode');
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    return () => {
      sessionStorage.setItem('from', location.pathname);
    };
  }, []);

  const onBack = () => {
    const previousPage = '/my-account/personal';
    const from = sessionStorage.getItem('from');
    return from?.startsWith(previousPage) ? navigate(-1) : navigate(previousPage);
  };
  
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: authUser?.data?.name || '',
      lastname: authUser?.data?.lastname || '',
      birthdate: authUser?.data?.birthdate || '',
      phoneNumber: authUser?.data?.phoneNumber || '',
      identificationValue: authUser?.data?.identificationValue || '',
    },
    validationSchema: contactSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const success = await updateProfile({
        name: values.name,
        lastname: values.lastname,
        birthdate: new Date(values.birthdate),
        phoneNumber: values.phoneNumber,
        identificationType: 'RUT',
        identificationValue: values.identificationValue,
      });
      if (success) {
        if (modeParam === 'beguru') {
          navigate('/new-publication?mode=beguru');
        } else {
          onBack();
        }
      }
    },
  });

  return (
    <DividedContainer>
      <NavigationBar variant="variant4" />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageTitle>{t(`personal.contact.${modeParam === 'beguru' ? 'titleBeGuru' : 'title'}`)}</PageTitle>
        </Grid>
        <Grid item xs={12}>
          <PageSubtitle>{t('personal.contact.subtitle')}</PageSubtitle>
        </Grid>
      </Grid>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <FormControl margin="dense" fullWidth>
            <TextField
              name="name"
              label={t('personal.contact.nameLabel') as string}
              value={form.values.name}
              onChange={form.handleChange}
              error={form.touched.name && Boolean(form.errors.name)}
              helperText={form.touched.name && form.errors.name}
              disabled={status === 'loading'}
              inputProps={{ maxLength: 100 }}
              autoFocus
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl margin="dense" fullWidth>
            <TextField
              name="lastname"
              label={t('personal.contact.lastnameLabel') as string}
              value={form.values.lastname}
              onChange={form.handleChange}
              error={form.touched.lastname && Boolean(form.errors.lastname)}
              helperText={form.touched.lastname && form.errors.lastname}
              disabled={status === 'loading'}
              inputProps={{ maxLength: 50 }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl margin="dense" fullWidth>
            <TextField
              name="identificationValue"
              label={t('personal.contact.identificationValueLabel') as string}
              fullWidth
              value={form.values.identificationValue}
              onChange={form.handleChange}
              error={form.touched.identificationValue && Boolean(form.errors.identificationValue)}
              helperText={form.touched.identificationValue && form.errors.identificationValue}
              disabled={status === 'loading'}
              inputProps={{ maxLength: 12 }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl margin="dense" fullWidth>
            <DateInput
              disableFuture
              label={t('personal.contact.birthdateLabel') as string}
              value={form.values.birthdate}
              onChange={(value: any) => { 
                form.setFieldValue('birthdate', value); 
              }}
              disabled={status === 'loading'}
              name="birthdate"
              size="medium"
              fullWidth 
              renderInput={() => <></>} 
              helperText={form.touched.birthdate && form.errors.birthdate}
              error={form.touched.birthdate && Boolean(form.errors.birthdate)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl margin="dense" fullWidth>
            <TextField
              name="phoneNumber"
              label={t('personal.contact.phoneNumberLabel') as string}
              fullWidth
              value={form.values.phoneNumber}
              onChange={form.handleChange}
              error={form.touched.phoneNumber && Boolean(form.errors.phoneNumber)}
              helperText={form.touched.phoneNumber && form.errors.phoneNumber}
              disabled={status === 'loading'}
              InputProps={{
                startAdornment: <InputAdornment position="start">+56</InputAdornment>,
              }}
              inputProps={{ maxLength: 15 }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <PaymentAccount disabled={status === 'loading'} />
        </Grid>
      </Grid>
      <NavigationBottom 
        handleClickSave={() => form.handleSubmit()}
        saveText={t(`personal.contact.${modeParam === 'beguru' ? 'continue' : 'save'}`)}
        handleClickPrevious={onBack}
        disabled={status === 'loading'}
        saveProps={{ loading: status === 'loading' }}
      />
    </DividedContainer>
  );
};

export default MyAccountPersonalContact;
