import API from './config/apiInstance';
import apiError from './apiError';

export async function postPublicationReview(reviewId: string, evaluation: number, comment?: string) {
  try {
    await API.post('/publication/updateReview', {
      reviewId,
      evaluation,
      comment,
    });
  } catch (error: any) {
    const message = error?.response?.data?.message || '';
    throw apiError({ code: '/publication/updateReview', message: `Hubo un problema al intentar enviar el review ${reviewId + evaluation + comment}. ${message}` });
  }
}
