import { useState } from 'react';
import { postQuestionAnswer, putQuestionAnswer } from '../../../services/questionAnswer';

interface Props {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
}

const useQuestionAnwser = () => {

  const [state, setState] = useState<Props>({ status: 'idle', error: null });

  const createQuestion = async (publicationId: number, question: string) => {
    setState({ status: 'loading' });
    try {
      await postQuestionAnswer(publicationId, question);
      setState({ status: 'loaded' });
    } catch (error) {
      setState({ status: 'loaded', error });
    }
  };

  const createAnswer = async (questionAnswerId: number, answer: string) => {
    setState({ status: 'loading' });
    try {
      await putQuestionAnswer(questionAnswerId, answer);
      setState({ status: 'loaded' });
    } catch (error) {
      setState({ status: 'loaded', error });
    }
  };

  return {
    status: state.status,
    error: state.error,
    createQuestion,
    createAnswer,
  };
};

export default useQuestionAnwser;