import Fab, { FabProps } from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { forwardRef } from 'react';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';

interface ScrollTopButtonProps extends FabProps {}

const ScrollTopButton = forwardRef<HTMLButtonElement, ScrollTopButtonProps>(
  ({ ...props }, ref) => {
    const { traceBtnClick } = useAnalytics();
    const ScrollToTop = () => {
      traceBtnClick({
        step: 'step ir al inicio de la página',
        product: 'Flecha arriba',
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
      <Fab 
        onClick={() => ScrollToTop()} 
        sx={{ position: 'absolute', top: '-28px', right: '10%', backgroundColor: '#cbc9e7', transition: '.3s', '&:hover': { backgroundColor: '#b5b3d1' } }}
        aria-label='scroll al inicio de la página'
        ref={ref}
        {...props}
      >
        <KeyboardArrowUpIcon />
      </Fab>
    );
  });

export default ScrollTopButton;