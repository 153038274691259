import { useState } from 'react';
import { ListEntity, PublicationEntity } from '../../../../entities';
import { getAdminPublications, putAdminStatePublication } from '../../../../services/adminPublication';

interface StateProps extends ListEntity {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
  data?: PublicationEntity[];
}

const useAdminPublications = () => {
  const [state, setState] = useState<StateProps>({ 
    status: 'idle',
  });

  const fetchAdminPublications = async (params: any) => {
    setState({ status: 'loading', data: [] });
    try {
      const response = await getAdminPublications(params);
      setState({ 
        data: response?.data,
        count: response?.count,
        page: response?.page,
        perPage: response?.per_page,
        totalPages: response?.total_pages,
        status: 'loaded', 
      });
    } catch (error) {
      setState({ status: 'loaded', error });
    }
  };

  const updateStatePublication = async (id: number, newState?: 'approved' | 'rejected') => {
    if (newState) {
      setState({ status: 'loading' });
      try {
        await putAdminStatePublication({ id, newState });
        setState({ status: 'loaded' });
        return true;
      } catch (error) {
        setState({ status: 'loaded', error });
      }
    }
    return false;
  };

  return {
    ...state,
    fetchAdminPublications,
    updateStatePublication,
  };
};

export default useAdminPublications;