export enum Action {
  PAGEVIEW = 'pageview',
  CLICK = 'click',
  MESSAGGE = 'messagge',

}
export type Product = string;

export enum STATUS {
  OK = 'OK',
  ERROR = 'ERROR',
}

export type Step =
  | 'step GurApp'
  | 'step ir a saber más para vender'
  | 'step ir a listado de cursos'
  | 'step home próximamente'
  | 'step home con buscador'
  | 'step be guru'
  | 'step mi cuenta'
  | 'step ir a crearse cuenta gurú'
  | 'step ir a más faqs'
  | string
;

export interface Params {
  // Rellenar como string con la URL donde se levanta el evento, ej: "/planes"
  path?: string;

  // Rellenar como string la acción del evento, ej: pageview, click, messagge
  action?: Action;

  // Rellenar como string según el evento: virtual_page con Título de página o vpv btn_click: Texto del botón, ej "continuar"
  product?: Product;

  // Identificar si existe un error
  status?: STATUS;

  // Identificar a qué paso del viaje o flujo del cliente corresponde. Siempre se inicia con la palabra "step"
  step?: Step;
}
