import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DividedContainer from '../../../../components/containers/divided-container/DividedContainer';
import SimpleError from '../../../../components/errors/simple-error/SimpleError';
import NavigationBar from '../../../../components/navigations/navigation-bar/NavigationBar';
import { Title, Subtitle } from '../../../../components/typographies';
import SimpleButton from '../../../../components/buttons/simple-button/SimpleButton';
import useAnalytics from '../../../../hooks/useAnalytics/useAnalytics';

const FinalStep = () => {
  const { t } = useTranslation('my-publication', { keyPrefix: 'details.final-step' });
  const navigate = useNavigate();
  const { traceBtnClick } = useAnalytics();

  const [searchParams] = useSearchParams();

  const typeParam: string | string[] | null = searchParams.get('type');
  const toReviewParam: boolean = searchParams.get('toReview') === 'true';
  const toSaveParam: boolean = searchParams.get('toSave') === 'true';

  const key = toReviewParam ? 'toReview' : toSaveParam ? 'save' : 'continue';
  const title = t(`${key}.title.${typeParam}`, '');
  const subtitle = t(`${key}.subtitle.${typeParam}`, '');

  const containerStyle = {
    paddingTop: 12,
    paddingLeft: 2,
    paddingRight: 2,
    spacing: 2,
  };

  const theme = useTheme();
  const isdownsm = useMediaQuery(theme.breakpoints.down('sm'));

  if (!typeParam) {
    return <SimpleError />;
  }

  const onButtonClick = () => {
    traceBtnClick({
      step: 'step ir a mi cuenta',
      product: t('button', ''),
    });
    navigate('/my-account');
  };

  return (
    <>
      <DividedContainer variant='variant3'>
        <NavigationBar variant="variant4" />
        <Grid container {...containerStyle}>
          <Grid item xs={10}>
            <Title variant="variant2">
              {title}
            </Title>
          </Grid>
          <Grid item xs={10}>
            <Subtitle variant="variant2">
              {subtitle}
            </Subtitle>
          </Grid>
          <Grid container direction="column" spacing={2} paddingTop={2}>
            <Grid item xs={12} marginTop={isdownsm ? 2 : 4} textAlign="center">
              <SimpleButton onClick={onButtonClick}>
                {t('button', '')}
              </SimpleButton>
            </Grid>
          </Grid>
        </Grid>
      </DividedContainer>
    </>
  );
};

export default FinalStep;
