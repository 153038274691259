import { forwardRef } from 'react';
import { Accordion } from '@mui/material';
import { GroupEntity } from '../../../entities';
import {
  StyledButton,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from './ShowSchedule.styled';
import Group from '../group/Group';

interface ShowScheduleProps {
  title: string;
  group: GroupEntity;
}

const ShowSchedule = forwardRef<HTMLDivElement, ShowScheduleProps>(
  ({ title = '', group }, ref) => (
    <Accordion disableGutters elevation={0} ref={ref}>
      <StyledAccordionSummary id="details" sx={{ padding: 0, margin: 0 }}>
        <StyledButton variant="text">{title}</StyledButton>
      </StyledAccordionSummary>
      <StyledAccordionDetails bg={1}>
        <Group group={group} />
      </StyledAccordionDetails>
    </Accordion>
  ),
);

ShowSchedule.displayName = 'ShowSchedule';
export default ShowSchedule;
