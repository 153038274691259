import { dayjs, locale } from '../configs/dayjs';

export const getTimeStringByNumber = (hours?: number) => {
  if (hours === 0) return '00:00';
  return !!hours ? dayjs().startOf('day').add(hours, 'hours').format('HH:mm') : '';
};

export const getTimeNumberByString = (hours: string) => {
  if (hours === '') return 0;
  const [hour, minute] = hours.split(':');
  return Number(hour) + Number(minute) / 60;
};

export const today = () => {
  const date = dayjs().locale(locale);
  return date.hour(0).minute(0).second(0);
};

export const getDateFormat = (date?: Date | string, format: string = 'YYYY/MM/DD') => {
  if (!date) { return ''; }
  const result = dayjs(date).format(format);
  return result;
};
