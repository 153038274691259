import { useContext, ReactElement } from 'react';
import { LinearProgress } from '@mui/material';
import FlagContext from '../../../contexts/FlagContext';
import NotFound from '../../../pages/not-found/NotFound';

interface PublicationsAccessProps {
  children: ReactElement;
}

const PublicationsAccess = ({ children }: PublicationsAccessProps) => {
  const { publicationsAvailable, loaded } = useContext(FlagContext);

  if (loaded) {
    return (publicationsAvailable ? children : <NotFound />);
  }
  return (<LinearProgress />);
};

PublicationsAccess.displayName = 'PublicationsAccess';
export default PublicationsAccess;
