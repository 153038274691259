import { useState } from 'react';
import { sendReviewMails } from '../../../../services/adminPublication';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded' | 'error';
  error?: any;
}

const useReviewMailing = () => {
  const [state, setState] = useState<StateProps>({ status: 'idle', error: null });
	
  const sendMailing = async () => {
    setState({ status: 'loading' });
    try {
      await sendReviewMails();
      setState({ 
        status: 'loaded',
      });
    } catch (error) {
      setState({ status: 'error', error });
    }
  };

  return {
    ...state,
    sendMailing,
  };
};

export default useReviewMailing;