import {  Box,  TextField,  Checkbox } from '@mui/material';
import { Autocomplete, AutocompleteProps } from '@mui/material';

interface CustomAutoCompleteProps<T> {
  autocompleteProps: AutocompleteProps<
  T,
  boolean | undefined,
  boolean | undefined,
  boolean | undefined
  >;
}

const CustomAutoComplete = <T extends unknown>(
  { autocompleteProps }: CustomAutoCompleteProps<T>,
) => {
  return <Autocomplete {...autocompleteProps} />;
};


type AutoCompleteSelectProps = {
  options: { value: string, label: string }[]  ;
  label: string;
  name: string;
  defaultValue: string[];
  onChange?: (value: any)  => void;
};

const AutoCompleteSelect = ({
  options,
  label,
  name,
  defaultValue,
  onChange,
}: AutoCompleteSelectProps) => {
  const handleChange = (event: any, value: string) => {
    onChange?.({ name, value });
  };


  let optionsAC: CustomAutoCompleteProps<any> = {
    autocompleteProps: {
      options,
      defaultValue: options.filter(option => defaultValue?.includes(option.value)),
      id: name,
      multiple: true,
      disableCloseOnSelect: true,
      onChange: handleChange,
      limitTags: 1,
      getOptionLabel: (option) => option.label,
      renderOption: (props, option, state) => {
        return (
          <Box
            component="li"
            {...props}
          >
            <Checkbox style={{ marginRight: 8 }} checked={state.selected} />
            {option.label}
          </Box>
        );
      },
      renderInput: (params) => <TextField {...params} label={label} />,
    },
  };
  return <CustomAutoComplete  {...optionsAC} />;
};

AutoCompleteSelect.displayName = 'AutoCompleteSelect';
export default AutoCompleteSelect;