import API from './config/apiInstance';
import apiError from './apiError';

export async function getPricesByTotalPrice(totalPrice: number) {
  try {
    const response = await API.get(`/price?totalPrice=${totalPrice}`);
    const prices = response?.data.prices;
    return {
      prices: {
        totalPrice: prices?.totalPrice,
        percentCommission: prices?.percentCommission,
        profit: prices?.profit,
        commission: prices?.commission,
      },
    };
  } catch (error) {
    throw apiError({ code: '/price', message: 'Hubo un problema al intentar buscar precio' });
  }
}
