import { useState } from 'react';
import { ListEntity, PublicationEntity } from '../../entities';
import { getPublications } from '../../services/publication';

interface StateProps extends ListEntity {
  status: 'idle' | 'loading' | 'loaded' | 'error';
  error?: 'generic';
  data?: PublicationEntity[];
}

const usePublications = () => {
  const [state, setState] = useState<StateProps>({
    status: 'idle',
  });

  const fetchPublications = async (params: any) => {
    setState({ status: 'loading', data: [] });
    try {
      const response = await getPublications(params);
      setState({
        data: response?.data,
        count: response?.count,
        page: response?.page,
        perPage: response?.per_page,
        totalPages: response?.total_pages,
        status: 'loaded', 
      });
    } catch (error: any) {
      setState({ status: 'error', error: error?.code || 'generic' });
    }
  };

  return {
    ...state,
    fetchPublications,
  };
};

export default usePublications;