import styled from '@emotion/styled';
import { SimpleButton } from '../../../../components/buttons';
import { primaryMain } from '../../../../themes/gurapp.theme';

export const Container = styled.div`
	background-color: #F4F2F2;
  margin-top: 16px;
  padding: 16px;
`;

export const Row = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
`;

export const StyledButton = styled(SimpleButton)`
  color: ${primaryMain};
  text-align: center;
  font-size: 14px;
  text-transform: none;
  text-decoration: underline;
  padding: 0px 16px 0px 16px;
  &:hover{
    text-decoration: underline;
  }
`;
