import { useEffect } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DividedContainer from '../../../components/containers/divided-container/DividedContainer';
import { NavigationBar } from '../../../components/navigations';
import useMyPublication from '../useMyPublication';
import { useParams } from 'react-router-dom';
import getPublicationId from '../../../utils/getPublicationId';
import SimpleError from '../../../components/errors/simple-error/SimpleError';
import Answers from '../../../components/publication/question-answer/Answers';
import NewAnswer from '../../../components/publication/question-answer/NewAnswer';
import PageTitle from '../../../components/typographies/PageTitle';
import PageSubtitle from '../../../components/typographies/PageSubtitle';

const Component = () => {
  const { t } = useTranslation('my-publication', { keyPrefix: 'question-answer' });
  const { identifier } = useParams();
  const { fetchAuthPublication, data: publication, status, error: errorFetch } = useMyPublication();

  useEffect(() => {
    const id = getPublicationId(identifier as string);
    const attributes = [
      'id', 
      'questionAnswers',
    ];
    fetchAuthPublication(id, attributes);
  }, []);

  return (
    <>
      {status === 'loading' && <LinearProgress />}
      {status === 'loaded' && (
        <DividedContainer>
          <NavigationBar variant="variant4" />
          {errorFetch ? (
            <SimpleError>{errorFetch?.message}</SimpleError>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <PageTitle>{t('title')}</PageTitle>
              </Grid>
              <Grid item xs={12}>
                <PageSubtitle>{t('subtitle')}</PageSubtitle>
              </Grid>
              {publication?.questionAnswers?.length === 0 && (
                <Grid item xs={12}>
                  <PageSubtitle>Por ahora no tienes preguntas</PageSubtitle>
                </Grid>
              )}
              {publication?.questionAnswers?.filter((item)=> (!item.answer))?.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <NewAnswer questionAnswer={item} />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Answers questionAnswers={publication?.questionAnswers?.filter((item)=> (item.answer))} />
              </Grid>
            </Grid>
          )}
        </DividedContainer>
      )}
    </>
  );
};

export default Component;