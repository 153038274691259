import { SimpleButton } from '../../../components/buttons';
import {
  AdminPanelSettingsOutlined as AdminIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';

const AdminMenu = () => {
  const navigate = useNavigate();
  const { traceBtnClick } = useAnalytics();
  
  return (<>
    <SimpleButton
      startIcon={<AdminIcon />}
      onClick={() => { 
        traceBtnClick({
          step: 'step ir a mi perfil / admin desde menú de admin',
          product: 'Admin',
        });
        navigate('/my-account/admin');
      }}
      style={{ marginLeft: 8 }}
    >
      Admin
    </SimpleButton>
  </>);
};

export default AdminMenu;
