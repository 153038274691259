import {
  Routes, Route, BrowserRouter, Navigate,
} from 'react-router-dom';
import AuthAccess from './components/access/auth-access/AuthAccess';
import NoAuthAccess from './components/access/noauth-access/NoAuthAccess';
import BeGuru from './pages/be-guru/BeGuru';
// import Home from './pages/home/Home';
// import Landing from './pages/landing/Landing';
import Login from './pages/login/Login';
import Guru from './pages/my-account/guru/Guru';
import Finances from './pages/my-account/guru/finances/Finances';

import MyAccount from './pages/my-account/MyAccount';
import MyAccountPersonal from './pages/my-account/personal/Personal';
import MyAccountPersonalContact from './pages/my-account/personal/contact/Contact';
import MyAccountPersonalProfile from './pages/my-account/personal/profile/Profile';

import MyPurchase from './pages/my-purchase/MyPurchase';
import MyPurchases from './pages/my-purchases/MyPurchases';

import AdminUsers from './pages/my-account/admin/users/AdminUsers';
import AdminPublications from './pages/my-account/admin/publications/AdminPublications';
import AdminPublicationsPreview from './pages/my-account/admin/publications/AdminPublicationsPreview';
import AdminHome from './pages/my-account/admin/home/AdminHome';
import AdminPublicationsOrder from './pages/my-account/admin/publications-order/AdminPublicationsOrder';
import NotFound from './pages/not-found/NotFound';
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditions';
import MyPublications from './pages/my-publications/MyPublications';
import MyPublication from './pages/my-publication/MyPublication';
import MyPublicationExplorers from './pages/my-publication/explorers/Explorers';
import MyPublicationQuestionAnswer from './pages/my-publication/question-answer/QuestionAnswer';
import MyPublicationDetailStepDescripcion from './pages/my-publication/details/step-descripcion/StepDescripcion';
import MyPublicationDetailStepDetalles from './pages/my-publication/details/step-detalles/StepDetalles';
import MyPublicationDetailStepAgenda from './pages/my-publication/details/step-agenda/StepAgenda';
import MyPublicationDetailStepHorarios from './pages/my-publication/details/step-horarios/StepHorarios';
import MyPublicationPreview from './pages/my-publication/preview/Preview';
import MyPublicationDetailFinalStep from './pages/my-publication/details/final-step/FinalStep';
import NewPublication from './pages/new-publication/NewPublication';
import NewPublicationType from './pages/new-publication/NewPublicationType/NewPublicationType';
import CreateAccount from './pages/create-account/CreateAccount';
import Publication from './pages/publication/Publication';
import Publications from './pages/publications/Publications';
import Purchase from './pages/purchase/Purchase';
import Checkout from './pages/checkout/Checkout';
import Identification from './pages/checkout/identification/Identification';
import FinancesAccess from './components/access/finances-access/FinancesAccess';
import PublicationsAccess from './components/access/publications-access/PublicationsAccess';
import MyPurchasePublication from './pages/my-purchase/publication/MyPurchasePublication';
import MyPurchaseGroup from './pages/my-purchase/group/MyPurchaseGroup';
import MyPurchaseCheckout from './pages/my-purchase/checkout/MyPurchaseCheckout';
import FAQs from './pages/faqs/FAQs';
import PublicationReview from './pages/publicationReview/PublicationReview';
import AdminAccess from './components/access/admin-access/AdminAccess';
import PageViewTracker from './components/tracker/PageViewTracker';
import PublicationStepsContainer from './components/containers/publication-steps-container/PublicationStepsContainer';
import AdminCheckouts from './pages/my-account/admin/checkouts/AdminCheckouts';

const App = () => (
  <BrowserRouter>
    <PageViewTracker />
    <Routes>
      <Route index element={<Navigate to="marketplace" replace />} />
      <Route path="my-account">
        <Route index element={<AuthAccess><MyAccount /></AuthAccess>} />
        <Route path="personal">
          <Route index element={<AuthAccess><MyAccountPersonal /></AuthAccess>} />
          <Route path="profile" element={<AuthAccess><MyAccountPersonalProfile /></AuthAccess>} />
          <Route path="contact" element={<AuthAccess><MyAccountPersonalContact /></AuthAccess>} />
        </Route>
        <Route path="guru">
          <Route index element={<AuthAccess><Guru /></AuthAccess>} />
          <Route path="finances" element={
            <AuthAccess><FinancesAccess><Finances /></FinancesAccess></AuthAccess>
          } />
        </Route>
        <Route path="admin">
          <Route index element={<AdminAccess><AdminHome /></AdminAccess>} />
          <Route path="users" element={<AdminAccess><AdminUsers /></AdminAccess>} />
          <Route path="publications" element={<AdminAccess><AdminPublications /></AdminAccess>} />
          <Route path="publications/:identifier/preview" element={<AdminAccess><AdminPublicationsPreview /></AdminAccess>} />
          <Route path="publications-order" element={<AdminAccess><AdminPublicationsOrder /></AdminAccess>} />
          <Route path="checkouts" element={<AdminAccess><AdminCheckouts /></AdminAccess>} />
        </Route>
      </Route>
      <Route path="my-purchases" element={<AuthAccess><MyPurchases /></AuthAccess>} />
      <Route path="my-purchase/:id">
        <Route index element={<AuthAccess><MyPurchase /></AuthAccess>} />
        <Route path="publication" element={<AuthAccess><MyPurchasePublication /></AuthAccess>} />
        <Route path="group" element={<AuthAccess><MyPurchaseGroup /></AuthAccess>} />
        <Route path="checkout" element={<AuthAccess><MyPurchaseCheckout /></AuthAccess>} />
      </Route>
      <Route path="my-publications" element={<AuthAccess><MyPublications /></AuthAccess>} />
      <Route path="my-publication/:identifier">
        <Route index element={<AuthAccess><MyPublication /></AuthAccess>} />
        <Route path="details" element={<Navigate to="step-descripcion" replace />} />
        <Route path="details/step-descripcion" element={
          <AuthAccess>
            <PublicationStepsContainer activeStepIndex={0}>
              <MyPublicationDetailStepDescripcion />
            </PublicationStepsContainer>
          </AuthAccess>
        } />
        <Route path="details/step-detalles" element={
          <AuthAccess>
            <PublicationStepsContainer activeStepIndex={1}>
              <MyPublicationDetailStepDetalles />
            </PublicationStepsContainer>
          </AuthAccess>
        } />
        <Route path="details/step-agenda" element={
          <AuthAccess>
            <PublicationStepsContainer activeStepIndex={2}>
              <MyPublicationDetailStepAgenda />
            </PublicationStepsContainer>
          </AuthAccess>
        } />
        <Route path="details/step-horarios" element={
          <AuthAccess>
            <PublicationStepsContainer activeStepIndex={3}>
              <MyPublicationDetailStepHorarios />
            </PublicationStepsContainer>
          </AuthAccess>
        } />
        <Route path="details/final-step" element={<AuthAccess><MyPublicationDetailFinalStep /></AuthAccess>} />
        <Route path="explorers" element={<AuthAccess><MyPublicationExplorers /></AuthAccess>} />
        <Route path="preview" element={<AuthAccess><MyPublicationPreview /></AuthAccess>} />
        <Route path="question-answer" element={<AuthAccess><MyPublicationQuestionAnswer /></AuthAccess>} />
      </Route>
      <Route path="new-publication">
        <Route index element={<AuthAccess><NewPublication /></AuthAccess>} />
        <Route path="type/:type" element={
          <AuthAccess>
            <PublicationStepsContainer activeStepIndex={0}>
              <NewPublicationType />
            </PublicationStepsContainer>
          </AuthAccess>
        } />
      </Route>
      <Route path="marketplace" element={<PublicationsAccess><Publications /></PublicationsAccess>} />
      <Route path="publications" element={<PublicationsAccess><Publications /></PublicationsAccess>} />
      <Route path="publication/:identifier" element={<PublicationsAccess><Publication /></PublicationsAccess>} />
      <Route path="be-guru" element={<BeGuru />} />
      <Route path="login" element={<NoAuthAccess><Login /></NoAuthAccess>} />
      <Route path="create-account" element={<CreateAccount />} />
      <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="faqs" element={<FAQs />} />
      <Route path="checkout">
        <Route index element={<Checkout />} />
        <Route path="identification" element={<Identification />} />
      </Route>
      <Route path="purchase/:mode" element={<Purchase />} />
      {/* <Route path="landing" element={<Landing />} /> */}
      <Route path="publicationReview" element={<PublicationReview />} /> 
      <Route path="not-found" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

export default App;
