import { useState } from 'react';
import { postPublicationReview } from '../../services/publicationReview';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
}

const usePublicationReview = () => {
  const [state, setState] = useState<StateProps>({ status: 'idle', error: null });

  const sendPublicationReview = async (reviewId: string, evaluation: number, comment?: string) => {
    setState({ status: 'loading' });
    try {
      await postPublicationReview(reviewId, evaluation, comment);
      setState({ status: 'loaded' });
    } catch (error) {
      setState({ status: 'loaded', error });
    }
  };

  return {
    ...state,
    sendPublicationReview,
  };
};

export default usePublicationReview;