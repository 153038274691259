import { useState, useEffect } from 'react';

const useBodyHasScroll = () => {
  const [hasScroll, setHasScroll] = useState(false);
  useEffect(() => {
    const checkScroll = () => {
      const hasVerticalScroll = document.documentElement.scrollHeight > window.innerHeight;
      setHasScroll(hasVerticalScroll);
    };
    checkScroll();
    window.addEventListener('scroll', checkScroll);
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);

  return {
    hasScroll,
  };
};

export default useBodyHasScroll;
