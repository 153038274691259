import DividedContainer from '../../components/containers/divided-container/DividedContainer';
import NavigationBar from '../../components/navigations/navigation-bar/NavigationBar';
import FormCreateAccount from './components/form-create-account/FormCreateAccount';

const CreateAccount = () => (
  <DividedContainer>
    <NavigationBar variant="variant1" />
    <FormCreateAccount />
  </DividedContainer>
);

export default CreateAccount;
