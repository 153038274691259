import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavigationBar from '../../../../components/navigations/navigation-bar/NavigationBar';
import DividedContainer from '../../../../components/containers/divided-container/DividedContainer';
import PageTitle from '../../../../components/typographies/PageTitle';
import PageSubtitle from '../../../../components/typographies/PageSubtitle';
import { FormControl, Grid, TextField } from '@mui/material';
import { useContext, useEffect } from 'react';
import SimpleDialog from '../../../../components/dialogs/SimpleDialog/SimpleDialog';
import AuthContext from '../../../../contexts/AuthContext';
import usePersonal from '../usePersonal';
import useDialog from '../../../../hooks/useDialog';
import { useFormik } from 'formik';
import TextFieldForm from '../../../../components/inputs/text-field-form-v2/TextFieldForm';
import { FileListItem } from '../../../../components/inputs';
import { NavigationBottom } from '../../../../components/navigations';
import { profileSchema } from './Profile.schema';

const MyAccountPersonalProfile = () => {
  const { t } = useTranslation(['my-account']);
  const { authUser } = useContext(AuthContext);
  const { updateProfile, uploadProfilePhoto, status, error } = usePersonal();
  const { dialog, onCloseDialog } = useDialog(error, 'Error');
  const [searchParams] = useSearchParams();
  const modeParam: string | null = searchParams.get('mode');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    return () => {
      sessionStorage.setItem('from', location.pathname);
    };
  }, []);

  const onBack = () => {
    const previousPage = '/my-account/personal';
    const from = sessionStorage.getItem('from');
    return from?.startsWith(previousPage) ? navigate(-1) : navigate(previousPage);
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      displayName: authUser?.data?.displayName || '',
      description: authUser?.data?.description || '',
      photoUrl: authUser?.data?.photoUrl ? [{ name: authUser.data.photoUrl, size: undefined }] : [],
    },
    validationSchema: profileSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      let photoUrl;
      if (values.photoUrl?.[0]?.size) {
        photoUrl = await uploadProfilePhoto(values.photoUrl?.[0]);
        if (!photoUrl) {
          return;
        }
      }
      const success = await updateProfile({
        displayName: values.displayName,
        description: values.description,
        ...(photoUrl && { photoUrl }),
      });
      if (success) {
        if (modeParam === 'beguru') {
          navigate('/my-account/personal/contact?mode=beguru');
        } else {
          onBack();
        }
      }
    },
  });

  return (
    <DividedContainer>
      <NavigationBar variant="variant4" />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageTitle>{t(`personal.profile.${modeParam === 'beguru' ? 'titleBeGuru' : 'title'}`)}</PageTitle>
        </Grid>
        <Grid item xs={12}>
          <PageSubtitle>{t('personal.profile.subtitle')}</PageSubtitle>
        </Grid>
      </Grid>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      <Grid container direction="column" spacing={1} mt={0.5}>
        <Grid item xs={12}>
          <FormControl margin='dense' fullWidth>
            <TextField
              variant="outlined"
              fullWidth
              label={t('personal.profile.emailLabel') as string}
              value={authUser?.data?.email || ''}
              disabled
            />
          </FormControl>
        </Grid>
        <TextFieldForm
          name="displayName"
          form={form}
          label={t('personal.profile.displayNameLabel') as string}
          loading={status === 'loading'}
          inputProps={{ maxLength: 100 }}
          autoFocus
        />
        <TextFieldForm
          name="description"
          form={form}
          label={t('personal.profile.descriptionLabel') as string}
          placeholder={t('personal.profile.descriptionPlaceholder')}
          loading={status === 'loading'}
          inputProps={{ maxLength: 600 }}
          multiline
          minRows={2}
          maxRows={4}
        />
        <Grid item xs={12}>
          <FileListItem
            name="photoUrl"
            label={t('personal.profile.photoUrlLabel')}
            secondaryLabel={t('personal.profile.photoUrlSecondaryLabel')}
            value={form.values.photoUrl}
            onChange={form.handleChange}
            disabled={status === 'loading'}
            error={form.touched.photoUrl && Boolean(form.errors.photoUrl)}
            helperText={(form.touched.photoUrl && form.errors.photoUrl) || undefined}
          />
        </Grid>
      </Grid>
      <NavigationBottom 
        handleClickSave={() => form.handleSubmit()}
        saveText={t(`personal.profile.${modeParam === 'beguru' ? 'continue' : 'save'}`)}
        handleClickPrevious={onBack}
        disabled={status === 'loading'}
        saveProps={{ loading: status === 'loading' }}
      />
    </DividedContainer>
  );
};

export default MyAccountPersonalProfile;
