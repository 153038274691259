import { createContext, useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';

const key = 'tr4c';

const getEncrypted = (value: []) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), `${key}5d`).toString();
  return encrypted;
};

const getDecrypted = (value: string) => {
  const bytes = CryptoJS.AES.decrypt(value, `${key}5d`);
  const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decrypted;
};

const getStorageValue = (defaultValue: any) => {
  const saved = localStorage.getItem(key);
  const decrypted = saved ? getDecrypted(saved) : defaultValue;
  return decrypted;
};

const getTotalPrice = (cart: any) => {
  const sum = cart.reduce((accumulator: any, currentValue: any) => {
    const price = currentValue.group?.publication?.price || 0;
    return (price * currentValue.quantity) + accumulator;
  }, 0);
  return sum;
};

const getTotalQuantity = (cart: any) => {
  const sum = cart.reduce((accumulator: any, currentValue: any) => {
    return currentValue.quantity + accumulator;
  }, 0);
  return sum;
};

interface CartContextProps {
  cart?: any[];
  setCart?: Function;
  openCart?: boolean;
  setOpenCart?: any;
  cleanCart?: any;
  totalPrice: number;
  totalQuantity: number;
}
const CartContext = createContext<CartContextProps>({
  totalPrice: 0,
  totalQuantity: 0,
});

export const CartContextProvider = (props: any) => {
  const [cart, setCart] = useState(() => getStorageValue([]));
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [openCart, setOpenCart] = useState<boolean>(false);

  const cleanCart = () => {
    setCart([]);
  };

  useEffect(() => {
    try {
      localStorage.setItem(key, getEncrypted(cart));
      setTotalPrice(getTotalPrice(cart));
      setTotalQuantity(getTotalQuantity(cart));
    } catch (error) {
      localStorage.setItem(key, getEncrypted([]));
      setTotalPrice(0);
      setTotalQuantity(0);
    }
  }, [cart]);

  return (
    <CartContext.Provider
      value={{
        cart,
        openCart,
        totalPrice,
        totalQuantity,
        setCart,
        setOpenCart,
        cleanCart,
      }}
      {...props}
    />
  );
};

export default CartContext;
