import { Typography, Divider } from '@mui/material';
import { forwardRef, ReactElement } from 'react';
import {
  StyledVariant1,
  StyledVariant2,
  StyledContainerVariant3,
  StyledVariant3,
  StyledVariant4,
  StyledVariant5,
  StyledVariant6,
  StyledVariant7,
} from './Subtitle.styled';

interface SubtitleProps {
  variant?: 'variant1' | 'variant2' | 'variant3' | 'variant4' | 'variant5' | 'variant6' | 'variant7';
  children?: string | ReactElement;
  id?: string;
}
const Subtitle = forwardRef<HTMLParagraphElement, SubtitleProps>(
  ({ variant, ...rest }, ref) => {
    if (variant === 'variant1') {
      return <StyledVariant1 variant="h2" ref={ref} {...rest} />;
    }

    if (variant === 'variant2') {
      return <StyledVariant2  ref={ref} {...rest} />;
    }

    if (variant === 'variant3') {
      return (
        <StyledContainerVariant3>
          <Divider>
            <StyledVariant3 variant="h2" ref={ref} {...rest} />
          </Divider>
        </StyledContainerVariant3>
      );
    }

    if (variant === 'variant4') {
      return <StyledVariant4 variant="h2" ref={ref} {...rest} />;
    }

    if (variant === 'variant5') {
      return <StyledVariant5 variant="h2" ref={ref} {...rest} />;
    }

    if (variant === 'variant6') {
      return <StyledVariant6 variant="h2" ref={ref} {...rest} />;
    }

    if (variant === 'variant7') {
      return <StyledVariant7 variant="h2" ref={ref} {...rest} />;
    }

    return (
      <Typography color="primary" ref={ref} {...rest} />
    );
  },
);

Subtitle.displayName = 'Subtitle';
export default Subtitle;
