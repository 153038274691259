import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n/config';
import App from './App';
import './configs/firebase';
import './themes/fonts';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs as dateAdapter } from '@mui/x-date-pickers/AdapterDayjs';
import { AuthContextProvider } from './contexts/AuthContext';
import { CartContextProvider } from './contexts/CartContext';
import { FlagContextProvider } from './contexts/FlagContext';
import gurappTheme from './themes/gurapp.theme';
// import CookieConsentContainer from './components/containers/cookie-consent-container/CookieConsentContainer';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ThemeProvider theme={gurappTheme}>
    <LocalizationProvider dateAdapter={dateAdapter} adapterLocale="es-mx">
      <AuthContextProvider>
        <CartContextProvider>
          <FlagContextProvider defaults={{
            publicationsAvailable: false,
            financesAvailable: false,
          }}>
            {/* <CookieConsentContainer> */}
            <App />
            {/* </CookieConsentContainer> */}
          </FlagContextProvider>
        </CartContextProvider>
      </AuthContextProvider>
    </LocalizationProvider>
  </ThemeProvider>,
);

