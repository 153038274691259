import { forwardRef } from 'react';
import IconInfo from './IconInfo';
import { PublicationEntity } from '../../../../entities';

interface IconInfoQuotaPros {
  publication?: PublicationEntity;
}

const IconInfoQuota = forwardRef<HTMLDivElement, IconInfoQuotaPros>(
  ({ publication }, ref) => {
    const isEqual = publication?.minQuota === publication?.maxQuota;
    const textEqual = `${publication?.minQuota} personas`;
    const textNotEqual = `Entre ${publication?.minQuota} y ${publication?.maxQuota} personas`;

    return !!publication?.minQuota && !!publication?.maxQuota ? (
      <IconInfo
        icon="quorum"
        text={isEqual ? textEqual : textNotEqual}
        ref={ref}
      />
    ) : null;
  });
export default IconInfoQuota;
