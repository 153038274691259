import { useEffect, useState } from 'react';
import { Grid, RadioGroup, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import DividedContainer from '../../components/containers/divided-container/DividedContainer';
import NavigationBar from '../../components/navigations/navigation-bar/NavigationBar';
import { Title, Subtitle } from '../../components/typographies';
import RadioLabel from '../../components/inputs/radio-label/RadioLabel';
import { NavigationBottom } from '../../components/navigations';

const NewPublication = () => {
  const { t } = useTranslation(['new-publication']);
  const [searchParams] = useSearchParams();
  const modeParam: string | null = searchParams.get('mode');
  const theme = useTheme();
  const isdownsm = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedOption, setSelectedOption] = useState({ option: '' });
  const navigate = useNavigate();
  const location = useLocation();
  const handleChange = (e: any) => setSelectedOption({ option: e.target.value || '' });

  useEffect(() => {
    return () => {
      sessionStorage.setItem('from', location.pathname);
    };
  }, []);

  return (
    <DividedContainer variant="variant2">
      <NavigationBar variant="variant4" />
      <Grid container paddingTop={isdownsm ? 6 : 9} paddingLeft={isdownsm ? 0 : 4} paddingRight={2} spacing={2}>
        <Grid item xs={10}>
          <Title variant="variant2" style={ isdownsm ? { fontSize: 40 } : {}}>
            {t(`${modeParam === 'beguru' ? 'beguru.' : ''}title`)}
          </Title>
        </Grid>
        <Grid item xs={12}>
          <Subtitle variant="variant2">
            {t(`${modeParam === 'beguru' ? 'beguru.' : ''}subtitle1`)}
          </Subtitle>
          <Subtitle variant="variant5">
            {t(`${modeParam === 'beguru' ? 'beguru.' : ''}subtitle2`)}
          </Subtitle>
        </Grid>
        <Grid item xs={12}>
          <Subtitle variant="variant2">{t('subtitle3')}</Subtitle>
        </Grid>
        <Grid container spacing={isdownsm ? 1 : 2} mt={2} ml={1} mr={1}>
          <RadioGroup onChange={handleChange}>
            <RadioLabel
              checked={selectedOption.option === 'class'}
              value="class"
              title={t('options.class.title')}
              description={t('options.class.description')}
              style={{ marginBottom: 16 }}
            />
            <RadioLabel 
              checked={selectedOption.option === 'course'}
              value="course"
              title={t('options.course.title')}
              description={t('options.course.description')}
              style={{ marginBottom: 16 }}
            />
          </RadioGroup>
        </Grid>
        <NavigationBottom
          handleClickSave={() => navigate(`/new-publication/type/${selectedOption.option}`)}
          handleClickPrevious={()=> {
            const previousPage = '/my-account/guru';
            const from = sessionStorage.getItem('from');
            return (from === previousPage) ? navigate(-1) : navigate(previousPage);
          }}
          disabled={selectedOption.option === ''}
          saveText={t('buttonStart')}
        />
      </Grid>
    </DividedContainer>
  );
};

export default NewPublication;
