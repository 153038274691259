import home from '../pages/home/i18n.json';
import notFound from '../pages/not-found/i18n.json';
import myAccount from '../pages/my-account/i18n.json';
import myAccountPersonal from '../pages/my-account/personal/i18n.json';
import myAccountPersonalContact from '../pages/my-account/personal/contact/i18n.json';
import myAccountPersonalProfile from '../pages/my-account/personal/profile/i18n.json';
import myPurchases from '../pages/my-purchases/i18n.json';
import myPurchase from '../pages/my-purchase/i18n.json';
import myAccountGuru from '../pages/my-account/guru/i18n.json';
import myAccountGuruFinances from '../pages/my-account/guru/finances/i18n.json';
import MyAccountAdminPublications from '../pages/my-account/admin/publications/i18n.json';
import myPublications from '../pages/my-publications/i18n.json';
import myPublication from '../pages/my-publication/i18n.json';
import myPublicationExplorers from '../pages/my-publication/explorers/i18n.json';
import myPublicationQuestionAnswer from '../pages/my-publication/question-answer/i18n.json';
import myPublicationDetailsStepDescripcion from '../pages/my-publication/details/step-descripcion/i18n.json';
import myPublicationDetailsStepDetalles from '../pages/my-publication/details/step-detalles/i18n.json';
import myPublicationDetailsStepAgenda from '../pages/my-publication/details/step-agenda/i18n.json';
import myPublicationDetailsStepHorariosFlexible from '../pages/my-publication/details/step-horarios/flexible/i18n.json';
import myPublicationDetailsStepHorariosClass from '../pages/my-publication/details/step-horarios/class/i18n.json';
import myPublicationDetailsStepHorariosCourse from '../pages/my-publication/details/step-horarios/course/i18n.json';
import myPublicationDetailsFinalStep from '../pages/my-publication/details/final-step/i18n.json';
import publications from '../pages/publications/i18n.json';
import beGuru from '../pages/be-guru/i18n.json';
import login from '../pages/login/i18n.json';
import createAccount from '../pages/create-account/i18n.json';
import landing from '../pages/landing/i18n.json';
import newPublication from '../pages/new-publication/i18n.json';
import purchase from '../pages/purchase/i18n.json';
import checkout from '../pages/checkout/i18n.json';

import shoppingCart from '../components/cart/shopping-cart/i18n.json';
import cartItem from '../components/cart/cart-item/i18n.json';
import navigationBar from '../components/navigations/navigation-bar/i18n.json';
import googleButton from '../components/buttons/google-button/i18n.json';
import backButton from '../components/buttons/back-button/i18n.json';
import group from '../components/cart/group/i18n.json';
import navigationBottom from '../components/navigations/navigation-bottom/i18n.json';
import cookieConsentContainer from '../components/containers/cookie-consent-container/i18n.json';
import publicationTemplate from '../components/publication/publication-template/i18n.json';
import groupDetail from '../components/publication/group-detail/i18n.json';

import schemas from '../schemas/i18n.json';

const es = {
  home,
  'not-found': notFound,
  'my-account': {
    ...myAccount,
    personal: {
      ...myAccountPersonal,
      contact: myAccountPersonalContact,
      profile: myAccountPersonalProfile,
    },
    guru: {
      ...myAccountGuru,
      finances: myAccountGuruFinances,
    },
    admin: {
      publications: MyAccountAdminPublications,
    },
  },
  'my-purchases': myPurchases,
  'my-purchase': myPurchase,
  'my-publications': myPublications,
  'my-publication': {
    ...myPublication,
    explorers: myPublicationExplorers,
    'question-answer': myPublicationQuestionAnswer,
    details: {
      'step-descripcion': myPublicationDetailsStepDescripcion,
      'step-detalles': myPublicationDetailsStepDetalles,
      'step-agenda': myPublicationDetailsStepAgenda,
      'step-horarios': { 
        class: myPublicationDetailsStepHorariosClass,
        course: myPublicationDetailsStepHorariosCourse,
        flexible: myPublicationDetailsStepHorariosFlexible,
      },
      'final-step': myPublicationDetailsFinalStep,
    },
  },
  'be-guru': beGuru,
  login,
  'create-account': createAccount,
  landing,
  publications,
  'new-publication': newPublication,
  purchase,
  checkout,
  components: {
    'shopping-cart': shoppingCart,
    'cart-item': cartItem,
    'navigation-bar': navigationBar,
    'google-button': googleButton,
    'back-button': backButton,
    'navigation-bottom': navigationBottom,
    'cookie-consent-container': cookieConsentContainer,
    'publication-template': publicationTemplate,
    'group-detail': groupDetail,
    group,
  },
  months: {
    'January': 'Enero',
    'February': 'Febrero',
    'March': 'Marzo',
    'April': 'Abril',
    'May': 'Mayo',
    'June': 'Junio',
    'July': 'Julio',
    'August': 'Agosto',
    'September': 'Septiembre',
    'October': 'Octubre',
    'November': 'Noviembre',
    'December': 'Diciembre',
  },

  schemas,
};

export default es;