import API from './config/apiInstance';
import apiError from './apiError';

export async function checkout(payload: any) {
  try {
    const response = await API.post('/checkout', payload);
    if (response?.data?.redirect) {
      return { id: response.data.id, redirect: response.data.redirect };
    }
    throw apiError({
      code: 'POST /checkout',
      message:
        'Hubo un problema al llamar a pasarela de pago.',
    });
  } catch (error) {
    throw apiError({
      code: 'POST /checkout',
      message:
        `Hubo un problema al llamar a pasarela de pago. Es probable que sus cursos ya no estén disponibles. 
        Por favor revise su carrito de compras.`,
    });
  }
}

export async function getCheckout(id: number, params?: string) {
  try {
    const response = await API.get(`/checkout/${id}${params}`);
    if (response && response.data) {
      return response.data;
    }
    return undefined;
  } catch (error) {
    throw apiError({ code: `GET /checkout/${id}`, message: 'Hubo un problema al intentar buscar checkout' });
  }
}
