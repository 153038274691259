import { useState } from 'react';
import { getAuthProfit } from '../../../../services/authProfit';

interface ProfitByMonth {
  year: string;
  month: string;
  monthName: string;
  totalProfit: number;
}

interface StateProps {
  status: 'idle' | 'loading' | 'loaded' | 'error';
  error?: any;
  data?: ProfitByMonth[];
}

const useMyProfits = () => {
  const [state, setState] = useState<StateProps>({ status: 'idle', error: null });

  const fetchMyProfits = async () => {
    setState({ status: 'loading', data: [] });
    try {
      const response = await getAuthProfit();
      setState({ 
        data: response?.data,
        status: 'loaded', 
      });
    } catch (error) {
      setState({ status: 'error', error });
    }
  };

  return {
    ...state,
    fetchMyProfits,
  };
};

export default useMyProfits;