import { Collapse, CollapseProps } from '@mui/material';
import { forwardRef } from 'react';
import {
  StyledContainer,
} from './CollapseCard.styled';

interface CollapseCardProps extends CollapseProps {
}

const CollapseCard = forwardRef<HTMLDivElement, CollapseCardProps>(
  ({ children, ...rest }, ref) => (
    <Collapse ref={ref} {...rest}>
      <StyledContainer>
        {children}
      </StyledContainer>
    </Collapse>
  ),
);

CollapseCard.displayName = 'CollapseCard';
export default CollapseCard;
