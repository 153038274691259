import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BackButton from '../../buttons/back-button/BackButton';
import SimpleButton from '../../buttons/simple-button/SimpleButton';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';

interface NavigationBottomProps {
  handleClickPrevious?: () => void;
  handleClickSave?: () => void;
  handleClickContinue?: () => void;
  disabled?: boolean;
  saveProps?: any;
  saveText?: string;
  continueText?: string;
  continueProps?: any;
}

const NavigationBottom = ({ 
  handleClickPrevious, 
  handleClickSave, 
  handleClickContinue, 
  disabled, 
  saveProps, 
  saveText,
  continueText,
  continueProps, 
  ...props
}: NavigationBottomProps) => {
  const { t } = useTranslation('components', { keyPrefix: 'navigation-bottom' });
  const { traceBtnClick } = useAnalytics();
  return (
    <>
      <Grid
        container
        direction="row-reverse"
        pt={1}
        pb={2}
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        {...props}
      >
        {!!handleClickSave && (
          <Grid item>
            <SimpleButton 
              onClick={() => { 
                traceBtnClick({
                  step: 'step guardar',
                  product: saveText || t('continue') as string,
                });
                handleClickSave();
              }} 
              disabled={disabled}
              {...saveProps}
            >
              {saveText || t('continue') as string}
            </SimpleButton>
          </Grid>
        )}
        {!!handleClickContinue && (
          <Grid item>
            <SimpleButton 
              onClick={() => { 
                traceBtnClick({
                  step: 'step continuar',
                  product: continueText || t('continue') as string,
                });
                handleClickContinue();
              }} 
              disabled={disabled}
              {...continueProps}
            >
              {continueText || t('continue') as string}
            </SimpleButton>
          </Grid>
        )}
        {!!handleClickPrevious && (
          <Grid item>
            <BackButton 
              // disabled={disabled} 
              onClick={() => { 
                traceBtnClick({
                  step: 'step atrás',
                  product: 'Atrás',
                });
                handleClickPrevious();
              }} 
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

NavigationBottom.displayName = 'NavigationBottom';
export default NavigationBottom;
