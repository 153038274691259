import { forwardRef } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DisableIcon from '@mui/icons-material/DoNotDisturbAlt';
import { SelectTime } from '../../../../../components/inputs';
import { getIn } from 'formik';
import { GroupEntity } from '../../../../../entities';
import { DayOfWeek, isOnlyOneAvailable } from '../StepHorarios.utils';
import useAnalytics from '../../../../../hooks/useAnalytics/useAnalytics';

interface PeriodicityWeeklyProps {
  statusUpdate?: string;
  form: any;
  group?: GroupEntity;
}

const PeriodicityWeekly = forwardRef<HTMLDivElement, PeriodicityWeeklyProps>(
  ({
    statusUpdate,
    form = undefined,
    group,
    ...props
  }, ref) => {    
    const { traceBtnClick } = useAnalytics();
    const Time = ({ name, label }: { name: string, label: string }) => (
      <SelectTime
        name={name}
        onChange={(value) => {
          form.setFieldValue(name, value);
        }}
        label={label}
        fullWidth
        disabled={statusUpdate === 'loading'}
        value={getIn(form.values, name)}
        error={getIn(form.touched, name) && Boolean(getIn(form.errors, name))}
        helperText={getIn(form.touched, name) && getIn(form.errors, name)}
        style={{ marginBottom: 2 }}
      />
    );

    const handleOptionDays = (action: 'add' | 'remove', indexDay: number, indexTime: number) => {
      const local = form.values.week[indexDay].times;
      if (action === 'add') {
        local.push({ startTime: '', endTime: '', quota: undefined });
      }
      if (action === 'remove') {
        local.splice(indexTime, 1);
      }
      form.setFieldValue(`week[${indexDay}].times`, local);
    };

    return (
      <div {...props} ref={ref}>
        {form.values.week?.map((day: DayOfWeek, index: number) => {
          const name = `week[${index}]`;
          const countTimes = day.times?.length ? day.times?.length : 0;
          const hasTimes = countTimes > 0;
          return (
            <Grid container columns={10} spacing={0.5} mt={0.5} key={name}>
              <Grid item xs={!hasTimes ? 2 : 10} sm={2} md={!hasTimes ? 2 : 10} lg={2} mt={0.5} alignSelf="center">
                <Typography variant="body2" style={{ fontWeight: 400 }}>{day.label}</Typography>	
              </Grid>
              {!hasTimes ? (
                <Grid item xs={7} sm={7} md={7} lg={7} pt={0.2} mt={0.5}>
                  <Typography variant="body2" style={{ fontWeight: 300 }}>No se dictará clase este día</Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={4.5} sm={3.5} md={4.5} lg={3.5}>
                    {([...Array(countTimes)]).map((_: any, indexTime: number) => (
                      <Time key={indexTime} name={`${name}.times[${indexTime}].startTime`} label="Hora inicio"/>
                    ))}
                  </Grid>
                  <Grid item xs={4.5} sm={3.5} md={4.5} lg={3.5}>
                    {([...Array(countTimes)]).map((_: any, indexTime: number) => (
                      <Time key={indexTime} name={`${name}.times[${indexTime}].endTime`} label="Hora término"/>
                    ))}
                  </Grid>
                </>
              )}
              <Grid item xs={1} display="flex" flexDirection="column" justifyContent="space-around">
                {([...Array(hasTimes ? countTimes : 1)]).map((time: any, indexTime: number) => (
                  <div style={{ display: 'flex' }} key={indexTime}>
                    <IconButton 
                      aria-label="quitar" 
                      disabled={statusUpdate === 'loading' || isOnlyOneAvailable(form.values.week)} 
                      onClick={() => { 
                        traceBtnClick({
                          step: `step quitar horario ${day.label} en flexible semanales`,
                          product: 'icono remove',
                        });
                        handleOptionDays('remove', index, indexTime); 
                      }}
                      style={{ padding: 4, visibility: hasTimes ? 'visible' : 'hidden' }}
                    >
                      <DisableIcon fontSize="small" />
                    </IconButton>
                    <IconButton 
                      aria-label="habilitar" 
                      disabled={statusUpdate === 'loading'} 
                      onClick={() => { 
                        traceBtnClick({
                          step: `step agregar horario ${day.label} en flexible semanales`,
                          product: 'icono add',
                        });
                        handleOptionDays('add', index, indexTime); 
                      }}
                      style={{ padding: 4, visibility: indexTime !== 0 ? 'hidden' : 'visible' }}
                    >
                      <AddIcon fontSize="small" />
                    </IconButton>
                  </div>
                ))}
              </Grid>
            </Grid>
          );
        })}
      </div>
    );
  },
);

PeriodicityWeekly.displayName = 'PeriodicityWeekly';
export default PeriodicityWeekly;
