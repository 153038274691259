import React from 'react';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

interface DateInputProps extends React.ComponentProps<typeof DatePicker> {
  size?: 'small' | 'medium';
  name?: string;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: any;
  defaultValue?: any;
}

const fontSizes = {
  small: '0.875rem',
  medium: '1rem',
  large: '1rem',
};

const DateInput = React.forwardRef<HTMLDivElement, DateInputProps>(
  ({ fullWidth, size = 'small', name, error, helperText, ...props }, ref) => {
    return (
      <DatePicker
        {...props}
        ref={ref}
        inputFormat="DD/MM/YYYY"
        renderInput={(params) => <TextField 
          {...params}
          size={size}
          name={name}
          fullWidth={fullWidth}
          InputLabelProps={{ style: { fontSize: fontSizes[size] } }}
          error={error}
          helperText={helperText}
          inputProps={{
            ...params.inputProps,
            placeholder: 'dd/mm/aaaa',
            style: { ...params.inputProps?.style, fontSize: fontSizes[size], height: '23px' },
            autoComplete: 'off',
          }}
        />}
      />
    );
  });

DateInput.displayName = 'DateInput';
export default DateInput;
