import { useState } from 'react';
import { KnowledgeEntity } from '../../../../../../entities';
import { getKnowledges } from '../../../../../../services/knowledges';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: 'generic';
  knowledges: KnowledgeEntity[];
}

const useKnowledges = () => {
  const [state, setState] = useState<StateProps>({
    status: 'idle',
    knowledges: [],
  });

  const fetchKnowledges = async () => {
    setState({ status: 'loading', knowledges: [] });
    try {
      const response = await getKnowledges();
      setState({
        status: 'loaded',
        knowledges: response?.data,
      });
    } catch (error: any) {
      setState({ status: 'loaded', knowledges: [], error: error?.code || 'generic' });
    }
  };

  return {
    ...state,
    fetchKnowledges,
  };
};

export default useKnowledges;