import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, LinearProgress, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BackButton, ListButton } from '../../components/buttons';
import NavigationBar from '../../components/navigations/navigation-bar/NavigationBar';
import DividedContainer from '../../components/containers/divided-container/DividedContainer';
import { Label } from '../../components/typographies';
import useMyPurchase from './useMyPurchase';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CollapseCard from '../../components/cards/collapse-card/CollapseCard';
import PageTitle from '../../components/typographies/PageTitle';
import useAnalytics from '../../hooks/useAnalytics/useAnalytics';

const MyPurchase = () => {
  const { t } = useTranslation(['my-purchase']);
  const { id } = useParams();
  const { fetchPurchase, data, status } = useMyPurchase();
  const { traceBtnClick } = useAnalytics();
  const navigate = useNavigate();

  useEffect(() => {
    fetchPurchase(+(id || 0));
  }, []);

  const [openGroup, setOpenGroup] = useState(false);
  const [openCheckout, setOpenCheckout] = useState(false);

  let title = '';
  if (data?.group?.agenda === 'flexible') {
    title += `Horario flexible\ncon ${data?.group?.sessions?.length} sesiones a elegir`;
  }
  if (data?.group?.agenda === 'scheduled') {
    title += (data?.group?.sessions?.length || 0) > 1 ? '\n- Inicio ' : '';
    title += `${data?.group?.firstSession?.dateFormat}, ${data?.group?.firstSession?.startTime} hrs.`;
  }

  return (
    <>
      {!!id && status === 'loading' && <LinearProgress />}
      {!!id && status !== 'loading' && !!data && (
        <DividedContainer>
          <NavigationBar variant="variant4" />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <PageTitle>{`${t(`title.${data?.group?.publication?.type}`, '') || t('title.class')}: ${data?.group?.publication?.title}`}</PageTitle>
            </Grid>
            <Grid item xs={12}>
              <ListButton
                title={t(`publication.${data?.group?.publication?.type}`, '') || t('publication.class')}
                onClick={() => { 
                  traceBtnClick({ step: 'step ver detalle de publicación', product: t(`publication.${data?.group?.publication?.type}`, '') || t('publication.class') });
                  navigate(`/my-purchase/${id}/publication`);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <ListButton 
                icon={openGroup ? <ExpandLess /> : <ExpandMore />} 
                onClick={() => { 
                  traceBtnClick({ step: 'step ver detalle de grupo', product: t(`group.${data?.group?.publication?.type}`, '') || t('group.class') });
                  setOpenGroup(!openGroup); 
                }} 
                title={t(`group.${data?.group?.publication?.type}`, '') || t('group.class')}
              >
              </ListButton>

              <CollapseCard in={openGroup} timeout="auto" unmountOnExit>
                <Label variant='variant2'>{title}</Label>
                {/* {data?.group?.sessions?.map((session: any) => (
                  <ListItemText 
                    key={session?.id} 
                    primary={`${session?.dateFormat}`} 
                    primaryTypographyProps={{ style: { textTransform: 'capitalize' } }}
                    secondary={
                      `${session?.startTime} a ${session?.endTime}, duración ${session?.durationFormat}
                    `}
                  />
                ))} */}
              </CollapseCard>
            </Grid>

            <Grid item xs={12}>
              <ListButton 
                icon={openCheckout ? <ExpandLess /> : <ExpandMore />} 
                onClick={() => { 
                  traceBtnClick({ step: 'step ver detalle de checkout', product: t(`checkout.${data?.group?.publication?.type}`, '') || t('checkout.class') });
                  setOpenCheckout(!openCheckout); 
                }} 
                title={t(`checkout.${data?.group?.publication?.type}`, '') || t('checkout.class')}
              >
              </ListButton>
              <CollapseCard in={openCheckout} timeout="auto" unmountOnExit>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <ListItemText primary={t('values.code') as string} secondary={data?.code}/>
                    <ListItemText primary={t('values.checkout.state') as string} secondary={t(`state.${data?.checkout?.state}`) as string}/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ListItemText primary={t('values.checkout.payer.fullname') as string} secondary={`${data?.checkout?.payer?.name} ${data?.checkout?.payer?.lastname}`}/>
                    <ListItemText primary={t('values.checkout.payer.fullphone') as string} secondary={`${data?.checkout?.payer?.phoneNumber}`}/>
                    <ListItemText primary={t('values.checkout.payer.email') as string} secondary={`${data?.checkout?.payer?.email}`}/>
                  </Grid>
                </Grid>
              </CollapseCard>
            </Grid>

            <Grid item paddingTop={2}>
              <BackButton onClick={() => { 
                traceBtnClick({ step: 'step volver a mis compras', product: 'Atrás' });
                navigate('/my-purchases'); 
              }} />
            </Grid>
          </Grid>
        </DividedContainer>
      )}
    </>
  );
};

export default MyPurchase;
