import { useState, useContext } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import {
  putAuthUser,
  getAuthPaymentAccount as getAuthPaymentAccountService,
  putAuthPaymentAccount,
} from '../../../services/authUser';
import { 
  getAccountTypes as getAccountTypesService, 
} from '../../../services/accountType';
import { 
  getBanks as getBanksService,
} from '../../../services/bank';
import AuthContext from '../../../contexts/AuthContext';
import { AccountTypeEntity, BankEntity, PaymentAccountEntity, UserEntity } from '../../../entities';
import { useTranslation } from 'react-i18next';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
  accountTypes?: AccountTypeEntity[];
  banks?: BankEntity[];
  paymentAccount?: PaymentAccountEntity;
}

const usePersonal = () => {
  const { t } = useTranslation(['my-account']);

  const [state, setState] = useState<StateProps>({ status: 'idle', error: null });
  const { refreshAuthUser } = useContext(AuthContext);

  const fetchAccountTypes = async () => {
    try {
      const { data } = await getAccountTypesService();
      const accountTypes = data?.map(
        (item: string) => ({
          id: item,
          name: t(`personal.contact.paymentAccount.accountTypes.${item}`, item),
        }),
      );
      setState({ status: 'loaded', accountTypes });
    } catch (error: any) {
      setState({
        status: 'loaded',
        error: {
          message: t(`error.${error.code}`, error.message),
        },
      });
    }
  };

  const fetchBanks = async () => {
    setState({ status: 'loading' });
    try {
      const { data: banks } = await getBanksService();
      setState({ status: 'loaded', banks });
    } catch (error: any) {
      setState({
        status: 'loaded',
        error: {
          message: t(`error.${error.code}`, error.message),
        },
      });
    }
  };
  
  const updateProfile = async (user: UserEntity) => {
    setState({ status: 'loading' });
    try {
      await putAuthUser(user);
      refreshAuthUser?.();
      setState({ status: 'loaded' });
      return true;
    } catch (error: any) {
      setState({
        status: 'loaded',
        error: {
          message: t(`error.${error.code}`, error.message),
        },
      });
      return false;
    }
  };

  const uploadProfilePhoto = async (photo: any) => {
    setState({ status: 'loading' });
    try {
      const uid = firebase.auth().currentUser?.uid;
      const refStorage = firebase.storage().ref(`users/${uid}/photo`);
      await refStorage.put(photo);
      const photoUrl = await refStorage.getDownloadURL();
      setState({ status: 'loaded', error: undefined });
      return photoUrl;
    } catch (error: any) {
      setState({
        status: 'loaded',
        error: {
          message: t(`error.${error.code}`, error.message),
        },
      });
      return undefined;
    }
  };

  const fetchAuthPaymentAccount = async () => {
    setState({ status: 'loading' });
    try {
      const paymentAccount = await getAuthPaymentAccountService();
      setState({ status: 'loaded', paymentAccount });
    } catch (error: any) {
      setState({
        status: 'loaded',
        error: {
          message: t(`error.${error.code}`, error.message),
        },
      });
    }
  };

  const updateAuthPaymentAccount = async (paymentAccount: any) => {
    setState({ ...state, status: 'loading' });
    try {
      await putAuthPaymentAccount(paymentAccount);
      setState({ ...state, status: 'loaded' });
      return true;
    } catch (error: any) {
      setState({
        ...state,
        status: 'loaded',
        error: {
          message: t(`error.${error.code}`, error.message),
        },
      });
      return false;
    }
  };

  return {
    ...state,
    fetchAccountTypes,
    fetchBanks,
    updateProfile,
    fetchAuthPaymentAccount,
    updateAuthPaymentAccount,
    uploadProfilePhoto,
  };
};

export default usePersonal;
