import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  margin-bottom: 8px;
`;

