import { forwardRef } from 'react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { StyledTypography } from './LinkButton.styled';

interface LinkProps extends MuiLinkProps {
  texts?: string[];
  style?: any;
  disabled?: boolean;
  children?: React.ReactNode;
  color?: string;
}
const LinkButton = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ texts = [], children, style, disabled, onClick, color = 'primary', ...rest }, ref) => {
    const localStyle = { cursor: 'pointer', ...style };
    const localStyleDisabled = { cursor: 'auto', color: 'gray', ...style };
  
    return (
      <MuiLink
        underline="none"
        color={color}
        style={disabled ? localStyleDisabled : localStyle}
        ref={ref}
        onClick={disabled ? undefined : onClick}
        {...rest}
      >
        {texts?.map((text, index) => (
          <StyledTypography key={text} style={{ fontWeight: index === 0 ? '400' : '700' }}>{`${text} `}</StyledTypography>
        ))}
        {children}
      </MuiLink>
    );
  });

LinkButton.displayName = 'Link';
export default LinkButton;
