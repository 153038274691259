import { useEffect, useContext, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { Grid, List, LinearProgress } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';
import DividedContainer from '../../../../components/containers/divided-container/DividedContainer';
import ListButton from '../../../../components/buttons/list-button/ListButton';
import AuthContext from '../../../../contexts/AuthContext';
import { Subtitle } from '../../../../components/typographies';
import NavigationBar from '../../../../components/navigations/navigation-bar/NavigationBar';
import { Label } from '../../../../components/typographies';
import useMyProfits from './useMyProfits';
import useFinanceExcel from './useFinanceExcel';
import useDialog from '../../../../hooks/useDialog';
import SimpleDialog from '../../../../components/dialogs/SimpleDialog/SimpleDialog';
import PageTitle from '../../../../components/typographies/PageTitle';
import PageSubtitle from '../../../../components/typographies/PageSubtitle';
import useAnalytics from '../../../../hooks/useAnalytics/useAnalytics';

const Finances = () => {
  const { t } = useTranslation(['my-account']);
  const { traceBtnClick } = useAnalytics();
  const { t: monthTranslation } = useTranslation(['months']);
  const addThousandSeparator = (number: number): string => {
    const numberAsString = number.toString();
    return numberAsString.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };
  const [fileName, setFileName] = useState('');

  const { authUser } = useContext(AuthContext);
  const displayName = authUser?.data?.displayName;
  const { fetchMyProfits, data, status } = useMyProfits();
  const { downloadExcel, data: downloadData, status: downloadStatus, error: downloadError } = useFinanceExcel();
  const { dialog, onCloseDialog } = useDialog(downloadError, 'Error de descarga');
  const dataAvailable = data && data?.length > 0;
  const downloadFinances = async (month: string, year: string) => {
    const excelDate = {
      year: year,
      month: month,
    };
    const searchParams = createSearchParams(excelDate);
    await downloadExcel(`?${searchParams}`);
  };
  useEffect(() => {
    if (downloadStatus === 'loaded') {
      const blob = new Blob([downloadData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.xlsx`);
      link.click();
    }
  }, [downloadStatus]);

  useEffect(() => {
    fetchMyProfits();
  }, []);

  return (
    <DividedContainer>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      <NavigationBar variant="variant4" />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageTitle>{t('guru.finances.title')}</PageTitle>
        </Grid>
        <Grid item xs={12}>
          <PageSubtitle>{t('guru.finances.subtitle')}</PageSubtitle>
        </Grid>
        <Grid item xs={12}>
          {status === 'error' && <PageSubtitle>{t('guru.finances.error')}</PageSubtitle>}
          {status === 'loading' && <LinearProgress />}
          {
            status === 'loaded' && dataAvailable && (
              <List>
                {
                  data.map((monthlyProfit) => {
                    const formattedAmount = addThousandSeparator(monthlyProfit.totalProfit);
                    const amountText = `CLP $${formattedAmount}`;
                    const monthText = monthTranslation(monthlyProfit.monthName);
                    const elementId = `${monthlyProfit.month}.${monthlyProfit.year}`;
                    return (
                      <ListButton
                        title={`${monthText} ${monthlyProfit.year}`}
                        icon={<DownloadIcon />}
                        onClick={() => {
                          traceBtnClick({
                            step: 'step descargar excel de finanzas',
                            product: `${monthText} ${monthlyProfit.year}`,
                          });
                          const userNameWithoutSpaces = displayName?.replace(/\s/g, '');
                          const fileNameToDownload = `VentasGurApp_${monthlyProfit.year}_${monthText}_${userNameWithoutSpaces}`;
                          setFileName(fileNameToDownload);
                          downloadFinances(monthlyProfit.month, monthlyProfit.year);
                        }}
                        disabled={downloadStatus === 'loading'}
                        loading={downloadStatus === 'loading'}
                        key={elementId}
                      >
                        {downloadStatus !== 'loading' && <Label>{amountText}</Label>}
                      </ListButton>
                    );
                  })
                }
              </List>
            )
          }
          {status === 'loaded' && !dataAvailable && <Subtitle variant="variant4">{t('guru.finances.noData')}</Subtitle>}
        </Grid>
      </Grid>
    </DividedContainer>
  );
};

export default Finances;
