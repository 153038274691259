import { forwardRef } from 'react';
import { FormControl, TextField, Typography, styled } from '@mui/material';
import { useFormik } from 'formik';
import * as validator from 'yup';
import i18n from 'i18next';
import { SimpleButton } from '../../buttons';
import useQuestionAnswer from './useQuestionAnswer';
import useDialog from '../../../hooks/useDialog';
import SimpleDialog from '../../dialogs/SimpleDialog/SimpleDialog';
import QuestionAnswerEntity from '../../../entities/QuestionAnswerEntity';
import { Label } from '../../typographies';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';

const schema = validator.object({
  answer: validator
    .string()
    .min(3)
    .max(1000)
    .required(i18n.t('schemas:answer:required')),
});

const LabelDate = styled(Label)`
  opacity: 0.4;
  margin-right: 4px;
  margin-left: 4px;
  display: flex;
  flex-direction: row-reverse;
`;

interface Props {
  questionAnswer: QuestionAnswerEntity,
}
const Component = forwardRef<HTMLDivElement, Props>(
  ({ questionAnswer, ...rest }, ref) => {
    const { status, error, createAnswer } = useQuestionAnswer();
    const { traceBtnClick } = useAnalytics();
    const { dialog, onCloseDialog } = useDialog(error, 'Error');

    const form = useFormik({
      enableReinitialize: true,
      initialValues: {
        answer: '',
      },
      validationSchema: schema,
      validateOnChange: true,
      onSubmit: async (values) => {
        if (questionAnswer.id) {
          await createAnswer(
            questionAnswer.id,
            values.answer,
          );
          window.location.reload();
        }
      },
    });
    
    return (
      <div {...rest} ref={ref}>
        <SimpleDialog {...dialog} onClose={onCloseDialog} />
        <div style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
   			  <Typography variant='h4'>{questionAnswer.question}</Typography>
          <LabelDate>{questionAnswer.updatedAtFormat}</LabelDate>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8, marginBottom: 24 }}>
          <FormControl margin="dense" fullWidth>
            <TextField
              name='answer'
              label='Escribe tu respuesta'
              variant="outlined"
              fullWidth
              value={form.values.answer}
              onChange={form.handleChange}
              error={form.touched.answer && Boolean(form.errors.answer)}
              helperText={form.touched.answer && form.errors.answer}
              disabled={status === 'loading'}
              multiline
              minRows={1}
              maxRows={4}
              inputProps={{ maxLength: 1000 }}
            />
          </FormControl>
          <SimpleButton 
            onClick={() => {
              traceBtnClick({
                step: 'step responder pregunta',
                product: 'Responder',
              });
              form.handleSubmit();
            }} 
            disabled={status === 'loading'}
            loading={status === 'loading'}
          >
            Responder
          </SimpleButton>
        </div>
      </div>
    );
  });

export default Component;