import { useTranslation } from 'react-i18next';
import MainContainer from '../../components/containers/main-container/MainContainer';
import SimpleError from '../../components/errors/simple-error/SimpleError';
import NavigationBar from '../../components/navigations/navigation-bar/NavigationBar';

const NotFound = () => {
  const { t } = useTranslation(['not-found']);
  return (
    <MainContainer>
      <NavigationBar variant="variant1" />
      <SimpleError>{t('title')}</SimpleError>
    </MainContainer>
  );
};

export default NotFound;
