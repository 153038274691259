import styled from '@emotion/styled';
import { Container as MuiContainer } from '@mui/material';

interface LeftContainerProps {
  isUpMD?: number;
}

interface RightContainerProps {
  url?: string;
  size?: string;
}

export const Container = styled(MuiContainer)`
  display: flex;
  min-height: 100%;
  position: 'relative';
`;
Container.defaultProps = {
  maxWidth: 'xl',
};

export const LeftContainer = styled.div(
  ({ isUpMD = 0 }: LeftContainerProps) => `
  width: ${isUpMD === 1 ? '50%' : '100%'};
  padding-top: 8px;
`);

export const RightContainer = styled.div(
  ({ url = '', size = 'cover' }: RightContainerProps) => `
    background-image: url('${url}');
    background-repeat: no-repeat;
    background-size: ${size};
    background-position: center;
    min-height: 100%;
    width: 49%;
    position: absolute;
    right: 0;
  `,
);
