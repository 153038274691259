import API from './config/apiInstance';
import apiError from './apiError';

export async function getAuthPublications(params: string) {
  try {
    const response = await API.get(`/auth/publication${params}`);
    return response?.data || [];
  } catch (error) {
    throw apiError({
      code: '/auth/publication/getAuthPublications',
      message: 'Hubo un problema al obtener publicaciones',
    });
  }
}

export async function getAuthPublication(id: number, attributes?: string[]) {
  try {
    const response = await API.get(`/auth/publication/${id}?attributes=${attributes?.join(',') ?? ''}`);
    return response?.data || undefined;
  } catch (error) {
    throw apiError({
      code: '/auth/publication/getAuthPublication',
      message: 'Hubo un problema al obtener publicación',
    });
  }
}

export async function postAuthPublication(payload: any) {
  try {
    const response = await API.post('/auth/publication', payload);
    return response;
  } catch (error) {
    throw apiError({
      code: '/auth/publication/postAuthPublication',
      message: 'Hubo un problema al crear publicación',
    });
  }
}

export async function putAuthPublication(id: number, payload: any) {
  try {
    const response = await API.put(`/auth/publication/${id}`, payload);
    return response;
  } catch (error) {
    throw apiError({
      code: '/auth/publication/putAuthPublication',
      message: 'Hubo un problema al modificar publicación',
    });
  }
}

export async function putToReviewAuthPublication(id: number) {
  try {
    const response = await API.put(`/auth/publication/${id}/to-review`);
    return response;
  } catch (error: any) {
    const errorResponse = error?.response?.data?.message;
    throw apiError({
      code: '/auth/publication/putToReviewAuthPublication',
      message: `Hubo un problema al finalizar crear publicación.\n${errorResponse}`,
    });
  }
}

export async function getAuthPublicationsCount(params: string) {
  try {
    const response = await API.get(`/auth/publication/count?${params}`);
    return response?.data;
  } catch (error) { 
    throw apiError({
      code: 'GET /auth/publication/count',
      message: 'Hubo un problema al obtener cantidad de publicaciones',
    });
  }
}