import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';

export const ContainerSearch = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const StyledSkeleton = styled(Skeleton)``;
StyledSkeleton.defaultProps = { variant: 'rectangular', height: 56 };
