import { forwardRef, ReactElement } from 'react';
import { Typography } from '@mui/material';
import {
  StyledVariant1,
  StyledVariant2,
  StyledVariant3,
  StyledVariant4,
  StyledVariant5,
  StyledVariant6,
} from './Title.styled';

interface TitleProps {
  variant?: 'variant1' | 'variant2' | 'variant3' | 'variant4' | 'variant5' | 'variant6';
  children?: string | ReactElement;
  id?: string;
  isdownmd?: number;
  style?: any;
}
const Title = forwardRef<HTMLParagraphElement, TitleProps>(
  ({ variant, ...rest }, ref) => {
    if (variant === 'variant1') {
      return <StyledVariant1 variant="h1" ref={ref} {...rest} />;
    }

    if (variant === 'variant2') {
      return <StyledVariant2 color="primary" variant="h1" ref={ref} {...rest} />;
    }

    if (variant === 'variant3') {
      return <StyledVariant3 color="primary" variant="h1" ref={ref} {...rest} />;
    }

    if (variant === 'variant4') {
      return <StyledVariant4 variant="h1" ref={ref} {...rest} />;
    }

    if (variant === 'variant5') {
      return <StyledVariant5 variant="h1" ref={ref} {...rest} />;
    }

    if (variant === 'variant6') {
      return <StyledVariant6 variant="h1" ref={ref} {...rest} />;
    }

    return (
      <Typography color="primary" ref={ref} {...rest} />
    );
  },
);

Title.displayName = 'Title';
export default Title;
