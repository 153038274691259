import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

interface StyledIconButtonProps {
  customtextcolor?: string;
}

export const StyledKebabMenu = styled.div`
`;

export const StyledIconButton = styled(IconButton)(
  ({ customtextcolor }: StyledIconButtonProps) => `
    color: ${customtextcolor};
  `,
);
