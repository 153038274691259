import API from './config/apiInstance';
import apiError from './apiError';

export async function getPublications(params: string) {
  try {
    const response = await API.get(`/publication${params}`);
    return response.data;
  } catch (error) {
    throw apiError({ code: '/publication', message: 'Hubo un problema al intentar buscar publicaciones' });
  }
}

export async function getPublication(id: number, attributes?: string[]) {
  try {
    const response = await API.get(`/publication/${id}?attributes=${attributes?.join(',') || ''}`);
    if (response && response.data) {
      return response.data;
    }
    return [];
  } catch (error: any) {
    const message = error?.response?.data?.message || '';
    throw apiError({ code: `/publication/${id}`, message: `Hubo un problema al intentar buscar la publicación.\n${message}` });
  }
}
