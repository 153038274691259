import { forwardRef, ReactElement } from 'react';
import { Container, IconButton, useMediaQuery, Modal } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { HighlightOff as HighlightOffIcon } from '@mui/icons-material';
import { StyledContent, StyledHeader } from './SimpleModal.styled';
import PageTitle from '../../typographies/PageTitle';
import PageSubtitle from '../../typographies/PageSubtitle';

interface ModalProps {
  id?: string;
  onClose?: () => void;
  open?: boolean;
  title?: string;
  subtitle?: string;
  nextSubtitle?: ReactElement;
  children: ReactElement;
}

const SimpleModal = forwardRef<HTMLDivElement, ModalProps>(
  ({
    id = 'modal', onClose, open = false, title, subtitle, nextSubtitle, children, ...rest
  }, ref) => {
    const handleClose = () => { onClose?.(); };

    const theme = useTheme();
    const isdownmd = useMediaQuery(theme.breakpoints.down('md'));

    return (
      <Modal
        onClose={handleClose}
        open={open}
        style={{ overflow: 'auto' }}
        aria-labelledby={`${id}-title`}
        aria-describedby={`${id}-description`}
        ref={ref}
        {...rest}
      >
        <Container maxWidth='xl'>
          <StyledContent isdownmd={isdownmd ? 1 : 0}>
            <div style={{ padding: 16 }}>
              <StyledHeader>
                <IconButton aria-label="Cerrar" component="span" onClick={onClose} style={{ padding: 0, float: 'right' }}>
                  <HighlightOffIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                </IconButton>
                {title && (
                  <PageTitle marginBottom={1}>{title}</PageTitle>
                )}
              </StyledHeader>
              {subtitle && (
                <PageSubtitle marginBottom={1}>{subtitle}</PageSubtitle>
              )}
              {children}
            </div>
          </StyledContent>
        </Container>
      </Modal>
    );
  },
);

SimpleModal.displayName = 'SimpleModal';
export default SimpleModal;
