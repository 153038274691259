import { 
  MainContainer, 
  Header, 
  HeaderImage, 
  Container, 
  Paragraph, 
  HazteGuruButton, 
  Logo, 
  BenefitsSection, 
  BeGuruImage, 
  HeaderBenefitsSection, 
  TitleBenefitsSection, 
  StrongText, 
  ContainerCard, 
  BeGuruImageDesktop, 
  HowToBeGuruSection, 
  Banner, 
  Image, 
  FirstImageContainer, 
  SecondImageContainer, 
  FAQSection, 
  FAQImg, 
} from './BeGuru.styled';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import imageMobile from './assets/Foto-Banner-Guru-01-M.webp';
import imageDesktop from './assets/Foto-Banner-Guru-01.webp';
import guruExplication from './assets/Guru-Explicacion.webp';
import imageBannerOne from './assets/retrato-mujer-sonriente-arcilla-amasada-taller.avif';
import imageBannerTwo from './assets/lanzamiento-profesional-tenista.avif';
import imageBannerThree from './assets/mujer-mayor-casa-sofa-laptop-lecciones-guitarra.avif';
import imageBannerFour from './assets/tiro-medio-joven-tejiendo-camara.avif';
import imageGuruFAQ from './assets/Guru-FAQ.webp';
import BenefitCard from '../../components/cards/benefit-card/BenefitCard';
import Footer from '../../components/footer/Footer';
import HowToBeGuruCard from '../../components/cards/how-to-be-guru/HowToBeGuruCard';
import SimpleAccordion from '../../components/accordion/SimpleAccordion';
import { useNavigate } from 'react-router-dom';
import { SyntheticEvent, useState } from 'react';
import { textOnWhite1 } from '../../themes/gurapp.theme';
import { SimpleButton } from '../../components/buttons';
import { GurusFaqs } from '../../services/FAQs';
import benefitOneImage from './assets/beneficio1.webp';
import benefitTwoImage from './assets/beneficio2.webp';
import benefitThreeImage from './assets/beneficio3.webp';
import benefitFourImage from './assets/beneficio4.webp';
import useAnalytics from '../../hooks/useAnalytics/useAnalytics';

const BeGuru = () => {
  const { traceBtnClick } = useAnalytics();
  const theme = useTheme();
  const isUpMD = useMediaQuery(theme.breakpoints.up('md'));
  const isUpLG = useMediaQuery(theme.breakpoints.up('lg'));
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const goToCreateAccount = () => { 
    traceBtnClick({
      step: 'step ir a crearse cuenta gurú',
      product: 'Hazte Gurú',
    });
    navigate('/create-account?mode=beguru');
  };

  const handleClickFaqs = () => {
    traceBtnClick({
      step: 'step ir a más faqs',
      product: 'Ver todas las preguntas',
    });
    navigate('/faqs#Gurus');
  };

  const benefits = [
    {
      id: '1',
      img: `${benefitOneImage}`,
      title: 'Mejora tu visibilidad y potencia tus ventas',
      description: 'Ampliamos tu alcance aumentando tus oportunidades de venta. Centralizamos múltiples alternativas en un solo lugar. Los exploradores de experiencias te están buscando',
      alt: 'Visibilidad',
    },
    {
      id: '2',
      img: `${benefitTwoImage}`,
      title: 'Administra tus clases con un sistema pensado en tí',
      description: 'Publica todos los cursos que quieras y gestiónalos fácilmente. Tú decides cuándo, donde y cómo dictarlos (online o presencial).',
      alt: 'Calendario',
    },
    {
      id: '3',
      img: `${benefitThreeImage}`,
      title: 'Obtén recomendaciones y consigue más exploradores',
      description: 'Quienes compran tus cursos podrán compartir sus experiencias a toda la comunidad ¡Las recomendaciones son tu mejor marketing!',
      alt: 'Exploradores',
    },
    {
      id: '4',
      img: `${benefitFourImage}`,
      title: '¡Relájate! Nosotros gestionamos el pago',
      description: 'Nos encargamos de gestionar los cobros entregando distintas opciones de pago. ¡Queremos que todos tengan la oportunidad de tomar tus clases!',
      alt: 'Pago',
    },
  ];

  return (
    <MainContainer>
      {
        isUpMD ?
          /* Desktop version  */
          <>
            <Header>
              <Container>
                <Logo></Logo>
                <Paragraph>
                  Únete y conecta con cientos de exploradores de experiencias
                </Paragraph>
                <HazteGuruButton onClick={goToCreateAccount}>Hazte Gurú</HazteGuruButton>
              </Container>
              <HeaderImage src={imageDesktop} alt='Chica y chico corriendo' />
            </Header>
          </>
          :
          /* Mobile version */
          <>
            <Header>
              <Logo></Logo>
              <HeaderImage src={imageMobile} alt='Chica y chico corriendo' />
              <Container>
                <Paragraph>
                  Únete y conecta con cientos de<br /> exploradores de experiencias
                </Paragraph>
                <HazteGuruButton onClick={goToCreateAccount}>Hazte Gurú</HazteGuruButton>
              </Container>
            </Header>
          </>
      }
      <BenefitsSection>
        <HeaderBenefitsSection>
          <BeGuruImage src={guruExplication} alt='Gurú explicando' />
          <TitleBenefitsSection>
            Descubre qué significa <StrongText>ser Gurú</StrongText>
          </TitleBenefitsSection>
        </HeaderBenefitsSection>
        <ContainerCard>
          {isUpLG && <BeGuruImageDesktop src={guruExplication} alt='Gurú explicando' />}
          {benefits.map((benefit) => (
            <BenefitCard 
              key={benefit.id} 
              img={benefit.img} 
              altImg={benefit.alt}
              title={benefit.title}
              description={benefit.description}
            />
          ))}
        </ContainerCard>
      </BenefitsSection>
      <HowToBeGuruSection>
        <Banner>
          <FirstImageContainer>
            <Image className='imageOne' src={imageBannerOne} alt='Persona cocinando sonriente' />
          </FirstImageContainer>
          <SecondImageContainer>
            <Image className='imageTwo' src={imageBannerTwo} alt='Persona haciendo un saque de tenis' />
          </SecondImageContainer>
        </Banner>
        <HowToBeGuruCard></HowToBeGuruCard>
        <Banner>
          <SecondImageContainer>
            <Image className='imageFour' src={imageBannerFour} alt='Persona joven tejiendo' />
          </SecondImageContainer>
          <FirstImageContainer>
            <Image className='imageThree' src={imageBannerThree} alt='Persona mayor en clases de guitarra' />
          </FirstImageContainer>
        </Banner>
      </HowToBeGuruSection>
      <FAQSection>
        {isUpMD && <FAQImg src={imageGuruFAQ} alt='Gurú buscando respuestas' />}
        <Box sx={{ justifySelf: 'center', alignSelf: 'start', marginBottom: '4rem', maxWidth: '470px' }}>
          <Typography sx={{ color: textOnWhite1, margin: ' 1rem  0', fontWeight: 'bold' }} variant="h5">
            Preguntas frecuentes
          </Typography>
          {GurusFaqs.filter(faq => faq.showBeGuru).map((faq) => (
            <SimpleAccordion 
              key={faq.id}
              id={faq.id} 
              expanded={expanded === faq.id} 
              title={faq.question} 
              content={!!faq.smallAnswer ? faq.smallAnswer : faq.fullAnswer}
              handleChange={handleChange}
              handleButton={!!faq.smallAnswer ? () => navigate(`/faqs#${faq.id}`) : undefined}
            />
          ))}
          <SimpleButton 
            size='medium' 
            variant='text' 
            onClick={handleClickFaqs}
            style={{ float: 'right', marginBottom: '.5rem', marginTop: '.5rem' }}
          >
            Ver todas las preguntas
          </SimpleButton>
        </Box>
      </FAQSection>
      <Footer></Footer>
      {/* <FloatButton onClick={goToCreateAccount}></FloatButton> */}
    </MainContainer>
  );
};

export default BeGuru;