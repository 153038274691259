import { forwardRef } from 'react';
import { Stepper } from '..';

interface CartStepperProps {
  activeStepIndex?: number;
}
const CartStepper = forwardRef<HTMLParagraphElement, CartStepperProps>(
  ({ activeStepIndex = 0, ...props }, ref) => {
    const steps = ['Carro', 'Datos', 'Pagar'];
    return (
      <Stepper 
        {...props}
        activeStepIndex={activeStepIndex}
        steps={steps}
        ref={ref}
      />
    );
  },
);

CartStepper.displayName = 'CartStepper';
export default CartStepper;
