import { forwardRef, ReactElement } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import styled from '@emotion/styled';

interface PageTitleProps extends TypographyProps {
  children?: string | ReactElement;
}
const StyledTypography = styled(Typography)`
`;
const PageTitle = forwardRef<HTMLParagraphElement, PageTitleProps>(
  ({ ...rest }, ref) => {
    return <StyledTypography variant="h1" ref={ref} {...rest} />;
  },
);

PageTitle.displayName = 'PageTitle';
export default PageTitle;
