import API from './config/apiInstance';
import apiError from './apiError';

export async function getAdminUsers(params: string) {
  try {
    const response = await API.get(`/admin/user${params}`);
    return response?.data || [];
  } catch (error) {
    throw apiError({
      code: '/admin/publication/getAdminUsers',
      message: 'Hubo un problema al obtener usuarios',
    });
  }
}