import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const StyledCircleNumber = styled(Typography)`
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  border: 6px solid rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  font-size: 60px;
  font-weight: 900;
  line-height: 1.25em;
  color: rgba(0, 0, 0, 0.7);
`;
