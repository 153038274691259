import * as validator from 'yup';
import i18n from 'i18next';

const PHOTO_SIZE = 0.256 * 1024 * 1024; // 256k max

const isValidFileSize = (value: any) => (value && (!value.size || value.size < PHOTO_SIZE));
const isValidLength = (value: any) => (value && value.length === 1);

export const profileSchema = validator.object({
  displayName: validator
    .string()
    .required(i18n.t('schemas:displayName:required')),
  description: validator
    .string()
    .max(600, i18n.t('schemas:userdescription:max'))
    .required(i18n.t('schemas:userdescription:required')),
  photoUrl: validator
    .array().of(validator
      .mixed()
      .test('isValidFileSize', i18n.t('schemas:photoUrl:size'), isValidFileSize))
    .test('isValidLength', i18n.t('schemas:photoUrl:required'), isValidLength),
});
