import {
  InputLabel, Select as MuiSelect, FormHelperText, FormControl, SelectProps,
} from '@mui/material';
import React from 'react';

interface Props {
  helperText?: any;
  error?: boolean;
  fullWidth?: boolean;
  size?: SelectProps['size'];
  label: SelectProps['label'];
  children: SelectProps['children'];
  disabled?: SelectProps['disabled'];
  id?: SelectProps['id'];
  name?: SelectProps['name'];
  placeholder?: SelectProps['placeholder'];
  variant?: SelectProps['variant']
  value?: SelectProps['value'];
  onChange?: SelectProps['onChange'];
  MenuProps?: SelectProps['MenuProps'];
   
}

const fontSizes = {
  small: '0.875rem',
  medium: '1rem',
};

const Select = React.forwardRef<HTMLSelectElement, Props>(
  ({ helperText, fullWidth, size = 'medium', ...props }, ref) => {
    const id = props.id || `${Math.floor(Math.random() * 10000)}-select`;
    const labelId = `${id}-label`;
    return (
      <FormControl margin="dense" fullWidth size={size} disabled={props.disabled}>
        <InputLabel 
          id={labelId}
          style={{ fontSize: fontSizes[size] }}
        >
          {props.label}
        </InputLabel>
        <MuiSelect
          labelId={labelId}
          id={id}
          fullWidth={fullWidth}
          error={props.error}
          {...props}
          size={size}
          ref={ref}
        >
          {props.children}
        </MuiSelect>
        {props.error && (
          <FormHelperText variant="outlined" error>
            {typeof helperText === 'object' ? Object.values(helperText).join(', ') : helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  });

Select.displayName = 'Select';
export default Select;
