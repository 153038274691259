import styled from '@emotion/styled';
import { Label } from '../../typographies';
import { DialogTitle } from '@mui/material';

export const StyledTitle = styled(Label)`
  margin-right: 28px;
`;

export const StyledDialogTitle = styled(DialogTitle)`
  min-height: 16px;
`;