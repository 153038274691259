import {
  ReactElement,
  createContext, useEffect, useState,
} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/remote-config';


interface FlagContextProps {
  publicationsAvailable?: boolean;
  financesAvailable?: boolean;
  loaded?: boolean;
}
const FlagContext = createContext<FlagContextProps>({ loaded: false });

interface Default {
  [key: string]: string | number | boolean;
}
interface FlagContextProviderProps {
  defaults: Default,
  children: ReactElement;
}
export const FlagContextProvider = ({ defaults, ...props }: FlagContextProviderProps) => {
  const [flags, setFlags] = useState(defaults);

  useEffect(() => {
    if (process.env.REACT_APP_IGNORE_REMOTE_CONFIG === 'true') {
      const newFlags = {
        ...flags,
        publicationsAvailable: true,
        financesAvailable: true,
        loaded: true,
      };
      setFlags(newFlags);
    } else {
      const remoteConfig = firebase.remoteConfig();

      remoteConfig.defaultConfig = defaults;
      remoteConfig.settings.minimumFetchIntervalMillis = 1;

      remoteConfig
        .fetchAndActivate()
        .then(() => {
          return remoteConfig.getAll();
        })
        .then(remoteFlags => {
          const newFlags = {
            ...flags,
            publicationsAvailable: remoteFlags.publicationsAvailable?.asBoolean(),
            financesAvailable: remoteFlags.financesAvailable?.asBoolean(),
            loaded: true,
          };
          setFlags(newFlags);
        });
    }
  }, []);

  return (
    <FlagContext.Provider 
      value={flags}  
      {...props}
    />
  );
};

export default FlagContext;
