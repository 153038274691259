import styled from '@emotion/styled';
import { ListItem, ListItemIcon } from '@mui/material';

interface StyledListItemProps {
  error: number;
}

export const StyledListItem = styled(ListItem)(
  ({ error }: StyledListItemProps) => `
  ${error === 1 && `
    border: 1px solid #f44336;
  `};
  background-color: #F4F2F2;
  box-shadow: 0px 0px 3px rgba(10, 9, 9, 0.25);
  margin: .5em 0;
`,
);

export const StyledListItemIcon = styled(ListItemIcon)`
  justify-content: flex-end;
  align-items: center;
`;
