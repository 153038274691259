import { forwardRef } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface BackButtonProps extends ButtonProps {
  children?: string;
  size?: 'small' | 'medium' | 'large';
}
const BackButton = forwardRef<HTMLButtonElement, BackButtonProps>(
  ({ children, size = 'large', ...rest }, ref) => {
    const { t } = useTranslation(['components']);
    return (
      <Button
        size={size}
        ref={ref}
        {...rest}
      >
        {children || t('back-button.text') as string}
      </Button>
    );
  },
);

BackButton.displayName = 'BackButton';
export default BackButton;
