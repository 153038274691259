import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { SimpleButton } from '../../components/buttons';
import GurappLogo from '../../components/logos/gurapp-logo/GurappLogo';
import backgroundImage from '../../assets/images/backgroundHome.avif';
import { breakpoints, primaryMain } from '../../themes/gurapp.theme';
import { keyframes } from '@emotion/react';

export const MainContainer = styled.div`
  background-color: #F8F8F8;
`;

/* *** styles header ****  */
export const Header = styled.header`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  color: #fff;
  width: 100%;
  @media (min-width:${breakpoints.values.md}px) {
    display: flex;
    justify-content: space-between;
    height: 90vh;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
  @media (min-width:${breakpoints.values.md}px) {
    margin-left: 8rem;
    padding-bottom: 0;
    justify-content: center;
    position: relative;
    margin: 4rem 0 3rem 5rem;
    align-items: flex-start;
  }
  @media (min-width:${breakpoints.values.lg}px) {
    margin-left: 12rem;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const HeaderImage = styled.img`
  width: 100%;
  height: auto;
  overflow:hidden;
  @media (min-width:${breakpoints.values.md}px) {
    width: max-content;
  }
  animation: ${fadeIn} .5s ease-in-out;
`;

export const Paragraph = styled(Typography)`
  margin-bottom: 1rem ;
  font-size: 1.2rem;
  font-weight: bold;
  @media (min-width:${breakpoints.values.md}px) {
    max-width: 255px;
    font-size: 1.6rem;
  }
  @media (min-width:${breakpoints.values.lg}px) {
    max-width: 380px;
    font-size: 2rem;
    line-height: 2.5rem;
  }
  @media (min-width:${breakpoints.values.xl}px) {
    max-width: 450px;
    font-size: 2.5rem;
    line-height: 3.5rem; 

  }
`;

export const HazteGuruButton = styled(SimpleButton)`
  background-color: #fff;
  color: ${primaryMain};
  &:hover {
    background-color: #f1f1f1;
  }
`;
HazteGuruButton.defaultProps = {
  variant: 'contained',
  endIcon: <ArrowForwardOutlinedIcon />,
};

export const Logo = styled(GurappLogo)`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  padding: 2rem;
  @media (min-width:${breakpoints.values.md}px) {
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
  }
`;
Logo.defaultProps = {
  variant: 'white2',
};

/* *** section benefits be Guru *** */
export const BenefitsSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width:${breakpoints.values.md}px) {
    width: 80%;
    margin: 2rem auto;
  }
`;

export const HeaderBenefitsSection = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem 1rem 2rem 1rem;
`;

export const StrongText = styled.span`
  font-weight: bold;
`;

export const TitleBenefitsSection = styled(Typography)`
  color: ${primaryMain};
  font-weight: normal;
  margin-bottom: 1rem;
  
  @media (min-width:${breakpoints.values.md}px) {
    font-size: 1.8rem;
  }
`;

export const ContainerCard = styled.div`
  display: grid;
  @media (min-width:${breakpoints.values.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width:${breakpoints.values.lg}px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const BeGuruImageDesktop = styled.img`
  max-width: 13rem;
  @media (min-width:${breakpoints.values.md}px) {
    justify-self:center;
    align-self: center;
  }
`;

export const BeGuruImage = styled.img`
  max-width: 150px;
  @media (min-width:${breakpoints.values.lg}px) {
    display: none;
  }
`;

/* *** Section How to be Gurú *** */
export const HowToBeGuruSection = styled.section`
  margin: 4rem auto;
  position: relative;
  height: 1250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;

  @media (min-width:${breakpoints.values.md}px) {
    flex-direction: row;
    height: auto;
    justify-content: center;
    align-items: center;
    margin: 4rem 0;
    max-width: none;
  }
`;

export const Banner = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media (min-width:${breakpoints.values.md}px) {
    display: none;
    flex-direction: column;
    flex-direction: column-reverse;
    max-width: 150px;
    margin: 1rem;
  }
  @media (min-width:${breakpoints.values.lg}px) {
    display: flex;
  }
`;

export const FirstImageContainer = styled.div`
  grid-column: span 2;
  
`;

export const SecondImageContainer = styled.div`
  grid-column: span 1;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  
  &.imageOne,
  &.imageFour {
    border-radius: 0 .3rem .3rem 0;
  }
  &.imageTwo,
  &.imageThree {
    border-radius: .3rem 0  0 .3rem;
  }
  &.imageOne,
  &.imageFour,
  &.imageTwo,
  &.imageThree {
    @media (min-width: 500px) {
        border-radius: .3rem;
    }
  }
`;

/* ** FAQ Section */
export const FAQSection = styled.section`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 3rem;
`;

export const FAQImg = styled.img`
  width: 35%;
  max-width: 300px;
  margin-bottom: -1rem;
  align-self: end;
  margin-right: 3rem;
`;
