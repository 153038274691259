import { forwardRef } from 'react';
import { Step, StepLabel, Stepper as MuiStepper, useMediaQuery, useTheme } from '@mui/material';

interface StepperProps {
  activeStepIndex?: number;
  steps?: string[];
}
const Stepper = forwardRef<HTMLParagraphElement, StepperProps>(
  ({ activeStepIndex = 0, steps, ...rest }, ref) => {
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    return (
      <MuiStepper
        activeStep={activeStepIndex}
        alternativeLabel={isDownSm}
        ref={ref}
        {...rest}
      >
        {steps?.map((step) => (
          <Step key={step}>
            <StepLabel>{step}</StepLabel>
          </Step>
        ))}
      </MuiStepper>
    );
  },
);

Stepper.displayName = 'Stepper';
export default Stepper;
