import React, { useRef, useState, useMemo, useEffect, forwardRef, ReactNode } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
  terms: { value: string }[];
}

interface Location {
  description: string,
  areaLevel1: string,
  areaLevel2: string,
  areaLevel3: string,
}

interface GooglMapsProps {
  label: string;
  noOptionsText?: string;
  name?: string;
  onChange?: (value: Location | null) => void;
  value?: PlaceType | null;
  error?: boolean;
  helperText?: ReactNode;
}

const GoogleMapsAutocomplete = forwardRef<HTMLDivElement, GooglMapsProps>(({
  label, 
  noOptionsText, 
  name,
  onChange,
  value,
  error,
  helperText,
  ...props
}, ref) => {
  const [localValue, setLocalValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    try {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
          document.querySelector('head'),
          'google-maps',
        );
      }
      loaded.current = true;
    } catch (err: unknown) {
      loaded.current = false;
    }
   
  }

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          
          (autocompleteService.current as any).getPlacePredictions(
            { ...request, componentRestrictions: { country: 'cl' } },
            callback,
          );
        },
        400,
      ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(localValue ? [localValue] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (localValue) {
          newOptions = [localValue];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [localValue, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map"
      sx={{ width: '100%' }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText={noOptionsText || 'Sin referencias'}
      onChange={(_: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setLocalValue(newValue); 
        const description = newValue?.description || '';
        if (description === '') {
          onChange?.(null);
          return;
        }
        const terms = newValue?.terms;
        const firstLength = (terms?.length || 0) - 2;
        onChange?.({
          description: newValue?.description || '',
          areaLevel1: terms?.[terms.length - 1]?.value || '',
          areaLevel2: terms?.[terms.length - 2]?.value || '',
          areaLevel3: terms
            ?.slice(0, firstLength)
            ?.map((term, index) => index === 1 && index !== firstLength - 1 ? `${term.value},` : term.value)
            ?.join(' ') || '',
        });
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label={label}
          helperText={helperText}
          error={error}
          fullWidth 
        />
      )}
      renderOption={(optionProps, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...optionProps}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      ref={ref}
      {...props}
    />
  );
});

export default GoogleMapsAutocomplete;