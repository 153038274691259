import styled from '@emotion/styled';
import {
  ListItem, AvatarGroup, Badge, ListItemIcon,
} from '@mui/material';

interface StyledListItemProps {
  error: number;
}

export const StyledListItem = styled(ListItem)(
  ({ error }: StyledListItemProps) => `
  ${error === 1 && `
    border: 1px solid #f44336;
  `};
  background-color: #F4F2F2;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  margin: .5em 0;
`,
);

export const StyledHiddenInput = styled.input`
  display: none;
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  justify-content: flex-end;
  align-items: center;
`;

export const StyledAvatarGroup = styled(AvatarGroup)`
  margin-right: 8px;
`;

export const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    padding: 0;
  }
`;
