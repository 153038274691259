import { forwardRef, Fragment, ReactNode, useState } from 'react';
import {  Paper, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Collapse, Box, Grid } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CheckoutEntity } from '../../../../../../entities';
import { dayjs } from '../../../../../../configs/dayjs';
import getPriceFormatted from '../../../../../../utils/getPriceFormatted';

interface CheckoutTableProps {
  data?: CheckoutEntity[];
  status?: 'idle' | 'loading' | 'loaded';
}

const styleOneLine = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const ContentRow = (props: { title?: string, children?: ReactNode }) => (
  <>
    <Grid item fontWeight="bold" xs={6}>
      {props?.title}
    </Grid>
    <Grid item xs={6}>
      {props?.children}
    </Grid>
  </>
);

const Row = (props: { row: CheckoutEntity }) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const getStateLabel = (state: string) => {
    if (state === 'paid') {
      return 'Pagado 🟢';
    }
    if (state === 'intent') {
      return 'Intención de compra 🟡';
    }
    if (state === 'rejected') {
      return 'Rechazado 🔴';
    }
    return `${state}`;
  };
  const stateLabel = getStateLabel(row.state);
  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{row.id}</TableCell>
        <TableCell component="th" scope="row" style={styleOneLine}>{stateLabel}</TableCell>
        <TableCell component="th" scope="row">{row.checkoutItems?.length}</TableCell>
        <TableCell component="th" scope="row">{row.payer?.email}</TableCell>
        <TableCell >{dayjs(row.createdAt).format('DD/MM/YYYY')}</TableCell>
        <TableCell >{dayjs(row.updatedAt).format('DD/MM/YYYY')}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Grid container direction="column" spacing={1}>
                <ContentRow title={`Pagador (${ stateLabel })`} />
                <ContentRow title={'Email'}>
                  {row.payer?.email || '-'}
                </ContentRow>
                <ContentRow title={'Nombre y apellido'}>
                  {`${row.payer?.name} ${row.payer?.lastname}` || '-'}
                </ContentRow>
                <ContentRow title={'Teléfono'}>
                  {row.payer?.phoneNumber || '-'}
                </ContentRow>
                <ContentRow title={'Email de usuario'}>
                  {row.payer?.user?.email || 'No tiene usuario'}
                </ContentRow>

                <ContentRow title={'Items'} />
                {row.checkoutItems?.map((item, index) => (
                  <Fragment key={index}>
                    <ContentRow title={'Título'}>
                      {`${item.group?.publication?.title}` || '-'}
                    </ContentRow>
                    <ContentRow title={'Precio'}>
                      {`${getPriceFormatted(item?.group?.publication?.price)}` || '-'}
                    </ContentRow>
                  </Fragment>
                ))}
                
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const CheckoutTable = forwardRef<HTMLDivElement, CheckoutTableProps>(
  ({ data, status, ...rest }, ref) => {
    return (
      <>
        <TableContainer component={Paper} ref={ref} {...rest}>
          <Table aria-label="collapsible table" size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>ID</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Cantidad de items</TableCell>
                <TableCell>Pagador</TableCell>
                <TableCell>Fecha de creación</TableCell>
                <TableCell>Fecha de actualización</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status === 'loaded' && (
                data?.map((row: CheckoutEntity) => (
                  <Row key={row.id} row={row} />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {status === 'loading' && <LinearProgress />}
      </>
    );
  },
);

CheckoutTable.displayName = 'CheckoutTable';
export default CheckoutTable;
