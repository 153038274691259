import { IconButton } from '@mui/material';
import styled from '@emotion/styled';

export const StyledIconButton = styled(IconButton)`
  height: 48px;
  margin: 4px 0 4px 16px;
  width: 67px;
  border-radius: 200px;
  background-color: #6501A3;
  color: #fff;
  &:hover {
    text-decoration: none;
    background-color: rgb(70, 0, 114);
  }
`;
