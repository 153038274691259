import * as validator from 'yup';
import i18n from 'i18next';
import emailSchema from '../../../schemas/email/emailSchema';

export const identificationSchema = validator.object({
  email: emailSchema,
  name: validator
    .string()
    .required(i18n.t('schemas:name:required')),
  lastname: validator
    .string()
    .required(i18n.t('schemas:lastname:required')),
  phoneNumber: validator
    .string()
    .required(i18n.t('schemas:phoneNumber:required'))
    .min(7, i18n.t('schemas:phoneNumber:notValid'))
    .max(15, i18n.t('schemas:phoneNumber:notValid')),
});
