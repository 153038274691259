import API from './config/apiInstance';
import apiError from './apiError';

export async function getAuthPurchases(params: string) {
  try {
    const response = await API.get(`/auth/checkout-item${params}`);
    if (response && response.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    throw apiError({
      code: 'GET /auth/checkout-item',
      message: 'Hubo un problema al obtener compras de usuario',
    });
  }
}

export async function getAuthPurchase(id: number) {
  try {
    const response = await API.get(`/auth/checkout-item/${id}`);
    if (response && response.data) {
      return response.data;
    }
    return undefined;
  } catch (error) {
    throw apiError({
      code: 'GET /auth/checkout-item/:id',
      message: 'Hubo un problema al obtener compra de usuario',
    });
  }
}

export async function getAuthPurchasesCount() {
  try {
    const response = await API.get('/auth/checkout-item/count');
    if (response && response.data) {
      return response.data;
    }
    throw Error('response.data is undefined');
  } catch (error) {
    throw apiError({
      code: 'GET /auth/checkout-item/count',
      message: 'Hubo un problema al obtener cantidad de compras de usuario',
    });
  }
}