import { useState } from 'react';
import { getPricesByTotalPrice } from '../../../../../../services/price';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: any | undefined;
  prices: {
    totalPrice: number;
    percentCommission: number;
    profit: number;
    commission: number;
  },
}

const initialState: StateProps = {
  status: 'idle',
  error: null,
  prices: {
    totalPrice: 0,
    percentCommission: 0,
    profit: 0,
    commission: 0,
  },
};

const usePrice = () => {
  const [state, setState] = useState<StateProps>(initialState);

  const setTotalPrice = async (totalPrice: number) => {
    if (totalPrice === 0) {
      setState({ ...initialState, status: 'loaded' });
    } else {
      setState({ ...initialState, status: 'loading' });
      try {
        const response = await getPricesByTotalPrice(totalPrice);
        setState({ ...initialState, ...response, status: 'loaded' });
      } catch (event: any) {
        setState({ 
          ...initialState, 
          error: { message: event.message },
          status: 'loaded',
        });
      }
    }
  };

  return {
    ...state,
    setTotalPrice,
  };
};

export default usePrice;
