import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import google from './assets/google.svg';
import {
  StyledGoogleButton,
  StyledImg,
  StyledContainerText,
  StyledText,
} from './GoogleButton.styled';

interface IButton {
  onClick?: () => any;
  disabled?: boolean;
  children?: string;
}
const Button = forwardRef<HTMLButtonElement, IButton>(
  ({
    onClick, disabled = false, children, ...rest
  }, ref) => {
    const { t } = useTranslation(['components']);
    return (
      <StyledGoogleButton
        disabled={disabled}
        onClick={!disabled ? onClick : undefined}
        ref={ref}
        {...rest}
      >
        <StyledImg src={google} alt={children || t('google-button.title')} height="32px" />
        <StyledContainerText>
          <StyledText align="center">{children || t('google-button.title') as string}</StyledText>
        </StyledContainerText>
      </StyledGoogleButton>
    );
  },
);

Button.displayName = 'Button';
export default Button;
