import { forwardRef, useRef, useState } from 'react';
import {
  ListItemText, IconButton, FormHelperText, Avatar, ListItemButton, CircularProgress, useMediaQuery, useTheme,
} from '@mui/material';
import {
  AddPhotoAlternateOutlined as PhotoIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import {
  StyledListItem,
  StyledHiddenInput,
  StyledAvatarGroup,
  StyledBadge,
  StyledListItemIcon,
} from './FileListItem.styled';
import getImagesCompressed from '../../../utils/getImagesCompressed';

interface FileListItemProps {
  error?: boolean;
  label: string;
  secondaryLabel?: string;
  name: string;
  disabled?: boolean;
  helperText?: string | any;
  onChange?: any;
  maxValues?: number;
  onErrorMaxValues?: () => void;
  value: any;
  canDelete?: boolean;
}

const FileListItem = forwardRef<HTMLLIElement, FileListItemProps>(
  ({
    error = false,
    label,
    secondaryLabel,
    name,
    disabled,
    helperText,
    onChange,
    maxValues = 1,
    onErrorMaxValues,
    value = [],
    canDelete = false,
    ...rest
  }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const internalDisabled = disabled || loading;

    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));
    const isSM = useMediaQuery(theme.breakpoints.only('sm'));
    const isMD = useMediaQuery(theme.breakpoints.only('md'));
    const isUpLG = useMediaQuery(theme.breakpoints.up('lg'));

    const handleDelete = (item: any) => {
      const newValue = value.filter((subitem: any) => subitem.name !== item.name);
      onChange?.({
        target: {
          value: newValue,
          name,
        },
      });
    };

    const getUniqueValues = (values: any) => values.reduce((accumulator: any, currentValue: any) => {
      if (!accumulator.find((item: any) => item.name === currentValue.name)) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, []);

    const onInternalChange = async () => {
      if (inputRef.current?.value.length === 0) {
        return;
      }
      if (maxValues !== 1) {
        const newFilesLength = Array.from(inputRef.current?.files || []).length;
        const currentFilesLength = Array.from(value || []).length;
        if (newFilesLength + currentFilesLength > maxValues) {
          onErrorMaxValues?.();
          return;
        }
      }
      setLoading(true);
      const finalFiles = await getImagesCompressed(inputRef.current?.files);
      onChange?.({
        ...inputRef,
        target: {
          ...inputRef.current,
          value: maxValues > 1 ? getUniqueValues([...value, ...finalFiles]) : finalFiles,
          name,
        },
      });
      setLoading(false);
    };

    const handleOnClickFile = () => { inputRef?.current?.click(); };

    const Avatars = () => (
      value?.map((item: any, index: number) => (
        item?.name && (
          <StyledBadge
            key={item?.name}
            overlap="circular"
            color="secondary"
            {...canDelete && {
              badgeContent:
              <IconButton style={{ color: 'white', padding: 0 }} size="small" onClick={() => { handleDelete(item); }}>
                <ClearIcon fontSize="small" />
              </IconButton>,
            }}
          >
            <Avatar
              key={item?.name}
              alt={`avatar ${index + 1}`}
              src={item?.size ? URL.createObjectURL(item) : item?.name}
              sx={{ width: 48, height: 48, opacity: internalDisabled ? 0.4 : 1 }}
              style={{ borderColor: index === 0 ? theme.palette.secondary.main : undefined }}
            />
          </StyledBadge>
        )
      ))
    );
    
    const Content = () => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {loading && <CircularProgress size={25} style={{ marginRight: 16 }}/>}
        <StyledAvatarGroup max={maxValues < 2 ? undefined : maxValues}>
          <Avatars />
        </StyledAvatarGroup>
      </div>
    );
    return (
      <>
        <StyledListItem 
          ref={ref} 
          error={error ? 1 : 0}
          disablePadding
          secondaryAction={
            <StyledListItemIcon>
              <StyledHiddenInput
                disabled={internalDisabled}
                accept="image/*"
                name={name}
                id="icon-button-file"
                ref={inputRef}
                type="file"
                onChange={onInternalChange}
                multiple={maxValues > 1}
              />
              {(isUpLG || isSM) && <Content />}
              {/* {loading && <CircularProgress size={25} style={{ marginRight: 16 }}/>}
              <StyledAvatarGroup max={maxValues}>
                <Avatars />
              </StyledAvatarGroup> */}
              <IconButton edge="end" aria-label={label} disabled={internalDisabled} component="span" onClick={handleOnClickFile}>
                <PhotoIcon />
              </IconButton>
            </StyledListItemIcon>
          }
          {...rest}
        >
          <ListItemButton disabled={internalDisabled} dense onClick={handleOnClickFile}>
            <ListItemText
              primary={label}
              secondary={<>{secondaryLabel}{(isXS || isMD) && <Content />}</>}
              secondaryTypographyProps={{ component: 'div', lineHeight: 2 }}
            />
          </ListItemButton>
        </StyledListItem>
        {error && (
          <FormHelperText variant="outlined" error>
            {typeof helperText === 'object' ? Object.values(helperText).join(', ') : helperText}
          </FormHelperText>
        )}
      </>
    );
  },
);

FileListItem.displayName = 'FileListItem';
export default FileListItem;
