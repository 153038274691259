import { Container, Grid } from '@mui/material';
import MainContainer from '../../components/containers/main-container/MainContainer';
import NavigationBar from '../../components/navigations/navigation-bar/NavigationBar';
import Title from '../../components/typographies/title/Title';
import SimpleAccordion from '../../components/accordion/SimpleAccordion';
import { GurusFaqs } from '../../services/FAQs';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const FAQsPage = () => {  
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  
  const { hash } = useLocation();
  useEffect(() => {
    const hashId = hash.replace('#', '');
    const element = document.getElementById(hashId);
    if (!!element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setExpanded(hashId);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);
    
  return (
    <>
      <MainContainer showFooter>
        <NavigationBar />
        <Container maxWidth='md'>
          <Grid container direction='column'>
            <Grid item mb={2}>
              <Title variant="variant2">Preguntas Frecuentes</Title>
            </Grid>
            <Grid item mb={8}>
              {GurusFaqs?.map((faq) => (
                <SimpleAccordion 
                  key={faq.id}
                  id={faq.id} 
                  expanded={expanded === faq.id} 
                  title={faq.question} 
                  content={faq.fullAnswer}
                  handleChange={handleChange}
                />
              ))}
            </Grid>
          </Grid>
        </Container>
      </MainContainer>
    </>
  );
};

export default FAQsPage;
