import { useEffect, useState } from 'react';
import { getAuthPublication } from '../../services/authPublication';
import { GroupEntity, PublicationEntity } from '../../entities';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
  data?: PublicationEntity;
  timeline: { current: number, future: number, past: number }
}

const useMyPublication = () => {
  const initialState: StateProps = { 
    status: 'idle', 
    error: null, 
    timeline: { current: 0, future: 0, past: 0 },
  };
  const [state, setState] = useState<StateProps>(initialState);

  const fetchAuthPublication = async (id: number, attributes?: string[]) => {
    setState({ ...initialState, status: 'loading' });
    try {
      const publication = await getAuthPublication(id, attributes);
      setState({ 
        ...initialState,
        data: publication,
        status: 'loaded',
      });
      return publication;
    } catch (error) {
      setState({ ...initialState, status: 'loaded', error });
    }
    return undefined;
  };

  useEffect(() => {
    const timeline = state.data?.groups?.reduce((acc: any, group: GroupEntity) => {
      return { 
        current: group?.timeline === 'current' ? acc.current + 1 : acc.current, 
        future: group?.timeline === 'future' ? acc.future + 1 : acc.future, 
        past: group?.timeline === 'past' ? acc.past + 1 : acc.past, 
      };
    }, initialState.timeline) || initialState.timeline;
    setState({ ...state, timeline });

  }, [state.data]);

  return {
    ...state,
    fetchAuthPublication,
  };
};

export default useMyPublication;