import { useState, useEffect } from 'react';

interface DialogProps {
  open: boolean;
  title?: string;
  content?: string;
}

const useDialog = (content: any, title: string) => {
  const [dialog, setDialog] = useState<DialogProps>({ open: false });

  const onCloseDialog = () => {
    setDialog({ ...dialog, open: false });
  };

  useEffect(() => {
    if (!content) { return; }
    setDialog({
      open: true,
      title,
      content: content.message,
    });
  }, [content, title]);

  return {
    dialog,
    onCloseDialog,
  };
};

export default useDialog;
