import styled from '@emotion/styled';

interface StyledContentProps {
  isdownmd: number;
}

export const StyledContent = styled.div(
  ({ isdownmd = 0 }: StyledContentProps) => `
  background: #F4F2F2;
  ${isdownmd === 0 && `
    width: calc(50%);
  `};
  margin-top: 32px;
  margin-bottom: 32px;
`,
);

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;
