import styled from '@emotion/styled';
import SimpleButton from '../../buttons/simple-button/SimpleButton';

interface StyledNavigationBarProps {
  isFloating?: boolean;
}
export const StyledNavigationBar = styled.div(
  ({ isFloating = false }: StyledNavigationBarProps) => `
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  ${isFloating && `
    padding: 16px 24px 0 24px;
    box-sizing: border-box;
    position: absolute;
    width: 100vw;
  `};
`,
);

export const LeftContainer = styled.div`
`;

export const RightContainer = styled.div`
  align-self: center;
  display: flex;
`;

export const StyledButton = styled(SimpleButton)`
  margin-left: 8px;
`;

export const StyledButtonToExplore = styled(SimpleButton)`
  color: #fff;
  background-color: #211F43;
  margin-left: 8px;
`;
