import styled from '@emotion/styled';
import { forwardRef, useState } from 'react';

const ContainerImages = styled.div`
  overflow-x: auto; /* Establecer el desplazamiento horizontal */
  overflow-y: hidden; /* Ocultar el desplazamiento vertical */
  display: flex;
  gap: 8px;
  white-space: nowrap;
  img {
    max-height: 250px;
  }
  transition: opacity 0.3s ease-in-out;
`;

interface Props {
  images?: { url: string, alt?: string }[];
}

const HorizontalImages = forwardRef<HTMLDivElement, Props>(
  ({ images }, ref) => {
    const [loadedImages, setLoadedImages] = useState<number>(0);

    const handleImageLoad = () => {
      setLoadedImages((prevLoadedImages) => prevLoadedImages + 1);
    };

    const allImagesLoaded = loadedImages === images?.length;
    return (
      <ContainerImages 
        ref={ref} 
        style={{ 
          opacity: !allImagesLoaded ? 0 : 1,
        }} >
        {images?.map((image) => (
          <img 
            src={image.url} 
            key={image.url} 
            alt={image.alt} 
            onLoad={handleImageLoad}
          />
        ))}
      </ContainerImages>
    );
  });

export default HorizontalImages;