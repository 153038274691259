import React, { useContext, useEffect, useState, forwardRef } from 'react';
import { Container, Grid, Skeleton, Typography, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DividedContainer from '../../components/containers/divided-container/DividedContainer';
import NavigationBar from '../../components/navigations/navigation-bar/NavigationBar';
import CartContext from '../../contexts/CartContext';
import CartItem from '../../components/cart/cart-item/CartItem';
import getPriceFormatted from '../../utils/getPriceFormatted';
import { LinkButton, SimpleButton } from '../../components/buttons';
import CartStepper from '../../components/navigations/cart-stepper/CartStepper';
import useCart from '../../hooks/useCart';
import TermsAndConditionsPage from '../terms-and-conditions/TermsAndConditions';
import SimpleDialog from '../../components/dialogs/SimpleDialog/SimpleDialog';
import PageTitle from '../../components/typographies/PageTitle';
import useAnalytics from '../../hooks/useAnalytics/useAnalytics';

const LoadingCartItem = () => (
  <Grid container spacing={2}>
    <Grid item xs={3}>
      <Skeleton height="100%" />
    </Grid>
    <Grid item xs={9}>
      <Skeleton height={50} />
      <Skeleton height={50} />
    </Grid>
  </Grid>
);

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const Checkout = () => {
  const { t } = useTranslation(['checkout']);
  const { cart = [], totalPrice } = useContext(CartContext);
  const { syncCart, status } = useCart();
  const navigate = useNavigate();
  const { traceBtnClick } = useAnalytics();
  const [open, setOpen] = useState(false);

  const handleGoToIdentification = () => { 
    traceBtnClick({
      step: 'step continuar a identificacion',
      product: t('continue'),
    });
    navigate('/checkout/identification');
  };

  const handleGoToBack = () => { 
    traceBtnClick({
      step: 'step retroceder a carrito',
      product: t('back'),
    });
    navigate(-1);
  };

  const handleClose = () => { 
    traceBtnClick({
      step: 'step cerrar terminos y condiciones',
      product: t('terms') + t('termslink'),
    });
    setOpen(false);
  };

  const handleOpen = () => { 
    traceBtnClick({
      step: 'step abrir terminos y condiciones',
      product: t('terms') + t('termslink'),
    });
    setOpen(true);
  };

  useEffect(() => {
    syncCart?.();
  }, []);

  return (
    <DividedContainer>
      <SimpleDialog 
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="lg"
        transitionDuration={500}
        actions={<SimpleButton variant="outlined" onClick={handleClose}>Cerrar</SimpleButton>}
      >
        <TermsAndConditionsPage />
      </SimpleDialog>
      <NavigationBar variant="variant4"/>
      {cart.length === 0 && (
        <Container maxWidth="xs">
          <Grid container paddingTop={16} spacing={2}>
            <Grid item xs={12} textAlign="center">
              <Typography variant="h1" >{t('empty') as string}</Typography>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <SimpleButton onClick={handleGoToBack}>
                {t('back')}
              </SimpleButton>
            </Grid>
          </Grid>
        </Container>
      )}

      {cart.length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CartStepper activeStepIndex={0}/>
          </Grid>
          <Grid item xs={12}>
            <PageTitle>{t('title', '')}</PageTitle>
          </Grid>
          <Grid item xs={12}>
            {status === 'loading' ? (
              <LoadingCartItem />
            ) : cart.map((item) => (
              <CartItem {...item} key={item.group.id} />
            ))}
          </Grid>

          {status !== 'loading' 
            && (
              <>
                <Grid item xs={8} textAlign="right" paddingTop={2}>
                  <Typography>{t('totalPrice') as string}</Typography>
                </Grid>
                <Grid item xs={4} textAlign="right" paddingTop={2}>
                  <Typography>{getPriceFormatted(totalPrice)}</Typography>
                </Grid>
              </>
            )}
          <Grid item xs={12} textAlign="center" marginTop={2}>
            <LinkButton onClick={handleOpen} texts={[t('terms'), t('termslink')]} />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="space-between" mt={2} mb={2}>
            <SimpleButton sx={{ marginRight: 2 }} variant="text" onClick={handleGoToBack} disabled={false} loading={false}>
              {t('back')}
            </SimpleButton>
            <SimpleButton onClick={handleGoToIdentification}>
              {t('continue')}
            </SimpleButton>
          </Grid>
        </Grid>
      )}
    </DividedContainer>
  );
};

export default Checkout;
