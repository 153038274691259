import { ChangeEvent, useEffect } from 'react';
import { useSearchParams, useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import { Grid, Container, Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMyPublications from './useMyPublications';
import PublicationCard from '../../components/cards/publication-card/PublicationCard';
import { PublicationEntity } from '../../entities';
import MainContainer from '../../components/containers/main-container/MainContainer';
import NavigationBar from '../../components/navigations/navigation-bar/NavigationBar';
import BackButton from '../../components/buttons/back-button/BackButton';
import { SkeletonText } from '../../components/typographies/title/Title.styled';
import getPublicationIdentifier from '../../utils/getPublicationIdentifier';
import { SkeletonPublicationCard } from '../../components/cards/publication-card/PublicationCard.styled';
import PageTitle from '../../components/typographies/PageTitle';
import PageSubtitle from '../../components/typographies/PageSubtitle';
import useAnalytics from '../../hooks/useAnalytics/useAnalytics';

const MyPublications = () => {
  const { t } = useTranslation(['my-publications']);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { traceBtnClick } = useAnalytics();
  const { fetchPublications, data, count, status, page, totalPages } = useMyPublications();

  const pageParam: string | null = searchParams.get('page');
  const stateParam: string | string[] | null = searchParams.get('state');

  const location = useLocation();
  useEffect(() => {
    return () => {
      sessionStorage.setItem('from', location.pathname);
    };
  }, []);
  
  useEffect(() => {
    fetchPublications({ page: pageParam, state: stateParam });
  }, [pageParam, stateParam]);

  const handleClickPage = (event: ChangeEvent<unknown>, eventPage: number) => {
    const params = {
      ...(stateParam && { state: stateParam }),
      ...(eventPage && { page: `${eventPage}` }),
    };
    traceBtnClick({
      step: 'step cambiar página',
      product: eventPage.toString(),
    });
    navigate(`/my-publications?${params && createSearchParams(params)}`);
  };

  const handleClickPublication = (publication: PublicationEntity) => {
    traceBtnClick({
      step: 'step ver detalle de publicación',
      product: getPublicationIdentifier(publication),
    });
    navigate(`/my-publication/${getPublicationIdentifier(publication)}`);
  };

  return (
    <MainContainer>
      <NavigationBar variant="variant2" />
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle>
              {status === 'loading' ? <SkeletonText /> : t(stateParam === 'draft' ? 'drafts.title' : 'title')}
            </PageTitle>
          </Grid>
          <Grid item xs={12}>
            <PageSubtitle>
              <>
                {status === 'loading' && <SkeletonText />}
                {status === 'loaded' && `${count || '0'} ${t('results')}`}
                {status === 'error' && `${t('error')}`}
              </>
            </PageSubtitle>
          </Grid>
        </Grid>

        {status === 'loading' && (
          <Grid container direction="row" spacing={4} paddingTop={4}>
            {[...Array(4)].map((_, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <SkeletonPublicationCard />
              </Grid>
            ))}
          </Grid>
        )}

        {status !== 'loading' && data && data?.length > 0 && (
          <Grid container direction="row" spacing={2} paddingTop={4}>
            {data?.map((publication: PublicationEntity) => (
              <Grid key={publication.id} item xs={12} sm={6} md={3}>
                <PublicationCard
                  publication={{
                    ...publication,
                    state: publication.state !== 'draft' ? publication.state : undefined,
                  }}
                  onClick={() => handleClickPublication(publication)}
                  header={!publication?.hasQuotaAvailable ? 'Sin cupos disponibles' : undefined}
                />
              </Grid>
            ))}
            <Grid item xs={12} display="flex" justifyContent="center" marginTop={1} marginBottom={2}>
              {page && (
                <Pagination
                  count={totalPages}
                  page={page}
                  color="primary"
                  onChange={handleClickPage}
                />
              )}
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item>
            <BackButton 
              disabled={status === 'loading'} 
              onClick={() => { 
                navigate('/my-account/guru'); 
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default MyPublications;
