import * as validator from 'yup';
import i18n from 'i18next';

const stepDescripcionSchema = validator.object({
  title: validator
    .string()
    .required(i18n.t('schemas:title:required'))
    .max(200, i18n.t('schemas:title:max')),
  description: validator
    .string()
    .required(i18n.t('schemas:description:required'))
    .max(2000, i18n.t('schemas:description:max')),
  detail: validator
    .string()
    .required(i18n.t('schemas:detail:required'))
    .max(2000, i18n.t('schemas:detail:max')),
  material: validator
    .string()
    .max(1000, i18n.t('schemas:material:max')),
});

export default stepDescripcionSchema;