import { useEffect } from 'react';
import { FormHelperText, Grid, LinearProgress, RadioGroup } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SimpleError from '../../../../components/errors/simple-error/SimpleError';
import { NavigationBottom } from '../../../../components/navigations';
import useDetails from '../useDetails';
import { formSchema } from './StepAgenda.schema';
import useDialog from '../../../../hooks/useDialog';
import SimpleDialog from '../../../../components/dialogs/SimpleDialog/SimpleDialog';
import getPublicationId from '../../../../utils/getPublicationId';
import useMyPublication from '../../useMyPublication';
import { RadioLabel } from '../../../../components/inputs';
import PageSubtitle from '../../../../components/typographies/PageSubtitle';
import Paragraph from '../../../../components/typographies/Paragraph';

const StepAgenda = () => {
  const { t } = useTranslation('my-publication', { keyPrefix: 'details.step-agenda' });
  const navigate = useNavigate();
  const location = useLocation();
  const { identifier } = useParams();
  const id = getPublicationId(identifier as string);

  const { fetchAuthPublication, status: statusFetch, error: errorFetch, data: publication } = useMyPublication();
  const { updateAuthPublication, status: statusUpdate, error: errorUpdate } = useDetails();
  const { dialog, onCloseDialog } = useDialog(errorUpdate, 'Error');

  const onContinue = () => {
    navigate(`/my-publication/${identifier}/details/step-horarios`);
  };

	type InitialValuesType = {
	  [key: string]: any,
	};
	const initialValues: InitialValuesType = {
	  agenda: publication?.agenda || undefined,
	};

	const form = useFormik({
	  enableReinitialize: true,
	  initialValues: initialValues,
	  validationSchema: formSchema,
	  validateOnChange: true,
	  onSubmit: async (values) => {
	    if (values.agenda === publication?.agenda) {
	      onContinue();
	    } else {
	      updateAuthPublication(id, values);
	    }
	  },
	});

	useEffect(() => {
	  if (id && statusFetch === 'idle') {
	    const attributes = [
	      'id',
	      'type',
	      'state',
	      'agenda',
	      'groups',
	      // 'groups.checkoutItems',
  			// 'groups.checkoutItems.checkout',
	      'quotaSold',
	      'hasSold',
	    ];
	    fetchAuthPublication(id, attributes);
	  }
	}, [id]);

	useEffect(() => {
	  return () => {
	    sessionStorage.setItem('from', location.pathname);
	  };
	}, []);

	useEffect(() => {
	  if (statusUpdate !== 'loaded') {
	    return;
	  }
	  if (statusUpdate === 'loaded' && !errorUpdate) {
	    onContinue();
	  }
	}, [statusUpdate]);

	const options = ['scheduled', 'flexible'];
	const handleRadioCheck = (event: any) => {
	  form.setFieldValue('agenda', event.target.value);
	};

	if (statusFetch === 'loading') {
	  return <LinearProgress />;
	}
	const hasGroups = publication?.groups && publication?.groups?.length > 0;
	let subtitle = '';
	if (hasGroups) {
	  if (publication?.hasSold) {
	    subtitle = 'Ya tienes ventas registradas por lo que no puedes cambiar la forma de agendamiento.';
	  } else {
	    subtitle = 'Ya tienes horarios cargados. Si decides cambiar la forma de agendamiento, los perderás.';
	  }
	}
	return (
	  <>
	    <SimpleDialog {...dialog} onClose={onCloseDialog} />
	    {(!id || errorFetch) && (
	      <SimpleError>{errorFetch?.message}</SimpleError>
	    )}
				
	    {!!publication && (
	      <>
	        <Grid container direction="column" spacing={1}>
	          <Grid item xs={12}>
	            <PageSubtitle>
	              {t(`subtitle.${publication?.type}`, '') || t('subtitle.class')}
	            </PageSubtitle>
	          </Grid>
	          {!!subtitle && (
	            <Grid item xs={12}>
	              <Paragraph fontWeight={500}>
	                {subtitle}
	              </Paragraph>
	            </Grid>
	          )}
	          <Grid item xs={12} mt={1}>
	            <RadioGroup onChange={handleRadioCheck}>
	              {options.map((option) => (
	                <RadioLabel
	                  key={option}
	                  checked={form.values.agenda === option}
	                  value={option}
	                  title={`${t(`form.options.${option}.title`)} ${hasGroups && option === publication?.agenda ? '(actual)' : ''}`}
	                  description={t(`form.options.${option}.description.${publication?.type}`)}
	                  disabled={statusUpdate === 'loading' || publication?.hasSold}
	                  style={{ marginBottom: 16 }}
	                />
	              ))}
	            </RadioGroup>
	            <FormHelperText variant="outlined" error={form.touched.agenda && Boolean(form.errors.agenda)}>
	              {form.touched.agenda && form.errors.agenda as string}
	            </FormHelperText>
	          </Grid>
	        </Grid>
	        <NavigationBottom 
	          handleClickSave={() => form.handleSubmit()}
	          handleClickPrevious={()=> {
	            const previousPageIdentifier = `/my-publication/${identifier}/details/step-detalles`;
	            const previousPageId = `/my-publication/${id}/details/step-detalles`;
	            const from = sessionStorage.getItem('from');
	            return (from === previousPageIdentifier || from === previousPageId) ? navigate(-1) : navigate(previousPageIdentifier);
	          }}
	          disabled={statusUpdate === 'loading'}
	          saveProps={{ loading: statusUpdate === 'loading' }}
	        />
	      </>
	    )}
	  </>
	);
};

export default StepAgenda;
