import { useState } from 'react';
import { AgerangeEntity } from '../../../../../../entities';
import { getAgeranges } from '../../../../../../services/ageranges';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: 'generic';
  ageranges: AgerangeEntity[];
}

const useAgeranges = () => {
  const [state, setState] = useState<StateProps>({
    status: 'idle',
    ageranges: [],
  });

  const fetchAgeranges = async () => {
    setState({ status: 'loading', ageranges: [] });
    try {
      const response = await getAgeranges();
      setState({
        status: 'loaded',
        ageranges: response?.data,
      });
    } catch (error: any) {
      setState({ status: 'loaded', ageranges: [], error: error?.code || 'generic' });
    }
  };

  return {
    ...state,
    fetchAgeranges,
  };
};

export default useAgeranges;