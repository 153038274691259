import { useState } from 'react';
import { PublicationEntity } from '../../../../entities';
import { getAdminPublication } from '../../../../services/adminPublication';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
  data?: PublicationEntity;
}

const useAdminPublication = () => {
  const initialState: StateProps = { 
    status: 'idle', 
    error: null, 
    data: undefined,
  };
  const [state, setState] = useState<StateProps>(initialState);

  const fetchAdminPublication = async (id: number, attributes?: string[]) => {
    setState({ ...initialState, status: 'loading' });
    try {
      const publication = await getAdminPublication(id, attributes);
      setState({ 
        ...initialState,
        data: publication,
        status: 'loaded',
      });
    } catch (error) {
      setState({ status: 'loaded', error });
    }
  };

  return {
    ...state,
    fetchAdminPublication,
  };
};

export default useAdminPublication;