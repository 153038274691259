import { forwardRef, Fragment, ReactNode, useState } from 'react';
import {  Paper, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, IconButton, Collapse, Box, Grid } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { UserEntity } from '../../../../../../entities';
import { dayjs } from '../../../../../../configs/dayjs';
import { useTranslation } from 'react-i18next';

interface UserTableProps {
  data?: UserEntity[];
  status?: 'idle' | 'loading' | 'loaded';
}

const ContentRow = (props: { title?: string, children?: ReactNode }) => (
  <>
    <Grid item fontWeight="bold" xs={6}>
      {props?.title}
    </Grid>
    <Grid item xs={6}>
      {props?.children}
    </Grid>
  </>
);

const Row = (props: { row: UserEntity }) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(['my-account']);
  const accountType = row.paymentAccount?.accountType;

  const accountTypeLabel = t(`personal.contact.paymentAccount.accountTypes.${accountType}`, accountType);
  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} >{row.email} {row.emailVerified ? '✅' : '❌' }</TableCell>
        <TableCell component="th" scope="row">{row.displayName}</TableCell>
        <TableCell component="th" scope="row">
          <Avatar
            alt={row?.displayName}
            src={row?.photoUrl}
            sx={{ width: 40, height: 40 }}
          />
        </TableCell>
        <TableCell >{dayjs(row.createdAt).format('DD/MM/YYYY')}</TableCell>
        <TableCell >{dayjs(row.updatedAt).format('DD/MM/YYYY')}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Grid container direction="column" spacing={1}>
                <ContentRow title={'ID'}>
                  {row.id}
                </ContentRow>
                <ContentRow title={'Nombre'}>
                  {row.name || '-'}
                </ContentRow>
                <ContentRow title={'Apellido'}>
                  {row.lastname || '-'}
                </ContentRow>
                <ContentRow title={'Descripción'}>
                  {row.description || '-'}
                </ContentRow>
                <ContentRow title={'Fecha de nacimiento'}>
                  {row.birthdate ? dayjs(row.birthdate).format('DD/MM/YYYY') : '-'}
                </ContentRow>
                <ContentRow title={'Teléfono'}>
                  {row.phoneNumber || '-'}
                </ContentRow>
                <ContentRow title={'RUT'}>
                  {row.identificationValue || '-'}
                </ContentRow>

                <ContentRow title={'Cuenta corriente:'} />
                <ContentRow title={'Nombre'}>
                  {row.paymentAccount?.name || '-'}
                </ContentRow>
                <ContentRow title={'RUT'}>
                  {row.paymentAccount?.rut || '-'}
                </ContentRow>
                <ContentRow title={'Banco'}>
                  {row.paymentAccount?.accountBank?.name || '-'}
                </ContentRow>
                <ContentRow title={'Tipo de cuenta'}>
                  {accountTypeLabel}
                </ContentRow>
                <ContentRow title={'Número de cuenta'}>
                  {row.paymentAccount?.accountNumber || '-'}
                </ContentRow>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const UserTable = forwardRef<HTMLDivElement, UserTableProps>(
  ({ data, status, ...rest }, ref) => {
    return (
      <>
        <TableContainer component={Paper} ref={ref} {...rest}>
          <Table aria-label="collapsible table" size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Email</TableCell>
                <TableCell>Nombre a mostrar</TableCell>
                <TableCell>Foto</TableCell>
                <TableCell>Fecha creación</TableCell>
                <TableCell>Fecha última modificación</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status === 'loaded' && (
                data?.map((row: UserEntity) => (
                  <Row key={row.id} row={row} />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {status === 'loading' && <LinearProgress />}
      </>
    );
  },
);

UserTable.displayName = 'UserTable';
export default UserTable;
