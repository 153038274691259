import { CircularProgress, Grid } from '@mui/material';
import { Label } from '../../../../components/typographies';
import NavigationBar from '../../../../components/navigations/navigation-bar/NavigationBar';
import MainContainer from '../../../../components/containers/main-container/MainContainer';
import { SimpleButton } from '../../../../components/buttons';
import useAdminPublicationsOrder from './useAdminPublicationsOrder';
import { useEffect } from 'react';
import PageTitle from '../../../../components/typographies/PageTitle';
import useAnalytics from '../../../../hooks/useAnalytics/useAnalytics';

const MyAccountAdminPublicationsOrder = () => {
  const { fetchAdminNextGroupUpdated, updateAdminNextGroupUpdated, status, countNotUpdated, error } = useAdminPublicationsOrder();
  const { traceBtnClick } = useAnalytics();
  
  useEffect(() => {
    traceBtnClick({
      step: 'step ejecutar actualización de orden de publicaciones desde menú de admin',
      product: 'Orden de Publicaciones',
    });
    fetchAdminNextGroupUpdated();
  }, []);
  return (
    <>
      <MainContainer>
        <NavigationBar variant="variant4" />
        <Grid container spacing={1} mb={1}>
          <Grid item xs={12}>
            <PageTitle>Administración / Orden de publicaciones</PageTitle>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            {status === 'loading' && (
              <CircularProgress />
            )}
            {status === 'loaded' && (
              <>
                <Label><>Cantidad de publicaciones no actualizada: {countNotUpdated}</></Label>
                {(!!countNotUpdated && countNotUpdated > 0) && (
                  <SimpleButton variant='text' onClick={updateAdminNextGroupUpdated}>Actualizar</SimpleButton>
                )}
              </>
            )}
            {!!error && (error)}
          </Grid>
        </Grid>
      </MainContainer>
    </>
  );
};

export default MyAccountAdminPublicationsOrder;
