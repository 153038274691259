import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SimpleDialog from '../../../components/dialogs/SimpleDialog/SimpleDialog';
import SimpleError from '../../../components/errors/simple-error/SimpleError';
import { NavigationBottom } from '../../../components/navigations';
import useDialog from '../../../hooks/useDialog';
import useNewPublication from '../useNewPublication';
import stepDescripcionSchema from '../../my-publication/details/step-descripcion/StepDescripcion.schema';
import { PublicationTypeEntity } from '../../../entities/PublicationEntity';
import TextFieldForm from '../../../components/inputs/text-field-form-v2/TextFieldForm';
import getPublicationIdentifier from '../../../utils/getPublicationIdentifier';
import AuthContext from '../../../contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { LinkButton, SimpleButton } from '../../../components/buttons';
import useCreateAccount from '../../create-account/useCreateAccount';
import PageTitle from '../../../components/typographies/PageTitle';
import PageSubtitle from '../../../components/typographies/PageSubtitle';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';

const NewPublicationType = () => {
  const { authUser, refreshAuthUser } = useContext(AuthContext);
  const { traceBtnClick } = useAnalytics();
  const { t } = useTranslation('my-publication', { keyPrefix: 'details.step-descripcion' });

  const navigate = useNavigate();
  const location = useLocation();

  const { type: typeParam } = useParams();
  const type: PublicationTypeEntity | undefined = typeParam === 'class' || typeParam === 'course' ? 
    typeParam as PublicationTypeEntity : undefined;

  const { createAuthPublication, status, error } = useNewPublication();
  const { dialog, onCloseDialog } = useDialog(error, 'Error');

  const form = useFormik({
    initialValues: {
      type,
      title: '',
      description: '',
      detail: '',
      material: undefined,
    },
    validationSchema: stepDescripcionSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const id = await createAuthPublication(values);
      if (!!id) {
        const identifier = getPublicationIdentifier({ id, title: values.title });
        const url = `/my-publication/${identifier}/details/step-descripcion`;
        navigate(url, { replace: true });
      }
    },
  });

  useEffect(() => {
    return () => {
      sessionStorage.setItem('from', location.pathname);
    };
  }, []);

  const [openDialogVerify, setOpenDialogVerify] = useState(false);
  const { sendEmailVerification } = useCreateAccount();
  
  const verifyEmail = () => {
    traceBtnClick({
      step: 'step reenviar correo electrónico de verificación',
      product:  t('resend') + t('resend2'),
    });
    sendEmailVerification();
    setOpenDialogVerify(true);
  };
  
  const handleCloseDialogVerify = () => {
    setOpenDialogVerify(false);
  };

  useEffect(() => {
    const handleFocus = () => {
      if (!authUser?.data?.emailVerified) refreshAuthUser?.();
    };
    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  if (!type) {
    return <SimpleError />;
  }
  
  return (
    <>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      <SimpleDialog 
        open={openDialogVerify} 
        title='Correo electrónico reenviado' 
        onClose={handleCloseDialogVerify} 
        actions={<SimpleButton onClick={handleCloseDialogVerify}>Cerrar</SimpleButton>}
      />
      {authUser?.data?.emailVerified ? <>
        <Grid container direction="column" spacing={1}>
          <TextFieldForm
            title={t(`form.title.describe.${type}`, '') || t('form.title.describe.class')}
            name="title"
            form={form}
            label={t('form.title.label') as string}
            loading={status === 'loading'}
            inputProps={{ maxLength: 200 }}
          />
          <TextFieldForm
            title={t(`form.description.describe.${type}`, '') || t('form.description.describe.class')}
            name="description"
            form={form}
            label={t('form.description.label') as string}
            loading={status === 'loading'}
            inputProps={{ maxLength: 2000 }}
            multiline
            rows={4}
          />
          <TextFieldForm
            title={t(`form.detail.describe.${type}`, '') || t('form.detail.describe.class')}
            name="detail"
            form={form}
            label={t(`form.detail.label.${type}`, '') as string || t('form.detail.label.class') as string}
            loading={status === 'loading'}
            inputProps={{ maxLength: 2000 }}
            multiline
            rows={3}
          />
          <TextFieldForm
            title={t(`form.material.describe.${type}`, '') || t('form.material.describe.class')}
            name="material"
            form={form}
            label={t('form.material.label') as string}
            loading={status === 'loading'}
            inputProps={{ maxLength: 1000 }}
            multiline
            rows={2}
          />
        </Grid>
        <NavigationBottom 
          handleClickSave={() => form.handleSubmit()}
          handleClickPrevious={()=> {
            const previousPage = '/new-publication';
            const from = sessionStorage.getItem('from');
            return (from === previousPage) ? navigate(-1) : navigate(previousPage);
          }}
          disabled={status === 'loading'}
          saveProps={{ loading: status === 'loading' }}
        />
      </> : <>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <PageTitle>{t('title.unverified')}</PageTitle>
          </Grid>
          <Grid item>
            <PageSubtitle>{t('subtitle.unverified')}</PageSubtitle>
          </Grid>
          <Grid item>
            <LinkButton
              onClick={verifyEmail}
              texts={[
                t('resend'),
                t('resend2'),
              ]}
            />
          </Grid>
        </Grid>
      </>}
    </>
  );
};

export default NewPublicationType;
