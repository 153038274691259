import { forwardRef, ReactElement } from 'react';
import { ButtonProps, CircularProgress } from '@mui/material';
import { Button } from '@mui/material';
import getTruncated from '../../../utils/getTruncated';

interface SimpleButtonProps extends ButtonProps {
  children: string | ReactElement;
  maxLength?: number;
  loading?: boolean;
  size?: 'small' | 'medium' | 'large' | undefined;
}

const SimpleButton = forwardRef<HTMLButtonElement, SimpleButtonProps>(
  ({
    children, maxLength = 99, size = 'large', loading = false, ...rest
  }, ref) => (
    <Button
      variant="contained"
      size={size}
      ref={ref}
      {...loading && { endIcon: <CircularProgress size={16} /> }}
      {...rest}
    >
      {typeof children === 'string' ? getTruncated(children, maxLength) : children}
    </Button>
  ),
);

SimpleButton.displayName = 'SimpleButton';
export default SimpleButton;
