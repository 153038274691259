import styled from '@emotion/styled';
import { Typography, ButtonBase } from '@mui/material';

export const StyledGoogleButton = styled(ButtonBase)`
  background-color: #4385F5;
  width: 100%;
  :disabled,[disabled]{
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
`;

export const StyledImg = styled.img`
  background-color: #FFF;
  padding: 8px 16px;
  margin: 4px;
`;

export const StyledContainerText = styled.div`
  justify-content: center;
  width: 100%;
`;

export const StyledText = styled(Typography)`
  color: #FFF;
`;
