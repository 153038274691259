import { useState, useContext } from 'react';
import { getGroup as getGroupService } from '../services/group';
import CartContext from '../contexts/CartContext';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
}

const useCart = () => {

  const [state, setState] = useState<StateProps>({ status: 'idle', error: null });

  const { cart = [], setCart = () => {} } = useContext(CartContext);

  const syncCart = async () => {
    setState({ status: 'loading', error: null });
    const promises = cart.map(async (currentItem) => {
      try {
        const group = await getGroupService(currentItem.group?.id);
        return { ...currentItem, group };
      } catch (e: any) {
        return { ...currentItem, group: null };
      }
    });
    const newCart = await Promise.all(promises);
    setCart(newCart.filter((currentItem) => currentItem.group));
    setState({ status: 'loaded' });
  };

  const addQuantityGroup = async (id: number) => {
    setState({ status: 'loading', error: null });
    try {
      const currentIndex = cart.findIndex((item: any) => item.group.id === id);
      if (currentIndex >= 0) {
        const currentItem = cart[currentIndex];
        const [...temporalCart] = cart;
        temporalCart.splice(currentIndex, 1, {
          ...currentItem,
          quantity: currentItem.quantity + 1,
        });
        setCart(temporalCart);
      } else {
        const group = await getGroupService(id);
        setCart(
          [...cart, {
            group,
            quantity: 1,
          }],
        );
      }
      setState({ status: 'loaded' });
    } catch (error) {
      setState({ status: 'loaded', error });
    }
    
  };

  const deleteGroup = (id: number) => {
    setCart(cart.filter((item: any) => item.group.id !== id));
  };

  const removeQuantityGroup = (id: number) => {
    setState({ status: 'loading', error: null });
    const currentIndex = cart.findIndex((item: any) => item.group.id === id);
    const currentItem = cart[currentIndex];
    if (currentItem.quantity > 1) {
      const [...temporalCart] = cart;
      temporalCart.splice(currentIndex, 1, {
        ...currentItem,
        quantity: currentItem.quantity - 1,
      });
      setCart(temporalCart);
    } else {
      deleteGroup(id);
    }
    setState({ status: 'loaded' });
  };

  return {
    ...state,
    cart,
    setCart,
    syncCart,
    addQuantityGroup,
    removeQuantityGroup,
    deleteGroup,
  };
};

export default useCart;
