import {
  forwardRef, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../../contexts/AuthContext';
import GurappLogo from '../../logos/gurapp-logo/GurappLogo';
import getTruncated from '../../../utils/getTruncated';
import KebabMenu, { Option } from '../kebab-menu/KebabMenu';
import LoginButton from '../../buttons/login-button/LoginButton';
import ShoppingCart from '../../cart/shopping-cart/ShoppingCart';
import {
  StyledNavigationBar,
  LeftContainer,
  RightContainer,
  StyledButton,
  StyledButtonToExplore,
} from './NavigationBar.styled';
import FlagContext from '../../../contexts/FlagContext';
import AdminMenu from '../../../pages/my-account/admin/AdminMenu';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';

interface NavigationBarProps {
  variant?: 'variant1' | 'variant1white' | 'variant2' | 'variant2white' | 'variant3' | 'variant4';
  isFloating?: boolean;
}

const NavigationBar = forwardRef<HTMLDivElement, NavigationBarProps>(
  ({ variant = 'variant1', ...rest }, ref) => {
    const { publicationsAvailable } = useContext(FlagContext);
    const { traceBtnClick } = useAnalytics();
    const variants = {
      variant1: {
        showLogo: true,
        showProfile: false,
        showBeGuru: false,
        showToExplore: false,
        color: undefined,
        showShoppingCart: false,
      },
      variant1white: {
        showLogo: true,
        showProfile: false,
        showBeGuru: false,
        showToExplore: false,
        color: 'white',
        showShoppingCart: false,
      },
      variant2: {
        showLogo: true,
        showProfile: true,
        showBeGuru: true,
        showToExplore: false,
        color: undefined,
        showShoppingCart: publicationsAvailable,
      },
      variant2white: {
        showLogo: true,
        showProfile: true,
        showBeGuru: true,
        showToExplore: false,
        color: 'white',
        showShoppingCart: publicationsAvailable,
      },
      variant3: {
        showLogo: true,
        showProfile: false,
        showBeGuru: false,
        showToExplore: true,
        color: undefined,
        showShoppingCart: false,
      },
      variant4: {
        showLogo: true,
        showProfile: true,
        showBeGuru: false,
        showToExplore: false,
        color: undefined,
        showShoppingCart: false,
      },
    };

    const { t } = useTranslation(['components']);
    const { authUser, isLogged } = useContext(AuthContext);
    const [optionsMobile, setOptionsMobile] = useState<Option[]>([]);
    const [optionsDesktop, setOptionsDesktop] = useState<any[]>([]);
    const [optionsAll, setOptionsAll] = useState<any[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(() => {
      const newOptionsMobile = [];
      const newOptionsDesktop = [];
      const newOptionsAll = [];

      if (variants[variant]?.showProfile) {
        if (isLogged) {
          newOptionsMobile.push({
            children: t('navigation-bar.my-profile'),
            onClick: () => {
              traceBtnClick({
                step: 'step ir a mi perfil desde navigation bar mobile',
                product: t('navigation-bar.my-profile'),
              });
              navigate('/my-account');
            },
          });
        } else {
          newOptionsMobile.push({
            children: t('navigation-bar.login'),
            onClick: () => {
              traceBtnClick({
                step: 'step ir a login desde navigation bar mobile',
                product: t('navigation-bar.login'),
              });
              navigate('/login');
            },
          });
        }
        newOptionsDesktop.push(
          <LoginButton
            key="login"
            color={variants[variant]?.color === 'white' ? undefined : 'primary'}
            style={variants[variant]?.color === 'white' && !isLogged ? { color: 'white' } : undefined}
          />
          ,
        );
      }
      if (variants[variant].showBeGuru) {
        newOptionsMobile.push({
          children: getTruncated(t('navigation-bar.be-guru'), 12),
          onClick: () => {
            traceBtnClick({
              step: 'step ir a ser gurú desde navigation bar mobile',
              product: getTruncated(t('navigation-bar.be-guru'), 12),
            });
            navigate('/be-guru');
          },
        });
        newOptionsDesktop.push(
          <StyledButton 
            key="beGuru" 
            onClick={() => { 
              traceBtnClick({
                step: 'step ir a ser gurú desde navigation bar desktop',
                product: t('navigation-bar.be-guru'),
              });
              navigate('/be-guru'); 
            }}
          >
            {t('navigation-bar.be-guru')}
          </StyledButton>,
        );
      }
      if (variants[variant].showToExplore) {
        newOptionsMobile.push({
          children: t('navigation-bar.toExplore'),
          onClick: () => {
            traceBtnClick({
              step: 'step ir a explorar desde navigation bar mobile',
              product: t('navigation-bar.toExplore'),
            });
            navigate('/');
          },
        });
        newOptionsDesktop.push(
          <StyledButtonToExplore 
            key="explore" 
            onClick={() => {
              traceBtnClick({
                step: 'step ir a explorar desde navigation bar desktop',
                product: t('navigation-bar.toExplore'),
              });
              navigate('/');
            }}
            startIcon={<ChevronLeftIcon />}
          >
            {t('navigation-bar.toExplore')}
          </StyledButtonToExplore>,
        );
      }
      if (variants[variant].showShoppingCart) {
        newOptionsAll.push(<ShoppingCart
          key="cart"
          style={{ color: variants[variant]?.color }}
        />);
      }
      if (isLogged && authUser?.data?.isAdmin && location.pathname.includes('/my-account/admin')) {
        newOptionsAll.push(<AdminMenu key="admin" />);
      }
      setOptionsMobile(newOptionsMobile);
      setOptionsDesktop(newOptionsDesktop);
      setOptionsAll(newOptionsAll);
    }, [authUser, isLogged, variant, t, navigate]);

    const theme = useTheme();
    const isdownsm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <StyledNavigationBar ref={ref} {...rest}>
        <LeftContainer>
          {variants[variant]?.showLogo && (
            <GurappLogo {...variants[variant]?.color === 'white' && { variant: 'white' }} />
          )}
        </LeftContainer>
        <RightContainer>
          {(!isdownsm && optionsDesktop.length > 0) && optionsDesktop}
          {(optionsAll.length > 0) && optionsAll}
          {(isdownsm && optionsMobile.length > 0) && (
            <KebabMenu
              style={{ color: variants[variant]?.color }}
              options={optionsMobile}
            />
          )}
        </RightContainer>
      </StyledNavigationBar>
    );
  },
);

NavigationBar.displayName = 'NavigationBar';
export default NavigationBar;
