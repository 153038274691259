import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import useAnalytics from '../../hooks/useAnalytics/useAnalytics';

const PageViewTracker = () => {
  const location = useLocation();
  // const { traceVirtualPage } = useAnalytics();

  useEffect(() => {
    // traceVirtualPage({
    //   product: 'GurApp',
    // });
  }, [location]);

  return null;
};

export default PageViewTracker;