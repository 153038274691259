import { Grid, TextField, FormControl } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formLoginSchema } from './FormLogin.schema';
import FormDialogForgotPassword from '../form-dialog-forgot-password/FormDialogForgotPassword';
import { Subtitle } from '../../../../components/typographies';
import { SimpleButton, LinkButton, GoogleButton } from '../../../../components/buttons';
import useDialog from '../../../../hooks/useDialog';
import SimpleDialog from '../../../../components/dialogs/SimpleDialog/SimpleDialog';
import useLogin from '../../useLogin';
import PageTitle from '../../../../components/typographies/PageTitle';
import useAnalytics from '../../../../hooks/useAnalytics/useAnalytics';

const FormLogin = () => {
  const { t } = useTranslation(['login']);
  const navigate = useNavigate();
  const {
    signInWithGoogle, signInWithEmailAndPassword, status, error,
  } = useLogin();
  const { dialog, onCloseDialog } = useDialog(error, 'Error');
  const { traceBtnClick } = useAnalytics();

  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: formLoginSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      await signInWithEmailAndPassword(values.email, values.password);
    },
  });

  const onClickButtonGoogle = async () => {
    traceBtnClick({
      step: 'step continuar con Google',
      product: 'continuar con Google',
    });
    await signInWithGoogle();
  };

  const onClickContinue = async () => {
    traceBtnClick({
      step: 'step continuar login',
      product: t('formLogin.continue'),
    });
    form.handleSubmit();
  };

  const onClickGoToRegister = async () => {
    traceBtnClick({
      step: 'step ir a registro',
      product: t('formLogin.createAccount') + t('formLogin.createAccount2'),
    });
    navigate('/create-account');
  };

  return (
    <>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      <form>
        <Grid container direction="column" spacing={1}>
          <Grid item xs={12}>
            <PageTitle>{t('formLogin.title')}</PageTitle>
          </Grid>
          <Grid item xs={12}>
            <GoogleButton onClick={onClickButtonGoogle} disabled={status === 'loading'} />
          </Grid>
          <Grid item xs={12}>
            <Subtitle variant="variant3">{t('formLogin.alternative')}</Subtitle>
          </Grid>
          <Grid item xs={12}>
            <FormControl margin="dense" fullWidth>
              <TextField
                name="email"
                label={t('formLogin.emailLabel') as string}
                variant="outlined"
                type="email"
                fullWidth
                value={form.values.email}
                onChange={form.handleChange}
                error={form.touched.email && Boolean(form.errors.email)}
                helperText={form.touched.email && form.errors.email}
                disabled={status === 'loading'}
                autoFocus
                inputProps={{ maxLength: 100 }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl margin="dense" fullWidth>
              <TextField
                name="password"
                label={t('formLogin.passwordLabel') as string}
                variant="outlined"
                type="password"
                fullWidth
                value={form.values.password}
                onChange={form.handleChange}
                error={form.touched.password && Boolean(form.errors.password)}
                helperText={form.touched.password && form.errors.password}
                disabled={status === 'loading'}
                inputProps={{ maxLength: 100 }}
              />
            </FormControl>
          </Grid>
          <Grid item alignSelf="center">
            <FormDialogForgotPassword email={form.values.email} />
          </Grid>
        </Grid>
      </form>
      <Grid
        container
        direction="row-reverse"
        paddingTop={3}
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item paddingBottom={2}>
          <SimpleButton 
            onClick={onClickContinue} 
            disabled={status === 'loading'} 
            loading={status === 'loading'}
          >
            {t('formLogin.continue')}
          </SimpleButton>
        </Grid>
        <Grid item paddingBottom={2}>
          <LinkButton
            onClick={onClickGoToRegister} 
            texts={[
              t('formLogin.createAccount'),
              t('formLogin.createAccount2'),
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
};

FormLogin.displayName = 'FormLogin';
export default FormLogin;
