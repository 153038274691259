import { forwardRef } from 'react';
import { StyledSimpleButton } from './SaveButton.styled';
import { Save as SaveIcon } from '@mui/icons-material';

interface SaveButtonProps {
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  style?: any;
}

const SaveButton = forwardRef<HTMLButtonElement, SaveButtonProps>(
  (props, ref) => (
    <StyledSimpleButton 
      variant="text"
      size="small"
      color="secondary"
      startIcon={<SaveIcon />}
      ref={ref}
      {...props} 
    >
      Guardar
    </StyledSimpleButton>
  ),
);

SaveButton.displayName = 'SaveButton';
export default SaveButton;
