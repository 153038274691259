import { useContext, ReactElement } from 'react';
import { LinearProgress } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';
import AuthContext from '../../../contexts/AuthContext';

interface AuthAccessProps {
  children: ReactElement;
}

const AuthAccess = ({ children }: AuthAccessProps) => {
  const { isLogged, isInitializing } = useContext(AuthContext);
  const location = useLocation();

  if (!isInitializing) {
    return (!isLogged ? <Navigate to="/login" state={{ from: location }} replace /> : children);
  }
  return (<LinearProgress />);
};

AuthAccess.displayName = 'AuthAccess';
export default AuthAccess;
