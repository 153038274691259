import { forwardRef } from 'react';
import { Link } from '@mui/material';
import { StyledImg } from './GurappLogo.styled';
import gurappColor from './assets/gurapp_color.svg';
import gurappWhite from './assets/gurapp_white.svg';
import gurappWhite2 from './assets/gurapp_white_2.webp';

interface GurappLogoProps {
  variant?: 'white' | 'color' | 'white2';
}
const GurappLogo = forwardRef<HTMLImageElement, GurappLogoProps>(
  ({ variant = 'color', ...rest }, ref) => {
    const variants = {
      white: {
        image: gurappWhite,
        width: undefined,
        height: undefined,
      },
      color: {
        image: gurappColor,
        width: undefined,
        height: undefined,
      },
      white2: {
        image: gurappWhite2,
        width: 208,
        height: 50,
      },
    };

    return (
      <Link 
        underline="none" 
        color="inherit" 
        href='/'
      >
        <StyledImg
          src={variants[variant].image}
          alt="GurApp"
          ref={ref}
          width={variants[variant].width}
          {...rest}
        />
      </Link>
    );
  },
);

GurappLogo.displayName = 'GurappLogo';
export default GurappLogo;
