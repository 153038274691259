import { useState } from 'react';
import { Grid } from '@mui/material';
import { useFormik, getIn } from 'formik';
import SimpleChip from '../../../../../components/chips/simple-chip/SimpleChip';
import SimpleModal from '../../../../../components/dialogs/SimpleModal/SimpleModal';
import { EditListItem } from '../../../../../components/inputs';
import { LinkButton, SimpleButton } from '../../../../../components/buttons';
import { GroupEntity, PublicationEntity, SessionEntity } from '../../../../../entities';
import { getMapGroup, isIdTemporal } from '../StepHorarios.utils';
import DeleteDialog from '../components/delete-dialog/DeleteDialog';
import useDetails from '../../useDetails';
import useDialog from '../../../../../hooks/useDialog';
import SimpleDialog from '../../../../../components/dialogs/SimpleDialog/SimpleDialog';
import PeriodicityNo from './PeriodicityNo';
import { groupCourseScheduledSchema } from '../StepHorarios.schema';
import useAnalytics from '../../../../../hooks/useAnalytics/useAnalytics';
import TextFieldForm from '../../../../../components/inputs/text-field-form-v2/TextFieldForm';

interface GroupProps {
  form: any;
  disabled?: boolean;
  publication: PublicationEntity;
  group: GroupEntity;
}

const Group = ({ form, disabled, publication, group }: GroupProps) => {
  const { traceBtnClick } = useAnalytics();
  const [open, setOpen] = useState(false);
  const index = form.values.groups.findIndex((item: GroupEntity) => item.id === group.id);
  const { removeAuthGroup, error: errorRemove } = useDetails();
  const { updateAuthGroups, error: errorUpdate, status: statusUpdate } = useDetails();
  // const { dialog, onCloseDialog } = useDialog(errorRemove || errorUpdate || error, 'Error');
  const { dialog, onCloseDialog } = useDialog(errorRemove || errorUpdate, 'Error');
  const [deleteDialog, setDeleteDialog] = useState({});
  const onCloseDeleteDialog = () => { setDeleteDialog({ ...deleteDialog, open: false }); };

  const name = `groups[${index}]`;

  const localForm = useFormik({
    enableReinitialize: false,
    initialValues: {
      quota: group.quota,
      minQuota: group.quotaSold || 0,
      sessions: group.sessions || [],
      // periodicity: group.periodicity || 'no',
      // weeklySince: group.weekly?.since ? dayjs(group.weekly?.since) : null,
      // weeklyUntil: group.weekly?.until ? dayjs(group.weekly?.until) : null,
      // week: initialWeek,
      // removeDates: getInitialRemoveDates(group.weekly?.week),
    },
    validationSchema: groupCourseScheduledSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      let groups: GroupEntity[] = [];
      // if (values.periodicity === 'weekly' && values.weeklySince && values.weeklyUntil) {
      //   const weekAvailable = values.week?.filter((item: DayOfWeek) => 
      //     item.times && item.times?.length > 0 && item.times?.every((time: any) => time.startTime && time.endTime));
      //   const dates = getDatesBetween({ 
      //     since: values.weeklySince, 
      //     until: values.weeklyUntil, 
      //     weekAvailable,
      //     removes: values.removeDates,
      //   });
      //   if (dates?.length === 0) {
      //     setError({ message: 'No se encontraron fechas disponibles' });
      //     return;
      //   }
      //   groups = getMapGroup([{
      //     id: group.id || 'temporal',
      //     quota: values.quota,
      //     periodicity: values.periodicity,
      //     weekly: { 
      //       since: values.weeklySince,
      //       until: values.weeklyUntil,
      //       week: getPeriodicityWeek(weekAvailable, values.removeDates),
      //     },
      //     sessions: dates.map((date: any) => ({
      //       id: 'temporal',
      //       date: date.fecha,
      //       startTime: date.startTime,
      //       endTime: date.endTime,
      //     })),
      //   }]);
      // }
      // if (values.periodicity === 'no') {
      groups = getMapGroup([{
        id: group.id,
        quota: values.quota,
        agenda: group.agenda || publication.agenda,
        sessions: values.sessions,
      }]);
      const success = await updateAuthGroups(publication.id || 0, groups);
      if (!success) {
        return;
      }
      window.location.reload();
    },
  });

  const ContentQuota = () => {
    return (group.quota ? <SimpleChip label={`${group.quota.toString()} cupos`} /> : null);
  };

  const ContentSessions = () => {
    const sessions = group.sessions?.filter((session: SessionEntity) => session.date) || [];
    return (sessions?.length > 0 ? <SimpleChip label={`${sessions?.length.toString()} sesiones`} /> : null);
  };

  const deleteGroupForm = () => {
    const values = [...form.values.groups];
    if (index > -1) {
      values.splice(index, 1);
    }
    form.setFieldValue('groups', values);
  };

  const handleDeleteGroup = () => {
    if (isIdTemporal(group?.id)) {
      deleteGroupForm();
      return;
    }
    setDeleteDialog({ open: true, value: group, groupHasSold: group?.hasSold });
  };

  const onContinueDelete = () => {
    removeAuthGroup(Number(group.id));
    deleteGroupForm();
    onCloseDeleteDialog();
  };

  const errorQuota = getIn(form.touched, `${name}.quota`) && Boolean(getIn(form.errors, `${name}.quota`));
  const errorSessions = getIn(form.touched, `${name}.sessions`) && Boolean(getIn(form.errors, `${name}.sessions`));
  const touchedQuota = getIn(form.touched, `${name}.quota`) && getIn(form.errors, `${name}.quota`);
  const touchedSessions = getIn(form.touched, `${name}.sessions`) && getIn(form.errors, `${name}.sessions`);
  const showDelete = !isIdTemporal(group?.id) || index !== 0;

  let subtitle = '';
  if (isIdTemporal(group?.id)) {
    subtitle = 'Ingresa el número de cupos. Además ingresa las distintas sesiones que tendrá este grupo.';
  } else {
    if (group?.hasSold) {
      subtitle = '¡Ya tienes ventas registradas! Sólo podrás editar los cupos no vendidos.';
    } else {
      subtitle = 'Puedes editar el cupo y las distintas sesiones.';
    }
  }
  let label = 'Grupo';
  if (!isIdTemporal(group?.id)) {
    label = `Grupo - Inicio ${group?.firstSession?.dateFormat}, ${group?.firstSession?.startTime} hrs.`;
  }
  return (
    <>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      <DeleteDialog {...deleteDialog} onClose={onCloseDeleteDialog} onContinue={onContinueDelete} />
      <SimpleModal
        open={open}
        onClose={() => { setOpen(false); }}
        title={'Grupo'}
        subtitle={subtitle}
      >
        <>
          {!isIdTemporal(group?.id) && publication?.state === 'approved' ? <LinkButton
            target='_blank'
            href={`/my-publication/${publication.id}/explorers`}
            rel="noopener"
            texts={['', 'Haz click aquí para ir a ver tus sesiones y exploradores']}
          /> : undefined}
          <Grid item mt={1}></Grid>
          <TextFieldForm 
            label='Cupos'
            name="quota"
            form={localForm}
            loading={statusUpdate === 'loading'}
            type="number"
            size='small'
            xs={6}
            helperText={(group?.quotaSold || 0) > 0 ? `¡Ya vendiste ${group.quotaSold} cupo${(group?.quotaSold || 0) > 1 ? 's' : ''}!` : ''}
          />

          {/*<Grid container spacing={0.5}>
             <Grid item xs={12} sm={6}>
              <FormControl margin="dense" fullWidth>
                <Select
                  size='small'
                  name="periodicity"
                  label={'Recurrencia'}
                  placeholder='Selecciona'
                  variant="outlined"
                  value={localForm.values.periodicity}
                  onChange={(event: any) => {
                    if (event.target.value === 'no') {
                      localForm.setFieldValue('sessions', group.sessions || []);
                      localForm.setFieldValue('week', []);
                    }
                    if (event.target.value === 'weekly') {
                      localForm.setFieldValue('sessions', []);
                      localForm.setFieldValue('week', initialWeek);
                    }
                    localForm.setFieldValue('periodicity', event.target.value);
                  }}
                  error={localForm.touched.periodicity && Boolean(localForm.errors.periodicity)}
                  helperText={localForm.touched.periodicity && localForm.errors.periodicity as string}
                  disabled={!isIdTemporal(group?.id) || statusUpdate === 'loading'}
                  fullWidth
                >
                  {periodicities?.map((periodicity) => (
                    <MenuItem value={periodicity.value} key={periodicity.value}>
                      <Typography style={{ fontSize: '0.875rem' }}>{periodicity.label}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
          {/* <Grid item xs={0} sm={6}></Grid>
            {localForm.values.periodicity === 'weekly' && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <DateInput
                      disablePast
                      shouldDisableDate={disableToday}
                      label={'Desde (fecha de inicio)'}
                      value={localForm.values.weeklySince}
                      onChange={(value) => {
                        localForm.setFieldValue('weeklySince', value);
                      }}
                      disabled={!!group?.quotaSold || statusUpdate === 'loading'}
                      size='small'
                      fullWidth
                      name="weeklySince"
                      error={localForm.touched.weeklySince && Boolean(localForm.errors.weeklySince)}
                      helperText={localForm.touched.weeklySince && localForm.errors.weeklySince as string}
                      renderInput={() => <></>} 
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <DateInput
                      disablePast
                      shouldDisableDate={disableToday}
                      label={'Hasta (fecha de término)'}
                      value={localForm.values.weeklyUntil}
                      onChange={(value) => {
                        localForm.setFieldValue('weeklyUntil', value);
                      }}
                      disabled={!!group?.quotaSold || statusUpdate === 'loading'}
                      size='small'
                      fullWidth
                      name="weeklyUntil"
                      error={localForm.touched.weeklyUntil && Boolean(localForm.errors.weeklyUntil)}
                      helperText={localForm.touched.weeklyUntil && localForm.errors.weeklyUntil as string}
                      renderInput={() => <></>} 
                    />
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>*/}
          <Grid item mt={1}></Grid>
          {/* {localForm.values.periodicity === 'no' && ( */}
          <PeriodicityNo 
            statusUpdate={statusUpdate}
            form={localForm}
            group={group}
          />
          {/* )} */}

          {/* {localForm.values.periodicity === 'weekly' && (
            <PeriodicityWeekly 
              statusUpdate={statusUpdate}
              form={localForm}
              group={group}
            />
          )} */}
          
          <Grid container item flexDirection="row-reverse">
            <SimpleButton 
              onClick={() => { 
                traceBtnClick({
                  step: 'step guardar grupo de curso',
                  product: 'botón guardar',
                });
                localForm.handleSubmit();
              }} 
              disabled={statusUpdate === 'loading'} 
              loading={statusUpdate === 'loading'}
            >
              Guardar
            </SimpleButton>
          </Grid>
        </>
      </SimpleModal>
      <EditListItem
        // label={`Grupo${!!group?.firstSession ? ` - Inicio ${group?.firstSession?.dateFormat}, ${group?.firstSession?.startTime} hrs.` : ''}`}
        label={label}
        onClick={() => { 
          traceBtnClick({
            step: 'step abrir grupo de curso',
            product: 'botón abrir',
          });
          setOpen(true); 
        }}
        content={<><ContentQuota/><ContentSessions/></>}       
        error={errorQuota || errorSessions}
        helperText={(touchedQuota || touchedSessions) && 'Debe llenar curso'}
        disabled={disabled}
        onDelete={showDelete ? () => { handleDeleteGroup(); } : undefined}
      />
    </>
  );
};

Group.displayName = 'Group';
export default Group;
