
import * as validator from 'yup';
import i18n from 'i18next';

const maxSizeMB = 0.128;
const isValidFileSize = (value: any) => (value && (!value.size || value.size < (maxSizeMB * 1024 * 1024)));

const minLenght = 0;
const maxLenght = 5;
const isValidLength = (value: any) => (value && (value.length >= minLenght && value.length <= maxLenght));

export const audiovisualsSchema = validator
  .array().of(validator
    .mixed()
    .test('isValidFileSize', i18n.t('schemas:audiovisual:size'), isValidFileSize))
  .max(5, i18n.t('schemas:audiovisual:length'))
  .test('isValidLength', i18n.t('schemas:audiovisual:required'), isValidLength);

export const formSchema = validator.object({
  price: validator
    .number().min(1000, i18n.t('schemas:price:required'))
    .required(i18n.t('schemas:price:required')),
  mode: validator
    .string()
    .required(i18n.t('schemas:mode:required')),
  modeDetail: validator
    .string(),
  knowledgesId: validator
    .array().of(validator.string())
    .min(1, i18n.t('schemas:knowledge:required')),
  knowledgeDetail: validator
    .string(),
  categoryId: validator
    .string()
    .required(i18n.t('schemas:category:required')),
  audiovisuals: audiovisualsSchema,
  agerangesId: validator
    .array().of(validator.number())
    .min(1, i18n.t('schemas:ageranges:required')),
});
