import { createSearchParams, useSearchParams } from 'react-router-dom';
import { Grid, Pagination } from '@mui/material';
import NavigationBar from '../../../../components/navigations/navigation-bar/NavigationBar';
import MainContainer from '../../../../components/containers/main-container/MainContainer';
import { ChangeEvent, useEffect, useState } from 'react';
import CheckoutTable from './components/checkout-table/CheckoutTable';
import PageTitle from '../../../../components/typographies/PageTitle';
import PageSubtitle from '../../../../components/typographies/PageSubtitle';
import useAdminCheckouts from './useAdminCheckouts';

const MyAccountAdminCheckouts = () => {
  const { fetchAdminCheckouts, data, count, status, page, totalPages } = useAdminCheckouts();
  const [filter, setFilter] = useState({ page: '', per_page: '' });

  const [searchParams] = useSearchParams();

  const idParam: string | null = searchParams.get('id');
  const searchParam: string | null = searchParams.get('search');
  const pageParam: string | null = searchParams.get('page');

  useEffect(() => {
    setFilter({
      ...(idParam && { id: idParam }),
      ...(searchParam && { search: searchParam }),
      per_page: '10',
      page: `${(parseInt(pageParam || '1', 10))}`,
    });
  }, [searchParams]);

  useEffect(() => {
    if (filter.page) {
      fetchAdminCheckouts(`?${filter && createSearchParams(filter)}`);
    }
  }, [filter]);

  const handleClickPage = (event: ChangeEvent<unknown>, pageEvent: number) => {
    fetchAdminCheckouts(`?${filter && createSearchParams({
      ...filter,
      page: `${pageEvent}`,
    })}`);
  };

  return (
    <MainContainer>
      <NavigationBar variant="variant4" />
      <Grid container spacing={1} mb={1}>
        <Grid item xs={12}>
          <PageTitle>Administración / Listado de Compras</PageTitle>
        </Grid>
        <Grid item xs={12}>
          {status === 'loaded' && (
            <PageSubtitle>
              {`${count || '0'} resultados`}
            </PageSubtitle>
          )}
        </Grid>
        <Grid item xs={12}>
          <CheckoutTable data={data} status={status} />
        </Grid>
        {page && (
          <Grid item xs={12} display="flex" justifyContent="center" marginTop={1} marginBottom={4}>
            <Pagination
              count={totalPages}
              page={page}
              color="primary"
              onChange={handleClickPage}
            />
          </Grid>
        )}
      </Grid>
    </MainContainer>
  );
};

export default MyAccountAdminCheckouts;
