import { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import { useTranslation } from 'react-i18next';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
}

const useCreateAccount = () => {
  const { t } = useTranslation(['create-account']);

  const [state, setState] = useState<StateProps>({ status: 'idle', error: null });

  const sendEmailVerification = async () => {
    firebase.auth().languageCode = t('appLanguage', 'es');
    await firebase.auth().currentUser?.sendEmailVerification({ 
      url: process.env.REACT_APP_ORIGIN_URL || 'https://www.gurapp.cl', 
    });
  };

  const createUserWithEmailAndPassword = async (email: string, password: string) => {
    setState({ status: 'loading', error: null });
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      await sendEmailVerification();
      setState({ status: 'loaded' });
      return true;
    } catch (event: any) {
      setState({
        status: 'loaded',
        error: {
          message: t(`error.${event.code}`, event.message),
        },
      });
      return false;
    }
  };

  const signInWithGoogle = async () => {
    setState({ status: 'loading', error: null });
    try {
      await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
      setState({ status: 'loaded' });
    } catch (event: any) {
      if (event?.code !== 'auth/popup-closed-by-user') {
        setState({
          status: 'loaded',
          error: {
            message: t(`error.${event.code}`, event.message),
          },
        });
      } else {
        setState({ status: 'loaded' });
      }
    }
  };

  return {
    error: state.error,
    status: state.status,
    createUserWithEmailAndPassword,
    signInWithGoogle,
    sendEmailVerification,
  };
};

export default useCreateAccount;
