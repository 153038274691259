import { forwardRef } from 'react';
import SimpleDialog from '../../../../../../components/dialogs/SimpleDialog/SimpleDialog';
import { GroupEntity, SessionEntity } from '../../../../../../entities';
import { SimpleButton } from '../../../../../../components/buttons';

interface DeleteDialogProps {
  onClose: () => void;
  onContinue: (entity: any) => void;
  open?: boolean;
  groupHasSold?: boolean;
  value?: GroupEntity | SessionEntity;
}

const DeleteDialog = forwardRef<HTMLDivElement, DeleteDialogProps>(
  ({
    onClose,
    onContinue,
    groupHasSold,
    value,
    ...props
  }, ref) => {
    const title = '¿Estás segura/o?';
    const content = 'Ya tienes ventas de esta opción. Si lo eliminas cancelaremos estas compras y devolveremos el pago a tus clientes.';
    const btnKeep = 'No, mantener opción';
    const btnContinue = 'Si, eliminar';
   
    return (
      <SimpleDialog 
        title={title}
        content={groupHasSold ? content : undefined}
        actions={
          <>
            <SimpleButton onClick={onClose} variant="text">
              {btnKeep}
            </SimpleButton>
            <SimpleButton onClick={() => onContinue(value)} variant="text">
              {btnContinue}
            </SimpleButton>
          </>}
        onClose={onClose}
        ref={ref}
        {...props} 
      />
    );
  },
);

DeleteDialog.displayName = 'DeleteDialog';
export default DeleteDialog;
