/* eslint-disable no-param-reassign */
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const ApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  timeout: 20000,
});

ApiInstance.interceptors.request.use(async (config) => {
  const token = await firebase.auth().currentUser?.getIdToken(true) || '';
  const headers = config?.headers;
  if (headers) {
    headers.token = token;
    headers['x-trace-id'] = uuidv4();
  }
  return config;
});

export default ApiInstance;
