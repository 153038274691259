import { forwardRef, MouseEvent, useState } from 'react';
import {
  Menu, MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  StyledKebabMenu,
  StyledIconButton,
} from './KebabMenu.styled';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';

export interface Option {
  onClick: () => void;
  children: string;
}

interface KebabMenuProps {
  options: Option[];
  style?: any;
}

const KebabMenu = forwardRef<HTMLDivElement, KebabMenuProps>(
  ({ options = [], style, ...rest }, ref) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { traceBtnClick } = useAnalytics();

    const handleOpenClick = (event: MouseEvent<HTMLElement>) => {
      traceBtnClick({
        step: 'step abrir menu kebab',
        product: 'Menu Kebab',
      });
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      traceBtnClick({
        step: 'step cerrar menu kebab',
        product: 'Cerrar Menu Kebab',
      });
      setAnchorEl(null);
    };

    return (
      <StyledKebabMenu ref={ref} {...rest}>
        <StyledIconButton
          aria-label="more"
          id="long-button"
          aria-controls="long-menu"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleOpenClick}
          style={style}
        >
          <MoreVertIcon />
        </StyledIconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {options.map((option) => (
            <MenuItem key={option.children} onClick={option.onClick}>
              {option.children}
            </MenuItem>
          ))}
        </Menu>
      </StyledKebabMenu>
    );
  },
);

KebabMenu.displayName = 'KebabMenu';
export default KebabMenu;
