export enum EventName {
  // Evento que se gatilla cada vez que se carga una pantalla
  VIRTUAL_PAGE = 'virtual_page',

  // Evento que se gatilla cada vez que se selecciona un botón
  BTN_CLICK = 'btn_click',
  
  // Evento que se gatilla cada vez que se carga una pantalla de éxito de un flujo
  END_FLOW = 'end_flow',
  
  // Evento que se gatilla cada vez que se muestra un mensaje de error funcional dentro de un flujo
  FUNCTIONAL_ERROR = 'functional_error',

  // Un usuario visita la página donde ve una lista inicial
  VIEW_ITEM_LIST = 'view_item_list',

  // Un usuario visita una página de item. (ej Detalle de item)
  VIEW_ITEM = 'view_item',

  // Un usuario selecciona un item.
  SELECT_ITEM = 'select_item',

  // Un usuario ve un banner
  VIEW_BANNER = 'view_banner',

  // Un usuario selecciona un banner
  CLICK_BANNER = 'click_banner',

  // Un usuario añade un artículo al carrito
  ADD_TO_CART = 'add_to_cart',

  // Un usuario confirma un carrito carrito
  CONFIRM_TO_CART = 'confirm_to_cart',

  // Un usuario quita un artículo del carrito
  REMOVE_FROM_CART = 'remove_from_cart',

  // Un usuario gatilla el checkout
  BEGIN_CHECKOUT = 'begin_checkout',

  // Un usuario completa el proceso de compra
  PURCHASE = 'purchase',
}
