import { forwardRef, ReactElement } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import styled from '@emotion/styled';

interface ParagraphProps extends TypographyProps {
  children?: string | ReactElement;
}
const StyledTypography = styled(Typography)`
  white-space: pre-line;
`;
const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(
  ({ ...rest }, ref) => {
    return <StyledTypography variant="body1" ref={ref} {...rest} />;
  },
);

Paragraph.displayName = 'Paragraph';
export default Paragraph;
