import { useState } from 'react';
import { getAdminNextGroupUpdated, putAdminNextGroupUpdated } from '../../../../services/adminPublication';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
  countNotUpdated?: number;
}

const useAdminPublications = () => {
  const [state, setState] = useState<StateProps>({ 
    status: 'idle',
  });

  const fetchAdminNextGroupUpdated = async () => {
    setState({ status: 'loading' });
    try {
      const response = await getAdminNextGroupUpdated();
      setState({ 
        countNotUpdated: response?.countNotUpdated,
        status: 'loaded', 
      });
    } catch (error) {
      setState({ status: 'loaded', error });
    }
  };

  const updateAdminNextGroupUpdated = async () => {
    setState({ status: 'loading' });
    try {
      await putAdminNextGroupUpdated();
      fetchAdminNextGroupUpdated();
      return true;
    } catch (error) {
      setState({ status: 'loaded', error });
    }
  };

  return {
    ...state,
    fetchAdminNextGroupUpdated,
    updateAdminNextGroupUpdated,
  };
};

export default useAdminPublications;