import API from './config/apiInstance';

import apiError from './apiError';

export async function getFinancesExcel(params: string) {
  try {
    API.interceptors.request.use((config) => {
      config.responseType = 'blob';
      return config;
    });
    const response = await API.get(`/auth/checkout-item/financesExcel${params}`);
    if (response?.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    throw apiError({ code: '/auth/checkout-item/financesExcel', message: 'Hubo un problema al intentar descargar el excel de finanzas' });
  }
}