import { Button, IconButton } from '@mui/material';
import styled from '@emotion/styled';
import SimpleButton from '../simple-button/SimpleButton';
import PersonIcon from '@mui/icons-material/Person';

export const StyledLoggedButton = styled(Button)`
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  background-color: white;
  margin-left: auto;
  &:hover {
    background-color: white;
  }
`;

export const StyledNotLoggedButton = styled(SimpleButton)`
  background-color: #1D1B42;
  margin-left: auto;
  &:hover {
    background-color: #171635;
  }
`;
StyledNotLoggedButton.defaultProps = {
  startIcon: <PersonIcon fontSize='small' />,
};

export const StyledNotLoggedIconButton = styled(IconButton)`  
  height: 48px;
  width: 48px;
  border-radius: 200px;
  background-color: #1D1B42;
  color: #fff;
  margin-left: auto;
  &:hover {
    background-color: #171635;
  }
`;
