import { 
  TextField, 
  StandardTextFieldProps, 
  FormControl,
  Grid,
  FormHelperText,
} from '@mui/material';
import Subtitle from '../../typographies/subtitle/Subtitle';

interface TextFieldFormProps extends StandardTextFieldProps {
  title?: string;
  name: string;
  form: any;
  loading?: boolean;
  type?: string;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

const fontSizes = {
  small: '0.875rem',
  medium: '1rem',
};

const TextFieldForm = ({ 
  title, 
  name, 
  form, 
  loading, 
  xs = 12, 
  sm, 
  md, 
  lg, 
  xl, 
  size = 'medium', 
  InputProps = {}, 
  InputLabelProps = {},
  ...props }: TextFieldFormProps) => {
  return (
    <>
      {title && (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          <Subtitle variant="variant4">{title}</Subtitle>
        </Grid>
      )}
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            fullWidth
            name={name}
            value={form.values[name]}
            error={form.touched[name] && Boolean(form.errors[name])}
            onChange={form.handleChange}
            disabled={loading}
            size={size}
            InputProps={{ style: { fontSize: fontSizes[size] }, ...InputProps }}
            InputLabelProps={{ style: { fontSize: fontSizes[size] }, ...InputLabelProps }}
            {...props}
          />
          <Grid item container direction="row-reverse" justifyContent="space-between" >
            <FormHelperText>
              {!!props?.inputProps?.maxLength && (
                `(${form.values[name]?.length || 0}/${props?.inputProps?.maxLength as string})`
              )}
            </FormHelperText>
            {/* {!!props.helperText && (
              <FormHelperText variant="outlined">
                {props.helperText}
              </FormHelperText>
            )} */}
            {(form.touched[name] && form.errors[name]) && (
              <FormHelperText variant="outlined" error>
                {form.errors[name]}
              </FormHelperText>
            )}
          </Grid>
        </FormControl>
      </Grid>
    </>
  );
};

TextFieldForm.displayName = 'TextFieldForm';
export default TextFieldForm;
