// import API from './config/apiInstance';
import apiError from './apiError';

export async function getAgeranges(): Promise<any> {
  try {
    // const response = await API.get('/agerange');
    const count = 4;
    const page = 1;
    const perPage = 10;
    const totalPages = Math.ceil(count / perPage);
    const rows = [
      {
        'id': 1,
        'name': 'Niños',
        'description': 'menores de 14 años',
      },
      {
        'id': 2,
        'name': 'Adolescentes',
        'description': 'entre 14 y 18 años',
      },
      {
        'id': 3,
        'name': 'Adulto joven',
        'description': 'entre 18 y 35 años',
      },
      {
        'id': 4,
        'name': 'Adulto',
        'description': 'entre 35 y 60 años',
      },
      {
        'id': 5,
        'name': 'Adulto mayor',
        'description': 'más de 60 años',
      },
    ];
    const response =  {
      data: {
        page,
        per_page: perPage <= count ? perPage : count,
        count,
        total_pages: totalPages,
        data: rows,
      },
    };
    if (response && response.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    throw apiError({ code: '/agerange', message: 'Hubo un problema al intentar buscar rangos de edad' });
  }
}
