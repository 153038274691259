import { useState } from 'react';
import { CategoryEntity } from '../../../../entities';
import { getCategories } from '../../../../services/category';

interface LocationType {
  value: string;
  label: string;
}
interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: 'generic';
  categories: CategoryEntity[];
  locations: LocationType[];

}

const useSearch = () => {
  const [state, setState] = useState<StateProps>({
    status: 'idle',
    categories: [],
    locations: [],
  });

  const fetchAll = async () => {
    setState({ ...state, status: 'loading' });
    try {
      // const responseLocations = await getLocations();
      const responseLocations = { data: [] };
      const responseCategories = await getCategories();
      const allCategories = { id: '', name: 'Todas', description: 'Todas las categorías' };
      const allLocations = [...responseLocations?.data.map((location: string) => ({ value: location, label: location }))];
      setState({
        ...state, 
        status: 'loaded',
        categories: [allCategories, ...responseCategories?.data],
        locations: allLocations,
      });
    } catch (error: any) {
      setState({ ...state, status: 'loaded', categories:[], locations: [], error: error?.code || 'generic' });
    }
  };

  return {
    ...state,
    fetchAll,
  };
};

export default useSearch;
