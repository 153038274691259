import * as validator from 'yup';
import i18n from 'i18next';
import isObsfuscated from '../../../../../../utils/isObsfuscated';
import { validateRut } from '../../../../../../utils/rutUtilities';

const isNameValid = (value?: string) => !isObsfuscated(value, 2);
const isRutValid = (value?: string) => !!(value && validateRut(value));

export const paymentAccountSchema = validator.object({
  name: validator
    .string()
    .required(i18n.t('schemas:name:required'))
    .min(2, i18n.t('schemas:name:min'))
    .max(50, i18n.t('schemas:name:max'))
    .test('isNameValid', i18n.t('schemas:name:notValid'), isNameValid),
  rut: validator
    .string()
    .required(i18n.t('schemas:rut:required'))
    .test('isRutValid', i18n.t('schemas:rut:notValid'), isRutValid),
  accountNumber: validator
    .number()
    .typeError(i18n.t('schemas:accountNumber:notValid'))
    .required(i18n.t('schemas:accountNumber:required')),
  accountBankId: validator
    .number()
    .required(i18n.t('schemas:accountBankId:required')),
  accountType: validator
    .string()
    .required(i18n.t('schemas:accountType:required')),
});
