import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const StyledVariant1 = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
`;

export const StyledVariant2 = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  /* opacity: .7; */
  display: inline;
`;

export const StyledContainerVariant3 = styled.div`
  width: 100%;
  margin: 8px 0;
`;

export const StyledVariant3 = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  padding: 0 8px;
  letter-spacing: 0.25px;
  line-height: 1.5;
  opacity: .5;
  white-space: nowrap;
`;

export const StyledVariant4 = styled(Typography)`
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.25px;
  line-height: 1.5;
  white-space: pre-line;
`;

export const StyledVariant5 = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  opacity: .7;
  display: inline;
`;

export const StyledVariant6 = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
`;

export const StyledVariant7 = styled(Typography)`
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 1.5;
  white-space: pre-line;
  margin-top: 4px;
`;