import styled from '@emotion/styled';
import GurappLogo from '../../components/logos/gurapp-logo/GurappLogo';
import { textOnWhite1 } from '../../themes/gurapp.theme';
import { Typography } from '@mui/material';

export const FooterComponent = styled.footer`
  background: ${textOnWhite1};
  padding: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CopyRight = styled(Typography)`
  color: #fff;
  text-align: center;
  font-size: .8rem;
  font-weight: 300;
  margin-top: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
`;

export const Logo = styled(GurappLogo)``;
Logo.defaultProps = {
  variant: 'white2',
};