import { forwardRef } from 'react';
import { QuestionAnswerEntity } from '../../../entities';
import { Label } from '../../typographies';
import AnswerIcon from '@mui/icons-material/SouthEast';
import { Typography, styled } from '@mui/material';

interface Props {
  questionAnswers?: QuestionAnswerEntity[],
}

const LabelDate = styled(Label)`
  opacity: 0.4;
  margin-right: 4px;
  margin-left: 4px;
  display: flex;
  flex-direction: row-reverse;
`;

const LabelAnswer = styled(Label)`
  opacity: 0.6;
`;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ questionAnswers = [], ...rest }, ref) => {
    if (!questionAnswers || questionAnswers.length === 0) {
      return null;
    }
    return (
      <div {...rest} ref={ref}>
        <Typography variant='h2'>Últimas preguntas realizadas</Typography>
        {questionAnswers.map((questionAnswer) => (
          <div key={questionAnswer.id} style={{ marginBottom: 12 }}>
            <Label>{questionAnswer.question}</Label>
            <div style={{ display: 'flex', gap: 4 }}>
              <AnswerIcon fontSize='small'/>
              <div style={{ width: '100%' }}>
                <LabelAnswer>{questionAnswer.answer}</LabelAnswer>
                <LabelDate>{questionAnswer.updatedAtFormat}</LabelDate>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  });

export default Component;




