import { IconButton } from '@mui/material';
import {
  ArrowForward,
  ArrowBack,
  DeleteOutlineOutlined,
  StarBorderOutlined,
  StarOutlined,
} from '@mui/icons-material';
import { ContainerBottomControls, ContainerControls, ContainerImageItem, ContainerTopControls } from './ImageItem.styled';

interface ImageItemProps {
  src: string;
  alt: string;
  fav?: boolean;
  disabled?: boolean;
  onLoad?: () => void;
  onClickDelete?: () => void;
  onClickArrowBack?: () => void;
  onClickArrowForward?: () => void;
  onClickStar?: () => void;
  onClickImage?: () => void;
  showDelete?: boolean;
  showArrowBack?: boolean;
  showArrowForward?: boolean;
  showStar?: boolean;
}

const ImageItem = 
  ({
    src,
    alt,
    fav,
    disabled,
    onLoad,
    onClickDelete,
    onClickArrowBack,
    onClickArrowForward,
    onClickStar,
    onClickImage,
    showDelete,
    showArrowBack,
    showArrowForward,
    showStar,
    ...rest
  }: ImageItemProps) => {
    
    const iconButtonProps = { size: 'small' as 'small' };
    const iconStyle = { height: 20, width: 20 };
    return (
      <ContainerImageItem variant="outlined" {...rest}>
        <img height="80px" src={src} alt={alt} onLoad={onLoad} onClick={onClickImage}  />
        <ContainerControls>
          <ContainerTopControls>
            {showArrowBack && (
              <IconButton aria-label="delete" {...iconButtonProps} disabled={disabled} onClick={onClickArrowBack}>
                <ArrowBack style={iconStyle}/>
              </IconButton>
            )}
            {showArrowForward && (
              <IconButton aria-label="edit" {...iconButtonProps} disabled={disabled} onClick={onClickArrowForward}>
                <ArrowForward style={iconStyle}/>
              </IconButton>
            )}            
          </ContainerTopControls>
          <ContainerBottomControls>
            {showDelete && (
              <IconButton aria-label="edit" {...iconButtonProps} disabled={disabled} onClick={onClickDelete}>
                <DeleteOutlineOutlined style={iconStyle}/>
              </IconButton>
            )}
            {showStar && (
              <IconButton aria-label="edit" {...iconButtonProps} disabled={disabled} onClick={onClickStar}>
                {fav ? <StarOutlined style={iconStyle} /> : <StarBorderOutlined style={iconStyle} />}
              </IconButton>
            )}
          </ContainerBottomControls>
          
        </ContainerControls>
      </ContainerImageItem>
    );
  };

ImageItem.displayName = 'ImageItem';
export default ImageItem;
