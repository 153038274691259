import { useState } from 'react';
import { getAuthPurchase } from '../../services/authPurchase';
import { CheckoutItemEntity } from '../../entities';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
  data?: CheckoutItemEntity;
}

const useMyPurchase = () => {
  const initialState: StateProps = { 
    status: 'idle', 
    error: null, 
  };
  const [state, setState] = useState<StateProps>(initialState);

  const fetchPurchase = async (id: number) => {
    setState({ ...initialState, status: 'loading' });
    try {
      let data = await getAuthPurchase(id);
      setState({ ...initialState, data, status: 'loaded' });
    } catch (error) {
      setState({ ...initialState, status: 'loaded', error });
    }
  };

  return {
    ...state,
    fetchPurchase,
  };
};

export default useMyPurchase;