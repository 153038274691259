import { forwardRef } from 'react';
import { StyledSimpleButton } from './EditButton.styled';
import { ModeEditOutline as ModeEditOutlineIcon } from '@mui/icons-material';

interface EditButtonProps {
  onClick: () => void;
  style?: any;
}

const EditButton = forwardRef<HTMLButtonElement, EditButtonProps>(
  (props, ref) => (
    <StyledSimpleButton 
      variant="text"
      size="small"
      color="primary"
      startIcon={<ModeEditOutlineIcon />}
      ref={ref}
      {...props} 
    >
      Editar
    </StyledSimpleButton>
  ),
);

EditButton.displayName = 'EditButton';
export default EditButton;
