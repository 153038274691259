import { forwardRef } from 'react';
import { Button } from '@mui/material';
import styled from '@emotion/styled';

const StyledTopSticky = styled.div`
  position: sticky; 
	display: flex;
	justify-content: space-between;
	top: 0; 
	background-color: #9840d5;
	color: white;
	font-weight: bold;
	padding: 16px 24px;
  align-items: center;
	z-index: 1;
`;

interface TopMessageProps {
  title?: string;
  onClickButton?: () => void;
  textButton?: string;
}

const TopMessage = forwardRef<HTMLDivElement, TopMessageProps>(
  ({ title, onClickButton, textButton = 'Volver', ...props }, ref) => {
    return (
      <StyledTopSticky {...props} ref={ref}>
        {title}
        {!!onClickButton && (
          <Button
            onClick={onClickButton} 
            variant='contained'
            size='small'
          > 
            {textButton}
          </Button>
        )}
      </StyledTopSticky>
    );
  });

TopMessage.displayName = 'TopMessage';
export default TopMessage;