import { forwardRef, Fragment } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { GroupEntity } from '../../../entities';
import Group from '../../cart/group/Group';

interface IGroupsProps {
  groups?: GroupEntity[];
  disabledActions?: boolean;
}

const Groups = forwardRef<HTMLDivElement, IGroupsProps>(
  ({ groups = [], disabledActions, ...rest }, ref) => {
    return (
      <Grid container spacing={1} paddingTop={2}  paddingBottom={2} {...rest} ref={ref}>
        {groups?.length > 0 && groups?.[0]?.publication?.agenda === 'scheduled' && (
          <>
            <Grid item xs={12}>
              <Typography fontWeight="bold">
                Selecciona la opción que más te acomode y agrégala al carrito
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Opciones más cercanas:</Typography>
            </Grid>
          </>
        )}
        {groups?.map((group: GroupEntity, indexGroup: number) => (
          <Fragment key={group.id}>
            <Grid pb={1} item xs={12}>
              <Group 
                group={group} 
                indexGroup={indexGroup} 
                showCartButton 
                disabledActions={disabledActions}
              />
            </Grid>
            {(groups?.length !== indexGroup + 1) && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </Fragment>
        ))}
      </Grid>
    );
  },
);

Groups.displayName = 'Groups';
export default Groups;
