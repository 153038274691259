import { forwardRef, ReactNode } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import gurappCreateAccountBackground from './assets/gurapp_create_account_background.svg';
import gurappSuccessCreateAccount from './assets/gurapp_success_create_account.svg';
import gurappSuccessCreatePublication from './assets/gurapp_success_create_publication.svg';
import iconFail from './assets/icon-fail.svg';
import iconPending from './assets/icon-pending.png';
import {
  Container,
  LeftContainer,
  RightContainer,
} from './DividedContainer.styled';

interface DividedContainerProps {
  children?: ReactNode;
  variant?: 'variant1' | 'variant2' | 'variant3' | 'variant4' | 'variant5';
  paddingTop?: number;
}

const DividedContainer = forwardRef<HTMLDivElement, DividedContainerProps>(
  ({
    children, variant = 'variant1', ...rest
  }, ref) => {
    const theme = useTheme();
    const isUpMD = useMediaQuery(theme.breakpoints.up('md'));

    const variants = {
      variant1: { url: gurappCreateAccountBackground, size: 'cover' },
      variant2: { url: gurappSuccessCreateAccount, size: 'contain' },
      variant3: { url: gurappSuccessCreatePublication, size: 'contain' },
      variant4: { url: iconPending, size: 'contain' },
      variant5: { url: iconFail, size: 'contain' },
    };

    return (
      <Container ref={ref} {...rest}>
        <LeftContainer isUpMD={isUpMD ? 1 : 0}>
          {children}
        </LeftContainer>
        {isUpMD && <RightContainer url={variants[variant].url} size={variants[variant].size}/>}
      </Container>
    );
  },
);

DividedContainer.displayName = 'DividedContainer';
export default DividedContainer;
