import { forwardRef, useState, Fragment, ReactNode } from 'react';
import { Box, Collapse, IconButton, Paper, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PublicationEntity } from '../../../../../../entities';
import { useTranslation } from 'react-i18next';
import PublicationChip from '../../../../../../components/chips/publication-chip/PublicationChip';
import NewStateDialog from '../new-state-dialog/NewStateDialog';
import useAdminPublications from '../../useAdminPublications';
import { LinkButton } from '../../../../../../components/buttons';
import PublicationCard from '../../../../../../components/cards/publication-card/PublicationCard';

interface PublicationTableProps {
  data?: PublicationEntity[];
  status?: 'idle' | 'loading' | 'loaded';
}

interface DialogProps {
  open: boolean;
  newState?: 'approved' | 'rejected';
  status?: string;
}

const ContentRow = (props: { title?: string, children?: ReactNode }) => (
  <>
    <Grid item fontWeight="bold">
      {props?.title}
    </Grid>
    <Grid item>
      {props?.children}
    </Grid>
  </>
);

const Row = (props: { row: PublicationEntity }) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = useState<DialogProps>({ open: false, newState: undefined });
  const { updateStatePublication, status } = useAdminPublications();

  const { t } = useTranslation('my-account', { keyPrefix: 'admin.publications.table' });
  
  const onCloseDialog = () => {
    setDialog({ ...dialog, open: false });
  };

  const prevStatesAllowed = ['review', 'approved'];
  const isPrevStateAllowed = prevStatesAllowed.some((state) => state === row?.state);
  const newStatesAllowed = ['approved', 'rejected'];

  const onClickState = (newState: 'approved' | 'rejected') => {
    setDialog({ open: true, newState });
  };

  const onContinueState = async () => {
    const success = await updateStatePublication(+(row.id || 0), dialog.newState);
    onCloseDialog();
    if (success) {
      window.location.reload();
    }
  };

  return (
    <Fragment>
      <NewStateDialog 
        open={dialog.open} 
        onClose={onCloseDialog}
        onContinue={onContinueState}
        value={row}
        status={status}
      />
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.title}
        </TableCell>
        <TableCell align="right">{t(`type.items.${row?.type}`) as string}</TableCell>
        <TableCell align="right"><PublicationChip type={row.state}/></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Grid container direction="column" spacing={1}>
                {isPrevStateAllowed && (
                  <ContentRow title={t('newState.title') as string}>
                    {newStatesAllowed
                      .filter((newState) => newState !== row.state)
                      .map((newState) => (
                        <PublicationChip 
                          type={newState} 
                          key={newState} 
                          onClick={() => onClickState(newState as 'approved' | 'rejected')}
                        />
                      ))
                    }
                  </ContentRow>
                )}
                <ContentRow title={t('actions.title') as string}>
                  <LinkButton
                    target='_blank'
                    href={`/my-account/admin/publications/${row.id}/preview`}
                    rel="noopener"
                    texts={[t('actions.preview') as string]}
                  />
                  <Grid item xs={12} sm={6} md={3} mt={1}>
                    <PublicationCard
                      publication={{
                        ...row,
                        state: undefined,
                      }}
                      onClick={() => (row)}
                      header={!row?.hasQuotaAvailable ? 'Sin cupos disponibles' : undefined}
                    />
                  </Grid>
                </ContentRow>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const PublicationTable = forwardRef<HTMLDivElement, PublicationTableProps>(
  ({ data, status, ...rest }, ref) => {
    const { t } = useTranslation('my-account', { keyPrefix: 'admin.publications.table' });

    return (
      <>
        <TableContainer component={Paper} ref={ref} {...rest}>
          <Table aria-label="collapsible table" size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>ID</TableCell>
                <TableCell>{t('title.title') as string}</TableCell>
                <TableCell align="right">{t('type.title') as string}</TableCell>
                <TableCell align="right">{t('state.title') as string}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status === 'loaded' && (
                data?.map((row: PublicationEntity) => (
                  <Row key={row.id} row={row} />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {status === 'loading' && <LinearProgress />}
      </>
    );
  },
);

PublicationTable.displayName = 'PublicationTable';
export default PublicationTable;
