import { forwardRef } from 'react';
import IconInfo from './IconInfo';
import { PublicationEntity } from '../../../../entities';
import { useTranslation } from 'react-i18next';

interface IconInfoModePros {
  publication?: PublicationEntity;
}

const IconInfoMode = forwardRef<HTMLDivElement, IconInfoModePros>(
  ({ publication }, ref) => {
    const { t } = useTranslation(['components'], { keyPrefix: 'publication-template' });
    return !!publication?.mode ? (
      <IconInfo
        icon="mode"
        text={t(`mode.${publication?.mode}`)}
        ref={ref}
      />
    ) : null;
  });
export default IconInfoMode;