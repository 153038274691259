import API from './config/apiInstance';
import apiError from './apiError';

export async function getGroup(id: number) {
  try {
    const response = await API.get(`/group/${id}`);
    if (response && response.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    throw apiError({ code: `/group/${id}`, message: 'Hubo un problema al intentar buscar grupo' });
  }
}
