import { useEffect, useState } from 'react';
import { getAuthPublicationsCount } from '../../../services/authPublication';
import { createSearchParams } from 'react-router-dom';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded' | 'error';
  error?: any;
  countMyPublications: number;
  countMyDrafts: number;
}

const useGuru = () => {
  const [state, setState] = useState<StateProps>({ status: 'idle', error: null, countMyPublications: 0, countMyDrafts: 0 });
	
  const fetchAuthPublicationsCount = async () => {
    setState({ status: 'loading', error: null, countMyPublications: 0, countMyDrafts: 0 });
    try {
      const promises = [
        await getAuthPublicationsCount(`${createSearchParams({ state: ['approved', 'review', 'rejected'] })}`),
        await getAuthPublicationsCount(`${createSearchParams({ state: 'draft' })}`),
      ];
      const [countMyPublications, countMyDrafts] = await Promise.all(promises);
      setState({ 
        status: 'loaded', 
        error: null, 
        countMyPublications, 
        countMyDrafts,
      });
    } catch (error) {
      setState({ status: 'error', error, countMyPublications: 0, countMyDrafts: 0 });
    }
  };

  useEffect(()=> {
    fetchAuthPublicationsCount();
  }, []);

  return {
    ...state,
  };
};

export default useGuru;