import { useContext, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CircularProgress, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NavigationBar from '../../components/navigations/navigation-bar/NavigationBar';
import DividedContainer from '../../components/containers/divided-container/DividedContainer';
import SimpleButton from '../../components/buttons/simple-button/SimpleButton';
import CartContext from '../../contexts/CartContext';
import { Title, Subtitle } from '../../components/typographies';
import PublicationCard from '../../components/cards/publication-card/PublicationCard';
import usePurchase from './usePurchase';
import useAnalytics from '../../hooks/useAnalytics/useAnalytics';

type ModeType = 'success' | 'fail' | 'pending' | undefined;
enum Variant {
  'success' = 'variant3',
  'fail' = 'variant5',
  'pending' = 'variant4',
}

const Purchase = () => {
  const { t } = useTranslation(['purchase']);
  const navigate = useNavigate();
  const { traceBtnClick } = useAnalytics();
  const mode = useParams().mode as ModeType || 'fail';
  const { cleanCart } = useContext(CartContext);

  useEffect(() => {
    const acceptable = ['success', 'fail', 'pending'];
    if (!(acceptable.includes(mode))) {
      navigate('/not-found');
    }

    if (mode === 'success') {
      cleanCart();
    }
  }, [mode]);

  const [searchParams] = useSearchParams();
  const checkoutId: string | null = searchParams.get('external_reference');
  const { fetchCheckout, data, status } = usePurchase();
  
  useEffect(() => {
    if (mode === 'success' && checkoutId) {
      fetchCheckout(+checkoutId, { state: ['paid'] });
    }
  }, []);

  return (
    <DividedContainer variant={Variant[mode]}>
      <NavigationBar variant="variant4" />
      <Grid container paddingTop={5} paddingLeft={0} paddingRight={2} spacing={1}>
        <Grid item>
          <Title variant="variant2">{t(`${mode}.title`)}</Title>
        </Grid>
        <Grid item xs={12}>
          <Subtitle variant="variant2">{t(`${mode}.subtitle1`)}</Subtitle>
          <Subtitle variant="variant5">{t(`${mode}.subtitle2`)}</Subtitle>
        </Grid>
        {status === 'loading' && <Grid item xs={12}><CircularProgress size={24} /></Grid>}
        {(mode === 'success') && (
          data?.checkoutItems?.map((checkoutItem) => (
            <Grid item xs={12} sm={6} md={3} key={checkoutItem.id}>
              <PublicationCard
                publication={checkoutItem?.group?.publication}
              />
            </Grid>
          ))
        )}
      </Grid>
      <Grid container flexDirection='row-reverse' paddingTop={2} paddingLeft={0} paddingRight={2} spacing={2}>
        {(mode === 'success') && (
          <Grid item>
            <SimpleButton 
              onClick={() => { 
                traceBtnClick({
                  step: 'step ir a publicaciones',
                  product: t('success.button'),
                });
                navigate('/marketplace');
              }}>
              {t('success.button')}
            </SimpleButton>
          </Grid>
        )}
        {(mode === 'fail') && (
          <Grid item>
            <SimpleButton 
              onClick={() => { 
                traceBtnClick({
                  step: 'step reintentar a checkout',
                  product: t('fail.button'),
                });
                navigate('/checkout');
              }}>
              {t('fail.button')}
            </SimpleButton>
          </Grid>
        )}
      </Grid>
    </DividedContainer>
  );
};

export default Purchase;
