import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useMyPurchase from './../useMyPurchase';
import { PublicationTemplate } from '../../../components/publication/publication-template/PublicationTemplate';
import {  LinearProgress } from '@mui/material';

const MyPurchasePublication = () => {
  const { id } = useParams();
  const { fetchPurchase, data, status } = useMyPurchase();

  useEffect(() => {
    fetchPurchase(+(id || 0));
  }, []);

  return (
    <>
      {status === 'loading' && <LinearProgress />}
      {status === 'loaded' && data && (
        <PublicationTemplate publication={data?.group?.publication} />
      )}
    </>
  );
};

export default MyPurchasePublication;