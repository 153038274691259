interface FAQ {
  id: string;
  question: string;
  smallAnswer?: string;
  fullAnswer: string;
  showBeGuru?: boolean;
}

const bullet = '> ';

export const GurusFaqs: FAQ[] = [
  {
    id: 'Gurus1',
    question: '¿Cómo publico mis clases?',
    fullAnswer: 'Para publicar tus clases solo debes: \n\n' +
      `${bullet} Crear tu perfil de Gurú para que todos sepan quién eres.\n` +
      'Aquí deberás completar 3 sencillos pasos: \n*1-* Crear tu cuenta.\n*2-* Ingresar información de tu perfil.\n*3-* Completar información básica de contacto y pagos. \n\n' +
      `${bullet} Crear tu clase y publicarla.\n` +
      'Debes publicar al menos una clase y hasta todas las que quieras. No tenemos límites. Para esto, debes pasar por 4 pasos: \n*1-* Descripción: dar un título, descripción, itinerario y si corresponde, especificar materiales. \n*2-* Detalles: dar información que nos ayudará a categorizar tus clases. \n*3-* Agenda: seleccionar el tipo de agenda, si con horario predefinido u horario flexible, y finalmente \n*4-* Grupos: crear diferentes horarios y días en que estarán disponibles tus clases. \n\n',
  }, 
  {
    id: 'Gurus2',
    question: '¿Quiénes pueden dar una clase en GurApp?',
    smallAnswer: 'En GurApp queremos que todas las personas puedan aprender de distintos intereses a través de momentos memorables y experiencias únicas. Por esto, es muy importante que nuestros Gurús sepan realmente sobre lo que enseñan, ya sea porque lo estudiaron o porque tienen una gran experiencia.',
    fullAnswer: 'En GurApp queremos que todas las personas puedan aprender de distintos intereses a través de momentos memorables y experiencias únicas. Por esto, es muy importante que nuestros Gurús sepan realmente sobre lo que enseñan, ya sea porque lo estudiaron o porque tienen una gran experiencia.\n' +
    'Cuando crees tu primera publicación te avisaremos dentro de los siguientes 7 días hábiles si ya estás listo para ser un Gurú y comenzar a vender tu conocimiento.',
    showBeGuru: true,
  },
  {
    id: 'Gurus3',
    question: '¿Qué tipo de clases puedes dar?',
    fullAnswer: 'Hemos identificado 5 categorías principales: Cocina, Deportes, Música, Arte y Bienestar. Sin embargo, si deseas impartir un curso sobre un tema que no encaje en estas categorías, puedes optar por la opción "Otros". ¡Nuestro objetivo es fomentar el conocimiento en distintas disciplinas!',
    showBeGuru: true,
  },
  {
    id: 'Gurus4',
    question: '¿Cómo se genera una venta de tus cursos?',
    fullAnswer: 'Los exploradores podrán comprar tu curso según las alternativas que ofreciste. Una vez que se genera la compra, recibirás un correo confirmando que tienes un nuevo explorador en tu curso y se actualizará la información en tu perfil Gurú. Por otro lado, el explorador recibirá un correo con el detalle del curso que acaba de comprar.',
    showBeGuru: true,
  },
  {
    id: 'Gurus5',
    question: '¿Qué hacer una vez que generaste una venta?',
    fullAnswer: 'En tu perfil Gurú, puedes acceder a todo el detalle de tus cursos publicados y vendidos. Además, puedes acceder a los datos de contacto de tus exploradores.',
  },
  {
    id: 'Gurus6',
    question: '¿Cómo funciona el pago?',
    fullAnswer: `*Pago dentro de los 11 Días hábiles desde la Fecha de Venta:*

Los pagos a los profesores se realizarán dentro de los 11 días después de la fecha de venta de las clases.
Esta medida garantiza un tiempo adecuado para procesar las transacciones y evaluar la efectividad de las clases antes de efectuar el pago.

*Pago Proporcional a lo Efectivamente Dictado:*

El monto del pago se calculará proporcionalmente al número de clases efectivamente dictadas durante el período de pago correspondiente.

*Frecuencia de Pagos:*

Los pagos se realizan semanalmente, todos los jueves del mes, para proporcionar una mayor regularidad y previsibilidad a los profesores.

*Cancelación de Clases:*

El explorador podrá cancelar su compra hasta 48 horas antes de iniciar la primera sesión. 
Después de la primera sesión, la cancelación de clases no será permitida, brindando estabilidad tanto a los profesores como a los estudiantes.

*Política de Devoluciones para Cancelación del Profesor:*

En caso de cancelación por parte del profesor, se reembolsará el costo de las clases restantes a los alumnos.
GurApp no asumirá el costo proporcional de la comisión por servicio de la plataforma de pago digital en caso de devoluciones, ya sea por parte del profesor o del estudiante.
    `,
  },
  {
    id: 'Gurus7',
    question: '¿Tiene algún costo publicar mis cursos en GurApp?',
    fullAnswer: 'Publicar tus clases no tiene costo. Solo cobramos una comisión sobre el valor del curso si realizas una venta. Podrás ver este porcentaje al asignar un precio a tus cursos durante el proceso de creación de tu publicación',
    showBeGuru: true,
  },
  // {
  //   id: 'Gurus8',
  //   question: '¿Dudas sobre tus impuestos?',
  //   fullAnswer: '¡No te preocupes, Gurapp te ayudará a tener todos los datos que necesitas de forma simple! \n\n' +
  //     'Para simplificar tu trabajo sobre materia de impuestos hemos creado un resumen con todas tus clases efectivamente dictadas durante un mes, considerando el monto total ganado. Lo puedes encontrar en “mis ventas”.. Además, en marzo te recordaremos sobre el pago del impuesto a la renta y dejaremos disponible en tu perfil un resumen con las ganancias del año fiscal. \n\n' +
  //     'En GurApp no somos asesores de impuestos ni tampoco somos responsables por las declaraciones de impuestos de los Gurús. Sin embargo, nos importa ayudarte en todos los ámbitos que sean necesarios y sabemos que las declaraciones de impuestos pueden ser uno de los temas más difíciles de entender. \n\n' +
  //     'Te dejamos algunas de las preguntas más comunes:',
  // },
  // {
  //   id: 'Gurus9',
  //   question: '¿Por qué declarar impuestos?',
  //   fullAnswer: 'Es importante considerar que en Chile el impuesto a la renta es obligatorio para todas las empresas y personas que llevan a cabo actividades remuneradas, sean chilenos o extranjeros. Sabemos que muchas personas prefieren no declarar todas sus obligaciones financieras, por distintos motivos. Sin embargo, te recomendamos realizar la declaración de impuesto a la renta ante el Servicio de Impuestos Internos (SII), ya que evitas problemas y puedes acceder a beneficios estatales y financieros privados. \n\n' +
  //     'Para ayudarte en este proceso, todos los meses te entregamos un resumen con las ganancias que generaste durante el periodo para que luego puedas revisar tranquilamente tus declaraciones de impuestos. \n\n' +
  //     'Para declarar tus impuestos debes haber iniciado actividades en el SII (Servicio de Impuestos Internos) y preocuparte todos los meses de pagar IVA (Impuesto al Valor Agregado) y el impuesto a la renta una vez al año en abril.',
  // },
  // {
  //   id: 'Gurus10',
  //   question: '¿Cómo presentar la Declaración de Renta?',
  //   fullAnswer: 'El sitio web del Servicio de Impuestos Internos (SII) entrega una propuesta de declaración de renta a la mayoría de los contribuyentes basada en la información que han otorgado las empresas o instituciones y agentes retenedores, o incluso tú mismo en las declaraciones mensuales de honorarios, disponible en las bases de datos del SII. \n\n' +
  //     'A partir de esta propuesta, se genera de forma automática el Formulario 22, que resume las rentas del año anterior. Sin embargo, en algunos casos el SII solo entrega una propuesta parcial, por lo que  debes completar los antecedentes que puedan faltar. \n\n' +
  //     'Debes revisar la información de las rentas, créditos, beneficios, acciones y otros que han sido informados al SII, los cuales sirven de base para confeccionar dicha propuesta. Si los datos están incompletos o erróneos, deberán ser regularizados. \n\n' +
  //     'En la propuesta entregada por el SII, si emitiste boletas de honorarios podrás optar por una base menor para el cálculo de las cotizaciones previsionales obligatorias. Para aplicar se debe ejercer este derecho antes de enviar el Formulario 22 de declaración de renta.',
  // },
  // {
  //   id: 'Gurus11',
  //   question: '¿Cómo y dónde se declara la renta?',
  //   fullAnswer: 'Online: Sitio web Servicio de Impuestos Internos (SII) \n\n' +
  //   `${bullet} Haz clic en ir al trámite en línea. \n` +
  //   `${bullet} Selecciona declarar renta.\n` +
  //   `${bullet} Escribe tu RUT y clave. Si no estás registrado, crea una cuenta en el SII.\n` +
  //   `${bullet} Selecciona al año tributario a declarar y haz clic en aceptar.\n` +
  //   `${bullet} Si la información del contribuyente es correcta y no debe ser modificada, haz clic en continuar.\n` +
  //   `${bullet} Verifica la información que aparece en pantalla o bien completa el formulario; revisa toda la información y haz clic en enviar. (Guarda una copia o imprime el documento como respaldo).\n` +
  //   `${bullet} Como resultado del trámite, habrás presentado tu declaración de renta.\n` +
  //   '\nPresencial: en oficinas del Servicio de Impuestos Internos (SII) \n\n' +
  //   `${bullet} Reúne los antecedentes mencionados en el campo documentos requeridos.\n` +
  //   `${bullet} Acércate a la oficina del SII más cercana.\n` +
  //   `${bullet} Explica el motivo de tu visita: en este caso, realizar la declaración de impuesto a la renta.\n` +
  //   `${bullet} Como resultado del trámite, habrás presentado tu declaración anual de renta.\n`,
  // },
  // {
  //   id: 'Gurus12',
  //   question: '¿Cómo saber si debes pagar el Impuesto al valor agregado (IVA)?',
  //   fullAnswer: 'Te recomendamos escoger tu actividad económica en la página del SII, lo cual debes hacer al momento de iniciar actividades. Probablemente tu actividad económica sea exenta de IVA (que no debes pagar); sin embargo, te recomendamos leer la página del SII y/o asesorarte con un experto en materia de impuestos para escoger el adecuado. \n\n' +
  //   'Recuerda que el IVA se declara/paga de forma mensual en la página del SII. \n\n' +
  //   'Si eres un profesor independiente y no declaras IVA, deberías emitir boletas de honorarios.',
  // },
  // {
  //   id: 'Gurus13',
  //   question: '¿Qué es una boleta de honorarios?',
  //   fullAnswer: 'Es un documento tributario electrónico (DTE) que sirve para respaldar pagos por servicios esporádicos de forma independiente. Las rentas por concepto de honorarios ascienden al 11,5% para el año 2021 y deben ser retenidos por quien paga la renta (en este caso tú como profesor). \n ' +
  //   'Honorario es el pago o retribución económica efectuado a quien realiza de forma independiente una labor para una empresa o persona. Se denomina honorarios puesto que quien lo realiza no mantiene una relación laboral normal con la empresa o persona a quien le presta su servicio. \n\n' +
  //   'La retención es del 12,25% del monto bruto de la boleta y, generalmente, la hace la empresa que emite. Luego, al término de un año se hace el cálculo del valor retenido, se declara en la operación renta y, si hay devolución, la Tesorería le devuelve al trabajador. \n\n' +
  //   'Puedes obtener mayor información relativa a este tema en el sitio web del SII, Servicios online, Declaración de Renta, menú Ayudas, opción Instrucciones Formulario 22 y Guía de Renta año 2021, donde encontrará las instrucciones para la confección del recuadro N° 1 de honorarios, para la línea 7 de honorarios y para la línea 78 de retenciones.',
  // },
  // {
  //   id: 'Gurus14',
  //   question: '¿Cómo iniciar actividades?',
  //   fullAnswer: 'Para declarar el impuesto a la renta y declarar el impuesto al valor agregado o emitir boletas de honorarios, debes haber iniciado actividades. En la página del SII existe una guía paso a paso para que puedas hacer tu iniciación de actividades sin problemas.',
  // },
];