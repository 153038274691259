import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import useDetails from '../../../useDetails';
import useDialog from '../../../../../../hooks/useDialog';
import SimpleChip from '../../../../../../components/chips/simple-chip/SimpleChip';
import SimpleDialog from '../../../../../../components/dialogs/SimpleDialog/SimpleDialog';
import SimpleModal from '../../../../../../components/dialogs/SimpleModal/SimpleModal';
import { EditListItem } from '../../../../../../components/inputs';
import SimpleButton from '../../../../../../components/buttons/simple-button/SimpleButton';
import useAnalytics from '../../../../../../hooks/useAnalytics/useAnalytics';
import { ImageListItem } from '../../../../../../components/inputs';
import { audiovisualsSchema } from '../../StepDetalles.schema';
import useMyPublication from '../../../../useMyPublication';

interface AudiovisualsProps {
  form: any;
  disabled?: boolean;
}

const Audiovisuals = ({ form, disabled }: AudiovisualsProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('my-publication', { keyPrefix: 'details.step-detalles.audiovisuals' });
  const { traceBtnClick } = useAnalytics();

  const { updateAuthPublication, status, error: errorUpdate } = useDetails();
  const [error, setError] = useState<any>(undefined);
  const { fetchAuthPublication } = useMyPublication();

  const { dialog, onCloseDialog } = useDialog(error || errorUpdate, 'Error');

  const localForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      audiovisuals: form.values.audiovisuals,
    },
    validationSchema: audiovisualsSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const success = await updateAuthPublication(form?.values?.id, values);
      if (!success) {
        return;
      }
      const fetchPublicationResponse = await fetchAuthPublication(form?.values?.id, ['audiovisuals']);
      form.setFieldValue('audiovisuals', fetchPublicationResponse.audiovisuals);
    },
  });

  const handleErrorMaxValues = () => {
    setError({ message: t('errorMaxValues') });
  };
  const Content = () => <SimpleChip label={`${form.values.audiovisuals?.length} imágenes`} />;

  useEffect(() => {
    if (status !== 'loaded') {
      return;
    }
    if (!error) {
      setOpen(false);
    }
  }, [status]);

  return (
    <>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      <SimpleModal
        open={open}
        onClose={() => { setOpen(false); }}
        title={t('title')}
        subtitle={t('subtitle')}
      >
        <Grid container direction="column" rowSpacing={1}>
          <Grid item  width="inherit">
            <ImageListItem
              name="audiovisuals"
              value={localForm.values.audiovisuals}
              onChange={localForm.handleChange}
              disabled={disabled}
              maxValues={5}
              onClick={() => { 
                traceBtnClick({
                  step: 'step agregar imagen audiovisual',
                  product: 'item audiovisual',
                });
              }}
              onErrorMaxValues={handleErrorMaxValues}
            />
          </Grid>
          <Grid item alignSelf="flex-end">
            <SimpleButton 
              onClick={() => { 
                traceBtnClick({
                  step: 'step guardar audiovisuals',
                  product: t('save') + 'item audiovisuals',
                });
                localForm.handleSubmit(); 
              }} 
              disabled={status === 'loading' || !localForm.dirty} 
              loading={status === 'loading'}
            >
              {t('save')}
            </SimpleButton>
          </Grid>
        </Grid>
      </SimpleModal>
      <EditListItem
        label={t('title')}
        onClick={() => { 
          traceBtnClick({
            step: 'step abrir dialogo knowledge',
            product: 'item knowledge',
          });
          setOpen(true); 
        }}
        content={form.values.audiovisuals?.length > 0 ? <Content /> : undefined}
        error={form.touched.audiovisuals && Boolean(form.errors.audiovisuals)}
        helperText={form.touched.audiovisuals && form.errors.audiovisuals}
        disabled={disabled}
      />
    </>
  );
};

Audiovisuals.displayName = 'Audiovisuals';
export default Audiovisuals;
